import ApiRequest from "./ApiRequest";

export type IUpdateTHRParam = {
  id: number;
  nama: string;
  tanggal: Date;
  nominal: number;
};

class AxiosUpdateTHR extends ApiRequest<IUpdateTHRParam> {
  constructor() {
    super("/tunjangan/edit");
  }

  post(token: string, data: IUpdateTHRParam) {
    return this.post_request({
      token: token,
      params: { ...data },
    });
  }
}

export default AxiosUpdateTHR;
