import clsx from "clsx";
import errorExclamation from "../../../vectors/errorExclamation.svg";
import IzinStyle from "./Izin.module.css";
import InputField from "../../../components/InputField";
import { useContext, useEffect, useState } from "react";
import PaginationLinks from "../../../components/PaginationLinks";
import Table from "../../../components/Table";
import { columns, Item } from "./ListIzinTableDefinitions";
import pegawaiContext from "../../../context/SelectedPegawai";
import AxiosListIzin from "../../../api/AxiosListIzin";
import userAuth from "../../../context/UserAuth";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import AxiosDeleteIzin from "../../../api/AxiosDeleteIzin";
import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ButtonTypeEnum } from "../../../enums/ButtonTypeEnum";
import { toast } from "react-toastify";
import { FileUploader } from "react-drag-drop-files";
import AxiosUploadDokumenIzin from "../../../api/AxiosUploadDokumenIzin";
import TOption from "../../../models/TOption";
import { capitalizeEachWord } from "../../../helpers/StringHelper";
import { TipeIzinEnum } from "../../../enums/TipeIzinEnum";
import DropdownField from "../../../components/DropDownField";

interface IListIzinProps {
  tanggal_mulai: [tanggal_mulai: string, setTanggalMulai: React.Dispatch<React.SetStateAction<string>>],
  tanggal_selesai: [tanggal_selesai: string, setTanggalSelesai: React.Dispatch<React.SetStateAction<string>>],
  pagination_links: [pagination_links: never[], setPaginationLinks: React.Dispatch<React.SetStateAction<never[]>>],
  selected_link: [selected_link: string, setSelectedLink: React.Dispatch<React.SetStateAction<string>>],
  watchDokumen: (id: number) => void;
}
const ListIzin: React.FC<IListIzinProps> = (props: IListIzinProps) => {
  const { user } = useContext(userAuth);
  const { pegawai } = useContext(pegawaiContext);
  const defaultData: Item[] = [];
  const [data, setData] = useState(() => [...defaultData]);
  const [tanggal_mulai, setTanggal_mulai] = props.tanggal_mulai
  const [tanggal_selesai, setTanggal_selesai] = props.tanggal_selesai
  const [pagination_links, setPaginationLinks] = props.pagination_links
  const [selected_link, setSelectedLink] = props.selected_link
  const [id_izin, setId_izin] = useState(0)
  const [action, setAction] = useState("")
  const [document, setDocument] = useState<File>();
  const [tipeOptions, setTipeOptions] = useState<TOption[]>([]);
  const [tipe, setTipe] = useState("");

  const [opened, { open, close }] = useDisclosure(false);

  function getIzin(link?: string) {
    if (!pegawai?.id) return
    if (!user.token) return
    if (!tanggal_mulai || !tanggal_selesai) return
    const axiosListIzin = new AxiosListIzin()

    axiosListIzin.get(
      user.token,
      {
        id_pegawai: parseInt(pegawai.id),
        tanggal_mulai,
        tanggal_selesai,
        tipe
      },
      link,
    ).then((response) => {
      const data_response = response.data.data.data
      data_response.map((item: any) => {
        item.onDetail = (id: number) => { props.watchDokumen(id) }
        item.onDelete = (id_izin: number) => {
          setAction("delete")
          setId_izin(id_izin)
          open()
        }
        item.onUpload = (id_izin: number) => {
          setAction("upload")
          setId_izin(id_izin)
          open()
        }
      })
      setPaginationLinks(response.data.data["links"]);
      setData(data_response)
    })
  }

  function deleteModalContent() {
    return <>
      <div className="flex justify-center">
        <img
          alt={"check"}
          src={errorExclamation}
          className={"w-24"}
        />
      </div>
      <div className={"text-4xl text-center"}>
        Yakin Hapus Izin ?
      </div>
      <div className={"mt-12 flex justify-around"}>
        <Button
          onClick={() => close()}
          text="Batal"
          size="small"
          buttonType={ButtonTypeEnum.CLICKABLE_TEXT}
          color="danger"
          className="min-w-[100px] min-h-[40px]"
        />
        <Button
          onClick={() => {

            if (!user.token) return
            const deleteIzin = new AxiosDeleteIzin();
            deleteIzin.post(user.token, { id: id_izin })
              .then(() => {
                toast.success("Berhasil delete Izin!", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
                getIzin(selected_link)
                close()
              })
          }}
          text="Hapus"
          size="small"
          className="min-w-[100px] min-h-[40px]"
        />
      </div>
    </>
  }
  function uploadModalContent() {
    return <>
      <div className={"text-4xl text-center"}>
        Upload Dokumen Izin
      </div>
      <div className={"mt-12 flex justify-around"}>
        <FileUploader
          handleChange={(doc: any) => setDocument(doc)}
          name="File"
          types={["JPG", "PNG", "PDF"]}
        />
      </div>
      <div className={"mt-12 flex justify-around"}>

        <Button
          onClick={() => close()}
          text="Batal"
          size="small"
          buttonType={ButtonTypeEnum.CLICKABLE_TEXT}
          color="danger"
          className="min-w-[100px] min-h-[40px]"
        />
        <Button
          onClick={() => {
            if (!user.token) return
            if (!document) {

              toast.error("Dokumen Harus Di isi", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              return
            }

            const axiosUploadDokumenIzin = new AxiosUploadDokumenIzin()
            axiosUploadDokumenIzin.post(
              user.token,
              {
                id_izin,
                file: document
              }
            ).then(() => {
              toast.success("Berhasil Upload Dokumen", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              getIzin()
              close()
            })

          }}
          text="Upload"
          size="small"
          className="min-w-[100px] min-h-[40px]"
        />
      </div>
    </>
  }

  function modalContent() {
    switch (action) {
      case 'delete':
        return deleteModalContent()
      case 'upload':
        return uploadModalContent()
    }
  }

  useEffect(() => getIzin(selected_link), [tanggal_mulai, tanggal_selesai, selected_link])

  useEffect(() => {
    const values = Object.values(TipeIzinEnum);
    const options: TOption[] = [];
    values.forEach((value, index) => {
        options.push({
            value: value,
            label: capitalizeEachWord(value, "_"),
            isDefault: index === 0,
        });
    });

    setTipeOptions(options);
}, []);

  return <>

    <Modal opened={opened} onClose={close} title="" radius={"md"} centered>
      {modalContent()}
    </Modal>
    <div className={clsx(IzinStyle.container, "!py-6 !px-12 !mx-[40px]")}>
      <div className="flex justify-center items-center gap-2">
        <div>
          <InputField
            type="date"
            placeholder="tgl"
            onChange={(value) => setTanggal_mulai(value)}
            className="w-52"
          />
        </div>
        S/D
        <div>
          <InputField
            type="date"
            placeholder="tgl"
            onChange={(value) => setTanggal_selesai(value)}
            className="w-52"
          />
        </div>
        <DropdownField
          name="tipe_izin"
          id="tipeizin"
          className="!border !py-[17px] !px-[31px] !rounded"
          onChange={(value) => setTipe(value)}
          value={tipe}
          options={tipeOptions}
      />
        <Button
          size="small"
          onClick={() => getIzin()}
          text="Refresh"
        />
      </div>

      <div className="flex flex-col py-[10px]">
        <div className="mx-[40px] mt-4">
          <PaginationLinks
            onPaginationClick={(link) => {
              setSelectedLink(link)
            }}
            pagination_links={pagination_links}
            className="!w-full"
          />
          {/* <Pagination value={pagination_links} onChange={setPaginationLinks} total={10} />; */}
        </div>
        <div
          className={"flex justify-center items-center bg-white rounded-s mx-5 my-10 pb-4 !pt-0 !mt-0"}
        >
          <Table<Item> data={data} columns={columns} />
        </div>
      </div>

    </div>
  </>
}
export default ListIzin;
