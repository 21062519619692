import { createColumnHelper } from "@tanstack/react-table";
import Button from "../../../components/Button";

export type Item = {
  id: number;
  waktu_mulai: string;
  waktu_selesai: string;
  periode: string;
  tanggal_pembayaran_gaji: string;
  action: () => void;
  deleteAction: () => void;
};

const columnHelper = createColumnHelper<Item>();
export const columns = [
  columnHelper.accessor("id", {
    header: () => "ID",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("periode", {
    header: () => "Periode",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("tanggal_pembayaran_gaji", {
    header: () => "Tanggal Bayar",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("id", {
    header: () => "Action",
    cell: (info) => (
      <>
        <div className="flex gap-2">
          <Button
            text="Detail"
            onClick={() => info.row.original.action()}
            size="small"
          />
          <Button
            text="Hapus"
            onClick={() => info.row.original.deleteAction()}
            size="small"
          />
        </div>
      </>
    ),
    footer: (info) => info.column.id,
  }),
];
