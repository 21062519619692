import ApiRequest from "./ApiRequest";

interface IListGajiParams {
  waktu_mulai: string;
  waktu_selesai: string;
  tipe_gaji?: string;
  id_jabatan?: number;
  id_perusahaan?: number;
  id_departemen?: number;
}

class AxiosListGaji extends ApiRequest<IListGajiParams> {
  constructor() {
    super("/payroll/list_gaji");
  }

  get(token: string, batch_param: IListGajiParams) {
    return this.get_request(token, {
      ...batch_param,
    });
  }
}

export default AxiosListGaji;
