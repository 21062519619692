import ApiRequest from "./ApiRequest";

interface IParamType {
}

class AxiosListJenisDokumen extends ApiRequest<IParamType> {
    constructor() {
        super("/list_jenis_dokumen");
    }

    get(token: string) {
        return this.get_request(token);
    }
}

export default AxiosListJenisDokumen;
