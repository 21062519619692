import ApiRequest from "./ApiRequest";

interface IParamType {}

class AxiosListKontrakSegeraBerakhir extends ApiRequest<IParamType> {
  constructor() {
    super("/find_kontrak_expiring_soon");
  }

  get(token: string) {
    return this.get_request(token);
  }
}

export default AxiosListKontrakSegeraBerakhir;
