import ApiRequest from "./ApiRequest";

interface IDeleteLemburPegawaiParams {
  id_lembur_pegawai: string;
}

class AxiosDeleteLemburPegawai extends ApiRequest<IDeleteLemburPegawaiParams> {
  constructor() {
    super("/lembur_pegawai/delete");
  }

  post(token: string, pegawai_data: IDeleteLemburPegawaiParams) {
    return this.post_request({
      token: token,
      params: { ...pegawai_data },
    });
  }
}

export default AxiosDeleteLemburPegawai;
