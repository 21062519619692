import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AxiosListPegawai from "../../../api/AxiosListPegawai";
import ContentHeader from "../../../components/ContentHeader";
import Table from "../../../components/Table";
import dashboardLoading from "../../../context/dashboardLoading";
import userAuth from "../../../context/UserAuth";
import ListPegawaiStyle from "./ListPegawai.module.css";
import {
  columns,
  constructItemFromResponse,
  Item,
} from "./ListPegawaiTableDefinitions";
import pegawaiContext from "../../../context/SelectedPegawai";
import PopUpCard from "../../../components/PopUpCard";
import CreatePegawaiPopup from "../CreatePegawaiPopup";
import Button from "../../../components/Button";
import PaginationLinks from "../../../components/PaginationLinks";
import SearchField from "../../../components/SearchField";
import clsx from "clsx";
import { toast } from "react-toastify";
import { Pagination } from "@mantine/core";
import AxiosListCreatePegawaiOptions from "../../../api/AxiosGetCreatePegawaiOptions";
import { capitalizeEachWord } from "../../../helpers/StringHelper";
import DropdownField from "../../../components/DropDownField";

interface IListPegawaiProps {
  jenis_perjanjian: string | undefined;
}

const ListPegawai: React.FC<IListPegawaiProps> = (props: IListPegawaiProps) => {
  const defaultData: Item[] = [];
  const { setIsLoading } = useContext(dashboardLoading);
  const { user } = useContext(userAuth);
  const [data, setData] = useState(() => [...defaultData]);
  const navigate = useNavigate();
  const { setPegawai } = useContext(pegawaiContext);
  const [pagination_links, setPaginationLinks] = useState([]);
  const [isPopUpCreatePegawai, setPopUpCreatePegawai] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  //filter
  const [selectedJabatan, setSelectedJabatan] = useState("");
  const [selectedPerusahaanId, setSelectedPerusahaanId] = useState(0);
  const [selectedDepartemenId, setSelectedDepartemenId] = useState(0);
  const [selectedStatus, setSelectedStatus] = useState("")

  const [jabatanOptions, setJabatanOptions] = useState([]);
  const [perusahaanOptions, setPerusahaanOptions] = useState([]);
  const [departemenOptions, setDepartemenOptions] = useState([]);
  const statusOptions = [
    { value: "aktif", label: "Aktif"},
    { value: "resign", label: "Resign"}
  ]

  let timer: any;

  useEffect(() => {
    
    getListPegawai();
  }, [
    props.jenis_perjanjian,
    selectedJabatan,
    selectedDepartemenId,
    selectedPerusahaanId,
    selectedStatus
  ]);

  useEffect(() => {
    getOptions();
  }, []);

  function onPaginationChange(link: string) {
    getListPegawai(link, searchQuery);
  }

  function onSearch(value: string) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearchQuery(value);
      getListPegawai(undefined, value);
    }, 500);
  }

  function getListPegawai(pagination_link?: string, query?: string) {
    if (user.token == null || !setPegawai) return;
    const axiosListPegawai = new AxiosListPegawai({
      jenis_perjanjian: props.jenis_perjanjian,
      id_perusahaan: selectedPerusahaanId,
      jabatan: selectedJabatan,
      id_departemen: selectedDepartemenId,
    });

    axiosListPegawai
      .get(user.token, pagination_link, query, undefined, selectedStatus)
      .then((response) => {
        const data_response = response.data.data.data;
        
        setPaginationLinks(response.data.data["links"]);
        setData(constructItemFromResponse(data_response, navigate, setPegawai));
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
      });
  }

  function getOptions() {
    if (!user?.token) return;
    const axiosGetOptions = new AxiosListCreatePegawaiOptions();

    axiosGetOptions.get(user.token).then((response) => {
      const data = response.data.data;

      const jabatanData = data.jabatan_options.map((item: any) => ({
        value: item.nama,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      setJabatanOptions(jabatanData);

      const perusahaanData = data.perusahaan_options.map((item: any) => ({
        value: item.id,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      setPerusahaanOptions(perusahaanData);

      const departemenData = data.departemen_options.map((item: any) => ({
        value: item.id,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      setDepartemenOptions(departemenData);
    });

  }

  function redirectToAddPerjanjian(jenis_perjanjian: string) {
    
    navigate(
      "/dashboard/hrd/tambah_kontrak?" + "jenis_perjanjian=" + jenis_perjanjian
    );
  }

  const popUpProps = {
    onClose: () => setPopUpCreatePegawai(false),
  };

  if (!setIsLoading) {
    
    return <></>;
  }


  return (
    <>
      <ContentHeader
        title={
          "List Pegawai " +
          (props.jenis_perjanjian === undefined ? "" : props.jenis_perjanjian)
        }
        additionalHeader={
          <>
            <div className={ListPegawaiStyle.header_container}>
              <SearchField
                placeholder="Cari nama ..."
                onChange={(value) => onSearch(value)}
              />
              {props.jenis_perjanjian === undefined ? (
                <Button
                  text="Tambah Pegawai"
                  onClick={() => navigate("/dashboard/hrd/tambah_pegawai")}
                  size="small"
                  color="accept"
                />
              ) : null}
            </div>
          </>
        }
      />

      {isPopUpCreatePegawai ? (
        <PopUpCard {...popUpProps}>
          Tambah Pegawai
          <CreatePegawaiPopup
            onSuccessCreate={(jenis_perjanjian: string) =>
              redirectToAddPerjanjian(jenis_perjanjian)
            }
          />
        </PopUpCard>
      ) : null}
      <div className="flex flex-col py-[10px]">
        <div className="mx-[40px] mt-4">
          <div className="flex">
            <div className="w-full mx-[20px] ">
              <DropdownField
                name="nama_perusahaan"
                id="nama_perusahaan"
                title="Filter Berdasarkan Perusahaan"
                options={perusahaanOptions}
                onChange={(value) => setSelectedPerusahaanId(parseInt(value))}
                value={selectedPerusahaanId.toString()}
                placeholder=""
              />
              <div className="w-full flex justify-end">
                <span
                  className="hover:cursor-pointer hover:underline text-blue-500"
                  onClick={() => setSelectedPerusahaanId(0)}
                >
                  Clear filter perusahaan
                </span>
              </div>
            </div>
            <div className="w-full mx-[20px]">
              <DropdownField
                name="nama_departemen"
                id="nama_departemen"
                title="Filter Berdasarkan departemen"
                options={departemenOptions}
                onChange={(value) => setSelectedDepartemenId(parseInt(value))}
                value={selectedDepartemenId.toString()}
                placeholder=""
              />
              <div className="w-full flex justify-end">
                <span
                  className="hover:cursor-pointer hover:underline text-blue-500"
                  onClick={() => setSelectedDepartemenId(0)}
                >
                  Clear filter jabatan
                </span>
              </div>
            </div>
            
          </div>
          
          <div className="flex">
            <div className="w-full mx-[20px] mb-4">
              <DropdownField
                name="nama_jabatan"
                id="nama_jabatan"
                title="Filter Berdasarkan Jabatan"
                options={jabatanOptions}
                onChange={(value) => setSelectedJabatan(value)}
                value={selectedJabatan}
                placeholder=""
              />
              <div className="w-full flex justify-end">
                <span
                  className="hover:cursor-pointer hover:underline text-blue-500"
                  onClick={() => setSelectedJabatan("")}
                >
                  Clear filter jabatan
                </span>
              </div>
            </div>
            
            <div className="w-full mx-[20px] mb-4">
              <DropdownField
                name="status"
                id="status"
                title="Filter Berdasarkan status"
                options={statusOptions}
                onChange={(value) => setSelectedStatus(value)}
                value={selectedStatus}
                placeholder=""
              />
              <div className="w-full flex justify-end">
                <span
                  className="hover:cursor-pointer hover:underline text-blue-500"
                  onClick={() => setSelectedStatus("")}
                >
                  Clear filter status
                </span>
              </div>
            </div>
          </div>
          
          <PaginationLinks
            onPaginationClick={(link) => onPaginationChange(link)}
            pagination_links={pagination_links}
            className="!w-full"
          />
        </div>
        <div
          className={clsx(ListPegawaiStyle.table_wrapper, "pb-4 !pt-0 !mt-0")}
        >
          <Table<Item> data={data} columns={columns} />
        </div>
      </div>
    </>
  );
};

export default ListPegawai;
