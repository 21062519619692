import ApiRequest from "./ApiRequest";

interface IParamType { }

class AxiosGetRekapPresensi extends ApiRequest<IParamType> {
  constructor() {
    super("/presensi/rekap_presensi");
  }

  get(
    token: string,
    waktu_mulai: string,
    waktu_selesai: string,
    nama_pegawai?: string,
    id_jabatan?: string,
    id_departemen?: string,
    id_perusahaan?: string,
  ) {
    return this.get_request(token, {
      waktu_mulai,
      waktu_selesai,
      nama_pegawai,
      id_jabatan,
      id_departemen,
      id_perusahaan,
    });
  }
}

export default AxiosGetRekapPresensi;
