/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import AxiosGetListSPK from "../../../api/AxiosGetListSPK";
import Button from "../../../components/Button";
import ContentHeader from "../../../components/ContentHeader";
import PopUpCard from "../../../components/PopUpCard";
import Table from "../../../components/Table";
import TimeoutPopup, {
  defaultPopUpProps,
  ITimeoutPopupProps,
} from "../../../components/TimeoutPopUp";
import userAuth from "../../../context/UserAuth";
import { columns, Item } from "./ListSPKTableDefinitions";
import ListSPKStyle from "./ListSPK.module.css";
import { ListSPKStatus } from "../../../enums/ListSPKStatus";
import SearchField from "../../../components/SearchField";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import AxiosCreateSPK from "../../../api/AxiosCreateSPK";
import AxiosCreateBorongan from "../../../api/AxiosCreateBorongan";
import InputFile from "../../../components/InputFile";
import { toast } from "react-toastify";
import { Loader } from "@mantine/core";
import InputField from "../../../components/InputField";
import { dateToString } from "../../../helpers/DateHelper";

interface IListSPKProps { }
const ListSPK: React.FC<IListSPKProps> = () => {
  const navigate = useNavigate();

  const { user } = useContext(userAuth);
  const [listSPK, setListSPK] = useState<any[]>([]);
  const [isPopUp, setIsPopUp] = useState(false);
  const [isPopUpBorongan, setIsPopUpBorongan] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingBorongan, setIsLoadingBorongan] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [startDateQuery, setQueryStart] = useState("");
  const [endDateQuery, setQueryEnd] = useState("");

  const [popUpState, setPopUpState] =
    useState<ITimeoutPopupProps>(defaultPopUpProps);

  function getListSPK() {
    if (!user?.token) return;
    const axiosGetListSPK = new AxiosGetListSPK();

    axiosGetListSPK.get(user.token, {
      search: searchQuery,
      start_date: startDateQuery,
      end_date: endDateQuery
    }).then((response) => {
      const data = response.data.data;
      const newData = data.map((item: any) => {
        return {
          id: item.id,
          noSPK: item.nomor_spk,
          name: item.nama_barang,
          dateStart: item.tanggal_mulai,
          dateEnd: item.tanggal_selesai,
          uploadedDate: item.created_at,
          status: (item.status.charAt(0).toUpperCase() +
            item.status.slice(1)) as ListSPKStatus,
          soft_deleted: false,
          action: {
            detail: () => {
              navigate("/dashboard/produksi/spk/detail", {
                state: { param: item },
              });
            },
            lembarKerjaBorongan: () => {
              navigate("/dashboard/produksi/spk/listLembarKerjaBorongan", {
                state: { param: item },
              });
            },
          },
        };
      });
      setListSPK(newData);
    });
  }

  function createSPK(uploadedFile: File | null) {
    if (!uploadedFile) {
      toast.error(`Tidak ada file untuk diunggah!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (!user?.token || !uploadedFile || isLoading === true) return;
    setIsLoading(true);

    const axiosCreateSPK = new AxiosCreateSPK();

    axiosCreateSPK
      .post(user.token, uploadedFile)
      .then(() => {
        toast.success("SPK berhasil ditambahkan!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // setPopUpState({
        //   ...popUpState,
        //   popupType: PopUpEnum.SUCCESS,
        //   message: "SPK Berhasil Ditambahkan",
        //   timeout: 1000,
        //   isEnabled: true,
        //   onTimeout() {
        //     setPopUpState({ ...popUpState, isEnabled: false });
        //   },
        // });
        setIsPopUp(false);
        setUploadedFile(null);
        getListSPK();
        setIsLoading(false);
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422 || response.status === 400)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
      })
      ;
  }

  function createBorongan(uploadedFile: File | null) {
    if (!uploadedFile) {
      toast.error(`Tidak ada file untuk diunggah!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (!user?.token || !uploadedFile || isLoadingBorongan === true) return;
    setIsLoadingBorongan(true);

    const axiosCreateBorongan = new AxiosCreateBorongan();

    axiosCreateBorongan
      .post(user.token, uploadedFile)
      .then(() => {
        toast.success("Borongan berhasil ditambahkan!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // setPopUpState({
        //   ...popUpState,
        //   popupType: PopUpEnum.SUCCESS,
        //   message: "SPK Berhasil Ditambahkan",
        //   timeout: 1000,
        //   isEnabled: true,
        //   onTimeout() {
        //     setPopUpState({ ...popUpState, isEnabled: false });
        //   },
        // });
        setIsPopUpBorongan(false);
        setUploadedFile(null);
        setIsLoadingBorongan(false);
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422 || response.status === 400)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoadingBorongan(false);
      })
      ;
  }
  const popUpCloseBorongan = {
    onClose: () => {
      setIsPopUpBorongan(false);
      setUploadedFile(null);
    },
  };

  useEffect(() => {
    getListSPK();
  }, [searchQuery, startDateQuery, endDateQuery]);

  const popUpClose = {
    onClose: () => {
      setIsPopUp(false);
      setUploadedFile(null);
    },
  };

  let timer: any;
  

  function onSearch(value: string) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearchQuery(value);
      
      // getListSPK(undefined, value);
    }, 500);
  }

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const handleFileSelect = (file: File | null) => {
    
    setUploadedFile(file);
  };

  return (
    <>
      <div className={ListSPKStyle.header_wrapper}>
        <ContentHeader
          title={"List SPK"}
          additionalHeader={
            <>
              <div className={ListSPKStyle.header_container}>
                <SearchField
                  placeholder="Cari no.spk ..."
                  onChange={(value) => onSearch(value)}
                />
                <InputField
                    type="date"
                    placeholder=""
                    onChange={(value) => setQueryStart(value)}
                  />
                  <span className="flex flex-col justify-center">s/d</span>
                  <InputField
                    type="date"
                    placeholder=""
                    onChange={(value) => setQueryEnd(value)}
                  />
                <Button
                  text="Upload SPK"
                  onClick={() => {
                    setIsPopUp(true);
                  }}
                  size="small"
                />
                <Button
                  text="Upload Laporan Hasil Kerja Borongan"
                  onClick={() => {
                    setIsPopUpBorongan(true);
                  }}
                  size="small"
                />
              </div>
            </>
          }
        />
      </div>
      <TimeoutPopup {...popUpState} />

      {isPopUp ? (
        <PopUpCard {...popUpClose} title={"Tambah SPK"}>
          <InputFile
            onFileSelect={handleFileSelect}
            title="Upload File"
            isRequired={true}
            helperText="File harus dalam format excel (.xlsx)"
          />

          <Button
            size="small"
            text={
              isLoading ? (
                <>
                  <Loader color="indigo" size="sm" />
                </>
              ) : (
                "Tambahkan SPK"
              )
            }
            isDisabled={isLoading}
            className="h-[55px]"
            onClick={() => createSPK(uploadedFile)}
          />
        </PopUpCard>
      ) : (
        <> </>
      )}

      {isPopUpBorongan ? (
        <PopUpCard {...popUpCloseBorongan} title={"Tambah Borongan"}>
          <InputFile
            onFileSelect={handleFileSelect}
            title="Upload File"
            isRequired={true}
            helperText="File harus dalam format excel (.xlsx)"
          />

          <Button
            size="small"
            text={
              isLoadingBorongan ? (
                <>
                  <Loader color="indigo" size="sm" />
                </>
              ) : (
                "Tambahkan Borongan"
              )
            }
            isDisabled={isLoadingBorongan}
            className="h-[55px]"
            onClick={() => createBorongan(uploadedFile)}
          />
        </PopUpCard>
      ) : (
        <> </>
      )}

      <div className={clsx(ListSPKStyle.table_wrapper, "pb-4")}>
        <Table<Item> data={listSPK} columns={columns} />
      </div>
    </>
  );
};
export default ListSPK;
