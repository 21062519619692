import React from "react";
import ContentHeaderStyle from "./ContentHeader.module.css";
import BackIcon from "../vectors/backIcon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import clsx from "clsx";

type FilterParameter = {
    key: string;
    value: string;
    onFilterApply: () => void;
};

interface IContentHeaderProps {
    title: string;
    subTitle?: string;
    enableBack?: boolean;
    filter?: FilterParameter;
    additionalHeader?: React.ReactNode;
    isLite?: boolean;
    className?: string;
    onBack?: () => void;
}

const ContentHeader: React.FC<IContentHeaderProps> = (
    props: IContentHeaderProps
) => {
    const navigate = useNavigate();
    const location = useLocation();
    function onBack() {
        // if (location.state.from)
        //     navigate(location.state.from, { state: location.state })
        // else
            navigate(-1);
    }

    return (
        <div
            className={
                ContentHeaderStyle.header +
                " " +
                (props.isLite ? ContentHeaderStyle.lite : "" + props.className)
            }
        >
            <div className={clsx(ContentHeaderStyle.left_part, "w-full gap-2")}>
                {props.enableBack ? (
                    <img
                        src={BackIcon}
                        className={ContentHeaderStyle.backButton}
                        onClick={() => {
                            if (props.onBack) props.onBack();
                            else onBack();
                        }}
                        alt="back"
                    />
                ) : null}
                <div
                    className={
                        props.subTitle
                            ? "flex flex-col w-full text-left"
                            : "text-left w-full"
                    }
                >
                    <div
                        className={
                            ContentHeaderStyle.title +
                            " " +
                            (props.isLite ? ContentHeaderStyle.title_lite : "") +
                            "w-full"
                        }
                    >
                        {props.title}
                    </div>
                    {props.subTitle ? (
                        <div className="text-left">{props.subTitle}</div>
                    ) : null}
                </div>
            </div>
            <div className={ContentHeaderStyle.right_part}>
                <div className={ContentHeaderStyle.filter}></div>
                {props.additionalHeader}
            </div>
        </div>
    );
};

export default ContentHeader;
