import PegawaiItemStyle from "./PegawaiItem.module.css";
import redMin from "../../../vectors/redMin.svg";
import greenPlus from "../../../vectors/greenPlus.svg";
import greyPlus from "../../../vectors/greyPlus.svg";
import { capitalizeEachWord } from "../../../helpers/StringHelper";

interface IPegawaiItemProps {
  nama: string;
  nama_jabatan: string;
  isAdd: boolean;
  onIconClick: () => void;
  isAdded?: boolean;
}
const PegawaiItem: React.FC<IPegawaiItemProps> = (props: IPegawaiItemProps) => {
  let image;

  if (props.isAdd) {
    image = (
      <img
        src={greenPlus}
        className={PegawaiItemStyle.icon}
        onClick={() => props.onIconClick()}
        alt="plus"
      />
    );
    if (props.isAdded)
      image = (
        <img
          src={greyPlus}
          className={PegawaiItemStyle.icon_grey}
          onClick={() => props.onIconClick()}
          alt="plus"
        />
      );
  } else
    image = (
      <img
        src={redMin}
        className={PegawaiItemStyle.icon}
        onClick={() => props.onIconClick()}
        alt="min"
      />
    );

  return (
    <div className={PegawaiItemStyle.item}>
      <div className={PegawaiItemStyle.image}></div>
      <div className={PegawaiItemStyle.info}>
        <div className={PegawaiItemStyle.nama}>{props.nama}</div>
        <div className={PegawaiItemStyle.tipe}>
          {props.nama_jabatan
            ? capitalizeEachWord(props.nama_jabatan, "_")
            : null}
        </div>
      </div>
      <div className={PegawaiItemStyle.iconButton}>{image}</div>
    </div>
  );
};
export default PegawaiItem;
