/* eslint-disable react-hooks/exhaustive-deps */
import { ColumnDef } from "@tanstack/react-table";
import React, { useContext, useEffect, useState } from "react";
import AxiosGetCalendar from "../../../api/AxiosGetCalendar";
import AxiosGetRekapPresensi from "../../../api/AxiosGetRekapPresensi";
import ContentHeader from "../../../components/ContentHeader";
import InputField from "../../../components/InputField";
import Table from "../../../components/Table";
import userAuth from "../../../context/UserAuth";
import { bulanTahunIndonesia, dateToString, flipStringDate } from "../../../helpers/DateHelper";
import { calculatePrensi } from "../../../helpers/PresensiHelper";
import BulananStyle from "./Bulanan.module.css";
import { columns, Item } from "./BulananTableDefinitions";
import SearchField from "../../../components/SearchField";
import clsx from "clsx";
import { toast } from "react-toastify";
import AxiosSyncAbsensi from "../../../api/AxiosSyncAbsensi";
import Button from "../../../components/Button";
import AxiosGetJabatan from "../../../api/AxiosGetJabatan";
import DropdownField from "../../../components/DropDownField";
import TOption from "../../../models/TOption";
import { capitalizeEachWord } from "../../../helpers/StringHelper";
import { Loader } from "@mantine/core";
import pegawaiContext from "../../../context/SelectedPegawai";
import GeneralContext from "../../../context/generalContext";
import { useNavigate } from "react-router-dom";
import AxiosListCreatePegawaiOptions from "../../../api/AxiosGetCreatePegawaiOptions";
import AxiosExportRekapPresensi from "../../../api/AxiosExportRekapPresensi";

interface IHarianProps { }

// type Recap = {
//   hadir: number;
//   alpha: number;
//   izin: number;
//   sakit: number;
// };

const Bulanan: React.FC<IHarianProps> = () => {
  const { user } = useContext(userAuth);
  const { setPegawai } = useContext(pegawaiContext);
  const { generalContext, setGeneralContext } = useContext(GeneralContext);
  const [data, setData] = useState<Item[]>([]);
  const [queryStart, setQueryStart] = useState("");
  const [queryEnd, setQueryEnd] = useState("");
  const [columDef, setColumnDef] = useState<ColumnDef<Item, any>[]>([]);
  const [kalender, setKalender] = useState<{ tipe: string; tanggal: string }[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingNew, setIsLoadingNew] = useState<boolean>(false);

  const [ITEMS_PER_PAGE, SET_ITEMS_PER_PAGE] = useState(15);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(0);
  const navigate = useNavigate();
  const [pegawaiName, setPegawaiName] = useState<string>("");

  //filter
  const [selectedJabatanId, setSelectedJabatanId] = useState<string>("");
  const [selectedPerusahaanId, setSelectedPerusahaanId] = useState<string>("");
  const [selectedDepartemenId, setSelectedDepartemenId] = useState<string>("");

  const [jabatanOptions, setJabatanOptions] = useState([]);
  const [perusahaanOptions, setPerusahaanOptions] = useState([]);
  const [departemenOptions, setDepartemenOptions] = useState([]);

  useEffect(() => {
    setTotalPages(Math.ceil(data.length / ITEMS_PER_PAGE));
  }, [data]);

  const paginatedData = data.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const handlePageChange = (newPage: number) => {
    if (newPage < 1 || newPage > totalPages) return;
    setCurrentPage(newPage);
  };

  function getRekap(query?: string, namaPegawai?: string) {
    if (!user.token) return;
    if (!queryStart || !queryEnd) return;

    const axiosGetRekap = new AxiosGetRekapPresensi();
    axiosGetRekap
      .get(
        user.token,
        queryStart,
        queryEnd,
        namaPegawai,
        selectedJabatanId,
        selectedDepartemenId,
        selectedPerusahaanId,
      )
      .then((response) => {
        const data = response.data.data;
        if (!data) {
          setData([]);
          return;
        }

        const newList: Item[] = [];
        Object.entries(data).forEach((value: any, key) => {
          const detail = value[1];
          const { presensiArray, recap } = calculatePrensi(
            detail.presensi,
            new Date(queryStart),
            new Date(queryEnd),
          );

          if (!presensiArray || !recap) return;
          newList.push({
            id: detail.id,
            nama: detail.nama,
            presensi: presensiArray,
            status_absen: recap,
            action: () => {
              if (!setPegawai || !setGeneralContext) return;
              setPegawai({
                id: detail.id,
                name: detail.nama,
              });

              setGeneralContext({
                waktu_mulai: queryStart,
                waktu_selesai: queryEnd,
              });
              navigate("/dashboard/absensi/detail");
            },
          });
        });
        setData([...newList]);
        setColumnDef(columns(createHeader()));
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
      });
  }
  useEffect(() => {
    if (!queryStart || !queryEnd) return;
    getKalender();
    getRekap();
  }, [queryStart, queryEnd, selectedJabatanId, selectedPerusahaanId, selectedDepartemenId]);
  useEffect(() => {
    if (!generalContext?.waktu_mulai || !generalContext.waktu_selesai) return
    setQueryStart(generalContext.waktu_mulai)
    setQueryEnd(generalContext.waktu_selesai)
  }, []);

  function getKalender() {
    if (!user.token) return;
    if (!queryStart || !queryEnd) return;

    const axiosGetKalender = new AxiosGetCalendar();

    axiosGetKalender.get(user.token, new Date(queryStart)).then((response) => {
      const data = response.data.data;
      setKalender(data);
    });
  }

  function createHeader() {
    if (!queryStart || !queryEnd) return;
    let tanggalCounter = new Date(queryStart);
    const dateElements = [];

    let deltaDay = new Date(queryEnd).getTime() - new Date(queryStart).getTime();
    let dayCount = Math.floor(deltaDay / (1000 * 3600 * 24));

    for (let i = 0; i < dayCount + 1; i++) {
      if (tanggalCounter.getDay() === 0)
        dateElements.push(<div className={BulananStyle.cell}>M</div>);
      else
        dateElements.push(
          <div className={BulananStyle.cell}>{tanggalCounter.getDate()}</div>
        );
      tanggalCounter.setDate(tanggalCounter.getDate() + 1);
    }

    return (
      <div className={BulananStyle.status_header}>
        <div>{bulanTahunIndonesia(new Date(queryStart))}</div>
        <div className={BulananStyle.status}>{dateElements}</div>
      </div>
    );
  }


  const handleDownloadExcel = () => {
    if (isLoading === true) return;
    if (!queryStart || !queryEnd) {
      toast.error(`Periode Harus Di isi`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return
    }
    setIsLoading(true);
    window.open(
      `${process.env.REACT_APP_BACKEND_PATH}/presensi/download?waktu_mulai=${queryStart}&waktu_selesai=${queryEnd}&jabatan_id${selectedJabatanId}&perusahaan_id=${selectedPerusahaanId}&departemen_id=${selectedDepartemenId}`,
      "_blank"
    );
    setIsLoading(false);
  };

  const handleDownloadExcelNew = () => {
    if (isLoadingNew === true) return;
    if (!queryStart || !queryEnd) {
      toast.error(`Periode Harus Di isi`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return
    }
    
    if (!user?.token) return;
  
    const axiosExportRekapPresensi = new AxiosExportRekapPresensi();
    setIsLoadingNew(true);

    axiosExportRekapPresensi
      .fetch(user.token, {
        id_perusahaan: selectedPerusahaanId,
        id_departemen: selectedDepartemenId,
        id_jabatan: selectedJabatanId,
        id_pegawai: "",
        nama: pegawaiName,
        start_date: queryStart,
        end_date: queryEnd,
        action: "export",
        source: "",
        export_type: "vertical"
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            "Rekap Absensi " + pegawaiName + " Periode " + flipStringDate(queryStart) + " - " + flipStringDate(queryEnd) + ".xlsx"
        );
        document.body.appendChild(link);
        link.click();
        setIsLoadingNew(false);
      })

    
  };

  useEffect(() => {
    getOptions()
  }, []);

  function getOptions() {
    if (!user?.token) return;
    const axiosGetOptions = new AxiosListCreatePegawaiOptions();

    axiosGetOptions.get(user.token).then((response) => {
      const data = response.data.data;

      const jabatanData = data.jabatan_options.map((item: any) => ({
        value: item.id,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      jabatanData.unshift({ value: 0, label: "No Filter" })
      setJabatanOptions(jabatanData);
      setSelectedJabatanId("")

      const perusahaanData = data.perusahaan_options.map((item: any) => ({
        value: item.id,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      perusahaanData.unshift({ value: 0, label: "No Filter" })
      setPerusahaanOptions(perusahaanData);
      setSelectedPerusahaanId("")

      const departemenData = data.departemen_options.map((item: any) => ({
        value: item.id,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      departemenData.unshift({ value: 0, label: "No Filter" })
      setDepartemenOptions(departemenData);
      setSelectedDepartemenId("")
    });

  }

  const [jabatan, setJabatan] = useState("");

  return (
    <>
      <ContentHeader
        title={"Absensi Bulanan"}
        additionalHeader={
          <>
            <div className="flex gap-2">
              <div className="flex gap-2">
                <InputField
                  type="text"
                  placeholder="Nama Pegawai..."
                  onChange={(value) => {
                    setPegawaiName(value);
                    getRekap(undefined, value);
                  }}
                  className=""
                />

                <InputField
                  type="date"
                  placeholder=""
                  onChange={(e) => {
                    if (!setGeneralContext) return
                    setQueryStart(e);
                    setGeneralContext({
                      waktu_mulai: e,
                      waktu_selesai: queryEnd,
                    });
                  }}
                  value={queryStart}
                />
                <span className="flex flex-col justify-center">s/d</span>
                <InputField
                  type="date"
                  placeholder=""
                  onChange={(e) => {
                    if (!setGeneralContext) return
                    setQueryEnd(e);
                    setGeneralContext({
                      waktu_mulai: queryStart,
                      waktu_selesai: e,
                    });
                  }}
                  value={queryEnd}
                />
              </div>

              <DropdownField
                id="jabatan"
                name="jabatan"
                className="!border !py-[17px] !px-[31px] !rounded !min-w-[200px]"
                options={jabatanOptions}
                onChange={(value) => {
                  setSelectedJabatanId(value);
                }}
                placeholder="Filter Jabatan"
                value={selectedJabatanId.toString()}
              />
              <DropdownField
                id="departemen"
                name="departemen"
                className="!border !py-[17px] !px-[31px] !rounded !min-w-[200px]"
                options={departemenOptions}
                onChange={(value) => {
                  setSelectedDepartemenId(value);
                }}
                placeholder="Filter Departemen"
                value={selectedDepartemenId.toString()}
              />
              <DropdownField
                id="perusahaan"
                name="perusahaan"
                className="!border !py-[17px] !px-[31px] !rounded !min-w-[200px]"
                options={perusahaanOptions}
                onChange={(value) => {
                  setSelectedPerusahaanId(value);
                }}
                placeholder="Filter Perusahaan"
                value={selectedPerusahaanId.toString()}
              />

              <Button
                text={
                  isLoading ? (<> <Loader color="indigo" size="sm" /> </>  ) : ( "Download Excell")
                }
                isDisabled={isLoading}
                onClick={() => {
                  handleDownloadExcel();
                }}
                size="small"
              />
              <Button
                text={ isLoadingNew ? ( <>  <Loader color="indigo" size="sm" /> </> ) : ( "Download New Format" )
                }
                isDisabled={isLoadingNew}
                onClick={() => {
                  handleDownloadExcelNew();
                }}
                size="small"
              />
            </div>
          </>
        }
      />

      {data.length > 0 ? (
        <div className="flex items-center justify-center space-x-1 mb-2">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            className="rounded-md cursor-pointer px-3 py-1 mx-1 bg-white text-blue-500 hover:bg-gray-100 border"
            disabled={currentPage === 1}
          >
            &lt;
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`rounded-md cursor-pointer px-3 py-1 mx-1 ${index + 1 === currentPage
                ? "bg-blue-700 text-white"
                : "bg-white text-blue-500 hover:bg-gray-100 border"
                } `}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            className="rounded-md cursor-pointer px-3 py-1 mx-1 bg-white text-blue-500 hover:bg-gray-100 border"
            disabled={currentPage === totalPages}
          >
            &gt;
          </button>
        </div>
      ) : null}
      <div className={clsx(BulananStyle.table_wrapper, "pb-4")}>
        <Table<Item> data={paginatedData} columns={columDef} />
      </div>
      <div className="text-red-500 mt-2 mb-[40px] text-left mx-[40px]">
        *Wajib memasukkan tanggal!
      </div>
    </>
  );
};

export default Bulanan;
