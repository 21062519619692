import { useContext, useEffect, useState } from "react";
import AxiosCreateAbsen from "../../../api/AxiosCreateAbsen";
import errorExclamation from "../../../vectors/errorExclamation.svg";
import Button from "../../../components/Button";
import ContentHeader from "../../../components/ContentHeader";
import InputField from "../../../components/InputField";
import TimeoutPopup, {
    defaultPopUpProps,
} from "../../../components/TimeoutPopUp";
import pegawaiContext from "../../../context/SelectedPegawai";
import userAuth from "../../../context/UserAuth";
import { completeTime } from "../../../helpers/DateHelper";
import AbsensiManualStyle from "./AbsensiManual.module.css";
import clsx from "clsx";
import { toast } from "react-toastify";
import { Loader, Modal } from "@mantine/core";
import AxiosListEditableLog from "../../../api/AxiosListEditableLog";
import Table from "../../../components/Table";
import { columns, Item } from "./AbsensiManualTableDefinitions";
import { useDisclosure } from "@mantine/hooks";
import AxiosDeleteEditableLog from "../../../api/AxiosDeleteEditableLog";
import { ButtonTypeEnum } from "../../../enums/ButtonTypeEnum";

interface IAbsensiManualProps { }
const AbsensiManual: React.FC<IAbsensiManualProps> = () => {
    const { user } = useContext(userAuth);
    const { pegawai } = useContext(pegawaiContext);
    const [tanggalMasuk, setTanggalMasuk] = useState("");
    const [waktuMasuk, setWaktuMasuk] = useState("");

    const [popUpProps, setPopUpProps] = useState(defaultPopUpProps);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [data, setData] = useState<Item[]>([])
    const [opened, { open, close }] = useDisclosure(false);
    const [id_presensi, setId_presensi] = useState(0)

    function getEditableLog() {
        if (!user?.token || !pegawai?.id) return;
        if (!tanggalMasuk) return

        const axiosListEditableLog = new AxiosListEditableLog();
        axiosListEditableLog.get(user.token, {
            tanggal: tanggalMasuk,
            id_pegawai: parseInt(pegawai.id)
        }).then((response) => {
            const data = response.data.data
            data.map((obj: Item) => {
                obj.onDelete = (id_presensi: number) => {
                    setId_presensi(id_presensi)
                    open()
                }
            })
            setData(data)

        });
    }

    function createAbsen() {
        if (!user?.token || !pegawai?.id || isLoading === true) return;
        setIsLoading(true);

        const totalTanggal = tanggalMasuk + " " + completeTime(waktuMasuk);
        const axiosCreateAbsen = new AxiosCreateAbsen();

        axiosCreateAbsen
            .post(user.token, {
                tanggal: totalTanggal,
                id_pegawai: pegawai.id,
            })
            .then((response) => {
                const data = response.data;
                if (data.success) {
                    toast.success("Berhasil menambahkan absensi!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    getEditableLog()
                    setIsLoading(false);
                }
            })
            .catch((e) => {
                const response = e.response;
                if (response.status === 422)
                    toast.error(`${response.data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                setIsLoading(false);
            });
    }

    useEffect(() => {
        getEditableLog()
    }, [tanggalMasuk])
    return (
        <div
            className={clsx(AbsensiManualStyle.container, "!py-6 !px-12 !mx-[40px]")}
        >
            <Modal opened={opened} onClose={close} title="" radius={"md"} centered>

                <div className="flex justify-center">
                    <img
                        alt={"check"}
                        src={errorExclamation}
                        className={"w-24"}
                    />
                </div>
                <div className={"text-4xl text-center"}>
                    Yakin Hapus Log ?
                </div>
                <div className={"mt-12 flex justify-around"}>
                    <Button
                        onClick={() => close()}
                        text="Batal"
                        size="small"
                        buttonType={ButtonTypeEnum.CLICKABLE_TEXT}
                        color="danger"
                        className="min-w-[100px] min-h-[40px]"
                    />
                    <Button
                        onClick={() => {

                            if (!user?.token) return;
                            const axiosDeleteEditableLog = new AxiosDeleteEditableLog();
                            axiosDeleteEditableLog.post(user.token,
                                { id: id_presensi }
                            ).then(() => {

                                toast.success("Berhasil menghapus absensi!", {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                });
                                close()
                                getEditableLog()

                            })
                        }}
                        text="Hapus"
                        size="small"
                        className="min-w-[100px] min-h-[40px]"
                    />
                </div>
            </Modal>

            <TimeoutPopup {...popUpProps} />
            <ContentHeader
                title={"Input Absensi " + pegawai?.name}
                className="!p-0"
            />
            <div className="w-full flex flex-col gap-1 text-left">
                <div>
                    <span>Waktu</span>
                    <span className="text-red-500">*</span>
                </div>
                <div className="w-full flex gap-2">
                    <InputField
                        type="date"
                        onChange={(value) => setTanggalMasuk(value)}
                        defaultValue={tanggalMasuk}
                        placeholder="Masukkan Tanggal Selesai"
                    />
                    <InputField
                        type="time"
                        onChange={(value) => setWaktuMasuk(value)}
                        defaultValue={waktuMasuk}
                        placeholder="Masukkan Jam Selesai"
                    />
                </div>
            </div>

            <Button
                text={
                    isLoading ? <Loader color="indigo" size="sm" /> : "Tambah Absensi"
                }
                isDisabled={isLoading}
                onClick={() => createAbsen()}
                size="small"
                className="mt-2 h-[55px] min-w-[200px]"
            />
            <div
                className={"flex justify-center items-center bg-white rounded-s mx-5 my-10 pb-4 !pt-0 !mt-0"}
            >
                <Table<Item> data={data} columns={columns} />
            </div>
        </div>
    );
};
export default AbsensiManual;
