import ApiRequest from "./ApiRequest";

interface ICreateAbsen {
    tanggal: string,
    id_pegawai: string
}

class AxiosCreateAbsen extends ApiRequest<ICreateAbsen> {
    constructor() {
        super("/presensi/create");
    }

    post(token: string, absen_data: ICreateAbsen) {
        
        return this.post_request({
            token: token,
            params: { ...absen_data }
        });
    }
}

export default AxiosCreateAbsen;
