import ApiRequest from "./ApiRequest";

interface IParamType {
    id_pegawai: number,
    tanggal_mulai: string,
    tanggal_selesai: string,
    tipe: string,
    page?: number,

}

class AxiosListIzin extends ApiRequest<IParamType> {
    constructor() {
        super("/izin/list");
    }

    get(
        token: string,
        param: IParamType,
        pagination?: string,
    ) {
        if (pagination) this.path = pagination;
        return this.get_request(token,
            { ...param }
        );
    }
}

export default AxiosListIzin;
