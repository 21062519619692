/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContentHeader from "../../../components/ContentHeader";
import Table from "../../../components/Table";
import dashboardLoading from "../../../context/dashboardLoading";
import userAuth from "../../../context/UserAuth";
import selectedPegawai from "../../../context/SelectedPegawai";
import ListKontrakStyle from "./MissingPegawai.module.css";
import { columns, Item } from "./MissingPegawaiTableDefinitions";
import AxiosListKontrakSegeraBerakhir from "../../../api/AxiosListKontrakSegeraBerakhir";
import pegawaiContext from "../../../context/SelectedPegawai";
import AxiosGetListMissingPegawai from "../../../api/AxiosGetListMissingPegawai";
import AxiosCreateMissingPegawai from "../../../api/AxiosCreateMissingPegawai";
import { toast } from "react-toastify";

type KontrakResponse = {
  id: number;
  id_finpro: number;
  pin_finpro: string;
  Finpro: string;
};

const MissingPegawai = () => {
  const defaultData: Item[] = [];
  const { setIsLoading } = useContext(dashboardLoading);
  const { setPegawai } = useContext(pegawaiContext);
  const { user } = useContext(userAuth);
  const [data, setData] = useState(() => [...defaultData]);
  const navigate = useNavigate();

  const handleAddPegawai = async (id_finpro: string, nama: string) => {
    if (!user.token) return;
    const axiosCreateMissingPegawai = new AxiosCreateMissingPegawai();

    try {
      await axiosCreateMissingPegawai
        .post(user.token, id_finpro)
        .then((response) => {
          if (response.status === 200) {
            if (!setPegawai) {
              toast.error(`Gagal menambahkan pegawai`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              return;
            }
            setPegawai({
              id: id_finpro,
              name: nama,
            });
          }
          toast.success("Pegawai berhasil ditambahkan!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          getMissingPegawai()
        })
        .catch((e) => {
          const response = e.response;
          if (response.status === 422)
            toast.error(`${response.data.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
        });

    } catch (error) {
      console.error("Error adding pegawai", error);
    }
  };

  const constructItemFromResponse = (data_response: KontrakResponse[]) => {
    const newItems: Item[] = [];
    let i = 1;

    data_response.forEach((data: KontrakResponse) => {
      const item: Item = {
        id: i,
        nama_pegawai: data.Finpro,
        pin_finpro: data.id_finpro.toString(),
        action_id: data.id_finpro,
        action: (id_finpro, nama) => handleAddPegawai(id_finpro, nama),
      };
      i++;
      newItems.push(item);
    });

    return newItems;
  };

  function getMissingPegawai() {
    if (!user?.token || !setPegawai) return;
    const axiosListMissingPegawai = new AxiosGetListMissingPegawai();
    axiosListMissingPegawai
      .get(user.token)
      .then((response) => {
        const data = response.data.data;
        if (!data) return setData([]);
        setData(constructItemFromResponse(data));
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
      });

  }

  useEffect(() => {
    getMissingPegawai()
  }, [user, setPegawai]);

  if (!setIsLoading) {
    
    return <></>;
  }

  return (
    <>
      <ContentHeader
        title={"List Missing Pegawai"}
        enableBack={false}
        className="mb-2"
        additionalHeader={<div className="flex gap-2"></div>}
      />

      <div className={ListKontrakStyle.table_wrapper}>
        <Table<Item> data={data} columns={columns} />
      </div>
    </>
  );
};

export default MissingPegawai;
