import ApiRequest from "./ApiRequest";

interface IDeleteKiriman {
    id: string;
}

class AxiosDeleteKiriman extends ApiRequest<IDeleteKiriman> {
    constructor() {
        super("/kiriman/delete");
    }

    post(token: string, absen_data: IDeleteKiriman) {
        return this.post_request({
            token: token,
            params: { ...absen_data }
        });
    }
}

export default AxiosDeleteKiriman;
