import ApiRequest from "./ApiRequest";

interface ICreateIzin {
    id:number,
}

class AxiosDeleteSPK extends ApiRequest<ICreateIzin> {
    constructor() {
        super("/produksi/delete_spk");
    }

    post(token: string, izin_data: ICreateIzin) {
        return this.post_request({
            token: token,
            params: { ...izin_data }
        });
    }
}

export default AxiosDeleteSPK;
