import ApiRequest from "./ApiRequest";

interface ICreateJatahCuti {
    id_pegawai: string;
    jatah_mulai: string;
    jatah_akhir: string;
    jumlah: number;
}

class AxiosCreateJatahCuti extends ApiRequest<ICreateJatahCuti> {
    constructor() {
        super("/jatah_cuti/create");
    }

    post(token: string, absen_data: ICreateJatahCuti) {
        
        return this.post_request({
            token: token,
            params: { ...absen_data }
        });
    }
}

export default AxiosCreateJatahCuti;
