
import ApiRequest from "./ApiRequest";

interface ICreateDepartemen {
    nama: string
}

class AxiosCreateDepartemen extends ApiRequest<ICreateDepartemen> {
    constructor() {
        super("/departemen/create");
    }

    post(token: string, departemen_data: ICreateDepartemen) {
        return this.post_request({
            token: token,
            params: { ...departemen_data }
        });
    }
}

export default AxiosCreateDepartemen;
