import { createContext, Dispatch, SetStateAction } from 'react'

export type General = {
  waktu_mulai?: string,
  waktu_selesai?: string,
  id_pegawai?: number,
}

interface GeneralContextType {
  generalContext?: General,
  setGeneralContext?: Dispatch<SetStateAction<General | undefined>>
}
const generalContext = createContext<GeneralContextType>({})

export default generalContext;
