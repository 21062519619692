import ApiRequest from "./ApiRequest";

interface IDeletePotonganParams {
  id: string;
}

class AxiosDeletePotongan extends ApiRequest<IDeletePotonganParams> {
  constructor() {
    super("/potongan/delete");
  }

  post(token: string, pegawai_data: IDeletePotonganParams) {
    return this.post_request({
      token: token,
      params: { ...pegawai_data },
    });
  }
}

export default AxiosDeletePotongan;
