// testing comment
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import DashboardStyle from "./dashboard.module.css";
import "../index.css";
import ReactLoading from "react-loading";
import dashboardLoading from "../context/dashboardLoading";
import pegawaiContext from "../context/SelectedPegawai";
import GeneralContext, { General } from "../context/generalContext";
import Panel from "./Panel";
import userAuth from "../context/UserAuth";
import ListPegawai from "./hrd/ListPegawai/ListPegawai";
import DetailDokumen from "./hrd/DetailDokumen";
import { motion } from "framer-motion";
import DetailPegawai from "./hrd/DetailPegawai/DetailPegawai";
import { Pegawai } from "../context/SelectedPegawai";
import TambahKontrak from "./hrd/TambahKontrak/TambahKontrak";
import ListKontrak from "./hrd/ListKontrak/ListKontrak";
import Harian from "./absensi/Harian/Harian";
import TambahPegawai from "./hrd/TambahPegawai/TambahPegawai";
import Bulanan from "./absensi/Bulanan/Bulanan";
import Mingguan from "./absensi/Mingguan/Mingguan";
import Kalender from "./absensi/Kalender/Kalender";
import InputAbsensi from "./absensi/InputAbsensi";
import InputAbsensiDetail from "./absensi/InputAbsensiDetail";
import THR from "./absensi/THR/THR";
import Lembur from "./absensi/Lembur/Lembur";
import TambahLembur from "./absensi/Lembur/TambahLembur";
import AbsensiDetail from "./absensi/AbsensiDetail/AbsensiDetail";
import ListMaster from "./master/ListDepartemen";
import ListJabatan from "./master/ListJabatan";
import DetailLembur from "./absensi/Lembur/DetailLembur";
import SlipGaji from "./payroll/SlipGaji";
import LaporanSlipGaji from "./payroll/LaporanSlipGaji/LaporanSlipGaji";
import DetailLaporan from "./payroll/DetailLaporan/DetailLaporan";
import ListSPK from "./produksi/ListSPK/ListSPK";
import ListLembarKerjaBorongan from "./produksi/ListBorongan/ListBorongan";
import DetailSPK from "./produksi/ListSPK/DetailSPK/DetailSPK";
import CreateLembarKerjaBorongan from "./produksi/ListBorongan/CreateBorongan";
import DetailBorongan from "./produksi/ListBorongan/DetailBorongan/DetailBorongan";
import RiwayatPegawai from "./produksi/RiwayatPegawai/RiwayatPegawai";
import GajiPokok from "./payroll/GajiPokok/GajiPokok";
import clsx from "clsx";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import ListKontrakSegeraBerakhir from "./hrd/ListKontrakSegeraBerakhir/ListKontrakSegeraBerakhir";
import MissingPegawai from "./hrd/MissingPegawai/MissingPegawai";
import ListCuti from "./absensi/Cuti/ListCuti";
import ListKiriman from "./absensi/Kiriman/ListKiriman";
import ListLogPegawai from "./hrd/ListLogPegawai/ListLogPegawai";
import Insentive from "./payroll/Insentive/Insentive";
import BiayaTambahan from "./payroll/BiayaTambahan/BiayaTambahan";
import Potongan from "./payroll/Potongan/Potongan";
import LemburPegawai from "./absensi/Lembur/LemburPegawai/LemburPegawai";
import ListUsers from "./master/ListUsers/ListUsers";
import Monitoring from "./absensi/Monitoring/Monitoring";
import ListRekapIzin from "./reporting/RekapIzin/ListRekapIzin";

interface IDashboardProps { }

const Dashboard: React.FC<IDashboardProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [pegawai, setPegawai] = useState<Pegawai>();
  const [generalContext, setGeneralContext] = useState<General>();
  const { user } = useContext(userAuth);
  const navigate = useNavigate();
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  useEffect(() => {
    if (user.token === undefined) navigate("/", { replace: true });
  }, []);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      exit={{ opacity: 0 }}
    >
      <div className={clsx(DashboardStyle.dashboard, "!flex")}>
        <div className="shrink-0 sticky left-0 flex">
          {isSidebarVisible && (
            <div className={clsx("")}>
              <Panel />
            </div>
          )}
          <button
            onClick={toggleSidebar}
            className={clsx(
              DashboardStyle.dashboard_toggle,
              "z-10 right-0 bg-white rounded-md text-[#79818e]"
            )}
          >
            {isSidebarVisible ? (
              <IoIosArrowBack className="w-8 h-8" />
            ) : (
              <IoIosArrowForward className="w-8 h-8" />
            )}
          </button>
        </div>

        <div
          className={clsx(DashboardStyle.dashboard_content, "!overflow-x-auto")}
        >
          <dashboardLoading.Provider value={{ isLoading, setIsLoading }}>
            {isLoading ? (
              <div className={DashboardStyle.dashboard_loader}>
                <ReactLoading
                  type="bubbles"
                  color="#2d61d6"
                  height="128px"
                  width="128px"
                />
              </div>
            ) : (
              <pegawaiContext.Provider value={{ pegawai, setPegawai }}>
                <GeneralContext.Provider
                  value={{ generalContext, setGeneralContext }}
                >
                  <Routes>
                    <Route path="/master/users" element={<ListUsers />} />
                    <Route path="/master/departemen" element={<ListMaster />} />
                    <Route path="/master/jabatan" element={<ListJabatan />} />

                    <Route path="/produksi/spk" element={<ListSPK />} />
                    <Route
                      path="/produksi/spk/detail"
                      element={<DetailSPK />}
                    />

                    <Route
                      path="/produksi/spk/listLembarKerjaBorongan"
                      element={<ListLembarKerjaBorongan noSPK={""} />}
                    />
                    <Route
                      path="/produksi/spk/listLembarKerjaBorongan/detailLembarKerjaBorongan"
                      element={<DetailBorongan />}
                    />
                    <Route
                      path="/produksi/spk/listLembarKerjaBorongan/createLembarKerjaBorongan"
                      element={<CreateLembarKerjaBorongan />}
                    />

                    <Route
                      path="/produksi/riwayat_pegawai"
                      element={<RiwayatPegawai />}
                    />

                    <Route
                      path="/hrd/list_pegawai"
                      element={<ListPegawai jenis_perjanjian={undefined} />}
                    />
                    <Route
                      path="/hrd/tambah_pegawai"
                      element={<TambahPegawai />}
                    />

                    <Route
                      path="/hrd/riwayat_update_pegawai"
                      element={<ListLogPegawai />}
                    />

                    <Route
                      path="/hrd/pkwt"
                      element={<ListPegawai jenis_perjanjian="PKWT" />}
                    />
                    <Route
                      path="/hrd/kontrak_PKWTT"
                      element={<ListPegawai jenis_perjanjian="PKWTT" />}
                    />
                    <Route
                      path="/hrd/pkkb"
                      element={<ListPegawai jenis_perjanjian="PKKB" />}
                    />
                    <Route
                      path="/hrd/tambah_kontrak/*"
                      element={<TambahKontrak />}
                    />
                    <Route
                      path="/hrd/list_kontrak/*"
                      element={<ListKontrak />}
                    />
                    <Route
                      path="/hrd/list_kontrak/segeraBerakhir"
                      element={<ListKontrakSegeraBerakhir />}
                    />

                    <Route
                      path="/hrd/missingPegawai"
                      element={<MissingPegawai />}
                    />

                    <Route path="/absensi/harian" element={<Harian />} />
                    <Route path="/absensi/detail" element={<AbsensiDetail />} />
                    <Route path="/absensi/mingguan" element={<Mingguan />} />
                    <Route path="/absensi/bulanan" element={<Bulanan />} />
                    <Route path="/absensi/kalender" element={<Kalender />} />
                    <Route path="/absensi/input" element={<InputAbsensi />} />
                    <Route
                      path="/absensi/inputabsensidetail"
                      element={<InputAbsensiDetail />}
                    />
                    <Route path="/absensi/thr" element={<THR />} />
                    <Route path="/absensi/lembur_perintah" element={<Lembur />} />
                    <Route path="/absensi/lembur_pegawai" element={<LemburPegawai />} />
                    <Route
                      path="/absensi/lembur/detail/:id"
                      element={<DetailLembur />}
                    />
                    <Route
                      path="/absensi/tambahlembur"
                      element={<TambahLembur />}
                    />

                    <Route path="/absensi/cuti" element={<ListCuti />} />
                    <Route path="/absensi/kiriman" element={<ListKiriman />} />
                    <Route path="/absensi/monitoring" element={<Monitoring />} />

                    <Route path="/detail/dokumen" element={<DetailDokumen />} />
                    <Route path="/detail/pegawai" element={<DetailPegawai />} />

                    <Route path="/payroll/slip" element={<SlipGaji />} />
                    <Route
                      path="/payroll/laporan"
                      element={<LaporanSlipGaji />}
                    />
                    <Route
                      path="/payroll/detail/:laporan_id"
                      element={<DetailLaporan />}
                    />

                    <Route path="/payroll/gajipokok" element={<GajiPokok />} />
                    <Route path="/payroll/insentive" element={<Insentive />} />
                    <Route
                      path="/payroll/biayatambahan"
                      element={<BiayaTambahan />}
                    />
                    <Route path="/payroll/potongan" element={<Potongan />} />

                    <Route path="/report/izin" element={<ListRekapIzin />} />
                  </Routes>
                </GeneralContext.Provider>
              </pegawaiContext.Provider>
            )}
          </dashboardLoading.Provider>
        </div>
      </div>
    </motion.div>
  );
};

export default Dashboard;
