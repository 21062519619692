import ApiRequest from "./ApiRequest";

interface IParamType {
}

class AxiosFindKontrak extends ApiRequest<IParamType> {
    constructor() {
        super("/find_latest_kontrak");
    }

    get(token: string, id: string) {
        return this.get_request(token, {
            id_pegawai: id
        });
    }
}

export default AxiosFindKontrak;
