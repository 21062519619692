import clsx from "clsx";
import DropdownField from "../../../components/DropDownField";
import InputField from "../../../components/InputField";
import SetupSlipGajiStyle from "./SetupSlipGaji.module.css";
import { useState } from "react";

interface ISetupSlipGajiProps {
  jenisGaji: string;
  setJenisGaji: React.Dispatch<React.SetStateAction<string>>;
  setWaktuMulai: React.Dispatch<React.SetStateAction<string>>;
  setWaktuSelesai: React.Dispatch<React.SetStateAction<string>>;
  setWaktuPenggajian: React.Dispatch<React.SetStateAction<string>>;
}
const SetupSlipGaji: React.FC<ISetupSlipGajiProps> = (
  props: ISetupSlipGajiProps
) => {
  return (
    <div className={clsx(SetupSlipGajiStyle.container, "w-full px-12 py-6")}>
      <div className={SetupSlipGajiStyle.input_div}>
        {/* <label className={SetupSlipGajiStyle.title}>Jenis Gaji</label> */}
        <DropdownField
          title="Jenis Gaji"
          name="jenis_gaji"
          isRequired
          id="jenis_gaji"
          options={[
            {
              label: "Bulanan",
              value: "bulanan",
            },
            {
              label: "Harian",
              value: "harian",
            },
            {
              label: "Borongan",
              value: "borongan",
            },
          ]}
          className="!border !py-[17px] !px-[31px] !rounded"
          value={props.jenisGaji}
          onChange={(value) => props.setJenisGaji(value)}
        />
      </div>
      <div className={SetupSlipGajiStyle.input_div}>
        {/* <label className={SetupSlipGajiStyle.title}>Periode Payroll</label> */}
        <div>
          <span>Periode Payroll</span>
          <span className="text-red-500">*</span>
        </div>

        <div
          className={clsx(
            SetupSlipGajiStyle.periode_input,
            "w-full !flex !gap-2"
          )}
        >
          <InputField
            type="date"
            onChange={(value) => props.setWaktuMulai(value)}
            placeholder=""
            className="!border !py-[17px] !px-[31px] !rounded"
          />
          <span>s/d</span>
          <InputField
            type="date"
            onChange={(value) => props.setWaktuSelesai(value)}
            placeholder=""
            className="!border !py-[17px] !px-[31px] !rounded"
          />
        </div>
      </div>
      <div className={SetupSlipGajiStyle.input_div}>
        {/* <label className={SetupSlipGajiStyle.title}>Tanggal Penggajian</label> */}
        <InputField
          title="Tanggal Penggajian"
          type="date"
          isRequired
          onChange={(value) => props.setWaktuPenggajian(value)}
          placeholder=""
          className="!border !py-[17px] !px-[31px] !rounded"
        />
      </div>
    </div>
  );
};
export default SetupSlipGaji;
