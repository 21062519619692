import { useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Login";
import Dashboard from "./main/Dashboard";
import userAuth from "./context/UserAuth";
import { ToastContainer } from "react-toastify";

function App() {
  const [user, setUser] = useState({});
  return (
    <div className="App overflow-y-hidden">
      <userAuth.Provider value={{ user, setUser }}>
        <Router>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard/*" element={<Dashboard />} />
          </Routes>
        </Router>
        <div className="!text-left">
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </div>
      </userAuth.Provider>
    </div>
  );
}

export default App;
