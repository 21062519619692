/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import AxiosCreateTHR from "../../../api/AxiosCreateTHR";
import AxiosListAllPegawai from "../../../api/AxiosListAllPegawai";
import AxiosListTHR from "../../../api/AxiosListTHR";
import Button from "../../../components/Button";
import ContentHeader from "../../../components/ContentHeader";
import DropdownField from "../../../components/DropDownField";
import InputField from "../../../components/InputField";
import PopUpCard from "../../../components/PopUpCard";
import Table from "../../../components/Table";
import TimeoutPopup, {
  defaultPopUpProps,
  ITimeoutPopupProps,
} from "../../../components/TimeoutPopUp";
import userAuth from "../../../context/UserAuth";
import TOption from "../../../models/TOption";
import TPegawai from "../../../models/TPegawai";
import THRStyle from "./THR.module.css";
import { columns, Item } from "./THRTableDefinitions";
import clsx from "clsx";
import { toast } from "react-toastify";
import AxiosUpdateTHR from "../../../api/AxiosUpdateTHR";
import { Loader } from "@mantine/core";
import AxiosGetDepartemen from "../../../api/AxiosGetDepartemen";
import { capitalizeEachWord } from "../../../helpers/StringHelper";
import AxiosDeleteTHR from "../../../api/AxiosDeleteTHR";

interface ITHRProps { }
const THR: React.FC<ITHRProps> = () => {
  const { user } = useContext(userAuth);
  const [year, setYear] = useState(new Date().getFullYear());
  const [isPopUp, setIsPopUp] = useState(false);
  const [thrData, setThrData] = useState<Item[]>([]);

  const [pegawaiOptions, setPegawaiOptions] = useState<TOption[]>([]);
  const [popUpProps, setPopUpProps] =
    useState<ITimeoutPopupProps>(defaultPopUpProps);

  //tambah thr
  const [idPegawai, setIdPegawai] = useState("");
  const [nominal, setNominal] = useState(0);
  const [pegawaiYear, setPegawaiYear] = useState("");
  const [isPopUpEdit, setIsPopUpEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [editData, setEditData] = useState({
    id: 0,
    nama: "",
    periode: "",
    nominal: 0,
  });

  const popUpClose = {
    onClose: () => setIsPopUp(false),
  };

  function getListPegawai() {
    if (!user?.token) return;
    const axiosListAllPegawai = new AxiosListAllPegawai();

    axiosListAllPegawai.get(user.token, {
      status: 'aktif'
    }).then((response) => {
      const data: TPegawai[] = response.data.data;
      const options: TOption[] = [];
      data.forEach((item: TPegawai) => {
        options.push({
          value: item.id.toString(),
          label: capitalizeWords(item.nama),
        });
      });
      setPegawaiOptions(options);
    });
  }

  function capitalizeWords(input: string): string {
    return input
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  }

  function listTHR() {
    if (!user?.token) return;
    const axiosListTHR = new AxiosListTHR();
    axiosListTHR
      .get(user.token, {
        waktu_mulai: year.toString() + "-01-01",
        waktu_selesai: year.toString() + "-12-31",
      })
      .then((response) => {
        const data: [] = response.data.data;
        const newTHRData: Item[] = data.map((thr: any) => ({
          id: thr.id,
          nama: thr.nama_pegawai,
          periode: thr.tanggal,
          nominal: thr.nominal,
          editAction: handleEdit,
          deleteAction: handleDelete
        }));
        setThrData(newTHRData);
      });
  }

  const handleDelete = (item: Item) => {
    if (!user?.token || isLoading === true) return;
    setIsLoading(true);

    const axiosCreateTHR = new AxiosDeleteTHR();
    axiosCreateTHR
      .post(user.token, { id: item.id })
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setIsPopUp(false);
          listTHR();
          toast.success("THR berhasil dihapus!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsLoading(false);
        }
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
      });
  };
  const handleEdit = (item: Item) => {
    setEditData(item);
    setIsPopUpEdit(true);
  };

  function createTHR() {
    if (!user?.token || isLoading === true) return;
    setIsLoading(true);

    const axiosCreateTHR = new AxiosCreateTHR();
    axiosCreateTHR
      .post(user.token, {
        id_pegawai: idPegawai.toString(),
        nominal: nominal,
        tanggal: pegawaiYear.toString() + "-12-31",
      })
      .then((response) => {
        const data = response.data;
        if (data.success) {
          setIsPopUp(false);
          listTHR();
          // setPopUpProps({
          //   ...popUpProps,
          //   isEnabled: true,
          //   message: "THR berhasil ditambahkan",
          //   timeout: 1500,
          //   onTimeout: () => setPopUpProps({ ...popUpProps, isEnabled: false }),
          // });
          toast.success("THR berhasil ditambahkan!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsLoading(false);
        }
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
      });
  }

  function updateTHR() {
    if (!user?.token || isLoading === true) return;
    setIsLoading(true);

    if (!editData || editData.id === 0) return;
    const axiosUpdateTHR = new AxiosUpdateTHR();
    axiosUpdateTHR
      .post(user.token, {
        id: Number(editData.id),
        nama: editData.nama,
        tanggal: new Date(editData.periode),
        nominal: Number(editData.nominal),
      })
      .then(() => {
        toast.success("THR pegawai berhasil diubah!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // setPopUpState({
        //   ...popUpState,
        //   popupType: PopUpEnum.SUCCESS,
        //   message: "Jabatan Berhasil Di Rubah",
        //   timeout: 1000,
        //   isEnabled: true,
        //   onTimeout() {
        //     setPopUpState({ ...popUpState, isEnabled: false });
        //   },
        // });
        setIsPopUp(false);
        setIsLoading(false);
        setEditData({
          id: 0,
          nama: "",
          periode: "",
          nominal: 0,
        });
        setThrData([]);
        listTHR();
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
      });
  }

  useEffect(() => {
    if (!year) return;
    listTHR();
  }, [year]);

  useEffect(() => {
    getListPegawai();
  }, []);

  const [listDepartemen, setListDepartemen] = useState<TOption[]>([]);

  function getDepartemen() {
    if (!user?.token) return;
    const axiosGetDepartemen = new AxiosGetDepartemen();

    axiosGetDepartemen.get(user.token).then((response) => {
      const data = response.data.data;
      const options: TOption[] = [];
      data.forEach((value: any, index: any) => {
        options.push({
          value: value.nama,
          label: capitalizeEachWord(value.nama, "_"),
          isDefault: index === 0,
        });
      });
      setListDepartemen(options);
    });
  }

  useEffect(() => {
    getDepartemen();
  }, []);

  const [departemen, setDepartemen] = useState("");

  return (
    <>
      <ContentHeader
        title={"THR"}
        additionalHeader={
          <>
            <div className={"flex gap-2"}>
              {/* <SearchField
                placeholder="Cari nama ..."
                onChange={(value) => onSearch(value)}
              /> */}
              <div className="flex gap-2">
                <span className="flex flex-col justify-center font-bold !min-w-max">
                  Periode Tahun
                </span>

                <InputField
                  type="number"
                  defaultValue={new Date().getFullYear()}
                  min={2000}
                  max={2099}
                  onChange={(value) => setYear(parseInt(value))}
                  placeholder=""
                  className="min-w-[200px]"
                  value={year.toString()}
                />

                <DropdownField
                  id="departemen"
                  name="departemen"
                  className="!border !py-[17px] !px-[31px] !rounded !min-w-[200px] hover:cursor-pointer"
                  options={listDepartemen}
                  onChange={(value) => {
                    setDepartemen(value);
                  }}
                  placeholder="Filter Departemen"
                  value={departemen}
                />
              </div>
              <Button
                text="Tambah THR"
                onClick={() => {
                  setIsPopUp(true);
                }}
                size="small"
                className="!w-max min-w-max !py-0 !h-[55px]"
              />
            </div>
          </>
        }
      />
      <TimeoutPopup {...popUpProps} />
      {isPopUp ? (
        <PopUpCard {...popUpClose} title="Tambah THR">
          <div className="flex flex-col gap-2">
            <DropdownField
              name="Nama Pegawai"
              id={""}
              placeholder=""
              value={idPegawai.toString()}
              title="Nama Pegawai"
              options={pegawaiOptions}
              onChange={(value) => setIdPegawai(value)}
              className="!border !py-[17px] !px-[31px] !rounded"
            />

            <InputField
              title="Nominal THR"
              isRequired
              type="number"
              placeholder="Nominal THR"
              onChange={(value) => setNominal(parseInt(value))}
              className="border w-full"
            />

            <InputField
              title="Periode Tahun"
              type="date"
              onChange={(value) => setPegawaiYear(value)}
              placeholder=""
              className="min-w-[200px]"
              value={pegawaiYear}
            />

            <Button
              size="small"
              text={
                isLoading ? <Loader color="indigo" size="sm" /> : "Tambah THR"
              }
              isDisabled={isLoading}
              className="h-[55px]"
              onClick={() => createTHR()}
            />
          </div>
        </PopUpCard>
      ) : (
        <></>
      )}

      {isPopUpEdit && (
        <PopUpCard title="Edit THR" onClose={() => setIsPopUpEdit(false)}>
          <InputField
            type="text"
            placeholder="Masukkan nama pegawai"
            title="Nama Pegawai"
            isRequired={true}
            onChange={(value) => setEditData({ ...editData, nama: value })}
            defaultValue={editData.nama}
            value={editData.nama}
            isDisabled
          />

          <InputField
            type="date"
            title="Periode"
            onChange={(value) => { }}
            placeholder="Periode"
            defaultValue={editData ? editData.periode : ""}
            value={editData ? editData.periode : ""}
            isDisabled={true}
            isRequired={true}
          />

          <InputField
            type="number"
            placeholder="Masukkan nominal THR pegawai"
            title="Nominal THR"
            isRequired={true}
            onChange={(value) =>
              setEditData({ ...editData, nominal: Number(value) })
            }
            value={editData.nominal.toString()}
            defaultValue={editData.nominal}
          />

          <Button
            text={
              isLoading ? (
                <>
                  <Loader color="indigo" size="sm" />
                </>
              ) : (
                "Ubah THR"
              )
            }
            isDisabled={isLoading}
            onClick={() => {
              updateTHR();
              setIsPopUpEdit(false);
            }}
            size="small"
            className="h-[55px]"
          />
        </PopUpCard>
      )}
      <div className="mx-[40px]">
        {/* <div className="flex gap-2 w-full py-[20px]">
          <div className="text-left w-full">
            Filter berdasarkan tahun
            <InputField
              type="number"
              defaultValue={2023}
              min={2000}
              max={2099}
              onChange={(value) => setYear(parseInt(value))}
              placeholder=""
              className="w-full"
            />
          </div>
          <div className="flex flex-col justify-end">
            <Button
              text="Tambah THR"
              onClick={() => {
                setIdPegawai(parseInt(pegawaiOptions[0].value));
                setNominal(parseInt(pegawaiOptions[0].value));
                setIsPopUp(true);
              }}
              size="small"
              className="!w-max min-w-max !py-0 !h-[55px]"
            />
          </div>
        </div> */}
        <div className={clsx(THRStyle.table_wrapper, "!mx-0 pb-4")}>
          <Table<Item> data={thrData} columns={columns} />
        </div>
      </div>
    </>
  );
};
export default THR;
