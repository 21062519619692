import ApiRequest from "./ApiRequest";

interface IParamType {
}

class AxiosGetPresensiPegawai extends ApiRequest<IParamType> {
    constructor() {
        super("/presensi/presensi_pegawai");
    }

    get(token: string, id_pegawai: number, waktu_mulai: string, waktu_selesai: string) {
        return this.get_request(token, {
            id: id_pegawai,
            waktu_mulai,
            waktu_selesai,
        });
    }
}

export default AxiosGetPresensiPegawai;
