import ApiRequest from "./ApiRequest";

interface IRequestTokenParam {
    email: string,
    password: string,
    device_name: string,
}

class AxiosRequestToken extends ApiRequest<IRequestTokenParam> {
    constructor() {
        super("/tokens/create");
    }

    post(
        email: string,
        password: string,
        device_name: string,
    ) {
        return this.post_request({
            params: {
                email: email,
                password: password,
                device_name: device_name,
            }
        });
    }
}

export default AxiosRequestToken;
