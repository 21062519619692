/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import ContentHeader from "../../../../components/ContentHeader";
import userAuth from "../../../../context/UserAuth";
import DetailBoronganStyle from "./DetailBorongan.module.css";
import { useLocation } from "react-router-dom";
import AxiosGetDetailLembarKerjaBorongan from "../../../../api/AxiosGetDetailLembarKerjaBorongan";
import { flipStringDate } from "../../../../helpers/DateHelper";
import { numberToMoney } from "../../../../helpers/StringHelper";

interface IListBoronganProps {}

const DetailBorongan: React.FC<IListBoronganProps> = () => {
  const location = useLocation();
  const stateParam = location.state?.param;

  const { user } = useContext(userAuth);
  const [detailLembarKerjaBorongan, setDetailLembarKerjaBorongan] = useState<
    any[]
  >([]);
  const [data, setData] = useState<any[]>([]);
  const [totalSum, setTotalSum] = useState(0);

  function getDetailLembarKerjaBorongan(id: string, tgl_pekerjaan: string) {
    if (!user?.token) return;
    const axiosGetDetailLembarKerjaBorongan =
      new AxiosGetDetailLembarKerjaBorongan(id, tgl_pekerjaan);

    axiosGetDetailLembarKerjaBorongan.get(user.token).then((response) => {
      const data = response.data.data;

      const total = data.reduce((acc: number,item:any) => {
        return acc + item.jumlah_output;
      }, 0);
      setTotalSum(total);

      const newData = data.map((item: any) => {
        return {
          ...item,
          action: () => {},
        };
      });
      setDetailLembarKerjaBorongan(newData);
    });
  }

  function convertDateTime(input: string) {
    return `${input.substring(0, 4)}-${input.substring(5, 7)}-${input.substring(
      8,
      10
    )} ${input.substring(11, 13)}:${input.substring(14, 16)}:${input.substring(
      17,
      19
    )}`;
  }

  useEffect(() => {
    if (stateParam)
      getDetailLembarKerjaBorongan(
        stateParam.id_list_pekerjaan_spk,
        stateParam.tanggal_pekerjaan
      );
  }, []);

  useEffect(() => {
    if (detailLembarKerjaBorongan && detailLembarKerjaBorongan.length > 0) {
      setData([
        {
          label: "Tanggal",
          value: flipStringDate(detailLembarKerjaBorongan[0].tanggal_pekerjaan),
        },
        // { label: "Mesin", value: detailLembarKerjaBorongan[0].mesin },
        
        { label: "No. SPK", value: location.state?.noSPK },
        // { label: "Bahan", value: detailLembarKerjaBorongan[0].nama_barang },
        {
          label: "Nama Pekerjaan",
          value: detailLembarKerjaBorongan[0].nama,
        },
        // {
        //   label: "Target",
        //   value: detailLembarKerjaBorongan[0].jumlah_pekerjaan,
        // },
        {
          label: "Jumlah Barang",
          value: totalSum + " pcs",
        },
        { label: "Harga Rp/pcs", value: numberToMoney(detailLembarKerjaBorongan[0].tarif) },
        {
          label: "Total Ongkos Kerja",
          value:
            numberToMoney(totalSum *
              detailLembarKerjaBorongan[0].tarif),
        },
      ]);
    }
  }, [detailLembarKerjaBorongan]);

  return (
    <>
      <div className={DetailBoronganStyle.header_wrapper}>
        <ContentHeader
          title={"Detail Lembar Kerja Borongan"}
          enableBack={true}
        />
      </div>

      <div className="mx-[40px] my-[20px] bg-white rounded-[10px] py-6 px-12 text-left">
        <table className="table-auto w-full">
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className="text-left">
                <td className="py-2 font-medium w-1/3">{item.label}</td>
                <td className="py-2 font-medium select-none">:</td>
                <td
                  className={`py-2 ${item.isHighlight ? "text-blue-600" : ""}`}
                >
                  {item.value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mx-[40px] my-[20px] bg-white rounded-[10px] py-6 px-12 text-left">
        <div className="text-3xl font-bold my-4">Rincian Pekerja</div>
        <table className="table-auto w-full">
          <tbody>
            <tr>
              <th>Nomor</th>
              <th>Nama Pekerja</th>
              <th>Jumlah Barang</th>
              <th>Harga Satuan</th>
              <th>Ongkos Kerja</th>
              <th>Keterangan</th>
            </tr>
            {detailLembarKerjaBorongan
              ? detailLembarKerjaBorongan.map((item, index) => (
                  <tr key={index} className="text-left">
                    <td className="py-2 font-medium ">
                       {index + 1}
                    </td>
                    {/* <td className="py-2 font-medium select-none">:</td> */}
                    <td className={`py-2`}>
                      {item.nama_pegawai.charAt(0).toUpperCase() +
                        item.nama_pegawai.slice(1)}
                    </td>
                    <td className={`py-2`}>
                      {item.jumlah_output + " pcs"}
                    </td>
                    <td className={`py-2`}>
                      { numberToMoney(item.tarif)}
                    </td>
                    <td className={`py-2`}>
                      { numberToMoney(item.jumlah_output * item.tarif) }
                    </td>
                    <td className={`py-2`}>
                      {item.keterangan}
                    </td>
                  </tr>
                ))
              : null}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default DetailBorongan;
