import ApiRequest from "./ApiRequest";

export type IUpdateJabatanParam = {
    id: number,
    nama: string,
    id_skema_gaji: number,
}

class AxiosUpdateJabatanParam extends ApiRequest<IUpdateJabatanParam> {
    constructor() {
        super("/jabatan/update");
    }

    post(
        token: string,
        data: IUpdateJabatanParam
    ) {
        return this.post_request({
            token: token,
            params: { ...data }
        });
    }
}

export default AxiosUpdateJabatanParam;
