import { createColumnHelper } from "@tanstack/react-table";

export type Item = {
    tanggal: string,
    masuk_1: string,
    masuk_2: string,
    masuk_3: string,
    masuk_4: string,
}
type PresensiResponse = {
    id: number,
    tanggal: string,
    masuk_1: string,
    masuk_2: string,
    masuk_3: string,
    masuk_4: string,
}

const columnHelper = createColumnHelper<Item>()
export const columns = [
    columnHelper.accessor('masuk_1', {
        header: 'Log 1',
        cell: info => info.renderValue(),
        footer: info => info.column.id,
    }),
    columnHelper.accessor('masuk_2', {
        header: 'Log 2',
        cell: info => info.renderValue(),
        footer: info => info.column.id,
    }),
    columnHelper.accessor('masuk_3', {
        header: 'Log 3',
        cell: info => info.renderValue(),
        footer: info => info.column.id,
    }),
    columnHelper.accessor('masuk_4', {
        header: 'Log 4',
        cell: info => info.renderValue(),
        footer: info => info.column.id,
    }),
];

export function constructItemFromResponse(data_response: PresensiResponse[]) {
    const newItems: Item[] = [];
    data_response?.forEach((data: PresensiResponse, key: number) => {
        const item: Item = {
            tanggal: data.tanggal,
            masuk_1: data.masuk_1,
            masuk_2: data.masuk_2,
            masuk_3: data.masuk_3,
            masuk_4: data.masuk_4,
        }
        newItems.push(item);
    });
    return newItems;
}
