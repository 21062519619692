import ApiRequest from "./ApiRequest";

interface ICreateLemburParams {
    waktu_mulai: string,
    waktu_selesai: string,
    pekerjaan: string,
    pegawai: any[],
}
export type PegawaiItem = {
    id: string,
    tarif: number
}

class AxiosCreateLembur extends ApiRequest<ICreateLemburParams> {
    constructor() {
        super("/lembur/create");
    }

    post(token: string, pegawai_data: ICreateLemburParams) {
        
        return this.post_request({
            token: token,
            params: { ...pegawai_data }
        });
    }
}

export default AxiosCreateLembur;
