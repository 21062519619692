import ApiRequest from "./ApiRequest";

interface IDeleteJatahCuti {
    id: string;
}

class AxiosDeleteJatahCuti extends ApiRequest<IDeleteJatahCuti> {
    constructor() {
        super("/jatah_cuti/delete");
    }

    post(token: string, absen_data: IDeleteJatahCuti) {
        return this.post_request({
            token: token,
            params: { ...absen_data }
        });
    }
}

export default AxiosDeleteJatahCuti;
