import React, { useRef, useEffect } from "react";
import closeIcon from "../vectors/closeIcon.svg";
import PopUpCardStyle from "./ConfirmationPopUpCard.module.css";
import clsx from "clsx";
import Button from "./Button";

interface IConfirmationPopUpCardProps {
  title?: string;
  onClose: () => void;
  children?: React.ReactNode;
  text?: string;
  leftButtonText?: string;
  onLeftButtonClick?: () => void;
  rightButtonText?: string;
  onRightButtonClick: () => void;
}

const ConfirmationPopUpCard: React.FC<IConfirmationPopUpCardProps> = ({
  title,
  onClose,
  children,
  text,
  leftButtonText,
  onLeftButtonClick,
  rightButtonText,
  onRightButtonClick,
}) => {
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className={PopUpCardStyle.container}>
      <div
        ref={cardRef}
        className={clsx(
          PopUpCardStyle.card,
          "flex flex-col gap-4 !rounded-lg !h-min !min-h-0 !min-w-[600px] shadow-md"
        )}
      >
        <div className={clsx(PopUpCardStyle.header)}>
          <span className="font-bold text-2xl w-full text-left">{title}</span>
          <img
            src={closeIcon}
            className={PopUpCardStyle.closeIcon}
            onClick={onClose}
            alt="Close"
          />
        </div>
        <div className="min-h-12 text-left">
          <p>{text}</p>
        </div>

        <line className="w-full h-px bg-gray-300" />

        <div className="flex justify-end gap-2">
          <button
            className={clsx(
              PopUpCardStyle.button,
              "text-black rounded px-8 py-2 hover:border hover:border-black border-transparent border"
            )}
            onClick={onClose}
          >
            {leftButtonText ? leftButtonText : "Batal"}
          </button>
          <Button
            text={rightButtonText ? rightButtonText : "Hapus"}
            size="small"
            onClick={onRightButtonClick}
            className="!max-h-[55px] !w-[99px] !min-w-0 !bg-red-500 !hover:bg-red-600"
          />
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopUpCard;
