import ApiRequest from "./ApiRequest";

interface IParamType {}

class AxiosGetDetailSPK extends ApiRequest<IParamType> {
  constructor(id: string) {
    super(`/produksi/get_spk?id=${id}`);
  }

  get(token: string) {
    return this.get_request(token);
  }
}

export default AxiosGetDetailSPK;
