import React from "react";
import SearchFieldStyle from "./SearchField.module.css";
import search from "../vectors/search.svg";
import clsx from "clsx";

interface ISearchFieldProps {
  type?: string;
  placeholder: string;
  className?: string;
  onChange: (value: string) => void;
}

class SearchField extends React.Component<ISearchFieldProps> {
  render(): React.ReactNode {
    return (
      <div
        className={clsx(
          SearchFieldStyle.container,
          this.props.className,
          "min-w-[200px] !rounded !border !border-gray-200"
        )}
      >
        <img src={search} className={SearchFieldStyle.search} alt="src" />
        <input
          {...this.props}
          className={clsx(SearchFieldStyle.input, "focus:outline-none")}
          onChange={(e) => this.props.onChange(e.target.value)}
        />
      </div>
    );
  }
}

export default SearchField;
