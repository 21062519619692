import { ReactNode, useContext, useState } from "react";
import Button from "../../components/Button";
import ContentHeader from "../../components/ContentHeader";
import TimeoutPopup, {
  defaultPopUpProps,
  ITimeoutPopupProps,
} from "../../components/TimeoutPopUp";
import TPegawai from "../../models/TPegawai";
import SelectPegawai from "./SelectPegawai/SelectPegawai";
import SetupSlipGaji from "./SetupSlipGaji/SetupSlipGaji";
import SlipGajiStyle from "./SlipGaji.module.css";
import errorExclamation from "../../vectors/errorExclamation.svg";
import { ButtonTypeEnum } from "../../enums/ButtonTypeEnum";
import HasilSlip from "./Hasil/HasilSlip";
import AxiosInsertQueueGaji from "../../api/AxiosInsertQueueGaji";
import userAuth from "../../context/UserAuth";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { toast } from "react-toastify";
import { useDisclosure } from "@mantine/hooks";
import { Loader, Modal } from "@mantine/core";

interface ISlipGajiProps { }

type StepSlipGaji = {
  title: string;
  component: ReactNode;
};
const SlipGaji: React.FC<ISlipGajiProps> = () => {
  const { user } = useContext(userAuth);
  const [step, setStep] = useState(0);
  const [popUpProps, setPopUpProps] =
    useState<ITimeoutPopupProps>(defaultPopUpProps);
  const [confirmPopUp, setConfirmPopUp] = useState(false);
  const navigate = useNavigate();

  //setup
  const [jenisGaji, setJenisGaji] = useState("");
  const [waktuMulai, setWaktuMulai] = useState("");
  const [waktuSelesai, setWaktuSelesai] = useState("");
  const [waktuPenggajian, setWaktuPenggajian] = useState("");

  //List Pegawai
  const [listPegawai, setListPegawai] = useState<TPegawai[]>([]);
  const [selectedPegawai, setSelectedPegawai] = useState<TPegawai[]>([]);

  const [opened, { open, close }] = useDisclosure(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //List
  const stepComponent: StepSlipGaji[] = [
    {
      title: "Setup",
      component: (
        <SetupSlipGaji
          {...{
            jenisGaji,
            setJenisGaji,
            setWaktuMulai,
            setWaktuSelesai,
            setWaktuPenggajian,
          }}
        />
      ),
    },
    {
      title: "List Pegawai",
      component: (
        <SelectPegawai
          {...{
            listPegawai,
            setListPegawai,
            selectedPegawai,
            setSelectedPegawai,
            jenisPerjanjian: gajiToPerjanjian(),
          }}
        />
      ),
    },
    // {
    //   title: "Hasil",
    //   component: <HasilSlip />,
    // },
  ];

  function gajiToPerjanjian() {
    switch (jenisGaji) {
      case "bulanan":
        return "PKWTT";
      case "harian":
        return "PKWT";
      case "borongan":
        return "PKKB";
      default:
        return "PKWTT";
    }
  }

  function verifySetup() {
    if (
      jenisGaji.length === 0 ||
      waktuMulai.length === 0 ||
      waktuSelesai.length === 0 ||
      waktuPenggajian.length === 0
    ) {
      toast.error(`Semua field wajib diisi!`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    }
    return true;
  }

  function onLanjut() {
    if (!verifySetup()) return;
    // if (step + 1 >= stepComponent.length) return;
    if (step === 1) {
      if (selectedPegawai.length <= 0) {
        toast.error(`Wajib memilih pegawai`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return
      }

      insertQueue();
      navigate("/dashboard/payroll/laporan");
    }
    setStep(step + 1);
  }

  function insertQueue() {
    if (!user.token || isLoading === true) return;
    setIsLoading(true);

    const axiosInsertQueue = new AxiosInsertQueueGaji();
    axiosInsertQueue
      .post(user.token, {
        ids_pegawai: selectedPegawai.map((pegawai) => pegawai.id),
        waktu_mulai: waktuMulai,
        waktu_selesai: waktuSelesai,
        tanggal_pembayaran_gaji: waktuPenggajian,
        tipe_gaji: jenisGaji,
      })
      .then(() => {
        toast.success("Slip gaji berhasil ditambahkan!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsLoading(false);
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
      });
  }

  return (
    <>
      <ContentHeader
        title={"Slip Gaji - " + stepComponent[step].title}
        enableBack={step === 1}
        onBack={() => setStep(step - 1)}
      />
      <TimeoutPopup {...popUpProps} />

      {confirmPopUp ? (
        <Modal opened={opened} onClose={close} title="" radius={"md"} centered>
          <div className="flex justify-center">
            <img
              alt={"check"}
              src={errorExclamation}
              className={SlipGajiStyle.confirm_icon}
            />
          </div>
          <div className={clsx(SlipGajiStyle.confirm_title, "text-center")}>
            Lanjut Buat Slip Gaji?
          </div>
          <div className={SlipGajiStyle.confirm_button_group}>
            <Button
              onClick={() => setConfirmPopUp(false)}
              text="Batal"
              size="small"
              buttonType={ButtonTypeEnum.CLICKABLE_TEXT}
              color="danger"
              className="min-w-[100px] min-h-[40px]"
            />
            <Button
              onClick={() => {
                setConfirmPopUp(false);
                onLanjut();
              }}
              text="Lanjut"
              size="small"
              className="min-w-[100px] min-h-[40px]"
            />
          </div>
        </Modal>
      ) : (
        <></>
      )}
      <div className={SlipGajiStyle.step_list}>
        {stepComponent.map((stepItem, index) => (
          <div
            className={
              SlipGajiStyle.step_item +
              " " +
              (step === index ? SlipGajiStyle.current_step_item : " ")
            }
          >
            {index + 1}. {stepItem.title}
          </div>
        ))}
      </div>
      <hr className="my-4 mx-[40px]" />

      <div
        className={clsx(
          SlipGajiStyle.component_container,
          "!m-0 !mx-[40px] !py-0"
        )}
      >
        {stepComponent[step].component}
      </div>
      <div className="mx-[40px] mt-4 mb-[40px] flex justify-end">
        <Button
          text={
            isLoading ? (
              <Loader color="indigo" size="sm" />
            ) : step === 1 ? (
              "Buat Slip Gaji"
            ) : (
              "Lanjut"
            )
          }
          isDisabled={isLoading}
          className="max-w-[200px] !p-0 h-[55px]"
          size="small"
          onClick={() => {
            if (step === 1) {
              setConfirmPopUp(true);
              open();
            } else onLanjut();
          }}
        />
      </div>
    </>
  );
};
export default SlipGaji;
