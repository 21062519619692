/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useSearchParams } from "react-router-dom";
import AxiosFindDokumen from "../../api/AxiosFindDokumen";
import ContentHeader from "../../components/ContentHeader";
import userAuth from "../../context/UserAuth";

interface IDetailDokumenProps {}

const DetailDokumen: React.FC<IDetailDokumenProps> = () => {
  const [searchParam] = useSearchParams();
  const { user } = useContext(userAuth);
  const [pdfbase64, setPdfBase64] = useState<string | undefined>(undefined);
  const [dokumenBase64, setDokumenBase64] = useState<string | undefined>(
    undefined
  );
  const [numPages, setNumPages] = useState(0);

  useEffect(() => {
    loadFile();
  }, []);

  async function loadFile() {
    const idDokumen = searchParam.get("id");

    const axiosFindDokumen = new AxiosFindDokumen();

    if (!user.token || !idDokumen) return;

    const pdfjslib = require("pdfjs-dist");
    const pdfjsWorker: any = pdfjslib;
    pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;
    axiosFindDokumen
      .get(user.token, idDokumen)
      .then((response) => {
        const extension = response.data.data.extension;
        const doc = response.data.data.base64;

        if (extension.toLowerCase() === "pdf") {
          loadPDF(doc);
        } else {
          setDokumenBase64(doc);
        }
      })
      .catch((e) => console.log(e));
  }

  function loadPDF(doc: any) {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    setPdfBase64(doc);
  }

  return (
    <>
      <ContentHeader title="Dokumen" enableBack={true} />
      {pdfbase64 !== undefined ? (
        <Document
          file={pdfbase64}
          onLoadSuccess={({ numPages }: { numPages: number }) =>
            setNumPages(numPages)
          }
        >
          {Array.apply(null, Array(numPages))
            .map((x, i) => i + 1)
            .map((page) => (
              <Page
                pageNumber={page}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            ))}
        </Document>
      ) : (
        <> </>
      )}
      {dokumenBase64 !== undefined ? (
        <img
          style={{
            width: "500px",
          }}
          src={dokumenBase64}
          alt="doc"
        />
      ) : (
        <> </>
      )}
    </>
  );
};

export default DetailDokumen;
