import React, { useState } from "react";
import FileUploaderStyle from "./FileUploader.module.css";
import { FileUploader } from "react-drag-drop-files";
import Button from "../../components/Button";

interface IFileUploaderProps {
  documentType?: string;
  onDocumentChange: (event: any) => void;
  onUpload: () => void;
}

const FileUploaderPopUp: React.FC<IFileUploaderProps> = (
  props: IFileUploaderProps
) => {
  const [fileName, setFileName] = useState("");

  function handleChange(e: any) {
    setFileName(e.name);
    props.onDocumentChange(e);
  }

  return (
    <div className={FileUploaderStyle.container}>
      {props.documentType ? (
        <div className={FileUploaderStyle.title}>
          {" "}
          Upload {props.documentType}{" "}
        </div>
      ) : null}

      <div className={FileUploaderStyle.wrapper}>
        <FileUploader
          handleChange={(e: any) => handleChange(e)}
          name="File"
          types={["JPG", "PNG", "PDF"]}
        />
        {fileName ? (
          <div className={FileUploaderStyle.file_detail}>
            filename: {fileName}
          </div>
        ) : null}
      </div>
      <Button
        onClick={() => props.onUpload()}
        text="Upload"
        size="small"
        className="h-[55px]"
      />
    </div>
  );
};

export default FileUploaderPopUp;
