/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { TLembarKerjaBorongan } from "../../../models/TLembarKerjaBorongan";
import DropdownField from "../../DropDownField";
import TOption from "../../../models/TOption";
import AxiosListAllPegawai from "../../../api/AxiosListAllPegawai";
import userAuth from "../../../context/UserAuth";
import TPegawai from "../../../models/TPegawai";
import InputField from "../../InputField";
import { useNavigate } from "react-router-dom";
import AxiosCreateLembarKerjaBorongan from "../../../api/AxiosCreateLembarKerjaBorongan";
import { toast } from "react-toastify";
import AxiosGetListPekerjaanSPK from "../../../api/AxiosGetListPekerjaanSPK";
import Button from "../../../components/Button";
import { Loader } from "@mantine/core";

type LembarKerjaBoronganFormProps = {
    no_spk: string;
    id_pekerjaan_spk: string;
};

const LembarKerjaBoronganForm: React.FC<LembarKerjaBoronganFormProps> = ({
    no_spk,
    id_pekerjaan_spk,
}) => {
    const { user } = useContext(userAuth);
    const navigate = useNavigate();
    const [formData, setFormData] = useState<TLembarKerjaBorongan>({
        tanggal: new Date(),
        // kepalaProduksi: "",
        mesin: "",
        // pengerjaan: "",
        noSPK: no_spk,
        // bahan: "",
        targetBahan: "",
        jumlahPotong: "",
        jenisPekerjaan: "",
        hargaSatuan: "",
        jumlah: "",
        pegawai: [""],
    });
    const [isPopUp, setIsPopUp] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    function CreateLembarKerjaBorongan() {
        if (!user?.token || isLoading === true) return;
        setIsLoading(true);

        const dataToPost = {
            tanggal_pekerjaan: formData.tanggal,
            list_pekerja: formData.pegawai.map((peg) => parseInt(peg)),
            mesin: formData.mesin,
            jumlah_pekerjaan: parseInt(formData.jumlahPotong),
            jumlah_output: parseInt(formData.jumlah),
            target_bahan: parseInt(formData.targetBahan),
            id_pekerjaan_spk: parseInt(formData.jenisPekerjaan),
        };

        const axiosCreateLembarKerjaBorongan = new AxiosCreateLembarKerjaBorongan();

        axiosCreateLembarKerjaBorongan
            .post(user.token, dataToPost)
            .then(() => {
                // setPopUpState({
                //   ...popUpState,
                //   popupType: PopUpEnum.SUCCESS,
                //   message: "SPK Berhasil Ditambahkan",
                //   timeout: 1000,
                //   isEnabled: true,
                //   onTimeout() {
                //     setPopUpState({ ...popUpState, isEnabled: false });
                //   },
                // });
                toast.success("Lembar kerja borongan berhasil ditambahkan!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                setIsPopUp(false);
                setFormData({
                    tanggal: new Date(),
                    // kepalaProduksi: "",
                    mesin: "",
                    // pengerjaan: "",
                    noSPK: no_spk,
                    // bahan: "",
                    targetBahan: "",
                    jenisPekerjaan: "",
                    jumlahPotong: "",
                    hargaSatuan: "",
                    jumlah: "",
                    pegawai: [""],
                });

                navigate("/dashboard/produksi/spk/listLembarKerjaBorongan", {
                    state: { param: { id: id_pekerjaan_spk, nomor_spk: no_spk } },
                });
                setIsLoading(false);
            })
            .catch((e) => {
                const response = e.response;
                if (response.status === 422)
                    toast.error(`${response.data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                setIsLoading(false);
            });
    }

    const [listBorongan, setListBorongan] = useState<any[]>([]);

    function getLembarKerjaBoronganOption(id: number) {
        if (!user?.token) return;
        const axiosGetLembarKerjaBorongan = new AxiosGetListPekerjaanSPK(id);

        axiosGetLembarKerjaBorongan.get(user.token).then((response) => {
            if (response.data.data) {
                const options = response.data.data.map((item: any) => ({
                    value: item.id,
                    label: item.nama,
                }));
                setListBorongan(options);
            } else {
                setListBorongan([]);
            }
        });
    }

    useEffect(() => {
        getLembarKerjaBoronganOption(Number(id_pekerjaan_spk));
    }, []);

    const [pegawaiOptions, setPegawaiOptions] = useState<TOption[]>([]);

    React.useEffect(() => {
        if (!user?.token) return;
        const axiosListAllPegawai = new AxiosListAllPegawai();
        axiosListAllPegawai.get(user.token).then((response) => {
            const options: TOption[] = response.data.data.map((item: TPegawai) => ({
                value: item.id.toString(),
                label: item.nama,
            }));
            setPegawaiOptions(options);
        });
    }, [user?.token]);

    const handlePegawaiChange = (index: number, value: string) => {
        const updatedPegawai = [...formData.pegawai];
        updatedPegawai[index] = value;
        setFormData({ ...formData, pegawai: updatedPegawai });
    };

    const addPegawaiField = () => {
        setFormData({ ...formData, pegawai: [...formData.pegawai, ""] });
    };

    const removePegawaiField = (index: number) => {
        const updatedPegawai = [...formData.pegawai];
        if (updatedPegawai.length > 1) {
            updatedPegawai.splice(index, 1);
            setFormData({ ...formData, pegawai: updatedPegawai });
        }
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        CreateLembarKerjaBorongan();
    };

    // const mesinOptions = [
    //   { value: "mesin1", label: "Mesin 1" },
    //   { value: "mesin2", label: "Mesin 2" },
    //   { value: "mesin3", label: "Mesin 3" },
    // ];

    const getFilteredPegawaiOptions = (currentSelectedPegawai: string) => {
        return pegawaiOptions.filter(
            (option) =>
                !formData.pegawai.includes(option.value.toString()) ||
                option.value === currentSelectedPegawai
        );
    };

    return (
        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
            <InputField
                title="Tanggal"
                type="date"
                onChange={(value) =>
                    setFormData({ ...formData, tanggal: new Date(value) })
                }
                placeholder="MM/DD/YYYY"
                className="border !py-[17px] !px-[31px] !rounded"
                value={formData.tanggal.toISOString().split("T")[0]}
                isRequired={true}
            />
            {/* <InputField
        title="Kepala Produksi"
        type="text"
        placeholder=""
        onChange={(value) =>
          setFormData({ ...formData, kepalaProduksi: value })
        }
        className="!border px-2 py-1 rounded"
      /> */}
            {/* <DropdownField
        title="Mesin"
        name="mesin"
        id="mesin"
        placeholder="Pilih Mesin"
        options={mesinOptions}
        onChange={(value) => setFormData({ ...formData, mesin: value })}
        value={formData.mesin}
        className="!border !py-[17px] !px-[31px] !rounded"
      /> */}
            {/* <InputField
        type="text"
        title="Pengerjaan"
        placeholder="Pengerjaan"
        onChange={(value) => setFormData({ ...formData, pengerjaan: value })}
        className="border px-2 py-1 rounded"
      /> */}
            <InputField
                title="No. SPK"
                type="text"
                isDisabled={true}
                defaultValue={no_spk}
                placeholder="No SPK"
                onChange={(value) => setFormData({ ...formData, noSPK: value })}
                className="border px-2 py-1 rounded"
                value={formData.noSPK}
                isRequired={true}
            />
            <DropdownField
                name="jenisPekerjaan"
                id={`jenisPekerjaan`}
                title="Jenis Pekerjaan"
                placeholder="Jenis Pekerjaan"
                options={listBorongan}
                onChange={(value) => {
                    setFormData({ ...formData, jenisPekerjaan: value });
                }}
                value={formData.jenisPekerjaan}
                className="!border !py-[17px] !px-[31px] !rounded"
                isRequired={true}
            />
            {/* <InputField
        title="Bahan"
        type="text"
        placeholder="Bahan"
        onChange={(value) => setFormData({ ...formData, bahan: value })}
        className="border px-2 py-1 rounded"
      /> */}
            <InputField
                type="number"
                title="Target Bahan"
                placeholder="Target Bahan"
                onChange={(value) => setFormData({ ...formData, targetBahan: value })}
                className="border px-2 py-1 rounded"
                value={formData.targetBahan}
                isRequired={true}
            />
            <InputField
                type="number"
                title="Target Pekerjaan"
                placeholder="Target Pekerjaan"
                onChange={(value) => setFormData({ ...formData, jumlahPotong: value })}
                className="border px-2 py-1 rounded"
                value={formData.jumlahPotong}
                isRequired={true}
            />
            <InputField
                type="number"
                title="Harga Satuan"
                placeholder="Harga Satuan"
                onChange={(value) => setFormData({ ...formData, hargaSatuan: value })}
                className="border px-2 py-1 rounded"
                value={formData.hargaSatuan}
                isRequired={true}
            />
            <InputField
                type="number"
                title="Jumlah Selesai"
                placeholder="Jumlah Selesai"
                onChange={(value) => setFormData({ ...formData, jumlah: value })}
                className="border px-2 py-1 rounded"
                value={formData.jumlah}
                isRequired={true}
            />

            <div className="flex flex-col gap-1">
                <div className="text-left">
                    <span>Pegawai</span>
                    <span className="text-red-500">*</span>
                </div>
                {formData.pegawai.map((pegawai, index) => (
                    <div key={index} className="flex items-center gap-2">
                        <DropdownField
                            name="pegawai"
                            id={`pegawai-${index}`}
                            placeholder="Pilih Pegawai"
                            options={getFilteredPegawaiOptions(pegawai)}
                            onChange={(value) => {
                                handlePegawaiChange(index, value);
                            }}
                            value={formData.pegawai[index]}
                            className="!border !py-[17px] !px-[31px] !rounded"
                        />

                        <div className="h-full">
                            <button
                                type="button"
                                onClick={() => removePegawaiField(index)}
                                className="bg-red-500 text-white px-2 py-1 h-[55px] w-[55px] rounded hover:bg-red-600 disabled:cursor-not-allowed disabled:bg-red-800"
                                disabled={formData.pegawai.length <= 1}
                            >
                                -
                            </button>
                        </div>
                        {index === formData.pegawai.length - 1 && (
                            <div className="flex flex-col justify-end">
                                <button
                                    type="button"
                                    onClick={addPegawaiField}
                                    className="bg-green-500 text-white px-2 py-1 h-[55px] w-[55px] rounded hover:bg-green-600"
                                >
                                    +
                                </button>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <Button
                size="small"
                text={
                    isLoading ? (
                        <>
                            <Loader color="indigo" size="sm" />
                        </>
                    ) : (
                        "Tambahkan Lembar Kerja Borongan"
                    )
                }
                isDisabled={isLoading}
                className="h-[55px]"
                onClick={() => {
                    CreateLembarKerjaBorongan();
                }}
            />
        </form>
    );
};

export default LembarKerjaBoronganForm;
