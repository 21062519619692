import { createColumnHelper } from "@tanstack/react-table";
import {
  capitalizeEachWord,
  numberToMoney,
} from "../../../helpers/StringHelper";
import HasilSlipStyle from "./HasilSlip.module.css";
import clsx from "clsx";
import { ButtonTypeEnum } from "../../../enums/ButtonTypeEnum";
import Button from "../../../components/Button";

export type Item = {
  id: string;
  nama: string;
  tipe_gaji: string;
  gajiPokok: number;
  gajiBorongan: number;
  tunjangan: number;
  lembur: number;
  nama_jabatan: string;
  nama_departemen: string;
  premiHadir: number;
  totalGaji: number;
  onDelete: (id: string) => void;
};

const columnHelper = createColumnHelper<Item>();

export const columns = [
  columnHelper.accessor("nama", {
    header: () => "Pegawai",
    cell: (info) => {
      const item = info.row.original;
      return (
        <div className={HasilSlipStyle.pegawaiCell}>
          <div className={HasilSlipStyle.image} />
          <div className={clsx(HasilSlipStyle.name_wrapper, "capitalize")}>
            <div className={HasilSlipStyle.name}>{info.getValue()}</div>
            <div className={HasilSlipStyle.posisi}>
              {capitalizeEachWord(item.tipe_gaji, "_")} -{" "}
              {capitalizeEachWord(item.nama_jabatan, "_")}
              {capitalizeEachWord(item.nama_departemen, "_")}
            </div>
          </div>
        </div>
      );
    },
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor("gajiPokok", {
    header: () => "Gaji Pokok",
    cell: (info) => numberToMoney(info.getValue()),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("gajiBorongan", {
    header: () => "Gaji Borongan",
    cell: (info) => numberToMoney(info.getValue()),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("tunjangan", {
    header: () => "Tunjangan",
    cell: (info) => numberToMoney(info.getValue()),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("premiHadir", {
    header: () => "Premi Hadir",
    cell: (info) => numberToMoney(info.getValue()),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("lembur", {
    header: () => "Lembur",
    cell: (info) => numberToMoney(info.getValue()),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("nama", {
    header: () => "Total",
    cell: (info) => numberToMoney(info.row.original.totalGaji),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("nama", {
    header: () => "Action",
    cell: (info) => <>
      <Button
        size="lite"
        text="Delete"
        className="h-5 rounded"
        color="danger"
        buttonType={ButtonTypeEnum.CLICKABLE_TEXT}
        onClick={() => {
          info.row.original.onDelete(info.row.original.id)
        }}
      />
    </>,
    footer: (info) => info.column.id,
  }),
];
