import ApiRequest from "./ApiRequest";

interface IParamType {
}

class AxiosSearchDokumen extends ApiRequest<IParamType> {
    constructor() {
        super("/search_dokumen");
    }

    get(token: string, id_pegawai: string, id_jenis_dokumen: string) {
        return this.get_request(token,
            {
                id_pegawai: id_pegawai,
                id_jenis_dokumen: id_jenis_dokumen,
            }
        );
    }
}

export default AxiosSearchDokumen;
