import ApiRequest from "./ApiRequest";

interface IParamType {
}

class AxiosListRoles extends ApiRequest<IParamType> {
  constructor() {
    super("/list_role");
  }

  get(token: string) {
    return this.get_request(token);
  }
}

export default AxiosListRoles;
