
import ApiRequest from "./ApiRequest";
interface IParamType {
}

class AxiosGetJabatan extends ApiRequest<IParamType> {
    constructor() {
        super("/jabatan");
    }

    get(token: string) {
        return this.get_request(token);
    }
}

export default AxiosGetJabatan;
