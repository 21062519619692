import ApiRequest from "./ApiRequest";

interface IDeleteIzin {
  id: number,
}

class AxiosDeleteTHR extends ApiRequest<IDeleteIzin> {
  constructor() {
    super("/tunjangan/delete");
  }

  post(token: string, izin_data: IDeleteIzin) {
    return this.post_request({
      token: token,
      params: { ...izin_data }
    });
  }
}

export default AxiosDeleteTHR;
