import ApiRequest from "./ApiRequest";

interface IParamType { }

class AxiosGetListPotongan extends ApiRequest<IParamType> {
  constructor() {
    super("/potongan/list");
  }

  get(
    token: string,
    pagination?: string,
    tanggal_mulai?: string,
    tanggal_selesai?: string,
    nama_pegawai?: string,
    id_jabatan?: number,
    id_departemen?: number,
    id_perusahaan?: number
  ) {
    if (pagination) this.path = pagination;
    return this.get_request(token, {
      tanggal_mulai: tanggal_mulai,
      tanggal_selesai: tanggal_selesai,
      nama_pegawai: nama_pegawai,
      id_jabatan,
      id_departemen,
      id_perusahaan
    });
  }
}

export default AxiosGetListPotongan;
