import ApiRequest from "./ApiRequest";

interface ICreateKiriman {
    id_pegawai: string;
    jam_berangkat: string;
    jam_kembali: string;
    kota: string;
}

class AxiosCreateKiriman extends ApiRequest<ICreateKiriman> {
    constructor() {
        super("/kiriman/create");
    }

    post(token: string, absen_data: ICreateKiriman) {
        return this.post_request({
            token: token,
            params: { ...absen_data }
        });
    }
}

export default AxiosCreateKiriman;
