import { useContext, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { SidebarGroup, SidebarItem } from "./SidebarData";
import SidebarGroupStyle from "./sidebargroup.module.css";
import { AnimatePresence, motion } from "framer-motion";
import clsx from "clsx";
import userAuth from "../context/UserAuth";

interface SidebarGroupProps {
  group: SidebarGroup;
  updatepath: (value: string) => void;
  selectedPath: string;
}
const SidebarGroupComponent: React.FC<SidebarGroupProps> = (
  props: SidebarGroupProps
) => {
  const [subnav, setSubnav] = useState(false);
  const { user } = useContext(userAuth);

  const showSubnav = () => setSubnav(!subnav);

  const group = props.group;
  const isSelected = props.selectedPath.includes(group.path);

  const listSidebarItem = () => {
    return group.subNav.map((item: SidebarItem, index: number) => {
      const sub_selected = props.selectedPath.includes(item.path);

      if (!user.role) return
      if (item.whitelist.includes(user.role))
        return (
          <Link
            to={"/dashboard" + item.path}
            key={index}
            onClick={() => props.updatepath("/dashboard" + item.path)}
            className={
              (sub_selected ? SidebarGroupStyle.selected_link : " ") +
              " " +
              SidebarGroupStyle.item
            }
          >
            <span>{item.title}</span>
            <div
              className={SidebarGroupStyle.indicator}
              style={{
                transition: "background-color 1s",
                backgroundColor: sub_selected ? "#2d61d6" : "",
              }}
            ></div>
          </Link>
        );
    });
  };

  return (
    <div className={SidebarGroupStyle.group}>
      <Link
        to="#"
        onClick={group.subNav && showSubnav}
        className={
          (isSelected ? SidebarGroupStyle.selected_link : " ") +
          " " +
          SidebarGroupStyle.group_link
        }
      >
        <div className="flex gap-2">
          <div className="flex flex-col justify-center">
            <group.icon
              className={clsx(SidebarGroupStyle.group_icon, "")}
              color={isSelected ? "#2d61d6" : " "}
            />
          </div>
          <span>{group.title}</span>
        </div>
        <div className="flex flex-col justify-center">
          {group.subNav && subnav ? (
            <group.iconOpened />
          ) : group.subNav.length > 0 ? (
            <group.iconClosed />
          ) : null}
        </div>
      </Link>
      <AnimatePresence>
        {subnav ? (
          <motion.div
            className={SidebarGroupStyle.item_wrapper}
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "fit-content", opacity: 1 }}
            transition={{ duration: 0.5 }}
            exit={{ height: 0, opacity: 0 }}
          >
            {listSidebarItem()}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </div>
  );
};

export default SidebarGroupComponent;
