import React, { useContext, useEffect, useState } from "react";
import AxiosFindKontrak from "../../../api/AxiosFindKontrak";
import userAuth from "../../../context/UserAuth";
import selectedPegawai from "../../../context/SelectedPegawai";
import ListKontrakStyle from "./InfoKontrak.module.css";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";

interface IInfoKontrakProps {}

type LatestKontrakResponse = {
  jenis_kontrak: string;
  kontrak_is_active: boolean;
  expired: Date;
};

const InfoKontrak: React.FC<IInfoKontrakProps> = () => {
  const { user } = useContext(userAuth);
  const { pegawai } = useContext(selectedPegawai);
  const [latestKontrak, setLatestKontrak] = useState<LatestKontrakResponse>();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user.token || !pegawai?.id) return;
    const axiosFindkontrak = new AxiosFindKontrak();

    axiosFindkontrak.get(user.token, pegawai.id).then((response) => {
      const data = response.data.data;
      setLatestKontrak({
        ...data,
      });
      
    });
  }, []);
  return (
    <>
      <div className="text-3xl font-bold text-left mb-4">Kontrak Kerja</div>
      <div
        className={`
            ${ListKontrakStyle.latest_kontrak} 
            ${
              latestKontrak?.kontrak_is_active
                ? ListKontrakStyle.active_kontrak
                : ListKontrakStyle.expired_kontrak
            }
        `}
      >
        <div>{latestKontrak?.jenis_kontrak}</div>
        <div>
          <>Exp: {latestKontrak?.expired}</>
        </div>
      </div>
      <div className={ListKontrakStyle.button_wrapper}>
        <Button
          text="Lihat Semua Kontrak"
          onClick={() => navigate("/dashboard/hrd/list_kontrak")}
          size="small"
          className="h-[55px]"
        />
      </div>
    </>
  );
};

export default InfoKontrak;
