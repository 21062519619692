import axios from "axios";

class ApiRequest<ParamType> {
  protected path: string;

  constructor(path: string) {
    const api_url = process.env.REACT_APP_API_PATH;
    this.path = api_url + path;
  }

  get_request(token?: string, params?: ParamType, options?: any) {
    const config = {
      headers: { Authorization: `Bearer ${token}` },
      params: params,
      ...options,
    };

    return axios.get(this.path, config);
  }

  post_request(param: {
    params?: ParamType;
    token?: string;
    formData?: FormData;
  }) {
    if (param.params == null && param.formData == null) {
      if (param.token == null) return axios.post(this.path);

      const config = {
        headers: { Authorization: `Bearer ${param.token}` },
      };
      return axios.post(this.path, undefined, {
        headers: { ...config.headers },
      });
    }

    const config = {
      headers: { Authorization: `Bearer ${param.token}` },
    };

    if (param.formData != undefined) {
      return axios.post(this.path, param.formData, {
        headers: {
          ...config.headers,
          "Content-Type": "multipart/form-data",
        },
      });
    }
    return axios.post(this.path, param.params, config);
  }
}

export default ApiRequest;
