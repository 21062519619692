/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import { ButtonTypeEnum } from "../../enums/ButtonTypeEnum";
import { extractTypeToArray } from "../../helpers/TypeHelper";
import TFormItem from "../../models/TFormItem";
import Button from "../Button";
import ListFormStyle from "./ListForm.module.css";
import ListItem from "./ListItem";
import clsx from "clsx";

interface IListFormProps<T> {
  onListChange: (list: T[], deleted_id: number[]) => void;
  initialList: T[];
  defaultCreateItem: T;
  listTitle: string;
  addButtonText: string;
  listField: TFormItem[];
}

export default function ListForm<T>(props: IListFormProps<T>) {
  const [listItem, setListItem] = useState<T[]>(props.initialList ?? []);
  const [headerList, setHeaderList] = useState<(keyof T)[]>([]);
  const [deletedID, setDeletedID] = useState<number[]>([]);

  useEffect(() => {
    if (listItem.length <= 0) return;
    setHeaderList(extractTypeToArray<T>(listItem[0]));
  }, [listItem]);

  function addList() {
    props.onListChange([...listItem, props.defaultCreateItem], [...deletedID]);
    setListItem([...listItem, props.defaultCreateItem]);
  }

  function changeList(index: number, key: keyof T, value: string) {
    listItem[index] = { ...listItem[index], [key]: value };
    props.onListChange([...listItem], [...deletedID]);
    setListItem(listItem);
  }

  function deleteList(index: number) {
    let newDeleted = [...deletedID];

    if (listItem.length > 0) {
      const deleted = listItem.splice(index, 1);
      if (deleted[0]["id" as keyof T]) {
        newDeleted.push(deleted[0]["id" as keyof T] as number);
      }
    }
    if (listItem.length === 0) setHeaderList([]);

    props.onListChange([...listItem], [...newDeleted]);
    setDeletedID([...newDeleted]);
    setListItem([...listItem]);
  }

  return (
    <div className={clsx(ListFormStyle.card_wrapper, "")}>
      <label className={ListFormStyle.title}>{props.listTitle}</label>
      <table className={clsx(ListFormStyle.table, "!w-full")}>
        <thead>
          {listItem.length > 0 ? (
            <th className={clsx(ListFormStyle.num_col, "font-normal")}>No.</th>
          ) : (
            <></>
          )}
          {headerList.map((value) => {
            const field = props.listField.filter((v) => v.key === value)[0];
            if (!field) return;
            return <th className="font-normal">{field.label}</th>;
          })}
        </thead>
        <tbody>
          {listItem.map((item: T, index) => {
            return (
              <ListItem<T>
                index={index}
                data={item}
                inputFields={props.listField}
                onItemChange={(index, key, value) =>
                  changeList(index, key, value)
                }
                onDelete={(index) => deleteList(index)}
              />
            );
          })}
        </tbody>
      </table>
      <Button
        text={props.addButtonText}
        onClick={() => addList()}
        size="small"
        buttonType={ButtonTypeEnum.CLICKABLE_TEXT}
      />
    </div>
  );
}
