import ApiRequest from "./ApiRequest";

interface ICreateLembarKerjaBorongan {
    tanggal_pekerjaan: Date;
    list_pekerja: number[];
    mesin: string;
    jumlah_pekerjaan: number;
    jumlah_output: number;
    target_bahan: number;
    id_pekerjaan_spk: number;
}

class AxiosCreateLembarKerjaBorongan extends ApiRequest<ICreateLembarKerjaBorongan> {
    constructor() {
        super("/produksi/create_borongan");
    }

    post(
        token: string,
        {
            tanggal_pekerjaan,
            list_pekerja,
            mesin,
            jumlah_pekerjaan,
            jumlah_output,
            target_bahan,
            id_pekerjaan_spk,
        }: ICreateLembarKerjaBorongan
    ) {
        return this.post_request({
            token: token,
            params: {
                tanggal_pekerjaan: tanggal_pekerjaan,
                list_pekerja: list_pekerja,
                mesin: mesin,
                jumlah_pekerjaan: jumlah_pekerjaan,
                jumlah_output: jumlah_output,
                target_bahan: target_bahan,
                id_pekerjaan_spk: id_pekerjaan_spk,
            },
        });
    }
}

export default AxiosCreateLembarKerjaBorongan;
