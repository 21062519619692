import ApiRequest from "./ApiRequest";

export type IUpdateDepartemenParam = {
    id: number,
    nama: string,
}

class AxiosUpdateDepartemen extends ApiRequest<IUpdateDepartemenParam> {
    constructor() {
        super("/departemen/update");
    }

    post(
        token: string,
        data: IUpdateDepartemenParam
    ) {
        return this.post_request({
            token: token,
            params: { ...data }
        });
    }
}

export default AxiosUpdateDepartemen;
