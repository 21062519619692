import React from "react"
import TSim from "../../../models/TSim";
import ListForm from "../ListForm";


interface ISimListFormProps {
    onSimListChange: (value: TSim[]) => void;
    defaultSim: TSim[]
}

const SimListForm: React.FC<ISimListFormProps> = (
    props: ISimListFormProps
) => {
    return (
        <>
            <ListForm<TSim>
                onListChange={(list) => {
                    props.onSimListChange(list)
                }}
                initialList={props.defaultSim}
                defaultCreateItem={{
                    tipe: "A",
                    no: "",
                    tgl_habis: ""
                }}
                listTitle="Sim"
                addButtonText="Tambah SIM"
                listField={[
                    {
                        label: "Tipe Sim",
                        key: "tipe",
                        type: "option",
                        placeholder: "",
                        onChange() { },
                        options: [
                            {
                                label: "A",
                                value: "a"
                            },
                            {
                                label: "B1",
                                value: "b1"
                            },
                            {
                                label: "B2",
                                value: "b2"
                            },
                            {
                                label: "C",
                                value: "c"
                            },
                            {
                                label: "D",
                                value: "d"
                            },
                        ]
                    },
                    {
                        label: "No.",
                        key: "no",
                        type: "text",
                        placeholder: "Nomor SIM",
                        onChange: () => { }
                    },
                    {
                        label: "Tanggal Berakhir",
                        key: "tgl_habis",
                        type: "date",
                        placeholder: "",
                        onChange: () => { }
                    },
                ]}
            />
        </>
    );
};

export default SimListForm;

