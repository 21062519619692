import ApiRequest from "./ApiRequest";
interface IParamType {}

class AxiosGetListSPK extends ApiRequest<IParamType> {
  constructor() {
    super("/produksi/list_spk");
  }

  get(token: string, param: IParamType) {
    return this.get_request(token, {...param});
  }
}

export default AxiosGetListSPK;
