
import ApiRequest from "./ApiRequest";

interface ICreateJabatan {
    nama: string,
    id_skema_gaji: number,
}

class AxiosCreateJabatan extends ApiRequest<ICreateJabatan> {
    constructor() {
        super("/jabatan/create");
    }

    post(token: string, departemen_data: ICreateJabatan) {
        return this.post_request({
            token: token,
            params: { ...departemen_data }
        });
    }
}

export default AxiosCreateJabatan;
