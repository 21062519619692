/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { ReactNode, useContext, useEffect, useState } from "react";
import userAuth from "../../../context/UserAuth";
import selectedPegawai from "../../../context/SelectedPegawai";
import DataPribadiStyle from "./DataPribadi.module.css";
import Button from "../../../components/Button";
import TOption from "../../../models/TOption";
import AxiosFindPegawai from "../../../api/AxiosFindPegawai";
import { capitalizeEachWord } from "../../../helpers/StringHelper";
import AxiosUpdatePegawai from "../../../api/AxiosUpdatePegawai";
import AxiosListCreatePegawaiOptions from "../../../api/AxiosGetCreatePegawaiOptions";
import UneditableListBahasaItem from "./UneditableBahasaItem";
import { TFormPegawai } from "../../../models/TFormPegawai";
import PegawaiForm from "../PegawaiForm";
import UneditableList from "./UneditableList";
import TSim from "../../../models/TSim";
import TBahasaItem from "../../../models/TBahasaItem";
import TKecelakaan from "../../../models/TKecelakaan";
import TCatatanPolisi from "../../../models/TCatatanPolisi";
import TPsikologi from "../../../models/TPsikologi";
import TMiliter from "../../../models/TMiliter";
import TBacaan from "../../../models/TBacaan";
import TKeluarga from "../../../models/TKeluarga";
import TSakit from "../../../models/TSakit";
import TKegiatanOrganisasi from "../../../models/TKegiatanOrganisasi";
import TPendidikan from "../../../models/TPendidikan";
import TPengalamanKerja from "../../../models/TPengalamanKerja";
import clsx from "clsx";
import { toast } from "react-toastify";
import { Loader } from "@mantine/core";

interface IDataPribadiProps {}

const DataPribadi: React.FC<IDataPribadiProps> = (props: IDataPribadiProps) => {
  const { user } = useContext(userAuth);
  const { pegawai } = useContext(selectedPegawai);

  const [jabatanOptions, setJabatanOptions] = useState<TOption[]>([]);
  const [perusahaanOptions, setPerusahaanOptions] = useState<TOption[]>([]);
  const [isEditing, setIsEditing] = useState(false);

  const [departemenOptions, setDepartemenOptions] = useState<TOption[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //data pegawai
  const [dataPegawai, setDataPegawai] = useState<TFormPegawai>({
    id_finpro: 0,
    nama: "",
    nip: "",
    id_perusahaan: "1",
    alias: "",
    telp: "",
    tgl_lahir: "",
    tempat_lahir: "",
    gender: "0",
    alamat_ktp: "",
    alamat_domisili: "",
    email: "",
    nik: "",
    id_jabatan: 1,
    id_departemen: 1,
    nama_bank: "",
    nama_rek: "",
    no_rek: "",
    no_npwp: "",
    agama: "",
    kebangsaan: "",
    no_bpjs_kesehatan: "",
    no_bpjs_tenaga_kerja: "",

    //detil pegawai
    sim: [],
    anak_ke: 1,
    status_nikah: "bujangan",
    tahun_nikah: "",
    bahasa_dikuasai: [],
    pengalaman_memimpin: "",
    minat: "",
    minat_lingkungan: "",
    hobby: "",
    berat_badan: 0,
    tinggi_badan: 0,
    kacamata_kiri: "",
    kacamata_kanan: "",
    detil_sakit: [],
    detil_kecelakaan: [],
    detil_polisi: [],
    detil_psikologi: [],
    detil_militer: [],
    detil_bacaan: [],
    keluarga: [],
    pendidikan: [],
    kegiatan_organisasi: [],
    pengalaman_kerja: [],
    tgl_mulai_kerja: "",
    tgl_resign: "",
  });
  const [deletedIds, setDeletedIds] = useState<{
    deleted_keluarga_id: number[];
    deleted_pendidikan_id: number[];
    deleted_kegiatan_organisasi_id: number[];
    deleted_pengalaman_kerja_id: number[];
  }>({
    deleted_keluarga_id: [] as number[],
    deleted_pendidikan_id: [] as number[],
    deleted_kegiatan_organisasi_id: [] as number[],
    deleted_pengalaman_kerja_id: [] as number[],
  });

  function uneditableField(label: string, value?: string) {
    switch (label) {
      case "bahasa_dikuasai":
        if (!value) break;
        return (
          <div className={DataPribadiStyle.uneditable_bahasa}>
            <span className={DataPribadiStyle.label}>
              {capitalizeEachWord(label, "_")}
            </span>
            <UneditableListBahasaItem data={value} />
          </div>
        );
      case "gender":
        return (
          <div className={DataPribadiStyle.uneditable_field}>
            <span className={DataPribadiStyle.label}>Gender</span>:
            <div className={DataPribadiStyle.value}>
              {parseInt(dataPegawai.gender) === 0 ? "Laki-Laki" : "Perempuan"}
            </div>
          </div>
        );
      case "id_jabatan":
        const jabatan = jabatanOptions.filter(
          (jabatan) => jabatan.value == value
        );
        let jabatanValue = "";
        if (jabatan[0]) jabatanValue = jabatan[0].label;
        return (
          <div className={DataPribadiStyle.uneditable_field}>
            <span className={DataPribadiStyle.label}>Jabatan</span>:
            <div className={DataPribadiStyle.value}>
              {jabatanValue !== ""
                ? capitalizeEachWord(jabatanValue, "_")
                : null}
            </div>
          </div>
        );
      case "id_departemen":
        const departemen = departemenOptions.filter(
          (departemen) => departemen.value == value
        );
        let departemenValue = "";
        if (departemen[0]) departemenValue = departemen[0].label;
        return (
          <div className={DataPribadiStyle.uneditable_field}>
            <span className={DataPribadiStyle.label}>Departemen</span>:
            <div className={DataPribadiStyle.value}>
              {departemenValue !== ""
                ? capitalizeEachWord(departemenValue, "_")
                : null}
            </div>
          </div>
        );
      case "id_perusahaan":
        const perusahaan = perusahaanOptions.filter(
          (perusahaan) => perusahaan.value == value
        );
        let perusahaanValue = "";
        if (perusahaan[0]) perusahaanValue = perusahaan[0].label;
        return (
          <div className={DataPribadiStyle.uneditable_field}>
            <span className={DataPribadiStyle.label}>Perusahaan </span>:
            <div className={DataPribadiStyle.value}>{perusahaanValue}</div>
          </div>
        );
      default:
        return (
          <div className={DataPribadiStyle.uneditable_field}>
            <span className={DataPribadiStyle.label}>
              {capitalizeEachWord(label, "_")}
            </span>
            :
            <div className={DataPribadiStyle.value}>
              {value !== "" && value ? capitalizeEachWord(value, "_") : null}
            </div>
          </div>
        );
    }
  }

  function fieldPegawai() {
    const leftComponents: ReactNode[] = [];
    const rightComponents: ReactNode[] = [];

    const dataList = Object.entries(dataPegawai);
    const excluded_key = [
      "id",
      "id_pegawai",
      "created_at",
      "updated_at",
      "sim",
      "detil_sakit",
      "detil_kecelakaan",
      "detil_polisi",
      "detil_psikologi",
      "detil_militer",
      "detil_bacaan",
      "bahasa_dikuasai",
      "perusahaan",
      "jabatan",
      "departemen",
      "keluarga",
      "pendidikan",
      "kegiatan_organisasi",
      "pengalaman_kerja",
    ];
    dataList.forEach((data, index) => {
      if (excluded_key.includes(data[0])) return;
      if (index < (dataList.length - 14) / 2) {
        leftComponents.push(uneditableField(data[0], data[1]?.toString()));
      } else {
        rightComponents.push(uneditableField(data[0], data[1]?.toString()));
      }
    });

    return (
      <>
        <div className={clsx(DataPribadiStyle.form_wrapper, "!p-0 flex gap-4")}>
          <div
            className={clsx(DataPribadiStyle.column_container, "!m-0")}
            key="left"
          >
            {leftComponents}
          </div>
          <div
            className={clsx(DataPribadiStyle.column_container, "!m-0")}
            key="right"
          >
            {rightComponents}
          </div>
        </div>
      </>
    );
  }

  function findPegawai() {
    const axiosFindPegawai = new AxiosFindPegawai();

    if (user.token == null || !pegawai) return;
    if (pegawai.id == null) return;

    axiosFindPegawai.get(user.token, pegawai.id).then((response) => {
      const response_pegawai = response.data.data;
      
      const total: any = {};
      Object.assign(total, {
        ...dataPegawai,
        ...response_pegawai.pegawai,
        status_nikah: response_pegawai.status_nikah ?? "bujangan",
        jabatan: response_pegawai.id_jabatan,
        perusahaan: response_pegawai.id_perusahaan,
        departemen: response_pegawai.id_departemen,
        keluarga: response_pegawai.keluarga.map((keluarga: TKeluarga) => ({
          ...keluarga,
          id_keluarga: keluarga.id,
        })),
        pendidikan: response_pegawai.pendidikan.map(
          (pendidikan: TPendidikan) => ({
            ...pendidikan,
            id_pendidikan: pendidikan.id,
          })
        ),
        kegiatan_organisasi: response_pegawai.kegiatan_organisasi.map(
          (kegiatan_organisasi: TKegiatanOrganisasi) => ({
            ...kegiatan_organisasi,
            id_kegiatan_organisasi: kegiatan_organisasi.id,
          })
        ),
        pengalaman_kerja: response_pegawai.pengalaman_kerja.map(
          (pengalaman_kerja: TPengalamanKerja) => ({
            ...pengalaman_kerja,
            id_pengalaman_kerja: pengalaman_kerja.id,
          })
        ),
      });
      const detail_pegawai = response_pegawai.detail_pegawai;
      if (detail_pegawai) {
        Object.assign(total, {
          ...detail_pegawai,
          sim: JSON.parse(detail_pegawai.sim),
          bahasa_dikuasai: JSON.parse(detail_pegawai.bahasa_dikuasai),
          detil_sakit: JSON.parse(detail_pegawai.detil_sakit),
          detil_kecelakaan: JSON.parse(detail_pegawai.detil_kecelakaan),
          detil_polisi: JSON.parse(detail_pegawai.detil_polisi),
          detil_psikologi: JSON.parse(detail_pegawai.detil_psikologi),
          detil_militer: JSON.parse(detail_pegawai.detil_militer),
          detil_bacaan: JSON.parse(detail_pegawai.detil_bacaan),
        });
      }

      setDataPegawai(total);

      ListPegawaiOptions(total);
    });
  }

  function updatePegawai() {
    if (!user.token || !pegawai?.id || isLoading === true) return;
    setIsLoading(true);

    const axiosCreatePegawai = new AxiosUpdatePegawai();

    axiosCreatePegawai
      .post(user.token, {
        ...dataPegawai,
        id_pegawai: parseInt(pegawai.id),
        keluarga_delete_id: deletedIds.deleted_keluarga_id,
        pendidikan_delete_id: deletedIds.deleted_pendidikan_id,
        kegiatan_organisasi_delete_id:
          deletedIds.deleted_kegiatan_organisasi_id,
        pengalaman_kerja_delete_id: deletedIds.deleted_pengalaman_kerja_id,
      })
      .then((response) => {
        if (response.data.success) {
          toast.success("Berhasil mengubah data pegawai!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsEditing(false);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
      });
  }

  function ListPegawaiOptions(currentDataPegawai: any) {
    if (!user.token) return;
    if (departemenOptions.length > 0 || jabatanOptions.length > 0) return;
    const axiosOptions = new AxiosListCreatePegawaiOptions();
    axiosOptions.get(user.token).then((response) => {
      const data = response.data.data;
      setDataPegawai({
        ...currentDataPegawai,
        jabatan: parseInt(data.jabatan_options[0].id),
      });
      setJabatanOptions(
        data.jabatan_options.map((option: any) => {
          return { label: option.nama, value: option.id };
        })
      );
      setDataPegawai({
        ...currentDataPegawai,
        id_perusahaan: parseInt(data.perusahaan_options[0].id),
      });
      setPerusahaanOptions(
        data.perusahaan_options.map((option: any) => {
          return { label: option.nama, value: option.id };
        })
      );
      setDataPegawai({
        ...currentDataPegawai,
        departemen: parseInt(data.departemen_options[0].id),
      });
      setDepartemenOptions(
        data.departemen_options.map((option: any) => {
          return { label: option.nama, value: option.id };
        })
      );
    });
  }

  useEffect(() => {
    findPegawai();
  }, []);

  return (
    <div className={clsx(DataPribadiStyle.data_pribadi, "!py-6 !px-12")}>
      <div
        className={clsx(
          DataPribadiStyle.main_title,
          "!p-0 !my-4 flex justify-between"
        )}
      >
        <div className="text-3xl font-bold w-full">
          Data Pribadi - {pegawai?.name}
        </div>
        <Button
          text={isEditing ? "Batal Edit Pegawai" : "Edit Pegawai"}
          onClick={() => {
            setIsEditing(!isEditing);
          }}
          size="minimum"
          className="min-w-[200px] min-h-[55px]"
        />
      </div>
      {isEditing ? (
        <PegawaiForm
          onPegawaiChange={(value) => setDataPegawai(value)}
          defaultDatapegawai={dataPegawai}
          onDeleted={(value) => {
            setDeletedIds(value);
          }}
          className="!py-0 !mx-0"
        />
      ) : (
        <div className={clsx(DataPribadiStyle.info_wrapper, "!p-0 !m-0")}>
          {fieldPegawai()}
          <UneditableList<TSim> list={dataPegawai.sim} title="SIM" />
          <UneditableList<TSakit>
            list={dataPegawai.detil_sakit}
            title="Sakit"
          />
          <UneditableList<TBahasaItem>
            list={dataPegawai.bahasa_dikuasai}
            title="Bahasa Dikuasai"
          />
          <UneditableList<TKecelakaan>
            list={dataPegawai.detil_kecelakaan}
            title="Riwayat Kecelakaan"
          />
          <UneditableList<TCatatanPolisi>
            list={dataPegawai.detil_polisi}
            title="Riwayat Polisi"
          />
          <UneditableList<TPsikologi>
            list={dataPegawai.detil_psikologi}
            title="Riwayat Psikologi"
          />
          <UneditableList<TMiliter>
            list={dataPegawai.detil_militer}
            title="Riwayat Militer"
          />
          <UneditableList<TBacaan>
            list={dataPegawai.detil_bacaan}
            title="Referensi Bacaan"
          />
          <UneditableList<TKeluarga>
            list={dataPegawai.keluarga}
            title="Keluarga"
          />
          <UneditableList<TKegiatanOrganisasi>
            list={dataPegawai.kegiatan_organisasi}
            title="Kegiatan Organisasi"
          />
          <UneditableList<TPendidikan>
            list={dataPegawai.pendidikan}
            title="Riwayat Pendidikan"
          />
          <UneditableList<TPengalamanKerja>
            list={dataPegawai.pengalaman_kerja}
            title="Riwayat Pengalaman Kerja"
          />
        </div>
      )}
      {isEditing ? (
        <Button
          onClick={() => updatePegawai()}
          text={
            isLoading ? (
              <>
                <Loader color="indigo" size="sm" />
              </>
            ) : (
              "Simpan Perubahan Data Pegawai"
            )
          }
          isDisabled={isLoading}
          size="small"
          className="h-[55px] mt-4"
        />
      ) : null}
    </div>
  );
};

export default DataPribadi;
