import ApiRequest from "./ApiRequest";

interface ICreateBiayaTambahanParams {
  id_pegawai: string;
  tipe: string;
  keterangan: string;
  tanggal: string;
  jumlah: string;
}

class AxiosCreateBiayaTambahan extends ApiRequest<ICreateBiayaTambahanParams> {
  constructor() {
    super("/biaya_tambahan/create");
  }

  post(token: string, pegawai_data: ICreateBiayaTambahanParams) {
    return this.post_request({
      token: token,
      params: { ...pegawai_data },
    });
  }
}

export default AxiosCreateBiayaTambahan;
