import React, { useContext, useState } from "react";
import { IconContext } from "react-icons";
import SidebarStyle from "./sidebar.module.css";
import { SidebarData, SidebarGroup } from "../main/SidebarData";
import SidebarGroupComponent from "./SidebarGroup";
import userAuth from "../context/UserAuth";

interface ISidebarProps { }

const Sidebar: React.FC<ISidebarProps> = () => {
  const [selectedPath, setSelectedPath] = useState("/dashboard");
  const { user } = useContext(userAuth);

  return (
    <IconContext.Provider value={{ color: "#79818e" }}>
      <nav className={SidebarStyle.sidebar}>
        <div className={SidebarStyle.item_wrapper}>
          {SidebarData.map((item: SidebarGroup, index) => {
            if (!user.role) return
            if (item.whitelist.includes(user.role))
              return <SidebarGroupComponent
                group={item}
                key={index}
                updatepath={(value: string) => setSelectedPath(value)}
                selectedPath={selectedPath}
              />
          }, user)}
        </div>
      </nav>
    </IconContext.Provider>
  );
};

export default Sidebar;
