import { createColumnHelper } from "@tanstack/react-table";
import Button from "../../components/Button";
import { capitalizeEachWord } from "../../helpers/StringHelper";

export type Item = {
    id: number;
    nama: string;
    soft_deleted: boolean;
    action: () => void;
};

const columnHelper = createColumnHelper<Item>();
export const columns = [
    columnHelper.accessor("id", {
        header: () => "No.",
        cell: (info) => info.getValue(),
        footer: (info) => info.column.id,
    }),

    columnHelper.accessor((row) => row.nama, {
        id: "nama",
        cell: (info) => <>{capitalizeEachWord(info.getValue(), "_")}</>,
        header: () => <span>Nama</span>,
        footer: (info) => info.column.id,
    }),
    // columnHelper.accessor('soft_deleted', {
    //     id: 'soft_deleted',
    //     cell: info => <>{info.getValue() ? "inactive" : "active"}</>,
    //     header: () => <span>Soft Deleted</span>,
    //     footer: info => info.column.id,
    // }),
    columnHelper.accessor("action", {
        header: "Action",
        cell: (inf) => (
            <Button
                size="small"
                text="Edit"
                onClick={() => {
                    inf.row.original.action();
                }}
            />
        ),
        footer: (info) => info.column.id,
    }),
];
