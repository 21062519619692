import { ReactNode, useEffect, useState } from "react";
import { capitalizeEachWord } from "../../../helpers/StringHelper";
import { extractTypeToArray } from "../../../helpers/TypeHelper";
import ListStyle from "./UneditableList.module.css";
import clsx from "clsx";

interface IUneditableListProps<T> {
  title: string;
  list: T[];
}
export default function UneditableList<T>(props: IUneditableListProps<T>) {
  const [headerList, setHeaderList] = useState<(keyof T)[]>([]);

  useEffect(() => {
    if (props.list.length <= 0) return;
    setHeaderList(extractTypeToArray<T>(props.list[0]));
  }, [props.list]);
  const exclude = [
    "id",
    "created_at",
    "updated_at",
    "id_pegawai",
    "id_dokumen",
    "id_keluarga",
    "id_pendidikan",
    "id_kegiatan_organisasi",
    "id_pengalaman_kerja",
  ];

  return (
    <div className={clsx(ListStyle.container, "overflow-x-auto")}>
      <div className={ListStyle.title}>{props.title}</div>
      {props.list.length > 0 ? (
        <table className={clsx(ListStyle.table, "")}>
          <thead className={ListStyle.t_head}>
            {props.list.length > 0 ? <th className={"hi"}>No.</th> : <></>}
            {headerList.map((value) => {
              if (exclude.includes(value as string)) return <> </>;
              if (value === "no") return <th>No Sim</th>;
              return <th>{capitalizeEachWord(value.toString(), "_")}</th>;
            })}
          </thead>
          <tbody>
            {props.list.map((item: T, index) => {
              const component: ReactNode[] = [];
              component.push(<td>{index + 1}</td>);
              headerList.forEach((key) => {
                if (exclude.includes(key as string)) return <> </>;
                if (key === "id") return <> </>;
                if (key === "gender") {
                  if (item[key] === 0) {
                    component.push(<td> Pria </td>)
                    return
                  }
                  if (item[key] === 1) {
                    component.push(<td> Wanita </td>)
                    return
                  }
                }
                if (key === "is_alive") {
                  if (item[key] === 1) {
                    component.push(<td> Hidup </td>)
                    return
                  }
                  if (item[key] === 0) {
                    component.push(<td> Meninggal </td>)
                    return
                  }
                }
                component.push(
                  <td>
                    <>{item[key]}</>
                  </td>
                );
              });
              return <tr className={ListStyle.t_row}>{component}</tr>;
            })}
          </tbody>
        </table>
      ) : (
        <div className="flex justify-center">Data tidak ditemukan.</div>
      )}
    </div>
  );
}
