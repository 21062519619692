import ApiRequest from "./ApiRequest";

interface IParamType {
    id_pegawai: string,
    tanggal: string,
}

class AxiosFindJatahCuti extends ApiRequest<IParamType> {
    constructor() {
        super("/jatah_cuti/find");
    }

    get(
        token: string,
        param: IParamType,
    ) {
        return this.get_request(token,
            { ...param }
        );
    }
}

export default AxiosFindJatahCuti;
