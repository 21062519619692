import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { ReactNode } from "react";
import Button from "../../../components/Button";
import { KodeKehadiranEnum } from "../../../enums/KodeKehadiranEnum";
import MingguanStyle from './Mingguan.module.css';

export type Item = {
    id: number,
    nama: string,
    presensi: KodeKehadiranEnum[],
    status_absen: statusAbsensi,
    action: () => void
}
type statusAbsensi = {
    hadir: number,
    alpha: number,
    izin: number,
    sakit: number,
}

const columnHelper = createColumnHelper<Item>()

export const columns = (presensi_header: ReactNode): ColumnDef<Item, any>[] => {
    return [
        columnHelper.accessor('id', {
            header: () => "id",
            cell: info => info.getValue(),
            footer: info => info.column.id,
        }),

        columnHelper.accessor('nama', {
            id: 'nama',
            cell: info => <>{info.getValue()}</>,
            header: () => <span>Nama</span>,
            footer: info => info.column.id,
        }),

        columnHelper.accessor('presensi', {
            header: () => presensi_header,
            cell: info => {
                const data = info.row.original.presensi
                return <div className={MingguanStyle.status}>
                    {data.map((kehadiran) => <>
                        <div className={MingguanStyle.cell}>
                            {kehadiran}
                        </div>
                    </>)}
                </div>
            },
            footer: info => info.column.id,
        }),

        columnHelper.accessor('status_absen', {
            header: () => <div className={MingguanStyle.status_header}>
                Status
                <div className={MingguanStyle.status}>
                    <div className={MingguanStyle.cell}>H</div>
                    <div className={MingguanStyle.cell}>A</div>
                    <div className={MingguanStyle.cell}>I</div>
                    <div className={MingguanStyle.cell}>S</div>
                </div>
            </div>,
            cell: info => {
                const data = info.row.original.status_absen

                return <div className={MingguanStyle.status}>
                    <div className={MingguanStyle.cell}>{data.hadir}</div>
                    <div className={MingguanStyle.cell}>{data.alpha}</div>
                    <div className={MingguanStyle.cell}>{data.izin}</div>
                    <div className={MingguanStyle.cell}>{data.sakit}</div>
                </div>
            },
            footer: info => info.column.id,
        }),

        columnHelper.accessor('id', {
            header: () => "Detail",
            cell: (inf) => <>
                <Button size="small" onClick={function() {
                    inf.row.original.action()
                }} text={"Detail"} />
            </>
        }),
    ]
}
