import ApiRequest from "./ApiRequest";

interface IDeleteBiayaTambahanParams {
  id: string;
}

class AxiosDeleteBiayaTambahan extends ApiRequest<IDeleteBiayaTambahanParams> {
  constructor() {
    super("/biaya_tambahan/delete");
  }

  post(token: string, pegawai_data: IDeleteBiayaTambahanParams) {
    return this.post_request({
      token: token,
      params: { ...pegawai_data },
    });
  }
}

export default AxiosDeleteBiayaTambahan;
