import ApiRequest from "./ApiRequest";

interface ICreateIzin {
  id_izin: number,
  file: File,
}

class AxiosUploadDokumenIzin extends ApiRequest<ICreateIzin> {
  constructor() {
    super("/izin/upload_dokumen");
  }

  post(token: string, izin_data: ICreateIzin) {
    const formData = new FormData();

    formData.append("id", izin_data.id_izin.toString());
    formData.append("dokumen", izin_data.file, izin_data.file.name);

    return this.post_request({
      token: token,
      formData: formData
    });
  }
}

export default AxiosUploadDokumenIzin;
