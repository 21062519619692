import clsx from "clsx";
import errorExclamation from "../../../vectors/errorExclamation.svg";
import IzinStyle from "./Izin.module.css";
import InputField from "../../../components/InputField";
import { useContext, useEffect, useState } from "react";
import PaginationLinks from "../../../components/PaginationLinks";
import Table from "../../../components/Table";
import { columns, Item } from "./ListCutiTableDefinitions";
import pegawaiContext from "../../../context/SelectedPegawai";
import AxiosListIzin from "../../../api/AxiosListIzin";
import userAuth from "../../../context/UserAuth";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";
import AxiosDeleteIzin from "../../../api/AxiosDeleteIzin";
import { Loader, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ButtonTypeEnum } from "../../../enums/ButtonTypeEnum";
import { toast } from "react-toastify";
import ContentHeader from "../../../components/ContentHeader";
import DropdownField from "../../../components/DropDownField";
import AxiosListAllPegawai from "../../../api/AxiosListAllPegawai";
import TPegawai from "../../../models/TPegawai";
import TOption from "../../../models/TOption";
import { capitalizeEachWord } from "../../../helpers/StringHelper";
import ListPegawai from "../../hrd/ListPegawai/ListPegawai";
import AxiosCreateJatahCuti from "../../../api/AxiosCreateJatahCuti";
import AxiosListJatahCuti from "../../../api/AxiosListJatahCuti";
import AxiosUpdateJatahCuti from "../../../api/AxiosUpdateJatahCuti";
import AxiosDeleteJatahCuti from "../../../api/AxiosDeleteJatahCuti";

interface IListCutiProps {
}
const ListCuti: React.FC<IListCutiProps> = (props: IListCutiProps) => {
    const year = new Date().getFullYear();

    const { user } = useContext(userAuth);
    const defaultData: Item[] = [];
    const [data, setData] = useState(() => [...defaultData]);
    const [id_izin, setId_izin] = useState(0)
    const [pegawai_options, setPegawaiOptions] = useState<TOption[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false);

    //filter and table
    const [pegawai_query, setPegawaiQuery] = useState("")
    const [tanggal_mulai_query, setTanggalMulaiQuery] = useState("")
    const [tanggal_selesai_query, setTanggalSelesaiQuery] = useState("")
    const [selected_link, setSelectedLink] = useState("");
    const [pagination_links, setPaginationLinks] = useState([]);

    //modal
    const [opened, { open, close }] = useDisclosure(false);
    const [action, setAction] = useState("")

    //create field
    const [id_pegawai, setIdPegawai] = useState(0)
    const [tanggal_mulai, setTanggalMulai] = useState("")
    const [tanggal_selesai, setTanggalSelesai] = useState("")
    const [jumlah, setJumlah] = useState(0)
    const [id_jatah_cuti, setIdJatahCuti] = useState(0)
    const [edit_nama_pegawai, setEditNamaPegawai] = useState("")

    function getListPegawai() {
        if (!user?.token) return;
        const axiosListAllPegawai = new AxiosListAllPegawai();
        axiosListAllPegawai
            .get(user.token, {
                status: 'aktif'
            })
            .then((response) => {
                const data: TPegawai[] = response.data.data;
                const options: TOption[] = [];
                data.forEach((item: TPegawai) => {
                    options.push({
                        value: item.id.toString(),
                        label: capitalizeEachWord(item.nama, "_"),
                    });
                });
                setPegawaiOptions(options);
            })
            .catch((e) => {
                const response = e.response;
                if (response.status === 422)
                    toast.error(`${response.data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
            });

    }

    function getCuti() {
        if (!user?.token) return
        if (!tanggal_mulai_query || !tanggal_selesai_query) return
        const axiosListCuti = new AxiosListJatahCuti();

        axiosListCuti.get(user.token, {
            tanggal_mulai: tanggal_mulai_query,
            tanggal_selesai: tanggal_selesai_query,
            nama_pegawai: pegawai_query
        }).then((response) => {
            const data = response.data.data
            data.map((jatahCuti: any) => {
                jatahCuti.onEdit = ((id_jatah_cuti: number, nama: string) => {
                    setIdJatahCuti(id_jatah_cuti)
                    setEditNamaPegawai(nama)
                    setTanggalMulai("")
                    setTanggalSelesai("")
                    setJumlah(0)
                    setAction("edit")
                    open()
                })
                jatahCuti.onDelete = ((id_jatah_cuti: number) => {
                    setIdJatahCuti(id_jatah_cuti)
                    setAction("delete")
                    open()
                })
            })
            setData(data)
        })
    }

    useEffect(() => {
        getListPegawai();
    }, [])

    useEffect(() => {
        getCuti();
    }, [tanggal_mulai_query, tanggal_selesai_query, pegawai_query])

    function modalContent() {
        if (action === "delete")
            return <>
                <div className="flex justify-center">
                    <img
                        alt={"check"}
                        src={errorExclamation}
                        className={"w-24"}
                    />
                </div>
                <div className={"text-4xl text-center"}>
                    Yakin Hapus Cuti ?
                </div>
                <div className={"mt-12 flex justify-around"}>
                    <Button
                        onClick={() => close()}
                        text="Batal"
                        size="small"
                        buttonType={ButtonTypeEnum.CLICKABLE_TEXT}
                        color="danger"
                        className="min-w-[100px] min-h-[40px]"
                    />
                    <Button
                        onClick={() => {
                            if (!user?.token) return
                            const axiosDeleteJatahCuti = new AxiosDeleteJatahCuti();

                            axiosDeleteJatahCuti.post(user.token, {
                                id: id_jatah_cuti.toString(),
                            }).then(() => {
                                toast.success(`Berhasil Edit Jatah Cuti`, {
                                    position: "top-right",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "colored",
                                });
                                getCuti();
                                close();
                            })


                        }}
                        text="Hapus"
                        size="small"
                        className="min-w-[100px] min-h-[40px]"
                    />
                </div></>

        if (action === "tambah")
            return <div>
                <div className="text-4xl text-center font-bold mb-4"> Tambah Jatah Cuti </div>
                <span>Pegawai</span>
                <DropdownField
                    name="pegawai"
                    id="pegawai"
                    options={pegawai_options}
                    onChange={(e) => { setIdPegawai(parseInt(e)) }}
                    value={id_pegawai.toString()}
                />
                <span>Tanggal Mulai</span>
                <InputField type="date" placeholder=""
                    defaultValue={year.toString + "-01-01"}
                    onChange={(e) => { setTanggalMulai(e) }}
                />
                <span>Tanggal Selesai</span>
                <InputField type="date" placeholder=""
                    defaultValue={year.toString + "-12-31"}
                    onChange={(e) => { setTanggalSelesai(e) }}
                />
                <span>Jumlah</span>
                <InputField type="number" placeholder=""
                    onChange={(e) => { setJumlah(parseInt(e)) }}
                />

                <Button
                    text={"Tambah Jatah Cuti"}
                    isDisabled={isLoading}
                    onClick={() => {
                        if (!user?.token) return
                        const axiosCreateJatahCuti = new AxiosCreateJatahCuti();

                        axiosCreateJatahCuti.post(user.token, {
                            id_pegawai: id_pegawai.toString(),
                            jatah_mulai: tanggal_mulai,
                            jatah_akhir: tanggal_selesai,
                            jumlah,
                        }).then(() => {

                            toast.success(`Berhasil Membuat Jatah Cuti`, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                            });
                            close()
                            getCuti()
                        })
                    }}
                    size="small"
                />
            </div>

        if (action === "edit")
            return <div>
                <div className="text-4xl text-center font-bold mb-4"> Edit Jatah Cuti </div>
                <span className="flex">Pegawai</span>
                <span className="flex text-2xl mb-4">{edit_nama_pegawai}</span>
                <span>Tanggal Mulai</span>
                <InputField type="date" placeholder=""
                    defaultValue={year.toString + "-01-01"}
                    onChange={(e) => { setTanggalMulai(e) }}
                />
                <span>Tanggal Selesai</span>
                <InputField type="date" placeholder=""
                    defaultValue={year.toString + "-12-31"}
                    onChange={(e) => { setTanggalSelesai(e) }}
                />
                <span>Jumlah</span>
                <InputField type="number" placeholder=""
                    onChange={(e) => { setJumlah(parseInt(e)) }}
                />

                <Button
                    text={"Simpan Perubahan"}
                    isDisabled={isLoading}
                    onClick={() => {
                        if (!user?.token) return
                        const axiosUpdateJatahCuti = new AxiosUpdateJatahCuti();

                        axiosUpdateJatahCuti.post(user.token, {
                            id: id_jatah_cuti.toString(),
                            tanggal_mulai,
                            tanggal_selesai,
                            jumlah,
                        }).then(() => {
                            toast.success(`Berhasil Edit Jatah Cuti`, {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                            });
                            getCuti();
                            close();
                        })
                    }}
                    size="small"
                />
            </div>

    }

    return <>
        <ContentHeader
            title={"Jatah Cuti"}
            additionalHeader={
                <>
                    <div className="flex gap-2">
                        <div className="flex gap-2">
                            <InputField type="text" placeholder="Nama Pegawai..."
                                onChange={(value) => { setPegawaiQuery(value); }}
                            />

                            <InputField type="date" placeholder=""
                                onChange={(e) => { setTanggalMulaiQuery(e) }}
                            />
                            <span className="flex flex-col justify-center">s/d</span>
                            <InputField type="date" placeholder=""
                                onChange={(e) => { setTanggalSelesaiQuery(e) }}
                            />
                        </div>

                        <Button
                            text={
                                isLoading ? (
                                    <>
                                        <Loader color="indigo" size="sm" />
                                    </>
                                ) : (
                                    "Tambah Jatah Cuti"
                                )
                            }
                            isDisabled={isLoading}
                            onClick={() => {
                                setAction("tambah")
                                open()
                            }}
                            size="small"
                        />
                    </div>
                </>

            }
        />

        <Modal opened={opened} onClose={close} title="" radius={"md"} centered>
            {modalContent()}
        </Modal>

        <div className="flex flex-col py-[10px]">
            <div className="mx-[40px] mt-4">
                <PaginationLinks
                    onPaginationClick={(link) => {
                        setSelectedLink(link)
                    }}
                    pagination_links={pagination_links}
                    className="!w-full"
                />
                {/* <Pagination value={pagination_links} onChange={setPaginationLinks} total={10} />; */}
            </div>
            <div
                className={"flex justify-center items-center bg-white rounded-s mx-5 my-10 pb-4 !pt-0 !mt-0"}
            >
                <Table<Item> data={data} columns={columns} />
            </div>
        </div>
    </>
}
export default ListCuti;
