/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import ContentHeader from "../../../components/ContentHeader";
import Table from "../../../components/Table";
import userAuth from "../../../context/UserAuth";
import { columns, Item } from "./RiwayatPegawaiTableDefinitions";
import ListSPKStyle from "./RiwayatPegawai.module.css";
import clsx from "clsx";
import AxiosGetListRiwayatPegawai from "../../../api/AxiosGetListRiwayatPegawai";
import InputField from "../../../components/InputField";
import Button from "../../../components/Button";
import DropdownField from "../../../components/DropDownField";
import TOption from "../../../models/TOption";
import TPegawai from "../../../models/TPegawai";
import AxiosListAllPegawai from "../../../api/AxiosListAllPegawai";
import { dateToString, flipStringDate } from "../../../helpers/DateHelper";
import { Loader } from "@mantine/core";
import { toast } from "react-toastify";

interface IListSPKProps {}
const RiwayatPegawai: React.FC<IListSPKProps> = () => {
  const { user } = useContext(userAuth);
  const [listSPK, setListSPK] = useState<any[]>([]);
  const [waktuMulai, setWaktuMulai] = useState(dateToString(new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000))));
  const [waktuSelesai, setWaktuSelesai] = useState(dateToString(new Date()));
  const [pegawaiOptions, setPegawaiOptions] = useState<TOption[]>([]);
  const [idPegawai, setIdPegawai] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function getListPegawai() {
    if (!user?.token) return;
    const axiosListAllPegawai = new AxiosListAllPegawai();
    axiosListAllPegawai.get(user.token, {
      type: 'borongan',
      status: 'aktif'
    }).then((response) => {
      const data: TPegawai[] = response.data.data;
      const options: TOption[] = [];
      data.forEach((item: TPegawai) => {
        options.push({
          value: item.id.toString(),
          label: item.nama,
        });
      });
      setPegawaiOptions(options);
    });
  }

  useEffect(() => {
    
    getListPegawai();
  }, []);

  function getListRiwayatPegawai() {
    if (!user?.token) return;
    const axiosGetListRiwayatPegawai = new AxiosGetListRiwayatPegawai();

    axiosGetListRiwayatPegawai
      .get(user.token, {
        id_pegawai: idPegawai,
        tanggal_mulai: waktuMulai,
        tanggal_selesai: waktuSelesai,
      })
      .then((response) => {
        const data = response.data.data;
        const newData = data.map((item: any) => {
          return {
            id: item.id,
            nomor_spk: item.nomor_spk,
            date: item.tanggal_pekerjaan,
            rincian_barang: item.nama,
            jumlah_barang: Number((item.jumlah_output).toFixed(2)),
            harga_satuan: item.tarif,
            harga_total: Number((item.tarif * item.jumlah_output).toFixed(2)),
          };
        });
        setListSPK(newData);
      });
  }

  useEffect(() => {
    if (!waktuMulai || !waktuSelesai || !idPegawai) return;
    getListRiwayatPegawai();
  }, [waktuMulai, waktuSelesai, idPegawai]);

  let timer: any;
  const [searchQuery, setSearchQuery] = useState("");

  function onSearch(value: string) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearchQuery(value);
      
      // getListSPK(undefined, value);
    }, 500);
  }

  const handleDownloadExcel = () => {
    if (isLoading === true) return;
    if (!waktuMulai || !waktuSelesai || !idPegawai) {
      toast.error(`Pegawai Harus Di pilih`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return
    }
    if (!user?.token) return;
  
    const axiosGetListRiwayatPegawai = new AxiosGetListRiwayatPegawai();

    axiosGetListRiwayatPegawai
      .fetch(user.token, {
        id_pegawai: idPegawai,
        tanggal_mulai: waktuMulai,
        tanggal_selesai: waktuSelesai,
        action: "export"
      }, {
        responseType:'arraybuffer'
      })
      .then(response => {
        var dataName = pegawaiOptions.filter(item => item.value === idPegawai.toString())
        const name = dataName[0].label ? dataName[0].label : idPegawai;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            "Laporan Borongan " + name + " Periode " + flipStringDate(waktuMulai) + " - " + flipStringDate(waktuSelesai) + ".xlsx"
        );
        document.body.appendChild(link);
        link.click();
      })
  };

  return (
    <>
      <div className={ListSPKStyle.header_wrapper}>
        <div className="mx-[40px] mt-[40px] mb-2 flex justify-between w-full">
          <ContentHeader title="Riwayat Pegawai" className="!p-0" />
          <div className="flex gap-2">
            <span className="flex flex-col justify-center font-bold">
              Periode
            </span>

            <InputField
              type="date"
              placeholder=""
              defaultValue={dateToString(new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000)))}
              onChange={(value) => setWaktuMulai(value)}
            />
            <span className="flex flex-col justify-center">s/d</span>
            <InputField
              type="date"
              placeholder=""
              defaultValue={dateToString(new Date())}
              onChange={(value) => setWaktuSelesai(value)}
            />
            <Button
                text={ isLoading ? ( <>  <Loader color="indigo" size="sm" />
                    </> ) : ( "Download Excell" )
                }
                isDisabled={isLoading}
                onClick={() => {
                  handleDownloadExcel();
                }}
                size="small"
              />
          </div>
        </div>
      </div>

      <div className="mx-[40px] flex">
        <DropdownField
          name=""
          id=""
          options={pegawaiOptions}
          value={idPegawai.toString()}
          onChange={(value) => setIdPegawai(parseInt(value))}
          className="!border !py-[17px] !px-[31px] !rounded"
        />
      </div>

      <div className={clsx(ListSPKStyle.table_wrapper, "pb-4 !mb-0")}>
        <Table<Item> data={listSPK} columns={columns} />
      </div>

      <div className="text-red-500 my-2 text-left mx-[40px]">
        *Wajib memasukkan periode dan nama pegawai!
      </div>
    </>
  );
};
export default RiwayatPegawai;
