import { TFormPegawai } from "../models/TFormPegawai";
import ApiRequest from "./ApiRequest";


class AxiosCreatePegawai extends ApiRequest<TFormPegawai> {
    constructor() {
        super("/create_pegawai");
    }

    post(token: string, pegawai_data: TFormPegawai) {
        return this.post_request({
            token: token,
            params: {
                ...pegawai_data,
                sim: JSON.stringify(pegawai_data.sim ?? "[]"),
                bahasa_dikuasai: JSON.stringify(pegawai_data.bahasa_dikuasai ?? "[]"),
                detil_sakit: JSON.stringify(pegawai_data.detil_sakit ?? "[]"),
                detil_kecelakaan: JSON.stringify(pegawai_data.detil_kecelakaan ?? "[]"),
                detil_polisi: JSON.stringify(pegawai_data.detil_polisi ?? "[]"),
                detil_psikologi: JSON.stringify(pegawai_data.detil_psikologi ?? "[]"),
                detil_militer: JSON.stringify(pegawai_data.detil_militer ?? "[]"),
                detil_bacaan: JSON.stringify(pegawai_data.detil_bacaan ?? "[]"),

            }
        });
    }
}

export default AxiosCreatePegawai;
