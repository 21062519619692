import React from "react";
import TOption from "../models/TOption";
import DropdownStyle from "./DropdownField.module.css";
import clsx from "clsx";

interface IDropdownFieldProps {
  name: string;
  id: string;
  options: TOption[];
  onChange: (value: string, text: string) => void;
  className?: string;
  placeholder?: string;
  title?: string;
  defaultValue?: string;
  value?: string;
  isRequired?: boolean;
  key?: string;
}

class DropdownField extends React.Component<IDropdownFieldProps> {
  handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.onChange(
      event.target.value,
      event.target.options[event.target.selectedIndex].text
    );
  };

  render(): React.ReactNode {
    return (
      <div className="flex flex-col gap-1 w-full">
        {this.props.title ? (
          <div className="text-left">
            <span>{this.props.title}</span>
            {this.props.isRequired ? (
              <span className="text-red-500">*</span>
            ) : null}
          </div>
        ) : this.props.isRequired ? (
          <span className="text-red-500">*</span>
        ) : null}
        <select
          name={this.props.name}
          id={this.props.id}
          className={clsx(DropdownStyle.input, this.props.className, "border")}
          value={this.props.value ?? ""}
          onChange={this.handleChange}
        >
          <option value="" disabled>
            {this.props.placeholder || "Please select an option"}
          </option>
          {this.props.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default DropdownField;
