import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AxiosListDokumen from "../../../api/AxiosListDokumen";
import AxiosUploadDokumen from "../../../api/AxiosUploadDokumen";
import Button from "../../../components/Button";
import DropdownField from "../../../components/DropDownField";
import PopUpCard from "../../../components/PopUpCard";
import Table from "../../../components/Table";
import userAuth from "../../../context/UserAuth";
import selectedPegawai from "../../../context/SelectedPegawai";
import FileUploaderPopUp from "../FileUploader";
import ListDokumenStyle from "./ListDokumen.module.css";
import AxiosListJenisDokumen from "../../../api/AxiosListJenisDokumen";
import AxiosSearchDokumen from "../../../api/AxiosSearchDokumen";
import { capitalizeEachWord } from "../../../helpers/StringHelper";

interface IListDokumenProps {}

type DokumenItem = {
  no: number;
  id: number;
  jenis_dokumen: string;
  tanggal_terbit: string;
};

type Option = {
  value: string;
  label: string;
};

const ListDokumen: React.FC<IListDokumenProps> = () => {
  const { user } = useContext(userAuth);
  const { pegawai } = useContext(selectedPegawai);
  const [searchQuery, setSearchQuery] = useState("");
  const [documentType, setDocumentType] = useState("");
  const [idDocumentType, setIdDocumentType] = useState(0);
  const [data_dokumen, setDataDokumen] = useState<DokumenItem[]>([]);
  const [isPopUpUpload, setPopUpUpload] = useState(false);
  const [document, setDocument] = useState<File>();
  const [jenisDokumenOptions, setJenisDokumenOptions] = useState<Option[]>([]);
  const [uploadDokumenOptions, setUploadDokumenOptions] = useState<Option[]>(
    []
  );

  const columnHelper = createColumnHelper<DokumenItem>();
  const columns: ColumnDef<DokumenItem, any>[] = [
    columnHelper.accessor("no", {
      cell: (info) => info.getValue(),
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.jenis_dokumen, {
      id: "jenis_dokumen",
      cell: (info) => <i>{info.getValue()}</i>,
      header: () => <span>Jenis Dokumen</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.tanggal_terbit, {
      id: "tanggal_terbit",
      cell: (info) => <i>{info.getValue().substring(0, 10)}</i>,
      header: () => <span>TanggalTerbit</span>,
      footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.id, {
      id: "Action",
      header: "action",
      cell: (inf) => (
        <Link to={"/dashboard/detail/dokumen?id=" + inf.getValue()}>
          detail{" "}
        </Link>
      ),
      footer: (info) => info.column.id,
    }),
  ];

  function tambahDokumen() {
    setPopUpUpload(true);
  }

  const popUpProps = {
    onClose: () => setPopUpUpload(false),
  };
  function listJenisDokumen() {
    if (user.token == null) return;

    const axiosListJenisDokumen = new AxiosListJenisDokumen();
    axiosListJenisDokumen.get(user.token).then((response) => {
      const data = response.data.data;
      const options: Option[] = [];
      const uploadOptions: Option[] = [];

      const excluded = [
        "SPK",
        "PKWT",
        "PKWTT",
        "PKKB",
        "IZIN",
      ];
      options.push({ label: "...", value: "0" });
      data.map((option: any) => {
        options.push({
          label: capitalizeEachWord(option.nama, "_"),
          value: option.id,
        });
        if (!excluded.includes(option.nama))
          uploadOptions.push({
            label: capitalizeEachWord(option.nama, "_"),
            value: option.id,
          });
      });

      setUploadDokumenOptions(uploadOptions);
      setJenisDokumenOptions(options);
      setDocumentType(uploadOptions[0].label);
      setIdDocumentType(parseInt(uploadOptions[0].value));
      setSearchQuery(uploadOptions[0].value);
    });
  }

  function listDokumen(pegawai_id: number) {
    const axiosListDokumen = new AxiosListDokumen();

    if (user.token == null) return;
    axiosListDokumen.get(pegawai_id, user.token).then((response) => {
      const data = response.data.data;
      constructResponseToRow(data);
    });
  }

  function constructResponseToRow(data: []) {
    const newDokumenItem: DokumenItem[] = [];
    data.forEach((dokumen: any, index: number) => {
      newDokumenItem.push({
        no: index + 1,
        id: dokumen.id,
        jenis_dokumen: dokumen.nama,
        tanggal_terbit: dokumen.created_at,
      });
    });

    setDataDokumen(newDokumenItem);
  }

  function uploadDokumen() {
    if (!document || !user.token || !pegawai?.id) return;

    const axiosUploadDokumen = new AxiosUploadDokumen();
    axiosUploadDokumen
      .post(document, parseInt(pegawai.id), idDocumentType, user.token)
      .then(() => {
        if (!pegawai) return;
        if (!pegawai.id) return;
        setPopUpUpload(false);
        listDokumen(parseInt(pegawai.id));
      })
      .catch((e) => {});
  }

  function searchDokumen() {
    if (!user.token || !pegawai?.id) return;

    if (searchQuery !== "0") {
      const axiosSearchDokumen = new AxiosSearchDokumen();
      axiosSearchDokumen
        .get(user.token, pegawai.id, searchQuery)
        .then((response) => {
          const data = response.data.data;
          constructResponseToRow(data);
        });
    } else listDokumen(parseInt(pegawai?.id));
  }

  useEffect(() => {
    if (!pegawai?.id) return;
    listDokumen(parseInt(pegawai?.id));
    listJenisDokumen();
  }, []);

  return (
    <>
      {isPopUpUpload ? (
        <PopUpCard {...popUpProps} title={`Upload ${documentType}`}>
          <FileUploaderPopUp
            onDocumentChange={(doc) => setDocument(doc)}
            onUpload={() => uploadDokumen()}
          />
        </PopUpCard>
      ) : null}
      <div className={ListDokumenStyle.main_header}>
        <div className="text-3xl font-bold">Dokumen</div>
        <div className={ListDokumenStyle.additional_part}>
          <div className={ListDokumenStyle.search}>
            <DropdownField
              key="doc_search"
              name="dokumentype"
              id="DT"
              options={jenisDokumenOptions}
              onChange={(text) => {
                setSearchQuery(text);
              }}
              value={searchQuery}
            />
            <Button
              size="small"
              onClick={() => searchDokumen()}
              text="Search"
            />
          </div>
          <div className={ListDokumenStyle.upload_file}>
            <DropdownField
              key="doc_type"
              name="dokumentype"
              id="DT"
              options={uploadDokumenOptions}
              onChange={(value, text) => {
                setDocumentType(text);
                setIdDocumentType(parseInt(value));
              }}
              value={idDocumentType.toString()}
            />

            <Button
              size="small"
              onClick={() => tambahDokumen()}
              text="Tambah Dokumen"
              className="min-h-[39px]"
            />
          </div>
        </div>
      </div>
      <Table<DokumenItem> data={data_dokumen} columns={columns} />
    </>
  );
};

export default ListDokumen;
