interface ITabHeaderProps {
    title: string;
    onClick: () => void;
}
const TabHeader: React.FC<ITabHeaderProps> = (props: ITabHeaderProps) => {
    return <>
        <div className={`
                cursor-pointer px-5 border-r-gray-300 border-solid border-r-2 h-10
                flex justify-center items-center
                hover:bg-blue-100 
                `}
            onClick={() => props.onClick()}
        >{props.title}</div>
    </>
}
export default TabHeader;
