export function dateToString(date: Date): string {
    const month: number = date.getMonth() + 1;
    const year = date.getFullYear();
    const formatedMonth = ("0" + month).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${year}-${formatedMonth}-${day}`;
}

export function bulanTahunIndonesia(date: Date): string | undefined {
    const bulanString = bulanStringIndonesia(date.getMonth())
    if (!bulanString) return
    return bulanString + " " + date.getFullYear()
}

export function dateToStringDMY(date: Date): string | undefined {
    const month: number = date.getMonth() + 1;
    const year = date.getFullYear();
    const formatedMonth = ("0" + month).slice(-2);
    const day = ("0" + date.getDate()).slice(-2);
    return `${day}-${formatedMonth}-${year}`;
}

export function flipStringDate(date: string): string {
    let slice = date.split('-')
    return `${slice[2]}-${slice[1]}-${slice[0]}`;
}

function hariStringIndonesia(hari: number) {
    switch (hari) {
        case 0: return "Minggu";
        case 1: return "Senin";
        case 2: return "Selasa";
        case 3: return "Rabu";
        case 4: return "Kamis";
        case 5: return "Jum'at";
        case 6: return "Sabtu";
    }
}

function bulanStringIndonesia(bulan: number) {
    switch (bulan) {
        case 0: return "Januari";
        case 1: return "Februari";
        case 2: return "Maret";
        case 3: return "April";
        case 4: return "Mei";
        case 5: return "Juni";
        case 6: return "Juli";
        case 7: return "Agustus";
        case 8: return "September";
        case 9: return "Oktober";
        case 10: return "November";
        case 11: return "Desember";
    }
}

export function get17YearsBack() {
    const now = new Date();
    const max_year = now.getFullYear() - 17;
    const max_month = ("0" + now.getMonth()).slice(-2);
    const max_day = ("0" + now.getDay()).slice(-2);
    return `${max_year}-${max_month}-${max_day}`
}

export function completeTime(time: string) {
    return time + ":00"
}

export function dateStringToDate(time: string) {

    const [year, month, day] = time.split('-').map(Number);

    return new Date(year, month - 1, day);
}
export function fullStringToDate(time: string) {
    const parts = time.split(' '); // Split date and time

    const datePart = parts[0];
    const timePart = parts[1];

    const [year, month, day] = datePart.split('-').map(Number);
    const [hour, minute, second] = timePart.split(':').map(Number);

    return new Date(year, month - 1, day, hour, minute, second);
}

export function extractTime(date: Date) {
    return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
}

export function compareDate(first: Date, second: Date) {
    return first.getDay() === second.getDay() &&
        first.getMonth() === second.getMonth() &&
        first.getFullYear() === second.getFullYear()
}
