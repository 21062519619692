import { useContext, useEffect, useState } from "react";
import AxiosCreateIzin from "../../../api/AxiosCreateIzin";
import Button from "../../../components/Button";
import ContentHeader from "../../../components/ContentHeader";
import DropdownField from "../../../components/DropDownField";
import InputField from "../../../components/InputField";
import TimeoutPopup, {
    defaultPopUpProps,
    ITimeoutPopupProps,
} from "../../../components/TimeoutPopUp";
import pegawaiContext from "../../../context/SelectedPegawai";
import userAuth from "../../../context/UserAuth";
import { TipeIzinEnum } from "../../../enums/TipeIzinEnum";
import { capitalizeEachWord } from "../../../helpers/StringHelper";
import TOption from "../../../models/TOption";
import IzinStyle from "./Izin.module.css";
import { toast } from "react-toastify";
import clsx from "clsx";
import { Loader } from "@mantine/core";
import FileUploaderPopUp from "../../hrd/FileUploader";
import { FileUploader } from "react-drag-drop-files";
import AxiosFindJatahCuti from "../../../api/AxiosFindJatahCuti";

interface IIzinProps { }

const Izin: React.FC<IIzinProps> = () => {
    const { user } = useContext(userAuth);
    const { pegawai } = useContext(pegawaiContext);
    const [tipe, setTipe] = useState("");
    const [alasan, setAlasan] = useState("");
    const [tanggal, setTanggal] = useState("");
    const [tanggal_selesai, setTanggalSelesai] = useState("");
    const [tipeOptions, setTipeOptions] = useState<TOption[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [document, setDocument] = useState<File>();
    const [jatahCuti, setJatahCuti] = useState<any>(undefined);

    const [popUpProps, setPopUpProps] =
        useState<ITimeoutPopupProps>(defaultPopUpProps);

    function getJatahCuti() {
        if (!user.token || !pegawai?.id || isLoading === true) return;
        if (!tanggal) return

        const axiosFindJatahCuti = new AxiosFindJatahCuti();
        axiosFindJatahCuti.get(user.token, {
            id_pegawai: pegawai.id,
            tanggal: tanggal
        }).then((response) => {
            const data = response.data.data
            setJatahCuti(data)
        });
    }

    function createIzin() {
        if (!user.token || !pegawai?.id || isLoading === true) return;
        setIsLoading(true);

        const axiosCreateIzin = new AxiosCreateIzin();
        axiosCreateIzin
            .post(user.token, {
                tipe,
                alasan,
                tanggal,
                tanggal_selesai,
                id_pegawai: pegawai.id,
                file: document
            })
            .then((response) => {
                const data = response.data;
                if (data.success) {
                    toast.success("Berhasil menambahkan izin!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
                setIsLoading(false);
            })
            .catch((e) => {
                const response = e.response;
                // if (response.status === 422)
                    toast.error(`${response.data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                setIsLoading(false);
            });
    }


    useEffect(() => getJatahCuti(), [tanggal])

    useEffect(() => {
        const values = Object.values(TipeIzinEnum);
        const options: TOption[] = [];
        values.forEach((value, index) => {
            options.push({
                value: value,
                label: capitalizeEachWord(value, "_"),
                isDefault: index === 0,
            });
        });

        setTipeOptions(options);
    }, []);

    return (
        <>
            <TimeoutPopup {...popUpProps} />
            <div className={clsx(IzinStyle.container, "!py-6 !px-12 !mx-[40px]")}>
                <ContentHeader title={"Input Izin " + pegawai?.name} className="!p-0" />
                <DropdownField
                    name="tipe_izin"
                    id="tipeizin"
                    className="!border !py-[17px] !px-[31px] !rounded"
                    onChange={(value) => setTipe(value)}
                    value={tipe}
                    options={tipeOptions}
                    title="Tipe Izin"
                    isRequired
                />
                <div className="w-full flex gap-2">
                <InputField
                    type="date"
                    onChange={(value) => setTanggal(value)}
                    placeholder="tanggal"
                    title="Tanggal Mulai"
                    isRequired
                />
                <InputField
                    type="date"
                    onChange={(value) => setTanggalSelesai(value)}
                    placeholder="tanggal"
                    title="Tanggal Selesai"
                />
                </div>
                
                <div className="text-left">
                    {jatahCuti ?
                        <>{
                            "jatah cuti periode (" + jatahCuti?.jatah_mulai + " - " + jatahCuti?.jatah_akhir + ") :  " +
                            jatahCuti?.jumlah + " kali"
                        }</>
                        : <> </>}
                </div>
                <InputField
                    type="text"
                    onChange={(value) => setAlasan(value)}
                    placeholder="Masukkan Alasan"
                    title="Alasan"
                    isRequired
                />
                <FileUploader
                    handleChange={(doc: any) => setDocument(doc)}
                    name="File"
                    types={["JPG", "PNG", "PDF"]}
                />
                <Button
                    text={isLoading ? <Loader color="indigo" size="sm" /> : "Tambah Izin"}
                    isDisabled={isLoading}
                    onClick={() => createIzin()}
                    className="mt-2 h-[55px] min-w-[200px]"
                    size="small"
                />
            </div>
        </>
    );
};

export default Izin;
