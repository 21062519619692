/* eslint-disable react-hooks/exhaustive-deps */
import errorExclamation from "../../../../vectors/errorExclamation.svg";
import { useContext, useEffect, useState } from "react";
import ContentHeader from "../../../../components/ContentHeader";
import userAuth from "../../../../context/UserAuth";
import DetailSPKStyle from "./DetailSPK.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import AxiosGetDetailSPK from "../../../../api/AxiosGetDetailSPK";
import SlipGajiStyle from "../../../payroll/SlipGaji.module.css";
import { numberToMoney } from "../../../../helpers/StringHelper";
import Button from "../../../../components/Button";
import { ButtonTypeEnum } from "../../../../enums/ButtonTypeEnum";
import { Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import clsx from "clsx";
import AxiosDeleteSPK from "../../../../api/AxiosDeleteSPK";
import { toast } from "react-toastify";

interface DataItem {
  label: string;
  value: React.ReactNode;
  isHighlight?: boolean;
}

interface IListSPKProps { }
const DetailSPK: React.FC<IListSPKProps> = () => {
  const location = useLocation();
  // const detailSPK = location.state.param;
  const [detailSPK, setDetailSPK] = useState<any>({
    created_at: "",
    id: 0,
    jumlah_barang: 0,
    nama_barang: "",
    nomor_spk: "",
    path_berita_acara: "",
    status: "",
    tanggal_mulai: "",
    tanggal_selesai: "",
    updated_at: "",
  });

  const { user } = useContext(userAuth);
  const [detailSPKData, setDetailSPKData] = useState<any>({});
  const [confirmPopUp, setConfirmPopUp] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const navigate = useNavigate()

  function getDetailSPK(id: string) {
    if (!user?.token) return;
    
    const axiosGetDetailSPK = new AxiosGetDetailSPK(id);

    axiosGetDetailSPK.get(user.token).then((response) => {
      const data = response.data.data;
      setDetailSPKData(data);
    });
  }

  useEffect(() => {
    if (location) {
      setDetailSPK(location.state.param);
    }
  }, []);

  useEffect(() => {
    if (detailSPK) getDetailSPK(detailSPK.id);
  }, [detailSPK]);

  const data: DataItem[] = [
    {
      label: "Nama barang",
      value: detailSPK.nama_barang,
    },
    { label: "No. SPK", value: detailSPK.nomor_spk },
    {
      label: "Tanggal mulai produksi",
      value: detailSPK.tanggal_mulai + " s/d " + detailSPK.tanggal_selesai,
    },
    {
      label: "Jumlah barang",
      value: detailSPK.jumlah_barang.toLocaleString() + " pcs",
    },
  ];

  const [totalHarga, setTotalHarga] = useState(0);

  useEffect(() => {
    if (detailSPKData?.list_pekerjaan) {
      const total = detailSPKData?.list_pekerjaan?.reduce(
        (total: number, item: any) => {
          return total + Number(item.tarif * item.target_output);
        },
        0
      );
      setTotalHarga(total);
    }
  }
    , [detailSPKData?.list_pekerjaan]);


  if (!user?.role) return <> </>

  return (
    <>
      <Modal opened={opened} onClose={close} title="" radius={"md"} centered>
        <div className="flex justify-center">
          <img
            alt={"check"}
            src={errorExclamation}
            className={SlipGajiStyle.confirm_icon}
          />
        </div>
        <div className={clsx(SlipGajiStyle.confirm_title, "text-center")}>
          Yakin Hapus SPK ?
        </div>
        <div className={SlipGajiStyle.confirm_button_group}>
          <Button
            onClick={() => close()}
            text="Batal"
            size="small"
            buttonType={ButtonTypeEnum.CLICKABLE_TEXT}
            color="danger"
            className="min-w-[100px] min-h-[40px]"
          />
          <Button
            onClick={() => {

              const axiosDeleteSPK = new AxiosDeleteSPK();
              if (!user.token) return

              axiosDeleteSPK
                .post(user.token, {
                  id: detailSPK.id
                })
                .then((response) => {
                  const data = response.data;
                  if (data.success) {
                    toast.success("Berhasil delete SPK!", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "colored",
                    });
                    close()
                    navigate("/dashboard/produksi/spk")
                  }
                })
            }}
            text="Hapus"
            size="small"
            className="min-w-[100px] min-h-[40px]"
          />
        </div>
      </Modal>
      <div className={DetailSPKStyle.header_wrapper}>
        <ContentHeader title={"Detail SPK"} enableBack={true}
          additionalHeader={
            <>
              <div >
                {(user.role === "super_admin" ||
                  user.role === "hrd_kepala" ||
                  user.role === "hrd_admin"
                ) ?
                  <Button
                    text="Delete SPK"
                    onClick={() => {
                      open()
                    }}
                    buttonType={ButtonTypeEnum.CLICKABLE_TEXT}
                    color="danger"
                    size="small"
                  /> : <> </>
                }
              </div>
            </>
          }
        />
      </div>

      <div className="mx-[40px] my-[20px] bg-white rounded-[10px] py-6 px-12 text-left">
        <table className="table-auto w-full">
          <tbody>
            {data.map((item, index) => (
              <tr key={index} className="text-left">
                <td className="py-2 font-medium w-1/3">{item.label}</td>
                <td className="py-2 font-medium select-none">:</td>
                <td
                  className={`py-2 ${item.isHighlight ? "text-blue-600" : ""}`}
                >
                  {item.value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="mx-[40px] my-[20px] bg-white rounded-[10px] py-6 px-12 text-left">
        <table className="table-auto w-full">
          <thead className="font-bold">
            <td className="py-2"></td>
            <td className="py-2">Nama Pekerjaan</td>
            {/* <td className="py-2">Bahan</td> */}
            <td className="py-2">Kuantitas</td>
            <td className="py-2">Harga/pcs</td>
            <td className="py-2">Harga</td>
          </thead>
          <tbody>
            {detailSPKData?.list_pekerjaan ? (
              <>
                {detailSPKData?.list_pekerjaan?.map((item: any, index: any) => (
                  <tr key={index} className="text-left">
                    <td className="py-2 font-medium">Pekerjaan {index + 1}</td>
                    <td className="py-2 font-medium">{item.nama}</td>
                    {/* <td className="py-2 font-medium">{item.nama_barang}</td> */}
                    <td className="py-2 font-medium">{item.target_output}</td>
                    <td className="py-2 font-medium">
                      {numberToMoney(item.tarif.toLocaleString())}
                    </td>
                    <td className="py-2 font-medium">
                      {numberToMoney(item.tarif * item.target_output)}
                    </td>
                  </tr>
                ))}
                <tr className="text-left border-t">
                  <td colSpan={3} className="py-2"></td>
                  <td className="py-2 font-bold text-right pr-4">
                    {" "}
                    Total Harga
                  </td>
                  <td className="py-2">
                    {numberToMoney(totalHarga)}
                  </td>
                </tr>
              </>
            ) : null}
          </tbody>
        </table>
      </div>

      {/* <div className="mx-[40px] my-[20px] bg-white rounded-[10px] py-6 px-12 text-left">
        <table className="table-auto w-full">
          <thead className="font-bold">
            <td className="py-2"></td>
            <td className="py-2">Nama Pegawai</td>
            <td className="py-2">Pekerjaan</td>
            <td className="py-2">Upah</td>
          </thead>
          <tbody>
            {dataPegawai.map((item, index) => (
              <tr key={index} className="text-left">
                <td className="py-2 font-medium">{item.pegawai}</td>
                <td className="py-2 font-medium">{item.namaPegawai}</td>
                <td className="py-2 font-medium">{item.pekerjaan}</td>
                <td className="py-2 font-medium">{item.upah}</td>
              </tr>
            ))}
            <tr className="text-left border-t">
              <td colSpan={2} className="py-2"></td>
              <td className="py-2 font-bold text-right pr-4"> Total Harga</td>
              <td className="py-2">Rp 866.000,-</td>
            </tr>
          </tbody>
        </table>
      </div> */}
    </>
  );
};
export default DetailSPK;
