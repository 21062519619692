import { useEffect, useState } from "react";
import TBahasaItem from "../../../models/TBahasaItem";
import DropdownField from "../../DropDownField";
import BahasaListStyle from "./BahasaListStyle.module.css";

interface IBahasaListItemProps {
  onBahasaChange: (bahasaObject: TBahasaItem) => void;
  currentBahasa: TBahasaItem;
  onBahasaDelete: (index: number) => void;
  index: number;
}
const BahasaListItem: React.FC<IBahasaListItemProps> = (
  props: IBahasaListItemProps
) => {
  const [bahasa, setBahasa] = useState<TBahasaItem>({
    bahasa: "",
    fasih: "",
  });

  useEffect(() => {
    setBahasa(props.currentBahasa);
  }, [props.currentBahasa]);

  return (
    <div className={BahasaListStyle.list_item}>
      <div className="flex gap-2 w-full">
        <DropdownField
          name={"bahasa"}
          id={"bahasa"}
          options={[
            {
              label: "Bahasa Indonesia",
              value: "bahasa_indonesia",
              isDefault: props.currentBahasa.bahasa === "bahasa_indonesia",
            },
            {
              label: "Bahasa Inggris",
              value: "bahasa_inggris",
              isDefault: props.currentBahasa.bahasa === "bahasa_inggris",
            },
            {
              label: "Bahasa Jawa",
              value: "bahasa_jawa",
              isDefault: props.currentBahasa.bahasa === "bahasa_jawa",
            },
          ]}
          value={props.currentBahasa.bahasa}
          onChange={(value) => {
            const newBahasa = { ...bahasa, bahasa: value };
            props.onBahasaChange(newBahasa);
            setBahasa(newBahasa);
          }}
          title="Bahasa"
          isRequired
          className="!border !py-[17px] !px-[31px] !rounded !min-w-[200px]"
        />
        <DropdownField
          name={"bahasa_status"}
          id={"bahasa_status"}
          options={[
            {
              label: "Aktif",
              value: "aktif",
              isDefault: props.currentBahasa.fasih === "aktif",
            },
            {
              label: "Pasif",
              value: "pasif",
              isDefault: props.currentBahasa.fasih === "pasif",
            },
          ]}
          value={props.currentBahasa.fasih}
          onChange={(value) => {
            const newBahasa = { ...bahasa, fasih: value };
            props.onBahasaChange(newBahasa);
            setBahasa(newBahasa);
          }}
          title="Status"
          isRequired
          className="!border !py-[17px] !px-[31px] !rounded !min-w-[200px]"
        />
        <div className="flex flex-col justify-end mb-2">
          <div
            onClick={() => props.onBahasaDelete(props.index)}
            className="ml-2 hover:cursor-pointer font-bold text-red-500"
          >
            x
          </div>
        </div>
      </div>
    </div>
  );
};

export default BahasaListItem;
