import ApiRequest from "./ApiRequest";

interface IDeleteLemburParams {
  id_lembur: string;
}

class AxiosDeleteLembur extends ApiRequest<IDeleteLemburParams> {
  constructor() {
    super("/lembur/delete");
  }

  post(token: string, pegawai_data: IDeleteLemburParams) {
    return this.post_request({
      token: token,
      params: { ...pegawai_data },
    });
  }
}

export default AxiosDeleteLembur;
