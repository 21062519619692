import { KodeKehadiranEnum } from "../enums/KodeKehadiranEnum";
import { TipeIzinEnum } from "../enums/TipeIzinEnum";

type Recap = {
  hadir: number;
  alpha: number;
  izin: number;
  sakit: number;
};

type RecapResponse = {
  tanggal: string,
  hadir: number,
  izin: number,
  libur: number,
  setengah_hari: number,
  tipe_izin: string,
}

export function calculatePrensi(
  presensi: RecapResponse[],
  queryStart: Date,
  queryEnd: Date,
): {
  //return
  presensiArray: KodeKehadiranEnum[] | null;
  recap: Recap | null;
} {
  let deltaDay = queryEnd.getTime() - queryStart.getTime();
  let dayCount = Math.floor(deltaDay / (1000 * 3600 * 24));

  if (!queryStart) return { presensiArray: null, recap: null };
  const arrnum = [];
  const recap: Recap = {
    hadir: 0,
    alpha: 0,
    izin: 0,
    sakit: 0,
  };

  for (let i = 0; i < presensi.length; i++) {
    if (i > dayCount) break;
    let currentPresensi = presensi[i]
    let currentTanggal = new Date(currentPresensi.tanggal)

    if (currentPresensi.hadir) {
      recap.hadir++;
      arrnum.push(KodeKehadiranEnum.HADIR);
    }
    else if (currentPresensi.libur) arrnum.push(KodeKehadiranEnum.LIBUR);
    else if (currentPresensi.izin) {
      recap.izin++
      if (
        currentPresensi.tipe_izin === TipeIzinEnum.CutiPribadi ||
        currentPresensi.tipe_izin === TipeIzinEnum.CutiNormatif
      )
        arrnum.push(KodeKehadiranEnum.CUTI);
      else
        arrnum.push(KodeKehadiranEnum.IZIN);

    }
    else if (currentPresensi.setengah_hari) {
      recap.hadir++;
      arrnum.push(KodeKehadiranEnum.SETENGAH_HARI);
    }
    else if (currentTanggal.getDay() === 0) arrnum.push(KodeKehadiranEnum.MINGGU);
    else {
      recap.alpha++;
      arrnum.push(KodeKehadiranEnum.NONE);
    }
  }

  return {
    presensiArray: arrnum,
    recap: recap,
  };
}
