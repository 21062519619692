import ApiRequest from "./ApiRequest";

export type IUpdateGajiPokokParam = {
    id_gaji_pokok: number;
    gaji_pokok: number;
    tarif_harian: number;
    premi_hadir: number;
    tunjangan_jabatan: number;
};

class AxiosUpdateGajiPokok extends ApiRequest<IUpdateGajiPokokParam> {
    constructor() {
        super("/payroll/update_gaji_pokok");
    }

    post(token: string, data: IUpdateGajiPokokParam) {
        return this.post_request({
            token: token,
            params: { ...data },
        });
    }
}

export default AxiosUpdateGajiPokok;
