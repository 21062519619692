import ApiRequest from "./ApiRequest";

interface IParamType {
  id_pegawai: number,
  tanggal_mulai: Date,
  tanggal_selesai: Date,
  id_pemberi_kuasa: number,
  jenis_kontrak: string,
  no_surat: string,
}

class AxiosDownloadTemplate extends ApiRequest<IParamType> {
  id_pegawai
  tanggal_mulai: Date
  tanggal_selesai: Date
  id_pemberi_kuasa: number
  jenis_kontrak: string
  no_surat: string

  constructor(
    jenis_perjanjian: string,
    id_pegawai: number,
    tanggal_mulai: Date,
    tanggal_selesai: Date,
    id_pemberi_kuasa: number,
    no_surat: string
  ) {
    super('/pdf/kontrak')
    this.jenis_kontrak = jenis_perjanjian
    this.id_pegawai = id_pegawai;
    this.tanggal_mulai = tanggal_mulai;
    this.tanggal_selesai = tanggal_selesai;
    this.id_pemberi_kuasa = id_pemberi_kuasa;
    this.no_surat = no_surat
  }

  get(token: string) {
    return this.get_request(
      token,
      {
        id_pegawai: this.id_pegawai,
        tanggal_mulai: this.tanggal_mulai,
        tanggal_selesai: this.tanggal_selesai,
        id_pemberi_kuasa: this.id_pemberi_kuasa,
        jenis_kontrak: this.jenis_kontrak,
        no_surat: this.no_surat,
      },
      { responseType: 'blob' }
    );
  }
}

export default AxiosDownloadTemplate;
