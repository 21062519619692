import ApiRequest from "./ApiRequest";

interface IParamType {}

class AxiosGetPresensiHarian extends ApiRequest<IParamType> {
  constructor() {
    super("/presensi/presensi");
  }

  get(
    token: string,
    waktu_mulai: string,
    waktu_selesai: string,
    jabatan?: string,
    nama_pegawai?: string
  ) {
    return this.get_request(token, {
      waktu_mulai,
      waktu_selesai,
      jabatan,
      nama_pegawai,
    });
  }
}

export default AxiosGetPresensiHarian;
