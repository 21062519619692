import TFormItem from "../../models/TFormItem";
import TOption from "../../models/TOption";

function getFormItems(
  updateStateDataPegawai: (key: string, value: any) => void,
  jabatanOptions: TOption[],
  departemenOptions: TOption[],
  perusahaanOptions: TOption[]
): TFormItem[] {
  return [
    {
      label: "ID Finpro",
      key: "id_finpro",
      type: "number",
      placeholder: "ID Finpro Pegawai",
      onChange(value: any) {
        updateStateDataPegawai("id_finpro", value);
      },
      required: false,
    },
    {
      label: "NIP",
      key: "nip",
      type: "string",
      placeholder: "Nomor Induk Pegawai",
      onChange(value: any) {
        updateStateDataPegawai("nip", value);
      },
      required: false,
    },
    {
      label: "Nama",
      key: "nama",
      type: "string",
      placeholder: "Nama Pegawai",
      onChange(value: any) {
        updateStateDataPegawai("nama", value);
      },
      required: true,
    },
    {
      label: "Perusahaan",
      key: "id_perusahaan",
      type: "option",
      placeholder: "",
      onChange(value: any) {
        updateStateDataPegawai("id_perusahaan", value);
      },
      options: perusahaanOptions,
    },
    {
      label: "Alias",
      key: "alias",
      type: "string",
      placeholder: "Masukan Alias",
      onChange(value: any) {
        updateStateDataPegawai("alias", value);
      },
      required: false,
    },
    {
      label: "No. Telp",
      key: "telp",
      type: "string",
      placeholder: "Masukon Nomor Telepon",
      onChange(value: any) {
        updateStateDataPegawai("telp", value);
      },
      required: false,
    },
    {
      label: "Tempat Lahir",
      key: "tempat_lahir",
      type: "string",
      placeholder: "",
      onChange(value: any) {
        updateStateDataPegawai("tempat_lahir", value);
      },
      required: true,
    },
    {
      label: "Tanggal Lahir",
      key: "tgl_lahir",
      type: "date",
      placeholder: "",
      onChange(value: any) {
        updateStateDataPegawai("tgl_lahir", value);
      },
      required: true,
    },
    {
      label: "Jenis Kelamin",
      key: "gender",
      type: "option",
      placeholder: "Jenis Kelamin",
      onChange(value: any) {
        updateStateDataPegawai("gender", value);
      },
      options: [
        {
          label: "Pria",
          value: "0",
        },
        {
          label: "Wanita",
          value: "1",
        },
      ],
    },
    {
      label: "Alamat KTP",
      key: "alamat_ktp",
      type: "string",
      placeholder: "Masukan Alamat KTP",
      onChange(value: any) {
        updateStateDataPegawai("alamat_ktp", value);
      },
      required: true,
    },
    {
      label: "Alamat Domisili",
      key: "alamat_domisili",
      type: "string",
      placeholder: "Masukan Alamat Domisili",
      onChange(value: any) {
        updateStateDataPegawai("alamat_domisili", value);
      },
      required: false,
    },
    {
      label: "Email",
      key: "email",
      type: "string",
      placeholder: "Masukan Alamat Email",
      onChange(value: any) {
        updateStateDataPegawai("email", value);
      },
      required: false,
    },
    {
      label: "NIK",
      key: "nik",
      type: "string",
      placeholder: "Masukan NIK",
      onChange(value: any) {
        updateStateDataPegawai("nik", value);
      },
      required: true,
    },
    {
      label: "Jabatan",
      key: "id_jabatan",
      type: "option",
      placeholder: "",
      onChange(value: any) {
        updateStateDataPegawai("jabatan", value);
      },
      options: jabatanOptions,
    },
    {
      label: "Departemen",
      key: "id_departemen",
      type: "option",
      placeholder: "",
      onChange(value: any) {
        updateStateDataPegawai("departemen", value);
      },
      options: departemenOptions,
    },
    {
      label: "Nama Bank",
      key: "nama_bank",
      type: "string",
      placeholder: "Masukan Nama Bank",
      onChange(value: any) {
        updateStateDataPegawai("nama_bank", value);
      },
      required: false,
    },
    {
      label: "Nama Rekening",
      key: "nama_rek",
      type: "string",
      placeholder: "Masukan Nama Pemilik Rekening",
      onChange(value: any) {
        updateStateDataPegawai("nama_rek", value);
      },
      required: false,
    },
    {
      label: "No. Rekening",
      key: "no_rek",
      type: "string",
      placeholder: "Masukan Nomor Rekening",
      onChange(value: any) {
        updateStateDataPegawai("no_rek", value);
      },
      required: false,
    },
    {
      label: "NPWP",
      key: "no_npwp",
      type: "string",
      placeholder: "Masukan Nomor NPWP",
      onChange(value: any) {
        updateStateDataPegawai("no_npwp", value);
      },
      required: false,
    },
    {
      label: "Agama",
      key: "agama",
      type: "option",
      placeholder: "",
      onChange(value: any) {
        updateStateDataPegawai("agama", value);
      },
      options: [
        {
          label: "Islam",
          value: "islam",
        },
        {
          label: "Katolik",
          value: "katolik",
        },
        {
          label: "Protestan",
          value: "protestan",
        },
        {
          label: "Hindu",
          value: "hindu",
        },
        {
          label: "Budha",
          value: "budha",
        },
        {
          label: "Lainnya",
          value: "lainnya",
        },
      ],
    },
    {
      label: "Kebangsaan",
      key: "kebangsaan",
      type: "option",
      placeholder: "",
      onChange(value: any) {
        updateStateDataPegawai("kebangsaan", value);
      },
      options: [
        {
          label: "WNI",
          value: "wni",
        },
        {
          label: "WNA",
          value: "wna",
        },
      ],
    },
    {
      label: "No. BPJS Kesehatan",
      key: "no_bpjs_kesehatan",
      type: "string",
      placeholder: "Masukan No. BPJS Kessehatan",
      onChange(value: any) {
        updateStateDataPegawai("no_bpjs_kesehatan", value);
      },
      required: false,
    },
    {
      label: "No. BPJS Ketenagakerjaan",
      key: "no_bpjs_tenaga_kerja",
      type: "string",
      placeholder: "Masukan No. BPJS Ketenagakerjaan",
      onChange(value: any) {
        updateStateDataPegawai("no_bpjs_tenaga_kerja", value);
      },
      required: false,
    },
    {
      label: "SIM",
      key: "sim",
      type: "sim",
      placeholder: "",
      onChange(value: any) {
        updateStateDataPegawai("sim", value);
      },
      required: false,
    },
    {
      label: "Anak Ke",
      key: "anak_ke",
      type: "number",
      placeholder: "Urutan Anak Dalam Keluarga",
      onChange(value: any) {
        updateStateDataPegawai("anak_ke", value);
      },
      required: false,
    },
    {
      label: "Status Nikah",
      key: "status_nikah",
      type: "option",
      placeholder: "",
      onChange(value: any) {
        updateStateDataPegawai("status_nikah", value);
      },
      options: [
        {
          label: "Bujangan",
          value: "bujangan",
        },
        {
          label: "Janda",
          value: "janda",
        },
        {
          label: "Menikah",
          value: "menikah",
        },
        {
          label: "Duda",
          value: "duda",
        },
      ],
    },
    {
      label: "Tahun Menikah",
      key: "tahun_nikah",
      type: "date",
      placeholder: "",
      onChange(value: any) {
        updateStateDataPegawai("tahun_nikah", value);
      },
    },
    {
      label: "Bahasa Yang Dikuasai",
      key: "bahasa_dikuasai",
      type: "bahasa",
      placeholder: "",
      onChange(value: any) {
        updateStateDataPegawai("bahasa_dikuasai", value);
      },
      required: false,
    },
    {
      label: "Pengalaman Memimpin",
      key: "pengalaman_memimpin",
      type: "pengalaman_memimpin",
      placeholder: "",
      onChange(value: any) {
        updateStateDataPegawai("pengalaman_memimpin", value);
      },
      required: false,
    },
    {
      label: "Minat",
      key: "minat",
      type: "string",
      placeholder: "Minat Umum",
      onChange(value: any) {
        updateStateDataPegawai("minat", value);
      },
      required: true,
    },
    {
      label: "Minat Lingkungan",
      key: "minat_lingkungan",
      type: "string",
      placeholder: "Minat Terhadap Lingkungan",
      onChange(value: any) {
        updateStateDataPegawai("minat_lingkungan", value);
      },
      required: true,
    },
    {
      label: "Hobby",
      key: "hobby",
      type: "string",
      placeholder: "Hobby",
      onChange(value: any) {
        updateStateDataPegawai("hobby", value);
      },
      required: false,
    },
    {
      label: "Berat Badan (kg)",
      key: "berat_badan",
      type: "number",
      placeholder: "Berat Badan",
      onChange(value: any) {
        updateStateDataPegawai("berat_badan", value);
      },
      required: true,
    },
    {
      label: "Tinggi Badan (cm)",
      key: "tinggi_badan",
      type: "string",
      placeholder: "Tinggi Badan",
      onChange(value: any) {
        updateStateDataPegawai("tinggi_badan", value);
      },
      required: true,
    },
    {
      label: "Kacamata Kiri",
      key: "kacamata_kiri",
      type: "number",
      placeholder: "Kacamata Kiri cth: (-1.5)",
      onChange(value: any) {
        updateStateDataPegawai("kacamata_kiri", value);
      },
      required: true,
    },
    {
      label: "Kacamata Kanan",
      key: "kacamata_kanan",
      type: "number",
      placeholder: "Kacamata Kanan cth: (-2)",
      onChange(value: any) {
        updateStateDataPegawai("kacamata_kanan", value);
      },
      required: true,
    },
    {
      label: "Tanggal Mulai Kerja",
      key: "tgl_mulai_kerja",
      type: "date",
      placeholder: "Masukkan Tanggal Mulai Kerja",
      onChange(value: any) {
        updateStateDataPegawai("tgl_mulai_kerja", value);
      },
      required: false,
    },
    {
      label: "Tanggal Resign",
      key: "tgl_resign",
      type: "date",
      placeholder: "Masukkan Tanggal Resign",
      onChange(value: any) {
        updateStateDataPegawai("tgl_resign", value);
      },
      required: false,
    },
    {
      label: "",
      key: "detil_sakit",
      type: "sakit",
      placeholder: "Enter details",
      onChange(value: any) {
        updateStateDataPegawai("detil_sakit", value);
      },
    },
    {
      label: "",
      key: "detil_kecelakaan",
      type: "kecelakaan",
      placeholder: "Enter details",
      onChange(value: any) {
        updateStateDataPegawai("detil_kecelakaan", value);
      },
    },
    {
      label: "",
      key: "detil_polisi",
      type: "polisi",
      placeholder: "Enter details",
      onChange(value: any) {
        updateStateDataPegawai("detil_polisi", value);
      },
    },
    {
      label: "",
      key: "detil_psikologi",
      type: "psikologi",
      placeholder: "Enter details",
      onChange(value: any) {
        updateStateDataPegawai("detil_psikologi", value);
      },
    },
    {
      label: "",
      key: "detil_militer",
      type: "militer",
      placeholder: "Enter details",
      onChange(value: any) {
        updateStateDataPegawai("detil_militer", value);
      },
    },
    {
      label: "",
      key: "detil_bacaan",
      type: "bacaan",
      placeholder: "Enter details",
      onChange(value: any) {
        updateStateDataPegawai("detil_bacaan", value);
      },
    },
    {
      label: "",
      key: "pengalaman_kerja",
      type: "pengalaman_kerja",
      placeholder: "012341234",
      onChange(value: any) {
        updateStateDataPegawai("pengalaman_memimpin", value);
      },
    },
    {
      label: "",
      key: "keluarga",
      type: "keluarga",
      placeholder: "",
      onChange(value: any) {
        updateStateDataPegawai("keluarga", value);
      },
    },
  ];
}

export default getFormItems;
