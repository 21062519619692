import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ContentHeader from "../../../components/ContentHeader";
import Table from "../../../components/Table";
import userAuth from "../../../context/UserAuth";
import DetailLemburStyle from "./DetailLembur.module.css";
import { columns, Item } from "./DetailLemburTableDefinitions";
import AxiosGetDetailLembur from "../../../api/AxiosGetDetailLembur";
import clsx from "clsx";
import AxiosApproveLembur from "../../../api/AxiosGetApproveLembur";

interface IDetailLemburProps { }
const DetailLembur: React.FC<IDetailLemburProps> = () => {
  const { user } = useContext(userAuth);
  const [data, setData] = useState<Item[]>([]);
  const [dataLembur, setDataLembur] = useState<any>();
  const { id } = useParams();

  const handleCheckboxClick = (id: number, is_verified: boolean) => {
    if (!user?.token) return;

    const axiosApproveLembur = new AxiosApproveLembur();
    axiosApproveLembur
      .post(user.token, {
        id_lembur_pegawai: [{ id: id, is_verified }]
      })
      .then((response) => {
        getLembur()
      })
      .catch((error) => {
        
      });
  };

  function getLembur() {
    if (!user?.token || !id) return;
    const axiosGetDetailLembur = new AxiosGetDetailLembur();
    axiosGetDetailLembur.get(user.token, { id: id }).then((response) => {
      const data: any = response.data.data;
      const newDataLembur: Item[] = data.pegawai_lembur.map(
        (pegawaiLemburItem: any) => ({
          ...pegawaiLemburItem,
          isVerified: pegawaiLemburItem.is_approved,
          action: {
            handleCheckboxClick: (e: any) => {
              handleCheckboxClick(pegawaiLemburItem.id, e.target.checked)
            }
          },
        })
      );
      setData(newDataLembur);
      setDataLembur(data.surat_lembur);
    });
  }

  useEffect(() => {
    getLembur();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user.role)
    return <> </>

  return (
    <>
      <ContentHeader title="Detail Lembur" enableBack />
      <div className={clsx(DetailLemburStyle.container, "!px-12 !py-6")}>
        <div className={clsx(DetailLemburStyle.form_container, "!w-full")}>
          Waktu Mulai
          <div>
            <span className="select-none">: </span>
            {dataLembur?.waktu_mulai}
          </div>
          Waktu Selesai
          <div>
            <span className="select-none">: </span>
            {dataLembur?.waktu_selesai}
          </div>
          Pekerjaan
          <div>
            <span className="select-none">: </span>
            {dataLembur?.pekerjaan}
          </div>
        </div>
        <div className="p-0 border rounded-[10px] text-left w-full pb-4 mt-2">
          <Table<Item> data={data} columns={columns(user.role)} />
        </div>
      </div>
    </>
  );
};
export default DetailLembur;
