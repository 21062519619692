import ApiRequest from "./ApiRequest";

interface IDeleteLaporanSlipGajiParams {
  waktu_mulai: string;
  waktu_selesai: string;
}

class AxiosDeleteLaporanSlipGaji extends ApiRequest<IDeleteLaporanSlipGajiParams> {
  constructor() {
    super("/payroll/delete_gaji");
  }

  post(token: string, pegawai_data: IDeleteLaporanSlipGajiParams) {
    return this.post_request({
      token: token,
      params: { ...pegawai_data },
    });
  }
}

export default AxiosDeleteLaporanSlipGaji;
