import errorExclamation from "../../../vectors/errorExclamation.svg";
import InputField from "../../../components/InputField";
import { useContext, useEffect, useState } from "react";
import PaginationLinks from "../../../components/PaginationLinks";
import Table from "../../../components/Table";
import { columns, Item } from "./ListKirimanTableDefinitions";
import userAuth from "../../../context/UserAuth";
import Button from "../../../components/Button";
import { Loader, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { ButtonTypeEnum } from "../../../enums/ButtonTypeEnum";
import { toast } from "react-toastify";
import ContentHeader from "../../../components/ContentHeader";
import DropdownField from "../../../components/DropDownField";
import AxiosListAllPegawai from "../../../api/AxiosListAllPegawai";
import TPegawai from "../../../models/TPegawai";
import TOption from "../../../models/TOption";
import { capitalizeEachWord } from "../../../helpers/StringHelper";
import AxiosListKiriman from "../../../api/AxiosListKiriman";
import AxiosCreateKiriman from "../../../api/AxiosCreateKiriman";
import AxiosUpdateKiriman from "../../../api/AxiosUpdateKiriman";
import AxiosDeleteKiriman from "../../../api/AxiosDeleteKiriman";

interface IListKirimanProps {
}
const ListKiriman: React.FC<IListKirimanProps> = (props: IListKirimanProps) => {
  const year = new Date().getFullYear();

  const { user } = useContext(userAuth);
  const defaultData: Item[] = [];
  const [data, setData] = useState(() => [...defaultData]);
  const [id_izin, setId_izin] = useState(0)
  const [pegawai_options, setPegawaiOptions] = useState<TOption[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //filter and table
  const [pegawai_query, setPegawaiQuery] = useState("")
  const [tanggal_mulai_query, setTanggalMulaiQuery] = useState("")
  const [tanggal_selesai_query, setTanggalSelesaiQuery] = useState("")
  const [selected_link, setSelectedLink] = useState("");
  const [pagination_links, setPaginationLinks] = useState([]);

  //modal
  const [opened, { open, close }] = useDisclosure(false);
  const [action, setAction] = useState("")

  //create field
  const [id_pegawai, setIdPegawai] = useState(0)

  const [tanggal_berangkat, setTanggalBerangkat] = useState("")
  const [jam_berangkat, setJamBerangkat] = useState("")

  const [tanggal_kembali, setTanggalKembali] = useState("")
  const [jam_kembali, setJamKembali] = useState("")
  const [kota, setKota] = useState("")

  const [id_kiriman, setIdKiriman] = useState(0)
  const [edit_nama_pegawai, setEdit_nama_pegawai] = useState("")

  function getListPegawai() {
    if (!user?.token) return;
    const axiosListAllPegawai = new AxiosListAllPegawai();
    axiosListAllPegawai
      .get(user.token, {
        status: 'aktif'
      })
      .then((response) => {
        const data: TPegawai[] = response.data.data;
        const options: TOption[] = [];
        data.forEach((item: TPegawai) => {
          options.push({
            value: item.id.toString(),
            label: capitalizeEachWord(item.nama, "_"),
          });
        });
        setPegawaiOptions(options);
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
      });

  }

  function getKiriman() {
    if (!user?.token) return
    if (!tanggal_mulai_query || !tanggal_selesai_query) return
    const axiosListKiriman = new AxiosListKiriman();

    axiosListKiriman.get(user.token, {
      jam_berangkat: tanggal_mulai_query,
      jam_kembali: tanggal_selesai_query,
      nama_pegawai: pegawai_query
    }).then((response) => {
      const data = response.data.data
      data.map((kiriman: any) => {
        kiriman.onEdit = ((id_kiriman: number, nama: string) => {
          setIdKiriman(id_kiriman)
          setEdit_nama_pegawai(nama)

          setTanggalBerangkat("")
          setJamBerangkat("")
          setTanggalKembali("")
          setJamKembali("")

          setAction("edit")
          open()
        })
        kiriman.onDelete = ((id_kiriman: number) => {
          setIdKiriman(id_kiriman)
          setAction("delete")
          open()
        })
      })
      setData(data)
    })
  }

  useEffect(() => {
    getListPegawai();
  }, [])

  useEffect(() => {
    getKiriman();
  }, [tanggal_mulai_query, tanggal_selesai_query, pegawai_query])

  function modalContent() {
    if (action === "delete")
      return <>
        <div className="flex justify-center">
          <img
            alt={"check"}
            src={errorExclamation}
            className={"w-24"}
          />
        </div>
        <div className={"text-4xl text-center"}>
          Yakin Hapus Kiriman ?
        </div>
        <div className={"mt-12 flex justify-around"}>
          <Button
            onClick={() => close()}
            text="Batal"
            size="small"
            buttonType={ButtonTypeEnum.CLICKABLE_TEXT}
            color="danger"
            className="min-w-[100px] min-h-[40px]"
          />
          <Button
            onClick={() => {
              if (!user?.token) return
              const axiosDeleteKiriman = new AxiosDeleteKiriman();

              axiosDeleteKiriman.post(user.token, {
                id: id_kiriman.toString(),
              }).then(() => {
                toast.success(`Berhasil Delete Kiriman`, {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
                getKiriman();
                close();
              })


            }}
            text="Hapus"
            size="small"
            className="min-w-[100px] min-h-[40px]"
          />
        </div></>

    if (action === "tambah")
      return <div>
        <div className="text-4xl text-center font-bold mb-4"> Tambah Kiriman</div>
        <span>Pegawai</span>
        <DropdownField
          name="pegawai"
          id="pegawai"
          options={
            [{
              value: "0",
              label: "Pilih Pegawai ...",
            }, ...pegawai_options]
          }
          onChange={(e) => { setIdPegawai(parseInt(e)) }}
          value={id_pegawai.toString()}
        />
        <span>Waktu Berangkat</span>
        <div className="flex gap-4">
          <InputField type="date" placeholder=""
            onChange={(e) => { setTanggalBerangkat(e) }}
          />
          <InputField type="time" placeholder=""
            onChange={(e) => { setJamBerangkat(e) }}
          />
        </div>
        <span>Waktu Kembali</span>
        <div className="flex gap-4">
          <InputField type="date" placeholder=""
            onChange={(e) => { setTanggalKembali(e) }}
          />
          <InputField type="time" placeholder=""
            onChange={(e) => { setJamKembali(e) }}
          />
        </div>
        <span>Kota</span>
        <InputField type="text" placeholder="Input Kota"
          onChange={(e) => { setKota(e) }}
        />

        <Button
          text={"Tambah Kiriman"}
          isDisabled={isLoading}
          onClick={() => {
            if (!user?.token) return
            const axiosCreateKiriman = new AxiosCreateKiriman();

            axiosCreateKiriman.post(user.token, {
              id_pegawai: id_pegawai.toString(),
              jam_berangkat: tanggal_berangkat + " " + jam_berangkat,
              jam_kembali: tanggal_kembali + " " + jam_kembali,
              kota: kota,
            }).then(() => {
              toast.success(`Berhasil Membuat Kiriman`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              close();
              getKiriman()
            })
          }}
          size="small"
        />
      </div>

    if (action === "edit")
      return <div>
        <div className="text-4xl text-center font-bold mb-4"> Edit Kiriman</div>
        <span className="flex">Pegawai</span>
        <span className="flex text-2xl mb-4">{edit_nama_pegawai}</span>

        <span>Waktu Berangkat</span>
        <div className="flex gap-4">
          <InputField type="date" placeholder=""
            onChange={(e) => { setTanggalBerangkat(e) }}
          />
          <InputField type="time" placeholder=""
            onChange={(e) => { setJamBerangkat(e) }}
          />
        </div>
        <span>Waktu Kembali</span>
        <div className="flex gap-4">
          <InputField type="date" placeholder=""
            onChange={(e) => { setTanggalKembali(e) }}
          />
          <InputField type="time" placeholder=""
            onChange={(e) => { setJamKembali(e) }}
          />
        </div>

        <Button
          text={"Simpan Perubahan"}
          isDisabled={isLoading}
          onClick={() => {
            if (!user?.token) return
            const axiosUpdateKiriman = new AxiosUpdateKiriman();

            axiosUpdateKiriman.post(user.token, {
              id: id_kiriman.toString(),
              jam_berangkat: tanggal_berangkat + " " + jam_berangkat,
              jam_kembali: tanggal_kembali + " " + jam_kembali,
            }).then(() => {
              toast.success(`Berhasil Edit Kiriman`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              getKiriman();
              close();
            })
          }}
          size="small"
        />
      </div>

  }

  if (!user?.role) return <> </>

  return <>
    <ContentHeader
      title={"Kiriman"}
      additionalHeader={
        <>
          <div className="flex gap-2">
            <div className="flex gap-2">
              <InputField type="text" placeholder="Nama Pegawai..."
                onChange={(value) => { setPegawaiQuery(value); }}
              />

              <InputField type="date" placeholder=""
                onChange={(e) => { setTanggalMulaiQuery(e) }}
              />
              <span className="flex flex-col justify-center">s/d</span>
              <InputField type="date" placeholder=""
                onChange={(e) => { setTanggalSelesaiQuery(e) }}
              />
            </div>

            <Button
              text={
                isLoading ? (
                  <>
                    <Loader color="indigo" size="sm" />
                  </>
                ) : (
                  "Tambah Kiriman"
                )
              }
              isDisabled={isLoading}
              onClick={() => {
                setAction("tambah")
                open()
              }}
              size="small"
            />
          </div>
        </>

      }
    />

    <Modal opened={opened} onClose={close} title="" radius={"md"} centered>
      {modalContent()}
    </Modal>

    <div className="flex flex-col py-[10px]">
      <div className="mx-[40px] mt-4">
        <PaginationLinks
          onPaginationClick={(link) => {
            setSelectedLink(link)
          }}
          pagination_links={pagination_links}
          className="!w-full"
        />
        {/* <Pagination value={pagination_links} onChange={setPaginationLinks} total={10} />; */}
      </div>
      <div
        className={"flex justify-center items-center bg-white rounded-s mx-5 my-10 pb-4 !pt-0 !mt-0"}
      >
        <Table<Item> data={data} columns={columns(user.role)} />
      </div>
    </div>
  </>
}
export default ListKiriman;
