/* eslint-disable react-hooks/exhaustive-deps */
import { ChangeEvent, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AxiosGetLembur from "../../../../api/AxiosGetLembur";
import Button from "../../../../components/Button";
import ContentHeader from "../../../../components/ContentHeader";
import Table from "../../../../components/Table";
import userAuth from "../../../../context/UserAuth";
import LemburStyle from "./LemburPegawai.module.css";
import { columns, Item } from "./LemburPegawaiTableDefinitions";
import InputField from "../../../../components/InputField";
import clsx from "clsx";
import { toast } from "react-toastify";
import ConfirmationPopUpCard from "../../../../components/ConfirmationPopUpCard";
import AxiosDeleteLembur from "../../../../api/AxiosDeleteLembur";
import SearchField from "../../../../components/SearchField";
import DropdownField from "../../../../components/DropDownField";
import AxiosListAllPegawai from "../../../../api/AxiosListAllPegawai";
import TPegawai from "../../../../models/TPegawai";
import TOption from "../../../../models/TOption";
import { capitalizeEachWord } from "../../../../helpers/StringHelper";
import AxiosGetLemburPegawai from "../../../../api/AxiosGetLemburPegawai";
import AxiosApproveLembur from "../../../../api/AxiosGetApproveLembur";
import GeneralContext from "../../../../context/generalContext";
import AxiosListCreatePegawaiOptions from "../../../../api/AxiosGetCreatePegawaiOptions";
import AxiosDeleteLemburPegawai from "../../../../api/AxiosDeleteLemburPegawai";

interface ILemburPegawaiProps { }
const LemburPegawai: React.FC<ILemburPegawaiProps> = () => {
  const { user } = useContext(userAuth);
  const { generalContext, setGeneralContext } = useContext(GeneralContext)
  const navigate = useNavigate();
  const [dataLembur, setDataLembur] = useState<Item[]>([]);
  const [waktuMulai, setWaktuMulai] = useState("");
  const [waktuSelesai, setWaktuSelesai] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pegawaiOptions, setPegawaiOptions] = useState<TOption[]>([]);
  const [selectedPegawaiId, setSelectedPegawaiId] = useState(0);

  //filter
  const [selectedJabatanId, setSelectedJabatanId] = useState(0);
  const [selectedPerusahaanId, setSelectedPerusahaanId] = useState(0);
  const [selectedDepartemenId, setSelectedDepartemenId] = useState(0);

  const [jabatanOptions, setJabatanOptions] = useState([]);
  const [perusahaanOptions, setPerusahaanOptions] = useState([]);
  const [departemenOptions, setDepartemenOptions] = useState([]);

  function getLembur() {
    if (!user?.token) return;
    const axiosGetLembur = new AxiosGetLemburPegawai();
    axiosGetLembur
      .get(user.token, {
        tanggal_mulai: waktuMulai,
        tanggal_selesai: waktuSelesai,
        id: selectedPegawaiId,
        id_jabatan: selectedJabatanId,
        id_perusahaan: selectedPerusahaanId,
        id_departemen: selectedDepartemenId,
      })
      .then((response) => {
        const data: any[] = response.data.data;
        const newDataLembur: Item[] = data.map((lemburItem) => ({
          ...lemburItem,
          handleCheckboxClick: (e: ChangeEvent<HTMLInputElement>) => {
            handleCheckboxClick(lemburItem.id, e.target.checked)
          },
          onDelete: (value: number) => {
            setDeleteLemburId(value.toString())
            setIsPeringatan(true)
          }


        }));
        setDataLembur(newDataLembur);
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
      });
  }
  function handleCheckboxClick(id: number, is_verified: boolean) {
    if (!user?.token) return;

    const axiosApproveLembur = new AxiosApproveLembur();
    axiosApproveLembur
      .post(user.token, {
        id_lembur_pegawai: [{ id: id, is_verified }]
      })
      .then((response) => {
        setDataLembur([])
        getLembur()
        
      })
      .catch((error) => {
       
      });
  };

  function getOptions() {
    if (!user?.token) return;
    const axiosGetOptions = new AxiosListCreatePegawaiOptions();

    axiosGetOptions.get(user.token).then((response) => {
      const data = response.data.data;

      const jabatanData = data.jabatan_options.map((item: any) => ({
        value: item.id,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      setJabatanOptions(jabatanData);

      const perusahaanData = data.perusahaan_options.map((item: any) => ({
        value: item.id,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      setPerusahaanOptions(perusahaanData);

      const departemenData = data.departemen_options.map((item: any) => ({
        value: item.id,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      setDepartemenOptions(departemenData);
    });

  }

  useEffect(() => {
    setWaktuMulai(generalContext?.waktu_mulai ?? "")
    setWaktuSelesai(generalContext?.waktu_selesai ?? "")
    setSelectedPegawaiId(generalContext?.id_pegawai ?? 0)
    getListPegawai()
    getOptions()
  }, []);


  useEffect(() => {
    setGeneralContext?.({
      waktu_mulai: waktuMulai,
      waktu_selesai: waktuSelesai,
      id_pegawai: selectedPegawaiId,
    })
    if (!waktuMulai || !waktuSelesai || !selectedPegawaiId) return;
    getLembur();
  }, [waktuMulai, waktuSelesai, selectedPegawaiId]);

  useEffect(() => {
    if (!waktuMulai || !waktuSelesai || !selectedPegawaiId) return;
    getLembur()
  }, [selectedJabatanId, selectedPerusahaanId, selectedDepartemenId])

  const [isPeringatan, setIsPeringatan] = useState(false);
  const [deleteLemburId, setDeleteLemburId] = useState<string | null>(null);

  const confirmDeleteLembur = () => {
    if (deleteLemburId) {
      if (!user.token || isLoading === true) return;
      setIsLoading(true);

      const axiosDeleteLemburPegawai = new AxiosDeleteLemburPegawai();
      axiosDeleteLemburPegawai
        .post(user.token, { id_lembur_pegawai: deleteLemburId, })
        .then((response) => {
          const data = response.data;
          if (data.success) {
            toast.success("Lembur Berhasil Di hapus!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            getLembur()
            setIsLoading(false);
          }
        })
        .catch((e) => {
          const response = e.response;
          if (response.status === 422)
            toast.error(`${response.data.message}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          setIsLoading(false);
        });

      setIsPeringatan(false);
    }
  };

  function getListPegawai() {
    if (!user?.token) return;
    const axiosListAllPegawai = new AxiosListAllPegawai();
    axiosListAllPegawai
      .get(user.token, {
        status: 'aktif'
      })
      .then((response) => {
        const data: TPegawai[] = response.data.data;
        const options: TOption[] = [];

        options.push({
          value: "0",
          label: "Pilih Pegawai ..."
        });
        data.forEach((item: TPegawai) => {
          options.push({
            value: item.id.toString(),
            label: capitalizeEachWord(item.nama, "_"),
          });
        });
        setPegawaiOptions(options);
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
      });
  }

  return (
    <>
      <div className="flex justify-between mt-[40px] mx-[40px] mb-2">
        <ContentHeader title="Lembur Pegawai" className="!p-0" />
        <div className="flex gap-2">
          <span className="flex flex-col justify-center font-bold">
            Periode
          </span>
          <InputField
            type="date"
            placeholder=""
            onChange={(value) => setWaktuMulai(value)}
            value={waktuMulai}
          />
          <span className="flex flex-col justify-center">s/d</span>
          <InputField
            type="date"
            placeholder=""
            onChange={(value) => setWaktuSelesai(value)}
            value={waktuSelesai}
          />
        </div>
      </div>
      <div className="m-4">
        <div className="flex gap-2">
          <DropdownField
            id="pegawai"
            name="pegawai"
            className="!border !py-[17px] !px-[31px] !rounded "
            options={pegawaiOptions}
            onChange={(selectedOption) => {
              setSelectedPegawaiId(parseInt(selectedOption))
            }}
            placeholder="Filter Pegawai"
            value={selectedPegawaiId.toString()}
          />
          <DropdownField
            id="jabatan"
            name="jabatan"
            className="!border !py-[17px] !px-[31px] !rounded !min-w-[200px]"
            options={jabatanOptions}
            onChange={(value) => { setSelectedJabatanId(parseInt(value)); }}
            placeholder="Filter Jabatan"
            value={selectedJabatanId.toString()}
          />

          <DropdownField
            id="departemen"
            name="Departemen"
            className="!border !py-[17px] !px-[31px] !rounded !min-w-[200px]"
            options={departemenOptions}
            onChange={(value) => { setSelectedDepartemenId(parseInt(value)); }}
            placeholder="Filter Departemen"
            value={selectedDepartemenId.toString()}
          />
          <DropdownField
            id="perusahaan"
            name="Perusahaan"
            className="!border !py-[17px] !px-[31px] !rounded !min-w-[200px]"
            options={perusahaanOptions}
            onChange={(value) => { setSelectedPerusahaanId(parseInt(value)); }}
            placeholder="Filter Perusahaan"
            value={selectedPerusahaanId.toString()}
          />
        </div>
      </div>
      {isPeringatan && (
        <ConfirmationPopUpCard
          title={"Peringatan"}
          text="Apakah Anda yakin ingin menghapus data lembur ini?"
          onClose={() => setIsPeringatan(false)}
          onRightButtonClick={confirmDeleteLembur}
          rightButtonText="Hapus"
        />
      )}
      <div className={clsx(LemburStyle.container, "px-0 pb-4")}>
        <Table<Item> data={dataLembur} columns={columns} />
      </div>
      <div className="text-red-500 my-2 text-left mx-[40px]">
        *Wajib memasukkan periode!
      </div>
    </>
  );
};
export default LemburPegawai;
