export enum TipeIzinEnum {
  TidakMasuk = 'tidak_masuk',
  PulangAwal = 'pulang_awal',
  DatangTerlambat = 'datang_terlambat',
  SakitSuratDokter = 'sakit_surat_dokter',
  SakitTanpaSuratDokter = 'sakit_tanpa_surat_dokter',
  MeninggalkanTempatKerja = 'meninggalkan_tempat_kerja',
  Dinas = 'dinas',
  DatangTerlambatDinas = 'datang_terlambat_dinas',
  PulangAwalDinas = 'pulang_awal_dinas',
  CutiNormatif = 'cuti_normatif',
  CutiPribadi = 'cuti_pribadi',
  TidakScan = 'tidak_scan',
  LainLain = 'lain_lain',
  Libur = 'libur',
  KerjaBorongan = 'kerja_borongan',
  KerjaHarian = 'kerja_harian',
}
