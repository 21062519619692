import React from "react";
import TMiliter from "../../../models/TMiliter"; // Import the TMiliter type
import ListForm from "../ListForm";

interface IMiliterListFormProps {
    onMiliterListChange: (value: TMiliter[]) => void;
    defaultMiliter: TMiliter[]
}

const MiliterListForm: React.FC<IMiliterListFormProps> = (
    props: IMiliterListFormProps
) => {
    return (
        <>
            <ListForm<TMiliter>
                onListChange={(list) => {
                    props.onMiliterListChange(list)
                }}
                initialList={props.defaultMiliter}
                defaultCreateItem={{
                    keperluan: "",
                    dimana: "",
                    tahun:undefined,
                    id: Date.now()
                }}
                listTitle="Catatan Militer"
                addButtonText="Tambah detil militer"
                listField={[
                    {
                        label: "Keperluan",
                        key: "keperluan",
                        type: "text",
                        placeholder: "Jenis urusan",
                        onChange: () => { }
                    },
                    {
                        label: "Dimana",
                        key: "dimana",
                        type: "text",
                        placeholder: "Tempat terjadi urusan",
                        onChange: () => { }
                    },
                    {
                        label: "tahun",
                        key: "tahun",
                        type: "number",
                        placeholder: "Tahun urusan",
                        onChange: () => { }
                    },
                ]}
            />
        </>
    );
};

export default MiliterListForm;

