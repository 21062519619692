import { createColumnHelper } from "@tanstack/react-table";
// import Button from "../../../components/Button";

export type Item = {
  id: number;
  nomor_spk: string;
  date: string;
  rincian_barang: string;
  jumlah_barang: number;
  harga_satuan: number;
  harga_total: number;
  soft_deleted: boolean;
  action: {
    detail: () => void;
    lembarKerjaBorongan: () => void;
  };
};

const columnHelper = createColumnHelper<Item>();
export const columns = [
  columnHelper.accessor("id", {
    header: () => "No.",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor((row) => row.date, {
    id: "date",
    cell: (info) => <>{info.getValue()}</>,
    header: () => <span>Tanggal</span>,
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor((row) => row.nomor_spk, {
    id: "nomor_spk",
    cell: (info) => <>{info.getValue().toString()}</>,
    header: () => <span>No. SPK</span>,
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor((row) => row.rincian_barang, {
    id: "rincian_barang",
    cell: (info) => <>{info.getValue()}</>,
    header: () => <span>Rincian Barang</span>,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor((row) => row.jumlah_barang, {
    id: "jumlah_barang",
    cell: (info) => <>{info.getValue()}</>,
    header: () => <span>Jumlah Barang</span>,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor((row) => row.harga_satuan, {
    id: "harga_satuan",
    cell: (info) => <>{info.getValue()}</>,
    header: () => <span>Harga Satuan</span>,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor((row) => row.harga_total, {
    id: "harga_total",
    cell: (info) => <>{info.getValue()}</>,
    header: () => <span>Harga Total</span>,
    footer: (info) => info.column.id,
  }),

  // columnHelper.accessor("action", {
  //   header: "Action",
  //   cell: (inf) => (
  //     <div className="flex gap-2">
  //       <Button
  //         size="small"
  //         text="Detail"
  //         onClick={() => {
  //           inf.row.original.action.detail();
  //         }}
  //       />
  //       <Button
  //         size="small"
  //         text="Lembar Kerja Borongan"
  //         onClick={() => {
  //           inf.row.original.action.lembarKerjaBorongan();
  //         }}
  //       />
  //     </div>
  //   ),
  //   footer: (info) => info.column.id,
  // }),
];
