import { createColumnHelper } from "@tanstack/react-table";
import { ChangeEvent } from "react";
import { numberToMoney } from "../../../helpers/StringHelper";

export type Item = {
  id: string;
  tarif: number;
  nama: string;
  isVerified: boolean;
  lembur_pegawai_id: string;
  action: {
    handleCheckboxClick: (value: ChangeEvent<HTMLInputElement>) => void;
  };
  logs: [];
};

const columnHelper = createColumnHelper<Item>();
export const columns = (role: string) => [
  columnHelper.accessor("id", {
    header: () => "No.",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor("nama", {
    id: "nama",
    cell: (info) => <span className="capitalize">{info.getValue()}</span>,
    header: () => <span>Nama</span>,
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor("tarif", {
    id: "upah",
    cell: (info) => <>{numberToMoney(info.getValue())}</>,
    header: () => <span>Upah</span>,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor(row => row, {
    id: "jam",
    cell: (info) => {
      let logs: any = info.row.original.logs
      const waktu_log = logs.at(-1)?.scan_date.split(" ")[1]

      if (logs.length > 0)
        return <>{waktu_log}</>
      else
        return <>Tidak Ada Log </>
    },
    header: () => <span>Log Terakhir</span>,
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor("isVerified", {
    id: "isVerified",
    cell: (info) => {
      const item = info.row.original;
      if (
        role === "super_admin" ||
        role === "hrd_admin" ||
        role === "hrd_kepala"
      ) return (
        <>{item.isVerified ?
          <input
            type="checkbox"
            onChange={(value) => info.row.original.action.handleCheckboxClick(value)}
            checked={item.isVerified}
            disabled
          /> :
          <input
            type="checkbox"
            onChange={(value) => info.row.original.action.handleCheckboxClick(value)}
            checked={item.isVerified}
          />
        }
        </>
      );
      else
        return <input
          type="checkbox"
          checked={item.isVerified}
          disabled
        />
    },
    header: () => <span>Verifikasi</span>,
  }),
];
