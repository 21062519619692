import ApiRequest from "./ApiRequest";

interface IParamType {}

class AxiosGetDetailLembarKerjaBorongan extends ApiRequest<IParamType> {
  constructor(id: string, tgl_pekerjaan: string) {
    super(
      `/produksi/list_borongan?id_list_pekerjaan_spk=${id}&tgl_pekerjaan=${tgl_pekerjaan}`
    );
  }

  get(token: string) {
    return this.get_request(token);
  }
}

export default AxiosGetDetailLembarKerjaBorongan;
