import ApiRequest from "./ApiRequest";

interface IUpdateKiriman {
    id: string;
    jam_berangkat: string;
    jam_kembali: string;
}

class AxiosUpdateKiriman extends ApiRequest<IUpdateKiriman> {
    constructor() {
        super("/kiriman/update");
    }

    post(token: string, absen_data: IUpdateKiriman) {
        return this.post_request({
            token: token,
            params: { ...absen_data }
        });
    }
}

export default AxiosUpdateKiriman;
