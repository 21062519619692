import ApiRequest from "./ApiRequest";
interface IParamType {}

class AxiosGetLeaveSummary extends ApiRequest<IParamType> {
  constructor() {
    super("/v2/report/leave-summary");
  }

  get(token: string, param: IParamType, pagination ?: string) {
    if (pagination) this.path = pagination;
    return this.get_request(token, {...param});
  }

  fetch(token: string, param: IParamType, options: any) {
    return this.get_request(token, { ...param }, { ...options })
  }
}

export default AxiosGetLeaveSummary;
