import React, { useContext, useState } from "react";
import LoginStyle from "./Login.module.css";
import LogoTMJM from "./images/logoTMJM.png";
import InputField from "./components/InputField";
import Button from "./components/Button";
import { useNavigate } from "react-router-dom";
import userAuth from "./context/UserAuth";
import AxiosRequestToken from "./api/AxiosRequestToken";
import { motion } from "framer-motion";
import TimeoutPopup, {
  defaultPopUpProps,
  ITimeoutPopupProps,
} from "./components/TimeoutPopUp";
import { toast } from "react-toastify";
import { Loader } from "@mantine/core";

interface ILoginProps {}

type InputFieldValues = {
  username: string;
  password: string;
};

const Login: React.FC<ILoginProps> = () => {
  const navigate = useNavigate();
  const { setUser } = useContext(userAuth);
  const [inputField, setInputField] = useState<InputFieldValues>({
    username: "",
    password: "",
  });
  const axiosRequestToken = new AxiosRequestToken();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [popUpState, setPopUpState] =
    useState<ITimeoutPopupProps>(defaultPopUpProps);

  function onLogin(): void {
    if (!setUser || isLoading === true) return;
    setIsLoading(true);

    axiosRequestToken
      .post(inputField.username, inputField.password, "PC")
      .then((response) => {
        setUser({
          token: response.data.data.token.plainTextToken,
          name: response.data.data.name,
          role: response.data.data.role,
        });
        navigate("/dashboard");
        toast.success("Berhasil login!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsLoading(false);
        // setPopUpState({
        //   ...popUpState,
        //   isEnabled: true,
        //   message: "Login Success",
        //   onTimeout() {
        //     setPopUpState({ ...popUpState, isEnabled: false });
        //     navigate("/dashboard");
        //   },
        // });
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
        // setPopUpState({
        //   ...popUpState,
        //   popupType: PopUpEnum.ERROR,
        //   message: "Email atau Password Salah",
        //   timeout: 1000,
        //   isEnabled: true,
        //   onTimeout() {
        //     setPopUpState({ ...popUpState, isEnabled: false });
        //   },
        // });
      });
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.3 }}
      exit={{ opacity: 0 }}
    >
      <TimeoutPopup {...popUpState} />
      <div className={LoginStyle.login}>
        <div className={LoginStyle.bg_part}>
          <div className={`${LoginStyle.rect} ${LoginStyle.top_left_rect}`} />
          <div className={`${LoginStyle.rect} ${LoginStyle.top_right_rect}`} />
          <div className={`${LoginStyle.rect} ${LoginStyle.left_rect}`} />
          <div className={`${LoginStyle.rect} ${LoginStyle.bottom_rect}`} />
          <img src={LogoTMJM} className={LoginStyle.logo} alt="Logo" />
        </div>
        <div className={LoginStyle.login_part}>
          <div className="flex flex-col gap-4 text-left w-full pr-[55px]">
            <div className={LoginStyle.login_title}> Login </div>
            <div className={LoginStyle.login_form}>
              <InputField
                type="text"
                placeholder="Email"
                onChange={(value) =>
                  setInputField({ ...inputField, username: value })
                }
                className="border w-full"
              />
              <InputField
                type="password"
                placeholder="Password"
                onChange={(value) =>
                  setInputField({ ...inputField, password: value })
                }
                className="border w-full"
              />
              <Button
                onClick={onLogin}
                text={
                  isLoading ? (
                    <>
                      <Loader color="indigo" size="sm" />
                    </>
                  ) : (
                    "Login"
                  )
                }
                size="small"
                className={`h-[55px]`}
                isDisabled={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="!text-left"></div>
    </motion.div>
  );
};

export default Login;
