import { createColumnHelper } from "@tanstack/react-table";
import Button from "../../../components/Button";
import { ButtonTypeEnum } from "../../../enums/ButtonTypeEnum";
import { capitalizeEachWord } from "../../../helpers/StringHelper";

export type Item = {
    date: Date;
    name: string;
    leave_type: string;
    leave_reason: string;
    company: string
};

const columnHelper = createColumnHelper<Item>();
export const columns = [
    columnHelper.accessor((row) => row.date, {
        id: "date",
        cell: (info) => <>{info.getValue()}</>,
        header: () => <span>Tanggal</span>,
        footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.company, {
        id: "company",
        cell: (info) => <>{info.getValue()}</>,
        header: () => <span>Perusahaan</span>,
        footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.name, {
        id: "name",
        cell: (info) => <>{info.getValue()}</>,
        header: () => <span>Nama</span>,
        footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.leave_type, {
        id: "leave_type",
        cell: (info) => <>{capitalizeEachWord(info.getValue(), "_")}</>,
        header: () => <span>Tipe</span>,
        footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.leave_reason, {
        id: "leave_reason",
        cell: (info) => <>{info.getValue()}</>,
        header: () => <span>Alasan</span>,
        footer: (info) => info.column.id,
    }),
    
    
];
