import ApiRequest from "./ApiRequest";

interface IParamType {
}

class AxiosListCreatePegawaiOptions extends ApiRequest<IParamType> {
    constructor() {
        super("/list_create_pegawai_options");
    }

    get(token: string) {
        return this.get_request(token);
    }
}

export default AxiosListCreatePegawaiOptions;
