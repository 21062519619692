
import ApiRequest from "./ApiRequest";

interface ICreateKontrakParam {
    id_pegawai: number,
    id_perusahaan: number,
    jenis_kontrak: string,
    expired: string,
    nomor_surat: string,
}

class AxiosCreateKontrak extends ApiRequest<ICreateKontrakParam> {
    constructor() {
        super("/create_kontrak");
    }

    post(token: string, file: File, kontrak_data: ICreateKontrakParam) {
        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("filename", file.name);
        formData.append("id_pegawai", kontrak_data.id_pegawai.toString());
        formData.append("id_perusahaan", kontrak_data.id_perusahaan.toString());
        formData.append("jenis_kontrak", kontrak_data.jenis_kontrak);
        formData.append("expired", kontrak_data.expired);
        formData.append("nomor_surat", kontrak_data.nomor_surat);

        return this.post_request({
            token: token,
            formData: formData
        });
    }
}

export default AxiosCreateKontrak;
