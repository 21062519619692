/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import AxiosGetPresensiHarian from "../../../api/AxiosGetPresensiHarian";
import ContentHeader from "../../../components/ContentHeader";
import InputField from "../../../components/InputField";
import Table from "../../../components/Table";
import userAuth from "../../../context/UserAuth";
import { dateToString, fullStringToDate } from "../../../helpers/DateHelper";
import HarianStyle from "./Harian.module.css";
import { columns, Item } from "./HarianTableDefinitions";
import Button from "../../../components/Button";
import { toast } from "react-toastify";
import AxiosSyncAbsensi from "../../../api/AxiosSyncAbsensi";
import clsx from "clsx";
import AxiosGetJabatan from "../../../api/AxiosGetJabatan";
import TOption from "../../../models/TOption";
import { capitalizeEachWord } from "../../../helpers/StringHelper";
import DropdownField from "../../../components/DropDownField";
import { Loader } from "@mantine/core";
import GeneralContext from "../../../context/generalContext";

interface IHarianProps { }

const Harian: React.FC<IHarianProps> = () => {
  const { user } = useContext(userAuth);
  const { generalContext, setGeneralContext } = useContext(GeneralContext);
  const [data, setData] = useState<Item[]>([]);
  const [tanggalQuery, setTanggalQuery] = useState(dateToString(new Date()));
  const [pegawaiName, setPegawaiName] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function getAbsensiHarian(query?: string, namaPegawai?: string) {
    if (!user.token) return;
    const axiosGetPresensiHarian = new AxiosGetPresensiHarian();

    axiosGetPresensiHarian
      .get(user.token, tanggalQuery, tanggalQuery, query, namaPegawai)
      .then((response) => {
        const data = response.data.data;
        if (!data) {
          return setData([]);
        }
        data.map((presensi: any) => {
          presensi.masuk_pagi = presensi.masuk_1.split(" ")[1];

          if (!presensi.masuk_2) return presensi;
          const istirahat_start = fullStringToDate("2000-01-02 10:00:00");
          const istirahat_end = fullStringToDate("2000-01-02 14:00:00");
          const time2 = fullStringToDate(presensi.masuk_2);

          if (
            istirahat_start.getHours() < time2.getHours() &&
            time2.getHours() < istirahat_end.getHours()
          ) {
            presensi.keluar_istirahat = presensi.masuk_2.split(" ")[1];
            if (!presensi.masuk_3) return presensi;
            presensi.masuk_istirahat = presensi.masuk_3.split(" ")[1];
            if (!presensi.masuk_4) return presensi;
            presensi.keluar_sore = presensi.masuk_4.split(" ")[1];
          } else {
            presensi.keluar_sore = presensi.masuk_2.split(" ")[1];
          }

          return presensi;
        });
        if (!data) setData([]);
        setData(data);
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
      });
  }

  useEffect(() => {
    if (!tanggalQuery) return
    setGeneralContext?.({ waktu_mulai: tanggalQuery })
    getAbsensiHarian();

  }, [tanggalQuery]);

  function syncAbsensi() {
    if (!user?.token || isLoading === true) return;
    setIsLoading(true);

    const axiosSyncAbsensi = new AxiosSyncAbsensi();

    axiosSyncAbsensi
      .post(user.token)
      .then(() => {
        toast.success(
          "Sinkronisasi sudah dijadwalkan, cek beberapa saat lagi!",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        setIsLoading(false);
        // setPopUpState({
        //   ...popUpState,
        //   popupType: PopUpEnum.SUCCESS,
        //   message: "SPK Berhasil Ditambahkan",
        //   timeout: 1000,
        //   isEnabled: true,
        //   onTimeout() {
        //     setPopUpState({ ...popUpState, isEnabled: false });
        //   },
        // });
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
      });
  }

  let timer: any;

  function onSearch(value: string) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      getAbsensiHarian(value);
    }, 500);
  }

  const [listJabatan, setListJabatan] = useState<TOption[]>([]);

  function getJabatan() {
    if (!user?.token) return;
    const axiosGetJabatan = new AxiosGetJabatan();

    axiosGetJabatan.get(user.token).then((response) => {
      const data = response.data.data;
      const options: TOption[] = [];
      options.push({
        value: '0',
        label: "No Filter",
        isDefault: true
      })
      data.forEach((value: any, index: any) => {
        options.push({
          value: value.nama,
          label: capitalizeEachWord(value.nama, "_"),
          isDefault: false,
        });
      });
      setListJabatan(options);
    });
  }

  useEffect(() => {
    setTanggalQuery(generalContext?.waktu_mulai ?? "")
    getJabatan();
  }, []);

  const [jabatan, setJabatan] = useState("");

  return (
    <>
      <ContentHeader
        title={"Absensi Harian"}
        additionalHeader={
          <>
            <div className={"flex gap-2"}>
              <InputField
                type="text"
                placeholder="Nama Pegawai..."
                onChange={(value) => {
                  setPegawaiName(value);
                  getAbsensiHarian(undefined, value);
                }}
                className=""
              />

              <InputField
                type="date"
                placeholder="tgl"
                onChange={(value) => setTanggalQuery(value)}
                value={tanggalQuery}
                className=""
              />

              <DropdownField
                id="jabatan"
                name="jabatan"
                className="!border !py-[17px] !px-[31px] !rounded !min-w-[200px]"
                options={listJabatan}
                onChange={(value) => {
                  getAbsensiHarian(value);
                  setJabatan(value);
                }}
                placeholder="Filter Jabatan"
                value={jabatan}
              />

              {/* <SearchField
                placeholder="Cari nama ..."
                onChange={(value) => onSearch(value)}
              /> */}

              <Button
                text={
                  isLoading ? (
                    <Loader color="indigo" size="sm" />
                  ) : (
                    "Sinkronisasi Absensi"
                  )
                }
                isDisabled={isLoading}
                onClick={() => {
                  syncAbsensi();
                }}
                size="small"
              />
            </div>
          </>
        }
      />

      <div className={clsx(HarianStyle.table_wrapper, "!pb-4 !mb-[40px]")}>
        <Table<Item> data={data} columns={columns} />
      </div>
    </>
  );
};

export default Harian;
