import ApiRequest from "./ApiRequest";

interface ICreateIzin {
    tipe: string,
    alasan: string,
    tanggal: string,
    tanggal_selesai: string,
    id_pegawai: string,
    file?: File,
}

class AxiosCreateIzin extends ApiRequest<ICreateIzin> {
    constructor() {
        super("/izin/create");
    }

    post(token: string, izin_data: ICreateIzin) {
        if (!izin_data.file) {
            return this.post_request({
                token: token,
                params: { ...izin_data }
            });
        }

        const formData = new FormData();

        formData.append("tipe", izin_data.tipe);
        formData.append("alasan", izin_data.alasan);
        formData.append("tanggal", izin_data.tanggal);
        formData.append("tanggal_selesai", izin_data.tanggal_selesai);
        formData.append("id_pegawai", izin_data.id_pegawai);
        formData.append("dokumen", izin_data.file, izin_data.file.name);

        return this.post_request({
            token: token,
            formData: formData
        });
    }
}

export default AxiosCreateIzin;
