import ApiRequest from "./ApiRequest";

interface IListTHRParams {
  waktu_mulai: string;
  waktu_selesai: string;
}

class AxiosListTHR extends ApiRequest<IListTHRParams> {
  constructor() {
    super("/tunjangan");
  }

  get(token: string, pegawai_data: IListTHRParams) {
    return this.get_request(token, {
      ...pegawai_data,
    });
  }
}

export default AxiosListTHR;
