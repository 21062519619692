import React from "react";
import TPendidikan from "../../../models/TPendidikan";
import ListForm from "../ListForm";

interface IPendidikanListFormProps {
  onPendidikanListChange: (value: TPendidikan[], deletedId: number[]) => void;
  defaultPendidikan: TPendidikan[];
}

const PendidikanListForm: React.FC<IPendidikanListFormProps> = (
  props: IPendidikanListFormProps
) => {
  return (
    <>
      <ListForm<TPendidikan>
        onListChange={(list, deletedId) => {
          props.onPendidikanListChange(list, deletedId);
        }}
        initialList={props.defaultPendidikan}
        defaultCreateItem={{
          nama: "",
          jenjang: "sd",
          waktu_mulai: "",
          waktu_selesai: "",
        }}
        listTitle="Pendidikan"
        addButtonText="Tambah Pendidikan"
        listField={[
          {
            label: "Nama",
            key: "nama",
            type: "text",
            placeholder: "Nama Pendidikan",
            onChange: () => {},
          },
          {
            label: "Jenjang",
            key: "jenjang",
            type: "option",
            placeholder: "Jenjang Pendidikan",
            onChange: () => {},
            options: [
              {
                label: "SD",
                value: "sd",
              },
              {
                label: "SMP",
                value: "smp",
              },
              {
                label: "SMA",
                value: "sma",
              },
              {
                label: "S1",
                value: "s1",
              },
              {
                label: "S2",
                value: "s2",
              },
              {
                label: "S3",
                value: "s3",
              },
            ],
          },
          {
            label: "Waktu Mulai",
            key: "waktu_mulai",
            type: "date",
            placeholder: "",
            onChange: () => {},
          },
          {
            label: "Waktu Selesai",
            key: "waktu_selesai",
            type: "date",
            placeholder: "",
            onChange: () => {},
          },
        ]}
      />
    </>
  );
};

export default PendidikanListForm;
