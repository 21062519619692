import ApiRequest from "./ApiRequest";

interface IUpdateKalender {
  id: number;
}

class AxiosUpdateKalender extends ApiRequest<IUpdateKalender> {
  constructor() {
    super("/kalender/delete");
  }

  post(token: string, absen_data: IUpdateKalender) {
    return this.post_request({
      token: token,
      params: { ...absen_data }
    });
  }
}

export default AxiosUpdateKalender;
