import { useEffect, useState } from "react";
import { v4 } from "uuid";
import TFormItem from "../../models/TFormItem";
import DropdownField from "../DropDownField";
import InputField from "../InputField";

interface IListItemProps<T> {
  index: number;
  data: T;
  inputFields: TFormItem[];
  onItemChange: (index: number, key: keyof T, value: string) => void;
  onDelete: (index: number) => void;
}

export default function ListItem<T>(props: IListItemProps<T>) {
  const [selectedValue, setSelectedValue] = useState<any>();

  useEffect(() => {
    let optionFields = props.inputFields.filter((field) => field.type === "option")
    let values: any = []
    optionFields.map((o) => {
      let key: keyof T = o.key as keyof T
      values[key as string] = props.data[key]
      return { key: props.data[key] }
    })
    setSelectedValue(values);
  }, [props.data, props.inputFields]);

  function optionInputField(item: TFormItem) {
    if (!item.options) return;

    return (
      <td>
        <DropdownField
          key={v4.toString()}
          name={item.label}
          id={item.key}
          options={item.options}
          onChange={(value, text) => {
            setSelectedValue(value);
            props.onItemChange(props.index, item.key as keyof T, value);
          }}
          value={selectedValue?.[item.key]}
          placeholder={item.placeholder}
          className="!border !py-[17px] !px-[31px] !rounded !min-w-[200px]"
        />
      </td>
    );
  }

  return (
    <tr key={props.index}>
      <>
        <td>{props.index + 1}</td>
        {props.inputFields.map((field) => {
          if (field.type === "option") return optionInputField(field);
          return (
            <td>
              <InputField
                defaultValue={props.data[field.key as keyof T]}
                type={field.type}
                value={props.data[field.key as keyof T] as string}
                placeholder={field.placeholder}
                onChange={(value) => {
                  props.onItemChange(props.index, field.key as keyof T, value);
                }}
                className="!border !py-[17px] !px-[31px] !rounded !min-w-[200px]"
              />
            </td>
          );
        })}
        <td
          className="ml-2 hover:cursor-pointer font-bold text-red-500"
          onClick={() => props.onDelete(props.index)}
        >
          X
        </td>
      </>
    </tr>
  );
}
