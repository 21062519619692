import React, { useState } from "react";
import TCatatanPolisi from "../../../models/TCatatanPolisi";
import ListForm from "../ListForm";

interface ICatatanPolisiListFormProps {
  onCatatanPolisiListChange: (value: TCatatanPolisi[]) => void;
  defaultPolisi: TCatatanPolisi[];
}

const CatatanPolisiListForm: React.FC<ICatatanPolisiListFormProps> = (
  props: ICatatanPolisiListFormProps
) => {
  return (
    <>
      <ListForm<TCatatanPolisi>
        onListChange={(list) => {
          props.onCatatanPolisiListChange(list);
        }}
        initialList={props.defaultPolisi}
        defaultCreateItem={{
          jenis_urusan: "",
          dimana: "",
          tahun: undefined,
          id: Date.now(),
        }}
        listTitle="Catatan Kepolisian"
        addButtonText="Tambah catatan kepolisian"
        listField={[
          {
            label: "Jenis Urusan",
            key: "jenis_urusan",
            type: "text",
            placeholder: "Jenis Urusan Kepolisian",
            onChange: () => {},
          },
          {
            label: "Dimana",
            key: "dimana",
            type: "text",
            placeholder: "Tempat Terjadi Urusan",
            onChange: () => {},
          },
          {
            label: "Tahun",
            key: "tahun",
            type: "number",
            placeholder: "Tahun Urusan",
            onChange: () => {},
          },
        ]}
      />
    </>
  );
};

export default CatatanPolisiListForm;
