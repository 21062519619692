
import ApiRequest from "./ApiRequest";

interface IParamType {
}

class AxiosGetDepartemen extends ApiRequest<IParamType> {
    constructor() {
        super("/departemen");
    }

    get(token: string) {
        return this.get_request(token);
    }
}

export default AxiosGetDepartemen;
