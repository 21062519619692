import clsx from "clsx";
import React, { useState } from "react";
import InputStyle from "./InputFile.module.css";

interface InputFileProps {
  title?: string;
  className?: string;
  helperText?: string;
  isRequired?: boolean;
  onFileSelect: (file: File | null) => void;
}

const InputFile: React.FC<InputFileProps> = ({
  title,
  className,
  helperText,
  isRequired,
  onFileSelect,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string>("Upload file");

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);
    const selectedFile = e.target.files ? e.target.files[0] : null;

    if (selectedFile) {
      const validTypes = [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];

      if (validTypes.includes(selectedFile.type)) {
        setFile(selectedFile);
        onFileSelect(selectedFile);
        let displayFileName = selectedFile.name;
        if (displayFileName.length > 27) {
          displayFileName = `${displayFileName.substr(0, 24)}...`;
        }
        setFileName(displayFileName);
      } else {
        setError(
          "Invalid file type. Please upload a JPG, PNG, PDF, or Excel file."
        );
        setFile(null);
        onFileSelect(null);
        setFileName("Upload file");
      }
    } else {
      setFile(null);
      onFileSelect(null);
      setFileName("Upload file");
    }
  };

  return (
    <div className={clsx("flex flex-col gap-1", className)}>
      {title ? (
        <div className="text-left">
          <span>{title}</span>
          {isRequired ? <span className="text-red-500">*</span> : null}
        </div>
      ) : null}

      <label htmlFor="file-input" className={InputStyle.fileInputLabel}>
        {fileName}
      </label>
      <input
        id="file-input"
        type="file"
        onChange={handleFileChange}
        className={clsx(InputStyle.input, InputStyle.fileInput, "mb-2")}
        style={{ display: "none" }}
      />
      {helperText ? (
        <p className="text-sm text-gray-600 text-left">{helperText}</p>
      ) : null}
      {error && <p className="text-sm text-red-500">{error}</p>}
    </div>
  );
};

export default InputFile;
