import { createContext, Dispatch, SetStateAction } from 'react'

export type Pegawai = {
    id?: string,
    name?: string,
}

interface PegawaiContextType {
    pegawai?: Pegawai,
    setPegawai?: Dispatch<SetStateAction<Pegawai | undefined>>
}
const pegawaiContext = createContext<PegawaiContextType>({
    pegawai: {
        id: undefined,
        name: undefined,
    },
    setPegawai: undefined
})

export default pegawaiContext;
