import { createColumnHelper } from "@tanstack/react-table";

export type Item = {
  id: number;
  jenis_kontrak: string;
  kontrak_is_active: string;
  expired: string;
  action: () => void;
};
type KontrakResponse = {
  id: number;
  jenis_kontrak: string;
  kontrak_is_active: number;
  expired: string;
};

const columnHelper = createColumnHelper<Item>();
export const columns = [
  columnHelper.accessor("id", {
    header: () => "ID",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor((row) => row.jenis_kontrak, {
    id: "Jenis Kontrak",
    cell: (info) => <>{info.getValue()}</>,
    header: () => <span>Jenis Kontrak</span>,
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor("kontrak_is_active", {
    header: () => "Status",
    cell: (info) => info.renderValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("expired", {
    header: () => "Expired",
    cell: (info) => info.renderValue(),
    footer: (info) => info.column.id,
  }),
];

export function constructItemFromResponse(data_response: KontrakResponse[]) {
  const newItems: Item[] = [];

  data_response.forEach((data: KontrakResponse) => {
    const item: Item = {
      id: data.id,
      jenis_kontrak: data.jenis_kontrak,
      kontrak_is_active: data.kontrak_is_active == 1 ? "Active" : "Non-Active",
      expired: data.expired,
      action: () => {},
    };
    newItems.push(item);
  });
  return newItems;
}
