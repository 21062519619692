import { createColumnHelper } from "@tanstack/react-table";
import Button from "../../../components/Button";
import { numberToMoney } from "../../../helpers/StringHelper";

export type Item = {
  id: number;
  nama_pegawai: string;
  gaji_pokok: number;
  tarif_harian: number;
  premi_hadir: number;
  tunjangan_jabatan: number;
  editAction: (item: Item) => void;
};

const columnHelper = createColumnHelper<Item>();
export const columns = [
  columnHelper.accessor("id", {
    header: () => "ID",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("nama_pegawai", {
    header: () => "Nama Pegawai",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("gaji_pokok", {
    header: () => "Gaji Pokok",
    cell: (info) => numberToMoney(info.getValue()),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("tarif_harian", {
    header: () => "Tarif Harian",
    cell: (info) => numberToMoney(info.getValue()),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("premi_hadir", {
    header: () => "Premi Hadir",
    cell: (info) => numberToMoney(info.getValue()),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("tunjangan_jabatan", {
    header: () => "Tunjangan Jabatan",
    cell: (info) => numberToMoney(info.getValue()),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("editAction", {
    header: "Action",
    cell: (inf) => (
      <Button
        size="small"
        text="Edit"
        onClick={() => {
          inf.row.original.editAction(inf.row.original);
        }}
      />
    ),
    footer: (info) => info.column.id,
  }),
];
