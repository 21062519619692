import React from "react";
import ContentHeader from "../../../components/ContentHeader";
import DetailPegawaiStyle from "./DetailPegawai.module.css";
import DataPribadi from "./DataPribadi";
import ListDokumen from "./ListDokumen";
import InfoKontrak from "./InfoKontrak";
import clsx from "clsx";
import Button from "../../../components/Button";
import { useNavigate } from "react-router-dom";

interface IDetailPegawaiProps { }

const DetailPegawai: React.FC<IDetailPegawaiProps> = (
    props: IDetailPegawaiProps
) => {
    const navigate = useNavigate();
    return (
        <div>
            <ContentHeader title="Detail Pegawai" enableBack={true}
                additionalHeader={<>
                    <Button
                        onClick={() => navigate("/dashboard/hrd/riwayat_update_pegawai") }
                        text="Riwayat Edit"
                        size="small"
                    />
                </>}
            />
            <div
                className={clsx(
                    DetailPegawaiStyle.container,
                    "!mx-[40px] !my-[20px] !px-0"
                )}
            >
                <div className={DetailPegawaiStyle.data_pribadi_wrapper}>
                    <DataPribadi />
                </div>
                <div
                    className={clsx(
                        DetailPegawaiStyle.document_wrapper,
                        "!py-6 !px-12 !my-4"
                    )}
                >
                    <ListDokumen />
                </div>
                <div
                    className={clsx(
                        DetailPegawaiStyle.status_wrapper,
                        "!py-6 !px-12 !m-0 !mb-12"
                    )}
                >
                    <InfoKontrak />
                </div>
            </div>
        </div>
    );
};

export default DetailPegawai;
