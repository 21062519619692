import ApiRequest from "./ApiRequest";

interface IUploadFileParam {
    file: FormData,
    filename: string,
    id_pegawai: number,
    id_jenis_dokumen: number,
}

class AxiosUploadDokumen extends ApiRequest<IUploadFileParam> {
    constructor() {
        super("/upload_dokumen");
    }

    post(file: File, id_pegawai: number, id_tipe_dokumen: number, token: string) {
        const formData = new FormData();
        formData.append("file", file, file.name);
        formData.append("filename", file.name);
        formData.append("id_pegawai", id_pegawai.toString());
        formData.append("id_jenis_dokumen", id_tipe_dokumen.toString());
        
        return this.post_request({
            token: token,
            formData: formData
        });
    }
}

export default AxiosUploadDokumen;
