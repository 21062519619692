import React, { useContext, useEffect, useState } from "react";
import AxiosCreatePegawai from "../../api/AxiosCreatePegawai";
import Button from "../../components/Button";
import DropdownField from "../../components/DropDownField";
import InputField from "../../components/InputField";
import userAuth from "../../context/UserAuth";
import selectedPegawai from "../../context/SelectedPegawai";
import CreatePegawaiStyle from "./CreatePegawaiPopup.module.css";
import AxiosListCreatePegawaiOptions from "../../api/AxiosGetCreatePegawaiOptions";
import axios from "axios";

interface ICreatePegawaiProps {
    onSuccessCreate: (jenis_perjanjian: string) => void
}

type Option = {
    value: string,
    label: string
}

type formItem = {
    label: string,
    type: string,
    placeholder: string,
    onChange: (value: string) => void,
    options?: Option[]
}

const CreatePegawaiPopup: React.FC<ICreatePegawaiProps> = (props: ICreatePegawaiProps) => {
    return <> </>
}

export default CreatePegawaiPopup;
