import ApiRequest from "./ApiRequest";

interface IParamType {
    id_pegawai: string,
    tanggal_mulai: string,
    tanggal_selesai: string,
}

class AxiosListLogPegawai extends ApiRequest<IParamType> {
    constructor() {
        super("/list_log_pegawai");
    }

    get(
        token: string,
        param: IParamType,
    ) {
        return this.get_request(token,
            { ...param }
        );
    }
}

export default AxiosListLogPegawai;
