import React from "react";
import { ButtonTypeEnum } from "../enums/ButtonTypeEnum";
import ButtonStyle from "./Button.module.css";
import clsx from "clsx";

interface IButtonProps {
  onClick: () => any;
  text: React.ReactNode;
  size?: string;
  color?: string;
  buttonType?: ButtonTypeEnum;
  className?: string;
  isDisabled?: boolean;
}

const Button: React.FC<IButtonProps> = ({
  onClick,
  text,
  size = "large",
  color = "info",
  buttonType = ButtonTypeEnum.SOLIDFILL,
  className = "",
  isDisabled = false,
}: IButtonProps) => {
  let classNames = clsx(
    ButtonStyle.default,
    ButtonStyle[ButtonTypeEnum[buttonType]],
    ButtonStyle[size],
    className,
    { [ButtonStyle.disabled]: isDisabled }
  );

  if (buttonType === ButtonTypeEnum.SOLIDFILL) {
    classNames = clsx(classNames, ButtonStyle["bg_" + color]);
  } else if (buttonType === ButtonTypeEnum.CLICKABLE_TEXT) {
    classNames = clsx(classNames, ButtonStyle["text_" + color]);
  }

  return (
    <button
      onClick={onClick}
      className={clsx(
        classNames,
        "min-w-[200px]",
        isDisabled ? " opacity-90 !cursor-not-allowed" : ""
      )}
      disabled={isDisabled}
    >
      {text}
    </button>
  );
};

export default Button;
