import ApiRequest from "./ApiRequest";

interface IParamType {}

class AxiosExportRekapPresensi extends ApiRequest<IParamType> {
  constructor() {
    super(`/v2/attendance/get-data`);
  }

  fetch(token: string, param: IParamType) {
    return this.get_request(token, { ...param }, {responseType:'arraybuffer'})
  }
}

export default AxiosExportRekapPresensi;
