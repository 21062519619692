import ApiRequest from "./ApiRequest";

interface ICreateKalenderParams {
    tanggal: string,
    nama: string,
    tipe: string
}

class AxiosCreateKalender extends ApiRequest<ICreateKalenderParams> {
    constructor() {
        super("/kalender/create");
    }

    post(token: string, pegawai_data: ICreateKalenderParams) {
        
        return this.post_request({
            token: token,
            params: { ...pegawai_data }
        });
    }
}

export default AxiosCreateKalender;
