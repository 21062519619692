import ApiRequest from "./ApiRequest";
interface IParamType {}

class AxiosGetLembarKerjaBorongan extends ApiRequest<IParamType> {
  constructor(id: number) {
    super(`/produksi/list_borongan_spk?id_spk=${id}`);
  }

  get(token: string, param: IParamType) {
    return this.get_request(token, {...param});
  }
}

export default AxiosGetLembarKerjaBorongan;
