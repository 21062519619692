/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate, useSearchParams } from "react-router-dom";
import AxiosFindPegawai from "../../../api/AxiosFindPegawai";
import AxiosListUser from "../../../api/AxiosListUser";
import Button from "../../../components/Button";
import ContentHeader from "../../../components/ContentHeader";
import DropdownField from "../../../components/DropDownField";
import InputField from "../../../components/InputField";
import userAuth from "../../../context/UserAuth";
import selectedPegawai from "../../../context/SelectedPegawai";
import TambahKontrakStyle from "./TambahKontrak.module.css";
import AxiosDownloadTemplate from "../../../api/AxiosDownloadTemplate";
import AxiosCreateKontrak from "../../../api/AxiosCreateKontrak";
import TOption from "../../../models/TOption";
import AxiosListCreatePegawaiOptions from "../../../api/AxiosGetCreatePegawaiOptions";
import { capitalizeFirst } from "../../../helpers/StringHelper";
import clsx from "clsx";
import { toast } from "react-toastify";
import { Loader } from "@mantine/core";

interface ITambahKontrak { }

type UserResponse = {
  id: number;
  nama: string;
};

const TambahKontrak: React.FC<ITambahKontrak> = (props: ITambahKontrak) => {
  const { user } = useContext(userAuth);
  const { pegawai } = useContext(selectedPegawai);
  const [searchParam] = useSearchParams();
  const [namaPegawai, setNamaPegawai] = useState(".....");
  const [document, setDocument] = useState<File>();
  const [idPemberiKuasa, setIdPemberiKuasa] = useState<number>(0);
  const [selectedPerusahaan, setSelectedPerusahaan] = useState<number>(0);
  const [userOptions, setUserOptions] = useState<TOption[]>([]);
  const [perusahaanOptions, setPerusahaanOptions] = useState<TOption[]>([]);
  const [tanggalMulai, setTanggalMulai] = useState<Date>();
  const [tanggalSelesai, setTanggalSelesai] = useState<Date>();
  const [nomorSurat, setNomorSurat] = useState("");
  const [tanggalSelesaiString, setTanggalSelesaiString] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    findPegawai();
    listUser();
    listOption();
  }, []);

  function findPegawai() {
    const id = pegawai?.id;
    if (!user.token || !id) return;
    const axiosFindPegawai = new AxiosFindPegawai();
    axiosFindPegawai
      .get(user.token, id)
      .then((response) => setNamaPegawai(response.data.data.pegawai.nama));
  }

  function listOption() {
    if (!user.token) return;
    const axiosOptions = new AxiosListCreatePegawaiOptions();
    axiosOptions.get(user.token).then((response) => {
      const data = response.data.data;
      setPerusahaanOptions(
        data.perusahaan_options.map((option: any) => {
          return { label: capitalizeFirst(option.nama), value: option.id };
        })
      );
    });
  }

  function listUser() {
    const axiosListUser = new AxiosListUser();
    if (!user.token) return;
    axiosListUser.get(user.token).then((response) => {
      const data = response.data.data;
      const newUsers: { label: string; value: string }[] = [];
      data.forEach((user: UserResponse) => {
        newUsers.push({ label: user.nama, value: user.id.toString() });
      });

      setIdPemberiKuasa(data[0].id);
      setUserOptions(newUsers);
    });
  }

  function createKontrak() {
    if (!user.token || !pegawai?.id || isLoading === true) return;
    if (!tanggalMulai) {
      toast.error("Mohon masukkan Tanggal Mulai!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    if (!tanggalSelesai) {
      toast.error("Mohon masukkan Tanggal Selesai!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    if (!document) {
      toast.error("Mohon masukkan Dokumen Template!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }
    if (!nomorSurat) {
      toast.error("Mohon masukkan Nomor Surat", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    }

    setIsLoading(true);

    const axiosCreateKontrak = new AxiosCreateKontrak();
    const jenis_perjanjian = searchParam.get("jenis_perjanjian");
    if (!jenis_perjanjian || !tanggalSelesai) return;
    axiosCreateKontrak
      .post(user.token, document, {
        id_pegawai: parseInt(pegawai.id),
        jenis_kontrak: jenis_perjanjian,
        expired: tanggalSelesaiString,
        id_perusahaan: selectedPerusahaan,
        nomor_surat: nomorSurat,
      })
      .then(() => {
        toast.success("Kontrak pegawai ditambahkan!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        navigate("/dashboard/detail/pegawai");
        setIsLoading(false);
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
      });
  }

  function downloadTemplate() {
    const jenis_kontrak = searchParam.get("jenis_perjanjian");
    if (
      !jenis_kontrak ||
      !user.token ||
      !pegawai?.id ||
      !tanggalMulai ||
      !tanggalSelesai
    ) {
      return;
    }

    const axiosDownloadTemplate = new AxiosDownloadTemplate(
      jenis_kontrak?.toLowerCase(),
      parseInt(pegawai.id),
      tanggalMulai,
      tanggalSelesai,
      idPemberiKuasa,
      nomorSurat,
    );
    axiosDownloadTemplate.get(user.token).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = window.document.createElement("a");
      link.href = url;
      link.setAttribute("download", jenis_kontrak?.toLowerCase() + ".pdf"); //or any other extension
      window.document.body.appendChild(link);
      link.click();
    });
  }

  const today = new Date();

  return (
    <>
      <ContentHeader
        title={
          "Perjanjian " +
          searchParam.get("jenis_perjanjian") +
          " " +
          namaPegawai
        }
        enableBack={true}
      />

      <div
        className={clsx(
          TambahKontrakStyle.form_template_wrapper,
          "!mx-[40px] !py-6 !px-12"
        )}
      >
        <span className={TambahKontrakStyle.label}>Tanggal Dibuat</span>
        <InputField
          type="date"
          onChange={(value) => {
            setTanggalSelesai(new Date(value));
            setTanggalSelesaiString(value);
          }}
          placeholder="Created Date"
          defaultValue={`${today.getFullYear()}-${(
            "0" +
            (today.getMonth() + 1)
          ).slice(-2)}-${("0" + today.getDate()).slice(-2)}`}
          isDisabled={true}
          value={`${today.getFullYear()}-${("0" + (today.getMonth() + 1)).slice(
            -2
          )}-${("0" + today.getDate()).slice(-2)}`}
        />

        <span className={TambahKontrakStyle.label}>Tanggal Mulai</span>
        <InputField
          type="date"
          onChange={(value) => {
            setTanggalMulai(new Date(value));
          }}
          placeholder="start_date"
        />
        <span className={TambahKontrakStyle.label}>Tanggal Berakhir</span>
        <InputField
          type="date"
          onChange={(value) => {
            setTanggalSelesai(new Date(value));
            setTanggalSelesaiString(value);
          }}
          placeholder="end_date"
        />

        <span className={TambahKontrakStyle.label}>Pemberi Kuasa</span>
        <DropdownField
          name="userList"
          id="DT"
          options={userOptions}
          onChange={(value, text) => {
            setIdPemberiKuasa(parseInt(value));
          }}
          className="min-h-[55px] min-w-[200px]"
          value={idPemberiKuasa.toString()}
        />
        <span className={TambahKontrakStyle.label}>Perusahaan</span>
        <DropdownField
          name="userList"
          id="DT"
          options={perusahaanOptions}
          onChange={(value, text) => {
            setSelectedPerusahaan(parseInt(value));
          }}
          className="min-h-[55px] min-w-[200px]"
          value={selectedPerusahaan.toString()}
        />

        <span className={TambahKontrakStyle.label}>Nomor Surat</span>
        <InputField
          type="text"
          onChange={(value) => {
            setNomorSurat(value);
          }}
        />
      </div>

      <div
        className={clsx(
          TambahKontrakStyle.document_wrapper,
          "!mx-[40px] !py-6 !px-12"
        )}
      >
        <Button
          text="Download Template"
          size="small"
          onClick={() => downloadTemplate()}
          className="h-[55px]"
        />
        <FileUploader handleChange={(e: any) => setDocument(e)} />
      </div>

      <div className="mx-[40px]">
        <Button
          text={
            isLoading ? (
              <>
                <Loader color="indigo" size="sm" />
              </>
            ) : (
              "Tambahkan Kontrak"
            )
          }
          size="small"
          onClick={() => {
            createKontrak();
          }}
          className="h-[55px]"
          isDisabled={isLoading}
        />
      </div>
    </>
  );
};

export default TambahKontrak;
