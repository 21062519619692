
import ApiRequest from "./ApiRequest";
interface IParamType {
}

class AxiosGetSkemaGaji extends ApiRequest<IParamType> {
    constructor() {
        super("/skema_gaji");
    }

    get(token: string) {
        return this.get_request(token);
    }
}

export default AxiosGetSkemaGaji;
