import ApiRequest from "./ApiRequest";

interface ICreateTHRParams {
  id_pegawai: string;
  nominal: number;
  tanggal: string;
}

class AxiosCreateTHR extends ApiRequest<ICreateTHRParams> {
  constructor() {
    super("/tunjangan/create");
  }

  post(token: string, pegawai_data: ICreateTHRParams) {
    return this.post_request({
      token: token,
      params: { ...pegawai_data },
    });
  }
}

export default AxiosCreateTHR;
