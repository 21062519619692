import ApiRequest from "./ApiRequest";

interface IDeleteIzin {
    id: number,
}

class AxiosDeleteIzin extends ApiRequest<IDeleteIzin> {
    constructor() {
        super("/izin/delete");
    }

    post(token: string, izin_data: IDeleteIzin) {
        return this.post_request({
            token: token,
            params: { ...izin_data }
        });
    }
}

export default AxiosDeleteIzin;
