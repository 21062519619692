import React, { useState } from "react";
import TSakit from "../../../models/TSakit";
import ListForm from "../ListForm";

interface ISakitListFormProps {
  onSakitListChange: (value: TSakit[]) => void;
  defaultSakit: TSakit[];
}

const SakitListForm: React.FC<ISakitListFormProps> = (
  props: ISakitListFormProps
) => {
  return (
    <>
      <ListForm<TSakit>
        onListChange={(list) => {
          props.onSakitListChange(list);
        }}
        initialList={props.defaultSakit}
        defaultCreateItem={{
          jenis_sakit: "",
          lama: 0,
          tahun: "",
          id: Date.now(),
        }}
        listTitle="Jenis-Jenis sakit"
        addButtonText="Tambah Riwayat Sakit"
        listField={[
          {
            label: "Jenis Sakit",
            key: "jenis_sakit",
            type: "text",
            placeholder: "Jenis Sakit",
            onChange: () => {},
          },
          {
            label: "Lama (Bulan)",
            key: "lama",
            type: "number",
            placeholder: "Lama Penyakit Diderita",
            onChange: () => {},
          },
          {
            label: "Tahun",
            key: "tahun",
            type: "number",
            placeholder: "Tahun Mulai Menderita",
            onChange: () => {},
          },
        ]}
      />
    </>
  );
};

export default SakitListForm;
