import ApiRequest from "./ApiRequest";
interface IParamType {}

class AxiosGetListPekerjaanSPK extends ApiRequest<IParamType> {
  constructor(id: number) {
    super(`/produksi/list_pekerjaan_spk?id_spk=${id}`);
  }

  get(token: string) {
    return this.get_request(token);
  }
}

export default AxiosGetListPekerjaanSPK;
