/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import Button from "../../../components/Button";
import ContentHeader from "../../../components/ContentHeader";
import Table from "../../../components/Table";
import TimeoutPopup, {
  defaultPopUpProps,
  ITimeoutPopupProps,
} from "../../../components/TimeoutPopUp";
import userAuth from "../../../context/UserAuth";
import { columns, Item } from "./ListBoronganTableDefinitions";
import ListBoronganStyle from "./ListBorongan.module.css";
import SearchField from "../../../components/SearchField";
import clsx from "clsx";
import { useLocation, useNavigate } from "react-router-dom";
import AxiosGetLembarKerjaBorongan from "../../../api/AxiosGetLembarKerjaBorongan";

interface IListBoronganProps {
  noSPK: string;
}
const ListLembarKerjaBorongan: React.FC<IListBoronganProps> = ({
  noSPK,
}: IListBoronganProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const stateParam = location.state?.param;
  const { user } = useContext(userAuth);
  const [listBorongan, setListBorongan] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [popUpState, setPopUpState] =
  useState<ITimeoutPopupProps>(defaultPopUpProps);

  function getLembarKerjaBorongan(id: number) {
    if (!user?.token) return;
    const axiosGetJabatan = new AxiosGetLembarKerjaBorongan(id);

    axiosGetJabatan.get(user.token, {
      search: searchQuery
    }).then((response) => {
      if (response.data.data) {
        const data = response.data.data.map((item: any) => ({
          ...item,
          action: () => {
            navigate(
              "/dashboard/produksi/spk/listLembarKerjaBorongan/detailLembarKerjaBorongan",
              { state: { param: item, noSPK: stateParam.nomor_spk } }
            );
          },
        }));
        setListBorongan(data);
      } else {
        setListBorongan([]);
      }
    });
  }

  useEffect(() => {
    
    if (stateParam) getLembarKerjaBorongan(stateParam.id);
  }, [searchQuery]);

  let timer: any;
  

  function onSearch(value: string) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearchQuery(value);
    }, 500);
  }

  function trimString(string: string, maxLength: number) {
    if (string.length > maxLength) {
      return string.substring(0, maxLength) + "...";
    }
    return string;
  }

  return (
    <>
      <div className={ListBoronganStyle.header_wrapper}>
        <ContentHeader
          title={"List Lembar Kerja Borongan"}
          enableBack={true}
          subTitle={`No. SPK: ${
            stateParam ? trimString(stateParam.nomor_spk, 30) : noSPK
          }`}
          additionalHeader={
            <>
              <div className={ListBoronganStyle.header_container}>
                <SearchField
                  placeholder="Cari nama pekerjaan ..."
                  onChange={(value) => onSearch(value)}
                />
                <Button
                  text="Tambah Borongan"
                  onClick={() =>
                    navigate(
                      "/dashboard/produksi/spk/listLembarKerjaBorongan/createLembarKerjaBorongan",
                      {
                        state: { param: stateParam },
                      }
                    )
                  }
                  size="small"
                />

              </div>

            </>
          }
        />
      </div>
      <TimeoutPopup {...popUpState} />
      
      

      <div className={clsx(ListBoronganStyle.table_wrapper, "pb-4")}>
        <Table<Item> data={listBorongan} columns={columns} />
      </div>
    </>
  );
};
export default ListLembarKerjaBorongan;
