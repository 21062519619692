import ApiRequest from "./ApiRequest";

interface IParamType {
    id: number,
}

class AxiosDeleteEditableLog extends ApiRequest<IParamType> {
    constructor() {
        super("/presensi/delete_editable_log");
    }

    post(
        token: string,
        param: IParamType,
    ) {
        return this.post_request({
            token: token,
            params: { ...param }
        });
    }
}

export default AxiosDeleteEditableLog;
