import ApiRequest from "./ApiRequest";

interface ICreateInsentiveParams {
  id_pegawai: string;
  tipe: string;
  keterangan: string;
  tanggal: string;
  jumlah: string;
}

class AxiosCreateInsentive extends ApiRequest<ICreateInsentiveParams> {
  constructor() {
    super("/insentive/create");
  }

  post(token: string, pegawai_data: ICreateInsentiveParams) {
    return this.post_request({
      token: token,
      params: { ...pegawai_data },
    });
  }
}

export default AxiosCreateInsentive;
