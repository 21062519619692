import ApiRequest from "./ApiRequest";

interface IDeleteInsentiveParams {
  id: string;
}

class AxiosDeleteInsentive extends ApiRequest<IDeleteInsentiveParams> {
  constructor() {
    super("/insentive/delete");
  }

  post(token: string, pegawai_data: IDeleteInsentiveParams) {
    return this.post_request({
      token: token,
      params: { ...pegawai_data },
    });
  }
}

export default AxiosDeleteInsentive;
