import ApiRequest from "./ApiRequest";

interface ICreateJatahCuti {
    id: string;
    tanggal_mulai: string;
    tanggal_selesai: string;
    jumlah: number;
}

class AxiosUpdateJatahCuti extends ApiRequest<ICreateJatahCuti> {
    constructor() {
        super("/jatah_cuti/update");
    }

    post(token: string, absen_data: ICreateJatahCuti) {
        return this.post_request({
            token: token,
            params: { ...absen_data }
        });
    }
}

export default AxiosUpdateJatahCuti;
