import ApiRequest from "./ApiRequest";

interface IDeleteGaji {
  id_gaji: string;
}

class AxiosDeleteGaji extends ApiRequest<IDeleteGaji> {
  constructor() {
    super("/payroll/delete_gaji_pegawai");
  }

  post(token: string, absen_data: IDeleteGaji) {
    return this.post_request({
      token: token,
      params: { ...absen_data }
    });
  }
}

export default AxiosDeleteGaji;
