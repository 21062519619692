import ApiRequest from "./ApiRequest";

interface IUpdateKalender {
  id: number;
  tanggal: string,
  nama: string,
  tipe: string
}

class AxiosUpdateKalender extends ApiRequest<IUpdateKalender> {
  constructor() {
    super("/kalender/edit");
  }

  post(token: string, absen_data: IUpdateKalender) {
    return this.post_request({
      token: token,
      params: { ...absen_data }
    });
  }
}

export default AxiosUpdateKalender;
