import ApiRequest from "./ApiRequest";

interface IParamType {
}

class AxiosListPerusahaan extends ApiRequest<IParamType> {
  constructor() {
    super("/list_perusahaan");
  }

  get(token: string) {
    return this.get_request(token);
  }
}

export default AxiosListPerusahaan;
