import React, { useState } from "react";
import TBahasaItem from "../../../models/TBahasaItem";
import Button from "../../Button";
import BahasaListItem from "./BahasaListItem";
import BahasaListStyle from "./BahasaListStyle.module.css";
import { ButtonTypeEnum } from "../../../enums/ButtonTypeEnum";
import clsx from "clsx";
interface IBahasaListForm {
  onBahasaChange: (value: TBahasaItem[]) => void;
  defaultBahasa?: TBahasaItem[];
}

const BahasaListForm: React.FC<IBahasaListForm> = (props: IBahasaListForm) => {
  const [bahasaList, setBahasaList] = useState<TBahasaItem[]>(
    props.defaultBahasa ?? []
  );
  return (
    <>
      <div
        className={clsx(
          BahasaListStyle.bahasa_list_wrapper,
          "!bg-transparent w-[900px] p-[20px]"
        )}
      >
        <div className="flex justify-start">
          <label className={clsx(BahasaListStyle.title)}>
            Kemampuan Bahasa
          </label>
        </div>
        <div className={BahasaListStyle.bahasa_list}>
          {bahasaList?.map((bahasaElement, index) => (
            <BahasaListItem
              index={index}
              key={bahasaElement.id}
              onBahasaChange={(bahasa) => {
                bahasaList[index] = bahasa;
                setBahasaList(bahasaList);
                props.onBahasaChange(bahasaList);
              }}
              currentBahasa={bahasaElement}
              onBahasaDelete={(index) => {
                const newBahasaList = [...bahasaList];
                newBahasaList.splice(index, 1);
                setBahasaList(newBahasaList);
                props.onBahasaChange(newBahasaList);
              }}
            />
          ))}
        </div>
        <Button
          text="Tambah Bahasa"
          onClick={() => {
            if (!bahasaList) return;
            const newBahasaList = [
              ...bahasaList,
              {
                bahasa: "",
                fasih: "",
                id: Date.now(),
              },
            ];
            setBahasaList(newBahasaList);
            props.onBahasaChange(newBahasaList);
          }}
          size="small"
          buttonType={ButtonTypeEnum.CLICKABLE_TEXT}
        />
      </div>
    </>
  );
};

export default BahasaListForm;
