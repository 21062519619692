/* eslint-disable react-hooks/exhaustive-deps */
import React, { ReactNode, useContext, useEffect, useState } from "react";
import AxiosListCreatePegawaiOptions from "../../api/AxiosGetCreatePegawaiOptions";
import DropdownField from "../../components/DropDownField";
import InputField from "../../components/InputField";
import userAuth from "../../context/UserAuth";
import { capitalizeEachWord, capitalizeFirst } from "../../helpers/StringHelper";
import TFormItem from "../../models/TFormItem";
import { TFormPegawai } from "../../models/TFormPegawai";
import TOption from "../../models/TOption";
import getFormItems from "./FormItemList";
import ListFormGroup from "./ListFormGroup";
import PegawaiFormStyle from "./PegawaiForm.module.css";
import clsx from "clsx";

interface IPegawawiProps {
  onPegawaiChange: (dataPegawai: TFormPegawai) => void;
  onPerjanjianChange?: (value: string) => void;
  onDeleted?: (deletedIds: {
    deleted_keluarga_id: number[];
    deleted_pendidikan_id: number[];
    deleted_kegiatan_organisasi_id: number[];
    deleted_pengalaman_kerja_id: number[];
  }) => void;
  defaultDatapegawai?: TFormPegawai;
  className?: string;
}

const PegawaiForm: React.FC<IPegawawiProps> = (props: IPegawawiProps) => {
  const { user } = useContext(userAuth);
  const [isUpdateMode, setIsUpdateMode] = useState<boolean>(false);
  //data pegawai
  const [dataPegawai, setDataPegawai] = useState<TFormPegawai>(
    props.defaultDatapegawai ?? {
      id_finpro: 0,
      nama: "",
      nip: "",
      id_perusahaan: "",
      alias: "",
      telp: "",
      tgl_lahir: "",
      tempat_lahir: "",
      gender: "0",
      alamat_ktp: "",
      alamat_domisili: "",
      email: "",
      nik: "",
      id_jabatan: 1,
      id_departemen: 1,
      nama_bank: "",
      nama_rek: "",
      no_rek: "",
      no_npwp: "",
      agama: "",
      kebangsaan: "",
      no_bpjs_kesehatan: "",
      no_bpjs_tenaga_kerja: "",
      tgl_mulai_kerja: "",
      tgl_resign: "",

      //detil pegawai
      sim: [],
      anak_ke: 1,
      status_nikah: "bujangan",
      tahun_nikah: "",
      bahasa_dikuasai: [],
      pengalaman_memimpin: "",
      minat: "",
      minat_lingkungan: "",
      hobby: "",
      berat_badan: 0,
      tinggi_badan: 0,
      kacamata_kiri: "",
      kacamata_kanan: "",
      detil_sakit: [],
      detil_kecelakaan: [],
      detil_polisi: [],
      detil_psikologi: [],
      detil_militer: [],
      detil_bacaan: [],
      keluarga: [],
      pendidikan: [],
      kegiatan_organisasi: [],
      pengalaman_kerja: [],
    }
  );

  useEffect(() => {
    setIsUpdateMode(!!props.defaultDatapegawai);
  }, [props.defaultDatapegawai]);

  const [departemenOptions, setDepartemenOptions] = useState<TOption[]>([]);
  const [jabatanOptions, setJabatanOptions] = useState<TOption[]>([]);
  const [perusahaanOptions, setPerusahaanOptions] = useState<TOption[]>([]);

  function updateStateDataPegawai(key: string, value: any) {
    const newData = { ...dataPegawai, [key]: value };
    
    setDataPegawai(newData);
    props.onPegawaiChange(newData);
  }

  const formItems = getFormItems(
    updateStateDataPegawai,
    jabatanOptions,
    departemenOptions,
    perusahaanOptions
  );

  if (props.onPerjanjianChange)
    formItems.splice(2, 0, {
      label: "Jenis Perjanjian",
      key: "jenis_perjanjian",
      type: "option",
      placeholder: "Jenis Perjanjian",
      onChange(value: any) {
        if (!props.onPerjanjianChange) return;
        props.onPerjanjianChange(value);
      },
      options: [
        {
          label: "PKWT",
          value: "PKWT",
        },
        {
          label: "PKWTT",
          value: "PKWTT",
        },
        {
          label: "PKKB",
          value: "PKKB",
        },
      ],
    });

  function standardInputField(item: TFormItem) {
    // if (isUpdateMode && item.key === "id_finpro") {
    //   return (
    //     <InputField
    //       title={item.label}
    //       isRequired={item.required}
    //       isDateLimited={item.label === "Tanggal Lahir"}
    //       type={item.type}
    //       placeholder={item.placeholder}
    //       isDisabled={true}
    //       onChange={(value) => item.onChange(value)}
    //       defaultValue={
    //         props.defaultDatapegawai
    //           ? props.defaultDatapegawai[item.key as keyof TFormPegawai]
    //           : undefined
    //       }
    //       value={
    //         props.defaultDatapegawai
    //           ? props.defaultDatapegawai[item.key as keyof TFormPegawai]
    //           : undefined
    //       }
    //       className="!border !py-[17px] !px-[31px] !rounded !min-w-[200px]"
    //     />
    //   );
    // } else {
      return (
        <>
          <InputField
            title={item.label}
            isRequired={item.required}
            isDateLimited={item.label === "Tanggal Lahir"}
            type={item.type}
            placeholder={item.placeholder}
            onChange={(value) => item.onChange(value)}
            defaultValue={
              props.defaultDatapegawai
                ? props.defaultDatapegawai[item.key as keyof TFormPegawai]
                : undefined
            }
            value={
              props.defaultDatapegawai
                ? props.defaultDatapegawai[item.key as keyof TFormPegawai]
                : undefined
            }
            className="!border !py-[17px] !px-[31px] !rounded !min-w-[200px]"
          />
        </>
      );
    // }
  }

  function optionInputField(
    item: TFormItem,
    currentValue: string | undefined,
    updateValue: (key: string, value: any) => void
  ) {
    if (!item.options) return null;
    if (item.key === "id_perusahaan") return null;

    return (
      <>
        <DropdownField
          title={item.label}
          name={item.label}
          id={item.label}
          options={item.options}
          value={currentValue ?? ""}
          onChange={(value) => updateValue(item.key, value)}
          className="!border !py-[17px] !px-[31px] !rounded !min-w-[200px]"
        />
      </>
    );
  }

  function chooseField(item: TFormItem) {
    if (item.key === "tahun_nikah" && dataPegawai.status_nikah === "bujangan")
      return;
    switch (item.type) {
      case "string":
      case "date":
      case "number":
        return standardInputField(item);
      case "option":
        return optionInputField(
          item,
          dataPegawai[item.key as keyof TFormPegawai],
          updateStateDataPegawai
        );
    }
  }

  function fieldPegawai() {
    const leftComponents: ReactNode[] = [];
    const rightComponents: ReactNode[] = [];

    formItems.forEach((item, index) => {
      if (index < (formItems.length - 12) / 2) {
        leftComponents.push(chooseField(item));
      } else {
        rightComponents.push(chooseField(item));
      }
    });

    return (
      <div className={PegawaiFormStyle.form_wrapper}>
        <div className={PegawaiFormStyle.column_container} key="left">
          {leftComponents}
        </div>
        <div className={PegawaiFormStyle.column_container} key="right">
          {rightComponents}
        </div>
      </div>
    );
  }

  function ListPegawaiOptions() {
    if (!user.token) return;
    if (departemenOptions.length > 0 || jabatanOptions.length > 0) return;
    const axiosOptions = new AxiosListCreatePegawaiOptions();
    axiosOptions.get(user.token).then((response) => {
      const data = response.data.data;
      updateStateDataPegawai("jabatan", parseInt(data.jabatan_options[0].id));
      setJabatanOptions(
        data.jabatan_options.map((option: any) => {
          return { label: capitalizeEachWord(option.nama, "_"), value: option.id };
        })
      );
      updateStateDataPegawai(
        "departemen",
        parseInt(data.departemen_options[0].id)
      );
      setDepartemenOptions(
        data.departemen_options.map((option: any) => {
          return { label: capitalizeFirst(option.nama), value: option.id };
        })
      );
      updateStateDataPegawai(
        "id_perusahaan",
        parseInt(data.departemen_options[0].id)
      );
      setPerusahaanOptions(
        data.perusahaan_options.map((option: any) => {
          return { label: capitalizeFirst(option.nama), value: option.id };
        })
      );
    });
  }

  useEffect(() => {
    ListPegawaiOptions();
  }, []);

  return (
    <>
      <div
        className={clsx(
          PegawaiFormStyle.wrapper,
          "w-full py-6",
          props.className
        )}
      >
        <>
          {fieldPegawai()}
          <ListFormGroup
            onDeleted={(value) => {
              if (props.onDeleted) props.onDeleted(value);
            }}
            defaultLists={{
              bahasaList: props.defaultDatapegawai?.bahasa_dikuasai,
              pengalamanKerjaList: props.defaultDatapegawai?.pengalaman_kerja,
              sakitList: props.defaultDatapegawai?.detil_sakit,
              kecelakaanList: props.defaultDatapegawai?.detil_kecelakaan,
              polisiList: props.defaultDatapegawai?.detil_polisi,
              psikologiList: props.defaultDatapegawai?.detil_psikologi,
              militerList: props.defaultDatapegawai?.detil_militer,
              bacaanList: props.defaultDatapegawai?.detil_bacaan,
              keluargaList: props.defaultDatapegawai?.keluarga,
              simList: props.defaultDatapegawai?.sim,
              pendidikanList: props.defaultDatapegawai?.pendidikan,
              kegiatanOrganisasiList:
                props.defaultDatapegawai?.kegiatan_organisasi,
            }}
            onListChange={(lists) => {
              const newDataPegawai = {
                ...dataPegawai,
                bahasa_dikuasai: lists.bahasaList,
                pengalaman_kerja: lists.pengalamanKerjaList,
                detil_sakit: lists.sakitList,
                detil_kecelakaan: lists.kecelakaanList,
                detil_polisi: lists.polisiList,
                detil_psikologi: lists.psikologiList,
                detil_militer: lists.militerList,
                detil_bacaan: lists.bacaanList,
                keluarga: lists.keluargaList,
                sim: lists.simList,
                pendidikan: lists.pendidikanList,
                kegiatan_organisasi: lists.kegiatanOrganisasiList,
              };
              setDataPegawai(newDataPegawai);
              props.onPegawaiChange(newDataPegawai);
            }}
          />
        </>
      </div>
    </>
  );
};

export default PegawaiForm;
