import { createColumnHelper } from "@tanstack/react-table";
import Button from "../../../components/Button";
import { ListSPKStatus } from "../../../enums/ListSPKStatus";
import { flipStringDate } from "../../../helpers/DateHelper";

export type Item = {
  id: number;
  noSPK: string;
  name: string;
  dateStart: string;
  dateEnd: string;
  status: ListSPKStatus;
  uploadedDate: string;
  soft_deleted: boolean;
  action: {
    detail: () => void;
    lembarKerjaBorongan: () => void;
  };
};

const columnHelper = createColumnHelper<Item>();
export const columns = [
  columnHelper.accessor("id", {
    header: () => "No.",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor((row) => row.noSPK, {
    id: "noSPK",
    cell: (info) => <>{info.getValue().toString()}</>,
    header: () => <span>No. SPK</span>,
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor((row) => row.name, {
    id: "name",
    cell: (info) => <>{info.getValue().toString()}</>,
    header: () => <span>Nama</span>,
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor((row) => row.dateStart, {
    id: "dateStart",
    cell: (info) => <>{flipStringDate(info.getValue())}</>,
    header: () => <span>Tanggal Mulai</span>,
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor((row) => row.dateEnd, {
    id: "dateEnd",
    cell: (info) => <>{flipStringDate(info.getValue())}</>,
    header: () => <span>Tanggal Selesai</span>,
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor((row) => row.status, {
    id: "status",
    cell: (info) => <>{info.getValue()}</>,
    header: () => <span>Status</span>,
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor((row) => row.uploadedDate, {
    id: "uploadedDate",
    cell: (info) => <>{info.getValue().substring(0, 10)}</>,
    header: () => <span>Tanggal Upload</span>,
    footer: (info) => info.column.id,
  }),
  // columnHelper.accessor('soft_deleted', {
  //     id: 'soft_deleted',
  //     cell: info => <>{info.getValue() ? "inactive" : "active"}</>,
  //     header: () => <span>Soft Deleted</span>,
  //     footer: info => info.column.id,
  // }),

  columnHelper.accessor("action", {
    header: "Action",
    cell: (inf) => (
      <div className="flex gap-2">
        <Button
          size="small"
          text="Detail"
          onClick={() => {
            inf.row.original.action.detail();
          }}
        />
        <Button
          size="small"
          text="Lembar Kerja Borongan"
          onClick={() => {
            inf.row.original.action.lembarKerjaBorongan();
          }}
        />
      </div>
    ),
    footer: (info) => info.column.id,
  }),
];
