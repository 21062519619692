import { createColumnHelper } from "@tanstack/react-table";
import Button from "../../../components/Button";
import { NavigateFunction } from "react-router-dom";
import { Pegawai } from "../../../context/SelectedPegawai";

export type Item = {
  id: number;
  nama_pegawai: string;
  jenis_kontrak: string;
  kontrak_is_active: string;
  expired: string;
  action_id: number;
  action: () => void;
};
type KontrakResponse = {
  id: number;
  id_pegawai: number;
  nama_pegawai: string;
  jenis_kontrak: string;
  kontrak_is_active: number;
  expired: string;
};

const columnHelper = createColumnHelper<Item>();
export const columns = [
  columnHelper.accessor("id", {
    header: () => "ID Kontrak",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor((row) => row.nama_pegawai, {
    id: "Nama Pegawai",
    cell: (info) => <>{info.getValue()}</>,
    header: () => <span>Nama Pegawai</span>,
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor((row) => row.jenis_kontrak, {
    id: "Jenis Kontrak",
    cell: (info) => <>{info.getValue()}</>,
    header: () => <span>Jenis Kontrak</span>,
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor("kontrak_is_active", {
    header: () => "Status",
    cell: (info) => info.renderValue(),
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor("expired", {
    header: () => "Expired",
    cell: (info) => info.renderValue(),
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor("action_id", {
    header: "Action",
    cell: (inf) => (
      <Button
        size="small"
        text="Detail"
        onClick={() => {
          inf.row.original.action();
        }}
      />
    ),
    footer: (info) => info.column.id,
  }),
];

export function constructItemFromResponse(
  data_response: KontrakResponse[],
  navigate: NavigateFunction,
  setPegawai: React.Dispatch<Pegawai | undefined>
) {
  const newItems: Item[] = [];

  data_response.forEach((data: KontrakResponse) => {
    const item: Item = {
      id: data.id,
      nama_pegawai: data.nama_pegawai,
      jenis_kontrak: data.jenis_kontrak,
      kontrak_is_active: data.kontrak_is_active === 1 ? "Aktif" : "Tidak Aktif",
      expired: data.expired,
      action_id: data.id_pegawai,
      action: () => {
        setPegawai({
          id: data.id_pegawai.toString(),
          name: data.nama_pegawai,
        });
        navigate("/dashboard/detail/pegawai");
      },
    };
    newItems.push(item);
  });
  return newItems;
}
