import { createColumnHelper } from "@tanstack/react-table";
import Button from "../../../components/Button";
import { ButtonTypeEnum } from "../../../enums/ButtonTypeEnum";
import { capitalizeEachWord } from "../../../helpers/StringHelper";

export type Item = {
  id: number;
  tanggal: string;
  nama: string;
  tipe: string;
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
};

const columnHelper = createColumnHelper<Item>();
export const columns = [
  columnHelper.accessor("tanggal", {
    header: () => "Tanggal",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor("nama", {
    header: () => "Keterangan",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("tipe", {
    header: () => "Tipe",
    cell: (info) => <>{capitalizeEachWord(info.getValue(), "_")}</>,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("id", {
    header: () => "Action",
    cell: (info) => <div className="flex flex-col gap-5">
      <Button
        size="lite"
        text="Edit"
        className="h-5 rounded"
        onClick={() => {
          info.row.original.onEdit(info.row.original.id)
        }}
      />
      <Button
        size="lite"
        text="Delete"
        className="h-5 rounded"
        color="danger"
        buttonType={ButtonTypeEnum.CLICKABLE_TEXT}
        onClick={() => {
          info.row.original.onDelete(info.row.original.id)
        }}
      />
    </div>,
    footer: (info) => info.column.id,
  }),
];
