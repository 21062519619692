import ApiRequest from "./ApiRequest";

interface IParamType {}

class AxiosGetListGajiPokok extends ApiRequest<IParamType> {
  constructor() {
    super("/payroll/list_gaji_pokok");
  }

  get(token: string, pagination?: string, query?: string, nama?:string, jabatan?:string) {
    if (pagination) this.path = pagination;
    return this.get_request(token, {
      query: query,
      nama: nama,
      jabatan: jabatan,
    });
  }
}

export default AxiosGetListGajiPokok;
