import React, { useContext, useState } from "react";
import ContentHeader from "../../../components/ContentHeader";
import userAuth from "../../../context/UserAuth";
import selectedPegawai from "../../../context/SelectedPegawai";
import TambahPegawaiStyle from "./TambahPegawai.module.css";
import Button from "../../../components/Button";
import AxiosCreatePegawai from "../../../api/AxiosCreatePegawai";
import { useNavigate } from "react-router-dom";
import PegawaiForm from "../PegawaiForm";
import { TFormPegawai } from "../../../models/TFormPegawai";
import clsx from "clsx";
import { toast } from "react-toastify";
import { Loader } from "@mantine/core";

interface ITambahPegawaiProps {}

const TambahPegawai: React.FC<ITambahPegawaiProps> = (
  props: ITambahPegawaiProps
) => {
  const { user } = useContext(userAuth);
  const { setPegawai } = useContext(selectedPegawai);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  //data pegawai
  const [dataPegawai, setDataPegawai] = useState<TFormPegawai>({
    id_finpro: 0,
    nama: "",
    nip: "",
    id_perusahaan: "1",
    alias: "",
    telp: "",
    tgl_lahir: "",
    tempat_lahir: "",
    gender: "0",
    alamat_ktp: "",
    alamat_domisili: "",
    email: "",
    nik: "",
    id_jabatan: 1,
    id_departemen: 1,
    nama_bank: "",
    nama_rek: "",
    no_rek: "",
    no_npwp: "",
    agama: "",
    kebangsaan: "",
    no_bpjs_kesehatan: "",
    no_bpjs_tenaga_kerja: "",
    tgl_mulai_kerja: "",
    tgl_resign: "",

    //detil pegawai
    sim: [],
    anak_ke: 1,
    status_nikah: "bujangan",
    tahun_nikah: "",
    bahasa_dikuasai: [],
    pengalaman_memimpin: "",
    minat: "",
    minat_lingkungan: "",
    hobby: "",
    berat_badan: 0,
    tinggi_badan: 0,
    kacamata_kiri: "",
    kacamata_kanan: "",
    detil_sakit: [],
    detil_kecelakaan: [],
    detil_polisi: [],
    detil_psikologi: [],
    detil_militer: [],
    detil_bacaan: [],
    keluarga: [],
    pendidikan: [],
    kegiatan_organisasi: [],
    pengalaman_kerja: [],
  });

  const [jenisPerjanjian, setJenisPerjanjian] = useState("PKWT");

  function tambahkanPegawai() {
    if (!user.token || isLoading === true) return;
    setIsLoading(true);

    const axiosCreatePegawai = new AxiosCreatePegawai();

    axiosCreatePegawai
      .post(user.token, { ...dataPegawai })
      .then((response) => {
        if (response.data.success) {
          toast.success("Pegawai berhasil ditambahkan!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          const new_pegawai_id = response.data.data;
          if (!new_pegawai_id || !setPegawai) return;
          setPegawai({ id: new_pegawai_id });
          navigate(
            "/dashboard/hrd/tambah_kontrak?" +
              "jenis_perjanjian=" +
              jenisPerjanjian
          );
          setIsLoading(false);
        }
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
      });
  }

  return (
    <>
      <ContentHeader
        title={"Tambah Pegawai"}
        enableBack={true}
        className="!w-full text-left"
      />
      <div className="!flex !flex-col !gap-4 !mb-[40px]">
        <div className={clsx(TambahPegawaiStyle.wrapper, "")}>
          <PegawaiForm
            onPegawaiChange={(dataPegawai) => setDataPegawai(dataPegawai)}
            onPerjanjianChange={(value) => setJenisPerjanjian(value)}
          />
        </div>
        <div className="mx-[40px] flex justify-end">
          <Button
            text={
              isLoading ? (
                <>
                  <Loader color="indigo" size="sm" />
                </>
              ) : (
                "Tambahkan Pegawai"
              )
            }
            isDisabled={isLoading}
            onClick={() => tambahkanPegawai()}
            size="small"
            className="max-w-[200px] !p-0 h-[40px]"
            color="accept"
          />
        </div>
      </div>
    </>
  );
};

export default TambahPegawai;
