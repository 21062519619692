/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import AxiosCreateDepartemen from "../../api/AxiosCreateDepartemen";
import AxiosGetDepartemen from "../../api/AxiosGetDepartemen";
import AxiosUpdateDepartemen from "../../api/AxiosUpdateDepartemen";
import Button from "../../components/Button";
import ContentHeader from "../../components/ContentHeader";
import InputField from "../../components/InputField";
import PopUpCard from "../../components/PopUpCard";
import Table from "../../components/Table";
import TimeoutPopup, {
  defaultPopUpProps,
  ITimeoutPopupProps,
} from "../../components/TimeoutPopUp";
import userAuth from "../../context/UserAuth";
import { columns, Item } from "./DepartemenTableDefinitions";
import ListDepartemenStyle from "./ListDepartemen.module.css";
import clsx from "clsx";
import { toast } from "react-toastify";
import { Loader } from "@mantine/core";

interface IListDepartemenProps {}
const ListDepartemen: React.FC<IListDepartemenProps> = () => {
  const { user } = useContext(userAuth);
  const [listDepartemen, setListDepartemen] = useState([]);
  const [isPopUp, setIsPopUp] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(0);
  const [newName, setNewName] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [popUpState, setPopUpState] =
    useState<ITimeoutPopupProps>(defaultPopUpProps);

  function getDepartemen() {
    if (!user?.token) return;
    const axiosGetDepartemen = new AxiosGetDepartemen();

    axiosGetDepartemen.get(user.token).then((response) => {
      const data = response.data.data;
      const newData = data.map((item: Item) => {
        return {
          ...item,
          action: () => {
            setIsPopUp(true);
            setIsEditing(true);
            setNewName(item.nama);
            setEditId(item.id);
          },
        };
      });
      setListDepartemen(newData);
    });
  }

  function createDepartemen() {
    if (!user?.token || isLoading === true) return;
    setIsLoading(true);

    const axiosCreateDepartemen = new AxiosCreateDepartemen();
    axiosCreateDepartemen
      .post(user.token, { nama: newName })
      .then(() => {
        toast.success("Departemen berhasil ditambahkan!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsPopUp(false);
        setNewName("");
        setListDepartemen([]);
        getDepartemen();
        setIsLoading(false);
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
        // setPopUpState({
        //   ...popUpState,
        //   popupType: PopUpEnum.ERROR,
        //   message: "Email atau Password Salah",
        //   timeout: 1000,
        //   isEnabled: true,
        //   onTimeout() {
        //     setPopUpState({ ...popUpState, isEnabled: false });
        //   },
        // });
      });
  }

  function updateDepartemen() {
    if (!user?.token || isLoading === true) return;
    setIsLoading(true);

    if (editId === 0) return;
    const axiosUpdateDepartemen = new AxiosUpdateDepartemen();
    axiosUpdateDepartemen
      .post(user.token, { id: editId, nama: newName })
      .then(() => {
        toast.success("Departemen berhasil diubah!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // setPopUpState({
        //   ...popUpState,
        //   popupType: PopUpEnum.SUCCESS,
        //   message: "Departemen Berhasil Di Rubah",
        //   timeout: 1000,
        //   isEnabled: true,
        //   onTimeout() {
        //     setPopUpState({ ...popUpState, isEnabled: false });
        //   },
        // });
        setIsPopUp(false);
        setNewName("");
        setEditId(0);
        setListDepartemen([]);
        getDepartemen();
        setIsLoading(false);
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getDepartemen();
  }, []);

  const popUpClose = {
    onClose: () => {
      setIsPopUp(false);
      setIsEditing(false);
      setEditId(0);
      setNewName("");
    },
  };

  return (
    <>
      <div className={ListDepartemenStyle.header_wrapper}>
        <ContentHeader
          title={"List Departemen"}
          additionalHeader={
            <>
              <div className={ListDepartemenStyle.header_container}>
                {/* <SearchField
                  placeholder="Cari no.spk ..."
                  onChange={(value) => onSearch(value)}
                /> */}
                <Button
                  text="Tambah Departemen"
                  onClick={() => setIsPopUp(true)}
                  size="small"
                />
              </div>
            </>
          }
        />
      </div>
      <TimeoutPopup {...popUpState} />

      {isPopUp ? (
        <PopUpCard
          {...popUpClose}
          title={isEditing ? "Edit Departemen" : "Tambah Departemen"}
        >
          <InputField
            title="Nama Departemen"
            type="text"
            placeholder="Nama departemen"
            onChange={(value) => setNewName(value)}
            defaultValue={newName}
            className="w-full"
            value={newName}
          />
          {isEditing ? (
            <Button
              size="small"
              text={
                isLoading ? (
                  <>
                    <Loader color="indigo" size="sm" />
                  </>
                ) : (
                  "Simpan Perubahan"
                )
              }
              isDisabled={isLoading}
              className="h-[55px]"
              onClick={() => updateDepartemen()}
            />
          ) : (
            <Button
              size="small"
              text={
                isLoading ? (
                  <>
                    <Loader color="indigo" size="sm" />
                  </>
                ) : (
                  "Tambahkan"
                )
              }
              isDisabled={isLoading}
              className="h-[55px]"
              onClick={() => createDepartemen()}
            />
          )}
        </PopUpCard>
      ) : (
        <> </>
      )}
      <div className={clsx(ListDepartemenStyle.table_wrapper, "pb-4")}>
        <Table<Item> data={listDepartemen} columns={columns} />
      </div>
    </>
  );
};
export default ListDepartemen;
