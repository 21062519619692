import ApiRequest from "./ApiRequest";

interface IParamType {
    tanggal: string,
    id_pegawai: number,
}

class AxiosListEditableLog extends ApiRequest<IParamType> {
    constructor() {
        super("/presensi/editable_log");
    }

    get(
        token: string,
        param: IParamType,
    ) {
        return this.get_request(token,
            { ...param }
        );
    }
}

export default AxiosListEditableLog;
