import ApiRequest from "./ApiRequest";

interface IParamType {
  id_finpro: string;
}

class AxiosCreateMissingPegawai extends ApiRequest<IParamType> {
  constructor() {
    super("/create_missing_pegawai");
  }

  post(token: string, id_finpro: string) {
    const params: IParamType = { id_finpro };
    return this.post_request({ params, token });
  }
}

export default AxiosCreateMissingPegawai;
