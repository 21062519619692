import ApiRequest from "./ApiRequest";

export type IUpdateUserParam = {
  id_user: number,
  nama: string,
  email: string,
  password: string,
  id_role: number,
  id_perusahaan: number,
}

class AxiosUpdateUser extends ApiRequest<IUpdateUserParam> {
  constructor() {
    super("/update_user");
  }

  post(
    token: string,
    data: IUpdateUserParam
  ) {
    return this.post_request({
      token: token,
      params: { ...data }
    });
  }
}

export default AxiosUpdateUser;
