import { createColumnHelper } from "@tanstack/react-table";
import Button from "../../../components/Button";
import { ButtonTypeEnum } from "../../../enums/ButtonTypeEnum";
import { capitalizeEachWord } from "../../../helpers/StringHelper";

export type Item = {
  id: number;
  tipe: string;
  alasan: string;
  tanggal: string;
  id_dokumen: number;
  onDelete: (id_izin: number) => void;
  onDetail: (id_dokumen: number) => void;
  onUpload: (id_izin: number) => void;
};

const columnHelper = createColumnHelper<Item>();
export const columns = [
  columnHelper.accessor((row) => row.id, {
    id: "id",
    cell: (info) => <>{info.getValue()}</>,
    header: () => <span>ID</span>,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor((row) => row.tipe, {
    id: "tipe",
    cell: (info) => <>{capitalizeEachWord(info.getValue(), "_")}</>,
    header: () => <span>Tipe</span>,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor((row) => row.alasan, {
    id: "alasan",
    cell: (info) => <>{info.getValue()}</>,
    header: () => <span>Alasan</span>,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor((row) => row.tanggal, {
    id: "tanggal",
    cell: (info) => <>{info.getValue()}</>,
    header: () => <span>Tanggal</span>,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor((row) => row, {
    id: "action",
    cell: (info) => {
      const id_dokumen = info.row.original.id_dokumen
      const id_izin = info.row.original.id
      return <>
        <div className="flex flex-col gap-2 w-40">
          {id_dokumen != null ?
            <Button
              onClick={() => info.row.original.onDetail(id_dokumen)}
              text="Dokumen"
              size="lite"
            /> :
            <Button
              onClick={() => info.row.original.onUpload(id_izin)}
              text="Upload Dokumen"
              size="lite"
            />
          }
          <Button
            onClick={() => info.row.original.onDelete(id_izin)}
            text="Delete"
            size="lite"
            color="danger"
            buttonType={ButtonTypeEnum.CLICKABLE_TEXT}
          />
        </div>
      </>

    },
    header: () => <span>Action</span>,
    footer: (info) => info.column.id,
  }),
];
