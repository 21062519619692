import { createColumnHelper } from "@tanstack/react-table";
import { NavigateFunction } from "react-router-dom";
import { calculateAge } from "../../../helpers/usiaHelper";
import Button from "../../../components/Button";
import { Pegawai } from "../../../context/SelectedPegawai";
import { capitalizeFirst } from "../../../helpers/StringHelper";

export type Item = {
  nip: number;
  name: string;
  tanggal: string;
  status: string;
  id_presence_status: number;
  count_working_minutes: number;
  count_latetime_minutes: number;
  count_erlytime_minutes: number;
  company: string;
  position: string;
};
type PegawaiResponse = {
  id: number;
  nip: number;
  name: string;
  status_name: string;
  date: string;
  id_presence_status: number,
  count_working_minutes: number,
  count_latetime_minutes: number,
  count_erlytime_minutes: number,
  company: string,
  position: string,
};

const columnHelper = createColumnHelper<Item>();
export const columns = [

  columnHelper.accessor("nip", {
      header: "NIP",
      footer: (info) => info.column.id,
    }),

  columnHelper.accessor((row) => row.name, {
    id: "nama",
    cell: (info) => <>{info.getValue()}</>,
    header: () => <span>Nama</span>,
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor("company", {
    header: "Perusahaan",
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor("position", {
    header: "Jabatan",
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor("tanggal", {
    header: "Tanggal",
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor("count_working_minutes", {
    header: "Durasi Kerja",
    cell: (info) => <>{info.getValue() + " Menit"}</>,
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor("count_latetime_minutes", {
    header: "Terlambat",
    cell: (info) => <>{info.getValue() + " Menit"}</>,
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor("count_erlytime_minutes", {
    header: "Lebih Awal",
    cell: (info) => <>{info.getValue() + " Menit"}</>,
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor("status", {
    header: "Status",
    cell: (props) => {
      return (
        <p style={{ color: props.row.original.id_presence_status === 1 ? "green" : props.row.original.id_presence_status === 9 ? 'red' : 'brown' }}>
          {props.row.original.status}
        </p>
      );
    },
    footer: (info) => info.column.id,
  }),

];

export function constructItemFromResponse(
  data_response: PegawaiResponse[],
) {
  const newItems: Item[] = [];
  data_response.forEach((data: PegawaiResponse, key: number) => {
    const item: Item = {
      nip: data.nip,
      name: data.name,
      tanggal: data.date,
      status: data.status_name,
      id_presence_status: data.id_presence_status,
      count_working_minutes: data.count_working_minutes,
      count_latetime_minutes: data.count_latetime_minutes,
      count_erlytime_minutes: data.count_erlytime_minutes,
      company: data.company,
      position: data.position,
    };
    newItems.push(item);
  });
  return newItems;
}
