import ApiRequest from "./ApiRequest";

interface IParamType {
}

class AxiosFindDokumen extends ApiRequest<IParamType> {
    constructor() {
        super("/find_dokumen");
    }

    get(token: string, id: string) {
        this.path += `?id=${id}`
        return this.get_request(token);
    }
}

export default AxiosFindDokumen;
