import { createColumnHelper } from "@tanstack/react-table";
import Button from "../../../components/Button";
import {
  capitalizeEachWord,
  numberToMoney,
} from "../../../helpers/StringHelper";

export type Item = {
  id: number;
  nama_pegawai: string;
  tanggal: string;
  tipe: string;
  keterangan: string;
  jumlah: number;
  deleteAction: () => void;
};

const columnHelper = createColumnHelper<Item>();
export const columns = [
  {
    id: "rowIndex",
    header: "No",
    cell: (info: any) => info.row.index + 1,
  },
  // columnHelper.accessor("id", {
  //   header: () => "ID",
  //   cell: (info) => info.getValue(),
  //   footer: (info) => info.column.id,
  // }),
  columnHelper.accessor("nama_pegawai", {
    header: () => "Nama Pegawai",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("tanggal", {
    header: () => "Tanggal",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("tipe", {
    header: () => "Tipe",
    cell: (info) => capitalizeEachWord(info.getValue(), "_"),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("keterangan", {
    header: () => "Keterangan",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("jumlah", {
    header: () => "Jumlah",
    cell: (info) => numberToMoney(info.getValue()),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("deleteAction", {
    header: "Action",
    cell: (info) => (
      <Button
        size="small"
        text="Hapus"
        onClick={() => info.row.original.deleteAction()}
      />
    ),
    footer: (info) => info.column.id,
  }),
];
