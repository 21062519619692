import InputField from "../../../components/InputField";
import { useContext, useEffect, useState } from "react";
import { columns, Item } from "./TableRekapIzin";
import userAuth from "../../../context/UserAuth";
import Button from "../../../components/Button";
import { Loader } from "@mantine/core";
import ContentHeader from "../../../components/ContentHeader";
import Table from "../../../components/Table";
import AxiosGetLeaveSummary from "../../../api/AxiosGetLeaveSummary";
import { toast } from "react-toastify";
import PaginationLinks from "../../../components/PaginationLinks";
import { dateToString, flipStringDate } from "../../../helpers/DateHelper";

interface IListRekapIzinProps {}

const ListRekapIzin: React.FC<IListRekapIzinProps> = (props: IListRekapIzinProps) => {

    const { user } = useContext(userAuth);
    const defaultData: Item[] = [];
    const [data, setData] = useState(() => [...defaultData]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [pagination_link, setPaginationLinks] = useState([])
    const [selected_link, setSelectedLink] = useState("")


    //filter and table
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const [tanggal_mulai_query, setTanggalMulaiQuery] = useState(dateToString(firstDay))
    const [tanggal_selesai_query, setTanggalSelesaiQuery] = useState(dateToString(new Date()))

    useEffect(() => getLeaveSummary(selected_link), [
        tanggal_mulai_query,
        tanggal_selesai_query,
        selected_link
    ])

    function getLeaveSummary(pagination_link?: string, query?: string, date?: string) {
        if (user.token == null || !tanggal_mulai_query || !tanggal_selesai_query) return;
        const axiosGetLeaveSummary = new AxiosGetLeaveSummary();
    
        axiosGetLeaveSummary
          .get(user.token, {
            'start_date': tanggal_mulai_query,
            'end_date': tanggal_selesai_query
          }, pagination_link)
          .then((response) => {
            const data_response = response.data.data;
            
            setPaginationLinks(response.data["links"]);
            setData(data_response);
          })
          .catch((e) => {
            const response = e.response;
            if (response.status === 422)
              toast.error(`${response.data.message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
          });
    }

    const handleDownloadExcel = () => {
        if (user.token == null || !tanggal_mulai_query || !tanggal_selesai_query) return;
        const axiosGetLeaveSummary = new AxiosGetLeaveSummary();
    
        axiosGetLeaveSummary
          .fetch(user.token, {
            start_date: tanggal_mulai_query,
            end_date: tanggal_selesai_query,
            action: "export"
          }, {
            responseType:'arraybuffer'
          })
          .then((response) => {
           
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
                "download",
                "Rekap Cuti Periode " + flipStringDate(tanggal_mulai_query) + " - " + flipStringDate(tanggal_selesai_query) + ".xlsx"
            );
            document.body.appendChild(link);
            link.click();
          })
          .catch((e) => {
            const response = e.response;
            if (response.status === 422)
              toast.error(`${response.data.message}`, {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
          });
    
        }

    return <>
        <ContentHeader
            title={"Rekap Izin"}
            additionalHeader={
                <>
                    <div className="flex gap-2">
                        <div className="flex gap-2">
                            <InputField type="date" placeholder=""
                                onChange={(e) => { setTanggalMulaiQuery(e) }}
                                value={tanggal_mulai_query}
                            />
                            <span className="flex flex-col justify-center">s/d</span>
                            <InputField type="date" placeholder=""
                                onChange={(e) => { setTanggalSelesaiQuery(e) }}
                                value={tanggal_selesai_query}
                            />
                        </div>

                        <Button
                            text={
                                isLoading ? (
                                    <>
                                        <Loader color="indigo" size="sm" />
                                    </>
                                ) : (
                                    "Export"
                                )
                            }
                            isDisabled={isLoading}
                            onClick={() => {handleDownloadExcel()}}
                            size="small"
                        />
                    </div>
                </>
            }
        />


        <div className="flex flex-col py-[10px]">
            <div className={"flex justify-center items-center bg-white rounded-s mx-5 my-10 pb-4 !pt-0 !mt-0"}>
                <Table<Item> data={data} columns={columns} />
                
            </div>
            <div className="mx-[40px] mt-4">
                <PaginationLinks
                    onPaginationClick={(link) => {
                    setSelectedLink(link)
                    }}
                    pagination_links={pagination_link}
                    className="!w-full"
                />
                {/* <Pagination value={pagination_links} onChange={setPaginationLinks} total={10} />; */}
            </div>
        </div>
    </>
}
export default ListRekapIzin;
