/* eslint-disable react-hooks/exhaustive-deps */

import { useContext, useEffect, useState } from "react";
import AxiosListAllPegawai from "../../../api/AxiosListAllPegawai";
import AxiosListPegawai from "../../../api/AxiosListPegawai";
import Button from "../../../components/Button";
import PaginationLinks, {
  PaginationItems,
} from "../../../components/PaginationLinks";
import SearchField from "../../../components/SearchField";
import userAuth from "../../../context/UserAuth";
import TPegawai from "../../../models/TPegawai";
import PegawaiItem from "./PegawaiItem";
import SelectPegawaiStyle from "./SelectPegawai.module.css";
import clsx from "clsx";
import DropdownField from "../../../components/DropDownField";
import AxiosGetJabatan from "../../../api/AxiosGetJabatan";
import { capitalizeEachWord } from "../../../helpers/StringHelper";
import AxiosListCreatePegawaiOptions from "../../../api/AxiosGetCreatePegawaiOptions";

interface ISelectPegawaiProps {
  listPegawai: TPegawai[];
  setListPegawai: React.Dispatch<React.SetStateAction<TPegawai[]>>;
  selectedPegawai: TPegawai[];
  setSelectedPegawai: React.Dispatch<React.SetStateAction<TPegawai[]>>;
  jenisPerjanjian: string;
}
const SelectPegawai: React.FC<ISelectPegawaiProps> = (
  props: ISelectPegawaiProps
) => {
  const { user } = useContext(userAuth);

  //list pegawai
  const [paginationLinks, setPaginationLinks] = useState([]);
  const [currentPagination, setCurrentPagination] = useState("");
  const [pegawaiQuery, setPegawaiQuery] = useState("");
  const [paginationInfo, setPaginationInfo] = useState({
    from: 0,
    to: 0,
    total: 0,
  });

  //list selected pegawai
  const [selectedQuery, setSelectedQuery] = useState("");
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedInfo, setSelectedInfo] = useState({
    from: 0,
    to: 0,
    total: 0,
  });
  const [filteredPegawai, setFilteredPegawai] = useState<TPegawai[]>([]);
  const [selectedJabatan, setSelectedJabatan] = useState("");
  const [selectedPerusahaanId, setSelectedPerusahaanId] = useState(0);
  const [selectedDepartemenId, setSelectedDepartemenId] = useState(0);

  const [jabatanOptions, setJabatanOptions] = useState([]);
  const [perusahaanOptions, setPerusahaanOptions] = useState([]);
  const [departemenOptions, setDepartemenOptions] = useState([]);

  const pageSize = 15;

  function getOptions() {
    if (!user?.token) return;
    const axiosGetOptions = new AxiosListCreatePegawaiOptions();

    axiosGetOptions.get(user.token).then((response) => {
      const data = response.data.data;

      const jabatanData = data.jabatan_options.map((item: any) => ({
        value: item.nama,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      setJabatanOptions(jabatanData);

      const perusahaanData = data.perusahaan_options.map((item: any) => ({
        value: item.id,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      setPerusahaanOptions(perusahaanData);

      const departemenData = data.departemen_options.map((item: any) => ({
        value: item.id,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      setDepartemenOptions(departemenData);
    });

  }

  useEffect(() => {
    getOptions();
  }, []);

  let timer: any;

  function ListPegawai() {
    if (!user.token) return;

    // const axiosListPegawai = new AxiosListPegawai({ jenis_perjanjian: props.jenisPerjanjian })
    const axiosListPegawai = new AxiosListPegawai({
      jenis_perjanjian: undefined,
      jabatan: selectedJabatan || undefined,
      id_departemen: selectedDepartemenId,
      id_perusahaan: selectedPerusahaanId,
    });

    axiosListPegawai
      .get(user.token, currentPagination, pegawaiQuery)
      .then((response) => {
        const data = response.data.data.data;
        setPaginationLinks(response.data.data["links"]);
        setPaginationInfo({ ...response.data.data });
        props.setListPegawai(data);
      });
  }

  function onSearch(value: string) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setPegawaiQuery(value);
      setCurrentPagination("");
    }, 1000);
  }

  function createPaginationFromSelected() {
    const paginationArray: PaginationItems[] = [];

    for (let i = 0; i < filteredPegawai.length / pageSize; i++) {
      const url = i + 1;
      paginationArray.push({
        url: url.toString(),
        label: url.toString(),
        active: true,
      });
    }

    return paginationArray;
  }

  function SelectAllPegawai() {
    if (!user.token) return;

    const axiosListPegawai = new AxiosListAllPegawai();

    // axiosListPegawai.get(user.token, { jenis_perjanjian: props.jenisPerjanjian })
    axiosListPegawai
      .get(user.token, { jenis_perjanjian: undefined })
      .then((response) => {
        const data = response.data.data;
        props.setSelectedPegawai([...data]);
      });
  }

  useEffect(() => {
    ListPegawai();
  }, [pegawaiQuery, currentPagination]);

  useEffect(() => ListPegawai(), [
    selectedDepartemenId,
    selectedPerusahaanId,
    selectedJabatan,
  ]);

  useEffect(() => {
    let newfilter: TPegawai[] = [...props.selectedPegawai];

    if (selectedQuery.length > 0)
      newfilter = props.selectedPegawai.filter((pegawai) =>
        pegawai.nama.includes(selectedQuery)
      );
    setFilteredPegawai(newfilter);

    const from = (selectedPage - 1) * pageSize;
    setSelectedInfo({
      from: from === 0 ? 1 : from,
      to: Math.min(selectedPage * pageSize, newfilter.length),
      total: newfilter.length,
    });
  }, [props.selectedPegawai, selectedPage, selectedQuery]);


  return (
    <>
      <div className={clsx(SelectPegawaiStyle.container, "px-12 py-6")}>
        <div className={SelectPegawaiStyle.list_container}>
          <div className={SelectPegawaiStyle.header}>
            <div className={SelectPegawaiStyle.unselected}>
              <div>
                Menampilkan {paginationInfo.from} ke {paginationInfo.to} dari{" "}
                {paginationInfo.total}
              </div>
              <Button
                onClick={() => SelectAllPegawai()}
                text="Pilih Semua"
                size="minimum"
              />
            </div>
            <div className={SelectPegawaiStyle.search}>
              <SearchField
                type="text"
                onChange={(value) => onSearch(value)}
                placeholder="Cari Nama"
              />
            </div>
            <div className="mx-[20px] mt-4 mb-4">
              <DropdownField
                name="nama_jabatan"
                id="nama_jabatan"
                title="Filter Berdasarkan Jabatan"
                options={jabatanOptions}
                onChange={(value) => setSelectedJabatan(value)}
                value={selectedJabatan}
                placeholder=""
                className={SelectPegawaiStyle.dropdown}
              />
              <div className="w-full flex justify-end">
                <span
                  className="hover:cursor-pointer hover:underline text-blue-500"
                  onClick={() => setSelectedJabatan("")}
                >
                  Clear filter jabatan
                </span>
              </div>
            </div>
            <div className="mx-[20px] mt-4 mb-4">
              <DropdownField
                name="nama_perusahaan"
                id="nama_perusahaan"
                title="Filter Berdasarkan Perusahaan"
                options={perusahaanOptions}
                onChange={(value) => setSelectedPerusahaanId(parseInt(value))}
                value={selectedPerusahaanId.toString()}
                placeholder=""
                className={SelectPegawaiStyle.dropdown}
              />
            </div>
            <div className="mx-[20px] mt-4 mb-4">
              <DropdownField
                name="nama_departemen"
                id="nama_departemen"
                title="Filter Berdasarkan departemen"
                options={departemenOptions}
                onChange={(value) => setSelectedDepartemenId(parseInt(value))}
                value={selectedDepartemenId.toString()}
                placeholder=""
                className={SelectPegawaiStyle.dropdown}
              />
              <div className="w-full flex justify-end">
                <span
                  className="hover:cursor-pointer hover:underline text-blue-500"
                  onClick={() => setSelectedDepartemenId(0)}
                >
                  Clear filter jabatan
                </span>
              </div>
            </div>
          </div>
          <div className={SelectPegawaiStyle.pagination}>
            <PaginationLinks
              onPaginationClick={(link) => setCurrentPagination(link)}
              pagination_links={paginationLinks}
            />
          </div>
          <div className={SelectPegawaiStyle.list}>
            {props.listPegawai.map((pegawai) => {
              const filtered = props.selectedPegawai.filter(
                (selected) => selected.id === pegawai.id
              );
              const isAdded = filtered.length > 0;
              return (
                <PegawaiItem
                  {...pegawai}
                  isAdd
                  isAdded={isAdded}
                  onIconClick={() => {
                    const isDuplicate = props.selectedPegawai.some((item) => {
                      return item.id === pegawai.id;
                    });

                    if (isDuplicate) return;
                    const newSelected = [...props.selectedPegawai];
                    newSelected.push(pegawai);
                    props.setSelectedPegawai(newSelected);
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className={SelectPegawaiStyle.list_container}>
          <div className={SelectPegawaiStyle.header}>
            <div className={SelectPegawaiStyle.unselected}>
              <div>
                Menampilkan {selectedInfo.from} ke {selectedInfo.to} dari{" "}
                {selectedInfo.total}
              </div>
            </div>
            <div className={SelectPegawaiStyle.search}>
              <SearchField
                type="text"
                onChange={(value) => setSelectedQuery(value)}
                placeholder="Cari Nama"
              />
            </div>
            <div className="mx-[20px] mt-4 mb-4">
              <DropdownField
                name="nama_jabatan"
                id="nama_jabatan"
                title="Filter Berdasarkan Jabatan"
                options={jabatanOptions}
                onChange={(value) => setSelectedJabatan(value)}
                value={selectedJabatan}
                placeholder=""
                className={SelectPegawaiStyle.dropdown}
              />
              <div className="w-full flex justify-end">
                <span
                  className="hover:cursor-pointer hover:underline text-blue-500"
                  onClick={() => setSelectedJabatan("")}
                >
                  Clear filter jabatan
                </span>
              </div>
            </div>
          </div>
          <div className={SelectPegawaiStyle.pagination}>
            <PaginationLinks
              onPaginationClick={(link) => {
                const page = parseInt(link);
                setSelectedPage(page);
              }}
              pagination_links={createPaginationFromSelected()}
            />
          </div>
          <div className={SelectPegawaiStyle.list}>
            {filteredPegawai.map((pegawai, index) => {
              let subtractor = 0;
              if (selectedPage === 1) subtractor = 1;
              if (
                index < selectedInfo.from - subtractor ||
                index > selectedInfo.to - 1
              )
                return <> </>;
              return (
                <PegawaiItem
                  {...pegawai}
                  isAdd={false}
                  onIconClick={() => {
                    const newSelected = [...props.selectedPegawai].filter(
                      (item) => item.id !== pegawai.id
                    );

                    props.setSelectedPegawai(newSelected);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default SelectPegawai;
