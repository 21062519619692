import { createColumnHelper } from "@tanstack/react-table";
import { ChangeEvent } from "react";
import Button from "../../../../components/Button";
import { ButtonTypeEnum } from "../../../../enums/ButtonTypeEnum";

export type Item = {
  id: number;
  waktu_mulai: string;
  waktu_selesai: string;
  pekerjaan: string;
  is_verified: number;
  handleCheckboxClick: (value: ChangeEvent<HTMLInputElement>) => void;
  onDelete: (id: number) => void;
};

const columnHelper = createColumnHelper<Item>();
export const columns = [
  {
    id: "rowIndex",
    header: "No",
    cell: (info: any) => info.row.index + 1,
  },
  columnHelper.accessor("waktu_mulai", {
    header: () => "Waktu Mulai",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("waktu_selesai", {
    header: () => "Waktu Selesai",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("pekerjaan", {
    header: () => "Pekerjaan",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("id", {
    header: () => "Verifikasi",
    cell: (info) => {
      const item = info.row.original;
      
      return (
        <>
          <input
            type="checkbox"
            onChange={(value) => info.row.original.handleCheckboxClick(value)}
            checked={item.is_verified === 1}
          />
          <Button
            size="lite"
            buttonType={ButtonTypeEnum.CLICKABLE_TEXT}
            color="danger"
            onClick={() => info.row.original.onDelete(info.row.original.id)}
            text="delete"

          />
        </>

      );
    },
    footer: (info) => info.column.id,
  }),
];
