import ApiRequest from "./ApiRequest";

interface IParamType {}

class AxiosGetListMissingPegawai extends ApiRequest<IParamType> {
  constructor() {
    super("/list_missing_pegawai_finpro");
  }

  get(token: string) {
    return this.get_request(token);
  }
}

export default AxiosGetListMissingPegawai;
