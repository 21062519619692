import { createContext, Dispatch, SetStateAction } from 'react'

interface dashboardLoadingContextType {
    isLoading: boolean,
    setIsLoading?: Dispatch<SetStateAction<boolean>>
}
const dashboardLoading = createContext<dashboardLoadingContextType>({
    isLoading: false,
    setIsLoading: undefined 
})
export default dashboardLoading
