
import ApiRequest from "./ApiRequest";

interface IParamType {
}

class AxiosListKontrak extends ApiRequest<IParamType> {
    constructor() {
        super("/list_kontrak");
    }

    get(id_pegawai: number, token: string) {
        this.path += `?id_pegawai=${id_pegawai}`;
        return this.get_request(token);
    }
}

export default AxiosListKontrak;
