import React from "react";
import TKegiatanOrganisasi from "../../../models/TKegiatanOrganisasi";
import ListForm from "../ListForm";

interface IKegiatanOrganisasiListFormProps {
  onKegiatanOrganisasiListChange: (
    value: TKegiatanOrganisasi[],
    deletedId: number[]
  ) => void;
  defaultKegiatanOrganisasi: TKegiatanOrganisasi[];
}

const KegiatanOrganisasiListForm: React.FC<IKegiatanOrganisasiListFormProps> = (
  props: IKegiatanOrganisasiListFormProps
) => {
  const handleListChange = (
    list: TKegiatanOrganisasi[],
    deletedId: number[]
  ) => {
    const updatedList = list.map((item) => ({
      ...item,
      is_active: item.is_active.toString() === "true" ? true : false, // Correctly convert string to boolean
    }));

    props.onKegiatanOrganisasiListChange(updatedList, deletedId);
  };

  return (
    <>
      <ListForm<TKegiatanOrganisasi>
        onListChange={handleListChange}
        initialList={props.defaultKegiatanOrganisasi}
        defaultCreateItem={{
          nama: "",
          waktu_mulai: "",
          waktu_selesai: "",
          jabatan: "",
          is_active: false,
        }}
        listTitle="Kegiatan Organisasi"
        addButtonText="Tambah Kegiatan Organisasi"
        listField={[
          {
            label: "Nama",
            key: "nama",
            type: "text",
            placeholder: "Nama Kegiatan",
            onChange: () => {},
          },
          {
            label: "Waktu Mulai",
            key: "waktu_mulai",
            type: "date",
            placeholder: "",
            onChange: () => {},
          },
          {
            label: "Waktu Selesai",
            key: "waktu_selesai",
            type: "date",
            placeholder: "",
            onChange: () => {},
          },
          {
            label: "Jabatan",
            key: "jabatan",
            type: "text",
            placeholder: "Jabatan Saat Aktif",
            onChange: () => {},
          },
          {
            label: "Status Keaktifan",
            key: "is_active",
            type: "option",
            placeholder: "",
            onChange: () => {},
            options: [
              {
                label: "Tidak Aktif",
                value: "false",
              },
              {
                label: "Aktif",
                value: "true",
              },
            ],
          },
        ]}
      />
    </>
  );
};

export default KegiatanOrganisasiListForm;
