import ApiRequest from "./ApiRequest";

interface IParamType {}

class AxiosGetListRiwayatPegawai extends ApiRequest<IParamType> {
  constructor() {
    super(`/produksi/list_borongan`);
  }

  get(token: string, param: IParamType) {
    return this.get_request(token, { ...param });
  }

  fetch(token: string, param: IParamType, options: any) {
    return this.get_request(token, { ...param }, { ...options })
  }
}

export default AxiosGetListRiwayatPegawai;
