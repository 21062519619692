import { dateToString } from "../helpers/DateHelper";
import ApiRequest from "./ApiRequest";

interface IParamType {
}

class AxiosGetCalendar extends ApiRequest<IParamType> {
    jenis_perjanjian: string | undefined;
    constructor() {
        super("/kalender");
    }

    get(token: string, tahun: Date) {
        return this.get_request(token,
            {
                tahun: tahun.getFullYear()
            }
        );
    }
}

export default AxiosGetCalendar;
