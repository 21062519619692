import { createColumnHelper } from "@tanstack/react-table";
import Button from "../../../components/Button";
import { capitalizeEachWord } from "../../../helpers/StringHelper";

export type Item = {
  id: number;
  nama: string;
  email: string;
  nama_role: string;
  nama_perusahaan: string;
  action: () => void;
};

const columnHelper = createColumnHelper<Item>();
export const columns = [
  columnHelper.accessor("id", {
    header: () => "No.",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor((row) => row.nama, {
    id: "nama",
    cell: (info) => <>{capitalizeEachWord(info.getValue(), "_")}</>,
    header: () => <span>Nama</span>,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor((row) => row.email, {
    id: "email",
    cell: (info) => <>{capitalizeEachWord(info.getValue(), "_")}</>,
    header: () => <span>Email</span>,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor((row) => row.nama_role, {
    id: "nama_role",
    cell: (info) => <>{capitalizeEachWord(info.getValue(), "_")}</>,
    header: () => <span>Role</span>,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor((row) => row.nama_perusahaan, {
    id: "nama_perusahaan",
    cell: (info) => <>{capitalizeEachWord(info.getValue(), "_")}</>,
    header: () => <span>Perusahaan</span>,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("action", {
    header: "Action",
    cell: (inf) => (
      <Button
        size="small"
        text="Edit"
        onClick={() => {
          inf.row.original.action();
        }}
      />
    ),
    footer: (info) => info.column.id,
  }),
];
