/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AxiosCreateLembur, { PegawaiItem } from "../../../api/AxiosCreateLembur";
import AxiosListAllPegawai from "../../../api/AxiosListAllPegawai";
import Button from "../../../components/Button";
import ContentHeader from "../../../components/ContentHeader";
import InputField from "../../../components/InputField";
import ListForm from "../../../components/ListForm/ListForm";
import userAuth from "../../../context/UserAuth";
import { completeTime } from "../../../helpers/DateHelper";
import TOption from "../../../models/TOption";
import TPegawai from "../../../models/TPegawai";
import TambahLemburStyle from "./TambahLembur.module.css";
import clsx from "clsx";
import { toast } from "react-toastify";
import { capitalizeEachWord } from "../../../helpers/StringHelper";
import { Loader } from "@mantine/core";

interface ITambahLemburProps { }

const TambahLembur: React.FC<ITambahLemburProps> = () => {
  const { user } = useContext(userAuth);
  const [tanggalMulai, setTanggalMulai] = useState("");
  const [waktuMulai, setWaktuMulai] = useState("");

  const [tanggalSelesai, setTanggalSelesai] = useState("");
  const [waktuSelesai, setWaktuSelesai] = useState("");

  const [upahDefault, setUpahDefault] = useState(10000);
  const [pekerjaan, setPekerjaan] = useState("");

  const [listPegawai, setListPegawai] = useState<PegawaiItem[]>([]);

  const [pegawaiOptions, setPegawaiOptions] = useState<TOption[]>([]);

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  function getListPegawai() {
    if (!user?.token) return;
    const axiosListAllPegawai = new AxiosListAllPegawai();
    axiosListAllPegawai
      .get(user.token, {
        status: 'aktif'
      })
      .then((response) => {
        const data: TPegawai[] = response.data.data;
        if (!data)
          return

        const options: TOption[] = [];
        data.forEach((item: TPegawai) => {
          options.push({
            value: item.id.toString(),
            label: capitalizeEachWord(item.nama, "_"),
          });
        });
        setPegawaiOptions(options);
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
      });
  }

  useEffect(() => {
    getListPegawai();
  }, []);

  function createLembur() {
    if (!user?.token || isLoading === true) return;
    setIsLoading(true);

    const dateTimeMulai = tanggalMulai + " " + completeTime(waktuMulai);
    const dateTimeSelesai = tanggalSelesai + " " + completeTime(waktuSelesai);

    const axiosCreateLembur = new AxiosCreateLembur();
    axiosCreateLembur
      .post(user.token, {
        waktu_mulai: dateTimeMulai,
        waktu_selesai: dateTimeSelesai,
        pekerjaan,
        pegawai: listPegawai,
      })
      .then(() => {
        toast.success("Perintah lembur berhasil ditambahkan!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        navigate(-1);
        setIsLoading(false);
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422 || response.status === 400)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
      });
  }

  return (
    <>
      <ContentHeader title="Input Perintah Lembur" enableBack />
      <div className={clsx(TambahLemburStyle.container, "!px-12 !py-6")}>
        <div
          className={clsx(
            TambahLemburStyle.form_container,
            "w-full text-left !flex !flex-col"
          )}
        >
          <div className="w-full flex flex-col gap-1">
            <div>
              <span>Waktu Mulai</span>
              <span className="text-red-500">*</span>
            </div>
            <div className="w-full flex gap-2">
              <InputField
                type="date"
                onChange={(value) => {
                  setTanggalMulai(value);
                  setTanggalSelesai(value);
                }}
                placeholder="Masukkan Tanggal Mulai"
              />
              <InputField
                type="time"
                onChange={(value) => setWaktuMulai(value)}
                placeholder="Masukkan Tanggal Selesai"
              />
            </div>
          </div>
          <div className="w-full flex flex-col gap-1">
            <div>
              <span>Waktu Selesai</span>
              <span className="text-red-500">*</span>
            </div>
            <div className="w-full flex gap-2">
              <InputField
                type="date"
                onChange={(value) => setTanggalSelesai(value)}
                defaultValue={tanggalSelesai}
                placeholder="Masukkan Tanggal Selesai"
              />
              <InputField
                type="time"
                onChange={(value) => setWaktuSelesai(value)}
                placeholder="Masukkan Jam Selesai"
              />
            </div>
          </div>
          <InputField
            title="Tarif per 30 menit"
            isRequired={true}
            type="number"
            onChange={(value) => setUpahDefault(parseInt(value))}
            placeholder="Masukkan Upah"
          />
          <InputField
            title="Pekerjaan"
            isRequired={true}
            type="text"
            onChange={(value) => setPekerjaan(value)}
            placeholder="Masukkan Pekerjaan"
          />
        </div>
      </div>
      <div className={clsx(TambahLemburStyle.container, "!px-12 !py-6")}>
        <ListForm<PegawaiItem>
          onListChange={(list) => {
            setListPegawai([...list]);
          }}
          initialList={listPegawai}
          defaultCreateItem={{
            id: "",
            tarif: upahDefault,
          }}
          listTitle="Pegawai Lembur"
          addButtonText="Tambah Pegawai"
          listField={[
            {
              label: "Nama",
              key: "id",
              type: "option",
              placeholder: "Pilih Pegawai",
              onChange: () => { },
              options: pegawaiOptions,
            },
            {
              label: "Tarif per 30 menit",
              key: "tarif",
              type: "number",
              placeholder: "Masukan tarif",
              defaultValue: upahDefault,
              onChange: () => { },
            },
          ]}
        />
      </div>
      <div className="mx-[40px] flex justify-end">
        <Button
          text={
            isLoading ? <Loader color="indigo" size="sm" /> : "Tambah Lembur"
          }
          isDisabled={isLoading}
          onClick={() => createLembur()}
          size="small"
          className="max-w-[200px] !p-0 h-[40px]"
          color="accept"
        />
      </div>
    </>
  );
};
export default TambahLembur;
