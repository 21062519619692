import ApiRequest from "./ApiRequest";

interface IParamType {
}

class AxiosListUser extends ApiRequest<IParamType> {
    constructor() {
        super("/list_user");
    }

    get(token: string) {
        return this.get_request(token);
    }
}

export default AxiosListUser;
