import ContentHeader from "../../../components/ContentHeader";
import CreateBoronganStyle from "./CreateBorongan.module.css";
import LembarKerjaBoronganForm from "../../../components/ListForm/LembarKerjaBoronganForm/LembarKerjaBoronganForm";
import { useLocation } from "react-router-dom";

interface IListSPKProps {}
const CreateLembarKerjaBorongan: React.FC<IListSPKProps> = () => {
  const location = useLocation();
  const stateParam = location.state?.param;
  const no_spk = stateParam?.nomor_spk;
  const id = stateParam?.id;

  return (
    <>
      <div className={CreateBoronganStyle.header_wrapper}>
        <ContentHeader
          title={"Tambah Lembar Kerja Borongan"}
          enableBack={true}
        />
      </div>

      <div className="mx-[40px] my-[20px] bg-white rounded-[10px] py-8 px-12 text-left">
        <LembarKerjaBoronganForm no_spk={no_spk} id_pekerjaan_spk={id} />
      </div>
    </>
  );
};
export default CreateLembarKerjaBorongan;
