import clsx from "clsx";
import React from "react";

export type PaginationItems = {
  url: string;
  active: boolean;
  label: string;
};

interface IPaginationLinksProps {
  pagination_links: PaginationItems[];
  className?: string;
  onPaginationClick: (link: string) => void;
}

const PaginationLinks: React.FC<IPaginationLinksProps> = ({
  pagination_links = [],
  className = "",
  onPaginationClick = (link) =>
    console.log("onPagination click not implemented"),
}) => {
  function generate_pagination_links() {
    return pagination_links.map((pagination, index) => {
      // Use Tailwind CSS for styling
      let itemClass = "cursor-pointer px-3 py-1 mx-1";
      if (pagination.active) {
        // Active link style
        itemClass += " bg-blue-700 text-white";
      } else {
        // Inactive link style
        itemClass += " bg-white text-blue-500 hover:bg-gray-100 border";
      }
      // Handle special labels for previous and next
      if (pagination.label.includes("&la")) pagination.label = "<";
      if (pagination.label.includes("&ra")) pagination.label = ">";

      return (
        <div
          key={index}
          className={clsx(itemClass, "rounded-md")}
          onClick={() => onPaginationClick(pagination.url)}
        >
          {pagination.label}
        </div>
      );
    });
  }

  return (
    <div className={`flex justify-center my-2 ${className}`}>
      {generate_pagination_links()}
    </div>
  );
};

export default PaginationLinks;
