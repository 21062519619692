/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContentHeader from "../../../components/ContentHeader";
import Table from "../../../components/Table";
import dashboardLoading from "../../../context/dashboardLoading";
import userAuth from "../../../context/UserAuth";
import selectedPegawai from "../../../context/SelectedPegawai";
import ListKontrakStyle from "./ListKontrakSegeraBerakhir.module.css";
import {
  columns,
  constructItemFromResponse,
  Item,
} from "./ListKontrakSegeraBerakhirTableDefinitions";
import Button from "../../../components/Button";
import DropdownField from "../../../components/DropDownField";
import AxiosListKontrakSegeraBerakhir from "../../../api/AxiosListKontrakSegeraBerakhir";
import pegawaiContext from "../../../context/SelectedPegawai";

interface IListKontrakSegeraBerakhirProps {}

const ListKontrakSegeraBerakhir: React.FC<IListKontrakSegeraBerakhirProps> = (
  props: IListKontrakSegeraBerakhirProps
) => {
  const defaultData: Item[] = [];
  const { setIsLoading } = useContext(dashboardLoading);
  const { setPegawai } = useContext(pegawaiContext);
  const { user } = useContext(userAuth);
  const { pegawai } = useContext(selectedPegawai);
  const [data, setData] = useState(() => [...defaultData]);
  const [jenis_perjanjian, setJenisPerjanjian] = useState("PKWT");
  const navigate = useNavigate();

  useEffect(() => {
    if (!user?.token || !setPegawai) return;
    const axiosListKontrak = new AxiosListKontrakSegeraBerakhir();
    axiosListKontrak.get(user.token).then((response) => {
      const data = response.data.data;
      setData(constructItemFromResponse(data, navigate, setPegawai));
    });
  }, []);

  if (!setIsLoading) {
    
    return <></>;
  }

  return (
    <>
      <ContentHeader
        title={"List Kontrak Segera Berakhir"}
        enableBack={false}
        className="mb-2"
        additionalHeader={
          <div className="flex gap-2">
            {/* <DropdownField
              name="dokumentype"
              id="DT"
              className="min-h-[55px] min-w-[200px]"
              options={[
                { label: "PKWT", value: "PKWT" },
                { label: "PKWTT", value: "PKWTT" },
                { label: "PKKB", value: "PKKB" },
              ]}
              value={jenis_perjanjian}
              onChange={(value) => {
                setJenisPerjanjian(value);
              }}
            />
            <Button
              text="Tambah Kontrak"
              onClick={() =>
                navigate(
                  "/dashboard/hrd/tambah_kontrak?" +
                    "jenis_perjanjian=" +
                    jenis_perjanjian
                )
              }
              size="small"
            /> */}
          </div>
        }
      />

      <div className={ListKontrakStyle.table_wrapper}>
        <Table<Item> data={data} columns={columns} />
      </div>
    </>
  );
};

export default ListKontrakSegeraBerakhir;
