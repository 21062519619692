import ApiRequest from "./ApiRequest";

interface IListBatchParams {
    waktu_mulai: string,
    waktu_selesai: string,
}

class AxiosListBatch extends ApiRequest<IListBatchParams> {
    constructor() {
        super("/payroll/list_batch");
    }

    get(token: string, batch_param: IListBatchParams) {
        return this.get_request(token, {
            ...batch_param
        });
    }
}

export default AxiosListBatch;
