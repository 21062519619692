/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AxiosListPegawai from "../../api/AxiosListPegawai";
import ContentHeader from "../../components/ContentHeader";
import Table from "../../components/Table";
import pegawaiContext from "../../context/SelectedPegawai";
import userAuth from "../../context/UserAuth";
import AbsensiStyle from "./InputAbsensi.module.css";
import {
  columns,
  constructItemFromResponse,
  Item,
} from "./IzinListPegawaiTableDefinitions";
import PaginationLinks from "../../components/PaginationLinks";
import clsx from "clsx";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import AxiosSyncAbsensi from "../../api/AxiosSyncAbsensi";
import SearchField from "../../components/SearchField";
import DropdownField from "../../components/DropDownField";
import TOption from "../../models/TOption";
import AxiosGetJabatan from "../../api/AxiosGetJabatan";
import { capitalizeEachWord } from "../../helpers/StringHelper";
import { Loader } from "@mantine/core";

interface IInputAbsensiProps {}
const InputAbsensi: React.FC<IInputAbsensiProps> = () => {
  const [dataPegawai, setDataPegawai] = useState<Item[]>([]);
  const [pagination_links, setPaginationLinks] = useState([]);
  const { user } = useContext(userAuth);
  const { setPegawai } = useContext(pegawaiContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function onPaginationChange(link: string) {
    getListPegawai(link);
  }

  function getListPegawai(
    pagination_link?: string,
    query?: string,
    jabatan?: string
  ) {
    if (user.token == null || !setPegawai) return;
    const axiosListPegawai = new AxiosListPegawai({
      jenis_perjanjian: undefined,
    });

    axiosListPegawai
      .get(user.token, pagination_link, query, jabatan)
      .then((response) => {
        const data_response = response.data.data.data;
        setPaginationLinks(response.data.data["links"]);
        setDataPegawai(
          constructItemFromResponse(
            data_response,
            navigate,
            setPegawai,
            response.data.data.current_page,
            response.data.data.per_page
          )
        );
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
      });
  }

  function syncAbsensi() {
    if (!user?.token || isLoading === true) return;
    setIsLoading(true);

    const axiosSyncAbsensi = new AxiosSyncAbsensi();

    axiosSyncAbsensi
      .post(user.token)
      .then(() => {
        toast.success(
          "Sinkronisasi sudah dijadwalkan, cek beberapa saat lagi!",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          }
        );
        // setPopUpState({
        //   ...popUpState,
        //   popupType: PopUpEnum.SUCCESS,
        //   message: "SPK Berhasil Ditambahkan",
        //   timeout: 1000,
        //   isEnabled: true,
        //   onTimeout() {
        //     setPopUpState({ ...popUpState, isEnabled: false });
        //   },
        // });
        setIsLoading(false);
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getListPegawai();
  }, []);

  let timer: any;

  function onSearch(value: string) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      getListPegawai(undefined, value);
    }, 500);
  }

  const [listJabatan, setListJabatan] = useState<TOption[]>([]);

  function getJabatan() {
    if (!user?.token) return;
    const axiosGetJabatan = new AxiosGetJabatan();

    axiosGetJabatan.get(user.token).then((response) => {
      const data = response.data.data;
      const options: TOption[] = [];
      data.forEach((value: any, index: any) => {
        options.push({
          value: value.nama,
          label: capitalizeEachWord(value.nama, "_"),
          isDefault: index === 0,
        });
      });
      setListJabatan(options);
    });
  }

  useEffect(() => {
    getJabatan();
  }, []);

  const [jabatan, setJabatan] = useState("");

  return (
    <>
      <ContentHeader
        title={"Input Absensi"}
        additionalHeader={
          <>
            <div className={"flex gap-2"}>
              <SearchField
                placeholder="Cari nama ..."
                onChange={(value) => onSearch(value)}
              />
              <DropdownField
                id="jabatan"
                name="jabatan"
                className="!border !py-[17px] !px-[31px] !rounded !min-w-[200px]"
                options={listJabatan}
                onChange={(value) => {
                  getListPegawai(undefined, undefined, value);
                  setJabatan(value);
                }}
                placeholder="Filter Jabatan"
                value={jabatan}
              />

              <Button
                text={
                  isLoading ? (
                    <>
                      <Loader color="indigo" size="sm" />
                    </>
                  ) : (
                    "Sinkronisasi Absensi"
                  )
                }
                isDisabled={isLoading}
                onClick={() => {
                  syncAbsensi();
                }}
                size="small"
              />
            </div>
          </>
        }
      />

      <div className={clsx("flex flex-col")}>
        <div className="mx-[40px] mt-4">
          <PaginationLinks
            onPaginationClick={(link) => onPaginationChange(link)}
            pagination_links={pagination_links}
            className="!w-full"
          />
        </div>

        <div className={clsx(AbsensiStyle.table_wrapper, "!mb-16 !pb-4")}>
          <Table<Item>
            data={dataPegawai}
            columns={columns}
            onPaginationChanges={(link) => onPaginationChange(link)}
          />
        </div>
      </div>
    </>
  );
};

export default InputAbsensi;
