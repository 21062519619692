import React from "react";
import TKecelakaan from "../../../models/TKecelakaan";
import ListForm from "../ListForm";

interface IKecelakaanListFormProps {
  onKecelakaanListChange: (value: TKecelakaan[]) => void;
  defaultKecelakaan: TKecelakaan[];
}

const KecelakaanListForm: React.FC<IKecelakaanListFormProps> = (
  props: IKecelakaanListFormProps
) => {
  return (
    <>
      <ListForm<TKecelakaan>
        onListChange={(list) => {
          props.onKecelakaanListChange(list);
        }}
        initialList={props.defaultKecelakaan}
        defaultCreateItem={{
          jenis_kecelakaan: "",
          akibat_kecelakaan: "",
          tahun: "",
          id: Date.now(),
        }}
        listTitle="Detail pernah kecelakaan"
        addButtonText="Tambah Riwayat Kecelakaan"
        listField={[
          {
            label: "Jenis Kecelakaan",
            key: "jenis_kecelakaan",
            type: "text",
            placeholder: "Jenis Kecelakaan",
            onChange: () => {},
          },
          {
            label: "Akibat Kecelakaan",
            key: "akibat_kecelakaan",
            type: "text",
            placeholder: "Akibat Kecelakaan",
            onChange: () => {},
          },
          {
            label: "Tahun",
            key: "tahun",
            type: "number",
            placeholder: "Tahun Terjadi",
            onChange: () => {},
          },
        ]}
      />
    </>
  );
};

export default KecelakaanListForm;
