import { createColumnHelper } from "@tanstack/react-table";

export type Item = {
    id_pegawai: number,
    nama: string,
    tanggal: string,
    masuk_pagi: string,
    keluar_istirahat: string,
    masuk_istirahat: string,
    keluar_sore: string,
}

const columnHelper = createColumnHelper<Item>()
export const columns = [
    columnHelper.accessor('id_pegawai', {
        header: () => "ID",
        cell: info => info.getValue(),
        footer: info => info.column.id,
    }),

    columnHelper.accessor('nama', {
        id: 'nama',
        cell: info => <>{info.getValue()}</>,
        header: () => <span>Nama</span>,
        footer: info => info.column.id,
    }),

    columnHelper.accessor('masuk_pagi', {
        header: '07:30',
        cell: info => info.renderValue(),
        footer: info => info.column.id,
    }),
    columnHelper.accessor('keluar_istirahat', {
        header: '12.00',
        cell: info => info.renderValue(),
        footer: info => info.column.id,
    }),
    columnHelper.accessor('masuk_istirahat', {
        header: '13.00',
        cell: info => info.renderValue(),
        footer: info => info.column.id,
    }),
    columnHelper.accessor('keluar_sore', {
        header: '17.00',
        cell: info => info.renderValue(),
        footer: info => info.column.id,
    }),
];

