import { createColumnHelper } from "@tanstack/react-table";
import {
  capitalizeEachWord,
  numberToMoney,
} from "../../../helpers/StringHelper";
import Button from "../../../components/Button";
import { ButtonTypeEnum } from "../../../enums/ButtonTypeEnum";

export type Item = {
  id: number;
  nama: string;
  periode: string;
  nominal: number;
  editAction?: (item: Item) => void;
  deleteAction?: (item: Item) => void;
};
type THRResponse = {
  id: number;
  nama: string;
  periode: string;
  nominal: number;
};

const columnHelper = createColumnHelper<Item>();
export const columns = [
  columnHelper.accessor("nama", {
    header: () => "Nama",
    cell: (info) => <>{capitalizeEachWord(info.getValue())}</>,
  }),
  columnHelper.accessor("periode", {
    header: () => "Periode",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("nominal", {
    header: () => "Nominal",
    cell: (info) => <>{numberToMoney(info.getValue())}</>,
  }),
  columnHelper.accessor("editAction", {
    header: "Action",
    cell: (info) => (
      <div className="flex flex-col gap-5">
        <Button
          size="small"
          text="Edit"
          onClick={() => {
            info.row.original.editAction?.(info.row.original);
          }}
        />
        <Button
          size="small"
          buttonType={ButtonTypeEnum.CLICKABLE_TEXT}
          color="danger"
          text="Delete"
          onClick={() => {
            info.row.original.deleteAction?.(info.row.original);
          }}
        />
      </div>
    ),
  }),
];

export function constructItemFromResponse(data_response: THRResponse[]) {
  const newItems: Item[] = [];
  data_response.forEach((data: THRResponse) => {
    const item: Item = { ...data };
    newItems.push(item);
  });
  return newItems;
}
