import { useState } from "react";
import BacaanListForm from "../../components/ListForm/BacaanListForm/BacaanListForm";
import BahasaListForm from "../../components/ListForm/BahasaListForm/BahasaListForm";
import CatatanPolisiListForm from "../../components/ListForm/CatatanPolisiListForm/CatatanPolisiListForm";
import KecelakaanListForm from "../../components/ListForm/KecelakaanListForm/KecelakaanListForm";
import KegiatanOrganisasiListForm from "../../components/ListForm/KegiatanOrganisasiListForm/KegiatanOrganisasiListForm";
import KeluargaListForm from "../../components/ListForm/KeluargaListForm/KeluargaListForm";
import MiliterListForm from "../../components/ListForm/MiliterListForm/MiliterListForm";
import PendidikanListForm from "../../components/ListForm/PendidikanListForm/PendidikanListForm";
import PengalamanKerjaListForm from "../../components/ListForm/PengalamanKerjaListForm/PengalamanKerjaListForm";
import PsikologiListForm from "../../components/ListForm/PsikologiListForm/PsikologiListForm";
import SakitListForm from "../../components/ListForm/SakitListForm/SakitListForm";
import SimListForm from "../../components/ListForm/SimListForm/SimListForm";
import TBacaan from "../../models/TBacaan";
import TBahasaItem from "../../models/TBahasaItem";
import TCatatanPolisi from "../../models/TCatatanPolisi";
import TKecelakaan from "../../models/TKecelakaan";
import TKegiatanOrganisasi from "../../models/TKegiatanOrganisasi";
import TKeluarga from "../../models/TKeluarga";
import TMiliter from "../../models/TMiliter";
import TPendidikan from "../../models/TPendidikan";
import TPengalamanKerja from "../../models/TPengalamanKerja";
import TPsikologi from "../../models/TPsikologi";
import TSakit from "../../models/TSakit";
import TSim from "../../models/TSim";

export interface IEditableList {
  bahasaList: TBahasaItem[];
  pengalamanKerjaList: TPengalamanKerja[];
  sakitList: TSakit[];
  kecelakaanList: TKecelakaan[];
  polisiList: TCatatanPolisi[];
  psikologiList: TPsikologi[];
  militerList: TMiliter[];
  bacaanList: TBacaan[];
  keluargaList: TKeluarga[];
  simList: TSim[];
  pendidikanList: TPendidikan[];
  kegiatanOrganisasiList: TKegiatanOrganisasi[];
}
interface IListFormGroupProps {
  onListChange: (lists: IEditableList) => void;
  onDeleted: (deletedIds: {
    deleted_keluarga_id: number[];
    deleted_pendidikan_id: number[];
    deleted_kegiatan_organisasi_id: number[];
    deleted_pengalaman_kerja_id: number[];
  }) => void;
  defaultLists?: IEditableList;
}

const ListFormGroup: React.FC<IListFormGroupProps> = (
  props: IListFormGroupProps
) => {
  
  const [editableList, setEditableList] = useState<IEditableList>({
    bahasaList: props.defaultLists?.bahasaList ?? [],
    pengalamanKerjaList: props.defaultLists?.pengalamanKerjaList ?? [],
    sakitList: props.defaultLists?.sakitList ?? [],
    kecelakaanList: props.defaultLists?.kecelakaanList ?? [],
    polisiList: props.defaultLists?.polisiList ?? [],
    psikologiList: props.defaultLists?.psikologiList ?? [],
    militerList: props.defaultLists?.militerList ?? [],
    bacaanList: props.defaultLists?.bacaanList ?? [],
    keluargaList: props.defaultLists?.keluargaList ?? [],
    simList: props.defaultLists?.simList ?? [],
    pendidikanList: props.defaultLists?.pendidikanList ?? [],
    kegiatanOrganisasiList: props.defaultLists?.kegiatanOrganisasiList ?? [],
  });
  const [deletedIds, setDeletedIds] = useState({
    deleted_keluarga_id: [] as number[],
    deleted_pendidikan_id: [] as number[],
    deleted_kegiatan_organisasi_id: [] as number[],
    deleted_pengalaman_kerja_id: [] as number[],
  });

  function updateList(list: IEditableList) {
    setEditableList(list);
    props.onListChange(list);
  }

  return (
    <>
      <BahasaListForm
        onBahasaChange={(value) =>
          updateList({ ...editableList, bahasaList: value })
        }
        defaultBahasa={editableList.bahasaList}
      />
      <PengalamanKerjaListForm
        onPengalamanKerjaChange={(value, deletedId) => {
          updateList({ ...editableList, pengalamanKerjaList: value });
          const newDeletedIds = {
            ...deletedIds,
            deleted_pengalaman_kerja_id: deletedId,
          };
          props.onDeleted(newDeletedIds);
          setDeletedIds(newDeletedIds);
        }}
        defaultPengalamanKerja={editableList.pengalamanKerjaList}
      />
      <SakitListForm
        onSakitListChange={(value) =>
          updateList({ ...editableList, sakitList: value })
        }
        defaultSakit={editableList.sakitList}
      />
      <KecelakaanListForm
        onKecelakaanListChange={(value) =>
          updateList({ ...editableList, kecelakaanList: value })
        }
        defaultKecelakaan={editableList.kecelakaanList}
      />

      <CatatanPolisiListForm
        onCatatanPolisiListChange={(value) =>
          updateList({ ...editableList, polisiList: value })
        }
        defaultPolisi={editableList.polisiList}
      />
      <PsikologiListForm
        onPsikologiListChange={(value) =>
          updateList({ ...editableList, psikologiList: value })
        }
        defaultPsikologi={editableList.psikologiList}
      />
      <MiliterListForm
        onMiliterListChange={(value) =>
          updateList({ ...editableList, militerList: value })
        }
        defaultMiliter={editableList.militerList}
      />
      <SimListForm
        onSimListChange={(value) =>
          updateList({ ...editableList, simList: value })
        }
        defaultSim={editableList.simList}
      />
      <KeluargaListForm
        onKeluargaListChange={(value, deletedId) => {
          updateList({ ...editableList, keluargaList: value });
          const newDeletedIds = {
            ...deletedIds,
            deleted_keluarga_id: deletedId,
          };
          props.onDeleted(newDeletedIds);
          setDeletedIds(newDeletedIds);
        }}
        defaultKeluarga={editableList.keluargaList}
      />
      <BacaanListForm
        onBacaanListChange={(value) =>
          updateList({ ...editableList, bacaanList: value })
        }
        defaultBacaan={editableList.bacaanList}
      />
      <PendidikanListForm
        onPendidikanListChange={(value, deletedId) => {
          updateList({ ...editableList, pendidikanList: value });
          const newDeletedIds = {
            ...deletedIds,
            deleted_pendidikan_id: deletedId,
          };
          props.onDeleted(newDeletedIds);
          setDeletedIds(newDeletedIds);
        }}
        defaultPendidikan={editableList.pendidikanList}
      />
      <KegiatanOrganisasiListForm
        onKegiatanOrganisasiListChange={(value, deletedId) => {
          updateList({ ...editableList, kegiatanOrganisasiList: value });
          const newDeletedIds = {
            ...deletedIds,
            deleted_kegiatan_organisasi_id: deletedId,
          };
          props.onDeleted(newDeletedIds);
          setDeletedIds(newDeletedIds);
        }}
        defaultKegiatanOrganisasi={editableList.kegiatanOrganisasiList}
      />
    </>
  );
};
export default ListFormGroup;
