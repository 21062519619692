import ApiRequest from "./ApiRequest";

interface IParamType {
    jam_berangkat: string,
    jam_kembali: string,
    nama_pegawai?: string,

}

class AxiosListKiriman extends ApiRequest<IParamType> {
    constructor() {
        super("/kiriman/list");
    }

    get(
        token: string,
        param: IParamType,
    ) {
        return this.get_request(token,
            { ...param }
        );
    }
}

export default AxiosListKiriman;
