/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import AxiosCreateJabatan from "../../../api/AxiosCreateJabatan";
import AxiosGetJabatan from "../../../api/AxiosGetJabatan";
import AxiosUpdateJabatan from "../../../api/AxiosUpdateJabatan";
import AxiosListUser from "../../../api/AxiosListUser";
import Button from "../../../components/Button";
import ContentHeader from "../../../components/ContentHeader";
import InputField from "../../../components/InputField";
import PopUpCard from "../../../components/PopUpCard";
import Table from "../../../components/Table";
import TimeoutPopup, {
  defaultPopUpProps,
  ITimeoutPopupProps,
} from "../../../components/TimeoutPopUp";
import userAuth from "../../../context/UserAuth";
import { columns, Item } from "./ListUserTableDefinitions";
import ListJabatanStyle from "./ListUsers.module.css";
import clsx from "clsx";
import { toast } from "react-toastify";
import { Loader } from "@mantine/core";
import DropdownField from "../../../components/DropDownField";
import TOption from "../../../models/TOption";
import { capitalizeEachWord } from "../../../helpers/StringHelper";
import AxiosListRoles from "../../../api/AxiosListRole";
import AxiosCreateUser from "../../../api/AxiosCreateUser";
import AxiosListPerusahaan from "../../../api/AxiosListPerusahaan";
import AxiosUpdateUser from "../../../api/AxiosUpdateUser";

interface IListUsersProps { }
const ListUsers: React.FC<IListUsersProps> = () => {
  const { user } = useContext(userAuth);
  const [listUser, setListUsers] = useState([]);
  const [roleOptions, setRoleOptions] = useState<TOption[]>([]);
  const [perusahaanOptions, setPerusahaanOptions] = useState<TOption[]>([]);
  const [isPopUp, setIsPopUp] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editId, setEditId] = useState(0);

  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [idPerusahaan, setIdPerusahaan] = useState(0);
  const [idRole, setIdRole] = useState(0);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [popUpState, setPopUpState] =
    useState<ITimeoutPopupProps>(defaultPopUpProps);

  function getPerusahaan() {
    if (!user?.token) return;

    const axiosListPerushaan = new AxiosListPerusahaan();

    axiosListPerushaan.get(user.token).then((response) => {
      const data = response.data.data;
      const newData = data.map((option: any) => {
        return {
          value: option.id,
          label: capitalizeEachWord(option.nama, "_")
        };
      });
      setPerusahaanOptions(newData);
    });
  }
  function getRoles() {
    if (!user?.token) return;

    const axiosListRoles = new AxiosListRoles();

    axiosListRoles.get(user.token).then((response) => {
      const data = response.data.data;
      const newData = data.map((option: any) => {
        return {
          value: option.id,
          label: capitalizeEachWord(option.nama, "_")
        };
      });
      setRoleOptions(newData);
    });
  }

  function getUser() {
    if (!user?.token) return;

    const axiosGetUser = new AxiosListUser();

    axiosGetUser.get(user.token).then((response) => {
      const data = response.data.data;
      const newData = data.map((item: any) => {
        return {
          ...item,
          action: () => {
            setEditId(item.id);
            setIsPopUp(true);
            setIsEditing(true);
            setNewName(item.nama)
            setNewEmail(item.email)
            setNewPassword("")
            setIdRole(item.role_id)
            setIdPerusahaan(item.perusahaan_id)
          }
        }
      });
      setListUsers(newData);
    });
  }

  function createUser() {
    if (!user?.token || isLoading === true) return;
    setIsLoading(true);

    if (newEmail === "")
      toast.error("Harus mengisi password", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    if (idRole === 0)
      toast.error("Harus memilih role", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    if (idPerusahaan === 0)
      toast.error("Harus memilih perusahaan", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    if (newPassword === "")
      toast.error("Harus mengisi password", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    if (newName === "")
      toast.error("Harus mengisi nama", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

    const axiosCreateUser = new AxiosCreateUser();
    axiosCreateUser
      .post(user.token, {
        nama: newName,
        email: newEmail,
        password: newPassword,
        id_role: idRole,
        id_perusahaan: idPerusahaan,
      })
      .then(() => {
        toast.success("Jabatan berhasil ditambahkan!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // setPopUpState({
        //   ...popUpState,
        //   popupType: PopUpEnum.SUCCESS,
        //   message: "Jabatan Berhasil Ditambahkan",
        //   timeout: 1000,
        //   isEnabled: true,
        //   onTimeout() {
        //     setPopUpState({ ...popUpState, isEnabled: false });
        //   },
        // });
        setIsPopUp(false);
        setNewName("");
        setListUsers([]);
        getUser();
        setIsLoading(false);
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
      });
  }

  function updateUser() {
    if (!user?.token || isLoading === true) return;
    setIsLoading(true);

    if (editId === 0) return;
    const axiosUpdateJabatan = new AxiosUpdateUser();
    axiosUpdateJabatan
      .post(user.token, {
        id_user: editId,
        nama: newName,
        email: newEmail,
        password: newPassword,
        id_role: idRole,
        id_perusahaan: idPerusahaan,
      })
      .then(() => {
        toast.success("Jabatan berhasil diubah!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // setPopUpState({
        //   ...popUpState,
        //   popupType: PopUpEnum.SUCCESS,
        //   message: "Jabatan Berhasil Di Rubah",
        //   timeout: 1000,
        //   isEnabled: true,
        //   onTimeout() {
        //     setPopUpState({ ...popUpState, isEnabled: false });
        //   },
        // });
        setIsPopUp(false);
        setNewName("");
        setEditId(0);
        setListUsers([]);
        getUser();
        setIsLoading(false);
        setIdRole(0)
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getUser();
    getRoles();
    getPerusahaan();
  }, []);

  const popUpClose = {
    onClose: () => {
      setIsPopUp(false);
      setIsEditing(false);
      setEditId(0);
      setNewName("");
    },
  };

  return (
    <>
      <div className={ListJabatanStyle.header_wrapper}>
        <ContentHeader
          title={"List Users"}
          additionalHeader={
            <>
              <div className={ListJabatanStyle.header_container}>
                {/* <SearchField
                  placeholder="Cari no.spk ..."
                  onChange={(value) => onSearch(value)}
                /> */}
                <Button
                  text="Tambah Users"
                  onClick={() => setIsPopUp(true)}
                  size="small"
                />
              </div>
            </>
          }
        />
      </div>
      <TimeoutPopup {...popUpState} />

      {isPopUp ? (
        <PopUpCard
          {...popUpClose}
          title={isEditing ? "Edit " : "Tambah User"}
        >
          <InputField
            title="Nama User"
            type="text"
            placeholder="Nama User"
            onChange={(value) => setNewName(value)}
            defaultValue={newName}
            value={newName}
            className="w-full"
          />
          <InputField
            title="Email"
            type="text"
            placeholder="Email"
            onChange={(value) => setNewEmail(value)}
            defaultValue={newEmail}
            value={newEmail}
            className="w-full"
          />
          <InputField
            title="Password (tidak perlu di isi jika tidak merubah)"
            type="password"
            placeholder="Password"
            onChange={(value) => setNewPassword(value)}
            defaultValue={newPassword}
            value={newPassword}
            className="w-full"
          />
          <DropdownField
            title="Role"
            name="role"
            id="role"
            options={roleOptions}
            onChange={(value) => {
              setIdRole(parseInt(value))
              
            }}
            value={idRole.toString()}
          />
          <DropdownField
            title="Perusahaan"
            name="perusahaan"
            id="role"
            options={perusahaanOptions}
            onChange={(value) => {
              setIdPerusahaan(parseInt(value))
              
            }}
            value={idPerusahaan.toString()}
          />
          {isEditing ? (
            <Button
              size="small"
              text={
                isLoading ? (
                  <>
                    <Loader color="indigo" size="sm" />
                  </>
                ) : (
                  "Simpan Perubahan"
                )
              }
              className="h-[55px]"
              onClick={() => updateUser()}
              isDisabled={isLoading}
            />
          ) : (
            <Button
              size="small"
              text={
                isLoading ? (
                  <>
                    <Loader color="indigo" size="sm" />
                  </>
                ) : (
                  "Tambahkan"
                )
              }
              className="h-[55px]"
              onClick={() => createUser()}
              isDisabled={isLoading}
            />
          )}
        </PopUpCard>
      ) : (
        <> </>
      )}
      <div className={clsx(ListJabatanStyle.table_wrapper, "pb-4")}>
        <Table<Item> data={listUser} columns={columns} />
      </div>
    </>
  );
};
export default ListUsers;
