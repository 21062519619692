import ApiRequest from "./ApiRequest";

interface IParamType {}

type ListPegawaiConstructor = {
    id_jabatan?: string | undefined;
    id_perusahaan?: string | undefined;
    id_departemen?: string | undefined;
    status?: string | undefined;
};

class AxiosGetMonitoringAttendance extends ApiRequest<IParamType> {
  
    id_jabatan: string | undefined;
    id_perusahaan: string | undefined;
    id_departemen: string | undefined;
    status: string | undefined;
  
    constructor({
        id_jabatan = undefined,
        id_perusahaan = undefined,
        id_departemen = undefined,
        status = undefined
    }: ListPegawaiConstructor) {
        super("/v2/attendance/monitoring");
        this.id_jabatan = id_jabatan;
        this.id_perusahaan = id_perusahaan;
        this.id_departemen = id_departemen;       
        this.status = status
    }
  
    get(token: string, pagination?: string, query?: string, date?: string) {
        if (pagination) this.path = pagination;
        return this.get_request(token, {
            query: query,
            date: date,
            id_jabatan: this.id_jabatan,
            id_perusahaan: this.id_perusahaan,
            id_departemen: this.id_departemen,
            status: this.status
        });
    }

}

export default AxiosGetMonitoringAttendance;
