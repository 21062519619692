import clsx from "clsx";
import Button from "../../../components/Button";
import Table from "../../../components/Table";
import HasilSlipStyle from "./HasilSlip.module.css";
import { columns, Item } from "./HasilTableDefinitions";
import { useContext, useState } from "react";
import { Loader } from "@mantine/core";
import userAuth from "../../../context/UserAuth";
import AxiosDeleteGaji from "../../../api/AxiosDeleteGaji";
import { toast } from "react-toastify";

interface IHasilSlipProps {
  onDelete: () => void;
  batch?: any;
  data?: Item[];
}
const HasilSlip: React.FC<IHasilSlipProps> = ({ batch, data, onDelete }) => {
  const { user } = useContext(userAuth);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const transformDataToItems = (data: any) => {
    return data.map((element: any) => {
      const item: Item = {
        id: element.id,
        nama: element.nama_pegawai,
        tipe_gaji: element.tipe_gaji,
        nama_jabatan: element.nama_jabatan,
        nama_departemen: element.nama_departemen,
        gajiPokok: element.gaji_pokok_harga,
        gajiBorongan: element.gaji_borongan,
        totalGaji: element.total_gaji,
        lembur: element.lembur,
        tunjangan:
          element.tunjangan_cabang +
          element.tunjangan_jabatan +
          element.tunjangan_hari_raya,
        premiHadir: element.premi_hadir_harga,
        onDelete: (id) => deleteGaji(id)
      };
      return item;
    });
  };
  function deleteGaji(id: string) {
    if (!user.token || isLoading === true) return;
    setIsLoading(true);

    const create = new AxiosDeleteGaji();
    create
      .post(user.token, { id_gaji: id })
      .then((response) => {
        const data = response.data;
        if (data.success) {
          toast.success("Gaji berhasil di hapus", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        setIsLoading(false);
        onDelete()
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
      });
  }

  const handleDownloadExcel = () => {
    if (!batch || isLoading === true) return;
    setIsLoading(true);
    window.open(
      `${process.env.REACT_APP_BACKEND_PATH}/payroll/download_gaji?waktu_mulai=${batch.waktu_mulai}&waktu_selesai=${batch.waktu_selesai}`,
      "_blank"
    );
    setIsLoading(false);
  };

  return (
    <div className={clsx(HasilSlipStyle.container, "w-full px-12 py-6")}>
      <div className={clsx("text-left font-bold text-3xl mb-2")}>
        Nomor Slip Gaji : {batch.id}
      </div>
      <div className={clsx(HasilSlipStyle.infobar, "mb-2")}>
        <div className={clsx(HasilSlipStyle.info_group, "!p-0")}>
          <div className={clsx(HasilSlipStyle.info_item, "!pl-0")}>
            <div className={HasilSlipStyle.info_title}>Periode Penggajian</div>
            <div className={"text-left font-bold"}>
              {batch.waktu_mulai} s/d {batch.waktu_selesai}
            </div>
          </div>
          <div
            className={
              HasilSlipStyle.info_item + " " + HasilSlipStyle.tanggal_penggajian
            }
          >
            <div className={HasilSlipStyle.info_title}>Tanggal Penggajian</div>
            <div className={"text-left font-bold"}>
              {batch.tanggal_pembayaran_gaji}
            </div>
          </div>
          <div className={HasilSlipStyle.info_item}>
            <div className={HasilSlipStyle.info_title}>Total Pegawai</div>
            <div className={"text-left font-bold"}>{data?.length} Pegawai</div>
          </div>
        </div>
        <div className={clsx(HasilSlipStyle.download_button, "!p-0")}>
          <Button
            onClick={() => handleDownloadExcel()}
            text={
              isLoading ? <Loader color="indigo" size="sm" /> : "Download Excel"
            }
            isDisabled={isLoading}
            size="minimum"
          />
        </div>
      </div>
      <div className={HasilSlipStyle.pagination}></div>
      <div
        className={clsx(
          HasilSlipStyle.hasil_table,
          "flex justify-center items-center bg-white rounded-lg pb-4 border"
        )}
      >
        <Table<Item> data={transformDataToItems(data)} columns={columns} />
      </div>
      <div className={HasilSlipStyle.sum}></div>
    </div>
  );
};
export default HasilSlip;
