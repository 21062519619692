import ApiRequest from "./ApiRequest";

interface IParamType {
    jenis_perjanjian?: string,
    type?: string,
    status?: string,
}

class AxiosListAllPegawai extends ApiRequest<IParamType> {
    constructor() {
        super("/list_all_pegawai");
    }

    get(token: string, param?: IParamType) {
        return this.get_request(token,
            { ...param }
        );
    }
}

export default AxiosListAllPegawai;
