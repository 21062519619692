/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AxiosGetLembur from "../../../api/AxiosGetLembur";
import Button from "../../../components/Button";
import ContentHeader from "../../../components/ContentHeader";
import Table from "../../../components/Table";
import userAuth from "../../../context/UserAuth";
import LemburStyle from "./Lembur.module.css";
import { columns, Item } from "./LemburTableDefinitions";
import InputField from "../../../components/InputField";
import clsx from "clsx";
import { toast } from "react-toastify";
import ConfirmationPopUpCard from "../../../components/ConfirmationPopUpCard";
import AxiosDeleteLembur from "../../../api/AxiosDeleteLembur";
import GeneralContext from "../../../context/generalContext";

interface ILemburProps { }
const Lembur: React.FC<ILemburProps> = () => {
  const { user } = useContext(userAuth);
  const { generalContext, setGeneralContext } = useContext(GeneralContext)
  const navigate = useNavigate();
  const [dataLembur, setDataLembur] = useState<Item[]>([]);
  const [waktuMulai, setWaktuMulai] = useState("");
  const [waktuSelesai, setWaktuSelesai] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function getLembur() {
    if (!user?.token) return;
    const axiosGetLembur = new AxiosGetLembur();
    axiosGetLembur
      .get(user.token, {
        tanggal_mulai: waktuMulai,
        tanggal_selesai: waktuSelesai
      })
      .then((response) => {
        const data: any[] = response.data.data;
        const newDataLembur: Item[] = data.map((lemburItem) => ({
          ...lemburItem,
          action: () =>
            navigate("/dashboard/absensi/lembur/detail/" + lemburItem.id, {
              state: { param: lemburItem.id },
            }),
          deleteAction: () => showDeleteConfirmation(lemburItem.id),
        }));
        setDataLembur(newDataLembur);
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
      });
  }

  function deleteLembur(id: string) {
    if (!user.token || isLoading === true) return;
    setIsLoading(true);

    const deleteLembur = new AxiosDeleteLembur();
    deleteLembur
      .post(user.token, {
        id_lembur: id,
      })
      .then((response) => {
        if (waktuMulai && waktuSelesai) getLembur();

        const data = response.data;
        if (data.success) {
          toast.success("Perintah lembur berhasil dihapus!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsLoading(false);
        }
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
      });
  }

  const showDeleteConfirmation = (id: string) => {
    setDeleteLemburId(id);
    setIsPeringatan(true);
  };

  useEffect(() => {
    setGeneralContext?.({
      waktu_mulai: waktuMulai,
      waktu_selesai: waktuSelesai,
    })
    if (!waktuMulai || !waktuSelesai) return;
    getLembur();
  }, [waktuMulai, waktuSelesai]);

  useEffect(() => {
    setWaktuMulai(generalContext?.waktu_mulai ?? "")
    setWaktuSelesai(generalContext?.waktu_selesai ?? "")
  }, []);


  const [isPeringatan, setIsPeringatan] = useState(false);
  const [deleteLemburId, setDeleteLemburId] = useState<string | null>(null);

  const confirmDeleteLembur = () => {
    if (deleteLemburId) {
      deleteLembur(deleteLemburId);
      setIsPeringatan(false);
    }
  };

  if (!user.role) return <> </>

  return (
    <>
      <div className="flex justify-between mt-[40px] mx-[40px] mb-2">
        <ContentHeader title="Daftar Lembur" className="!p-0" />
        <div className="flex gap-2">
          <span className="flex flex-col justify-center font-bold">
            Periode
          </span>

          <InputField
            type="date"
            placeholder=""
            onChange={(value) => setWaktuMulai(value)}
            value={waktuMulai}
          />
          <span className="flex flex-col justify-center">s/d</span>
          <InputField
            type="date"
            placeholder=""
            onChange={(value) => setWaktuSelesai(value)}
            value={waktuSelesai}
          />
          <Button
            text="Tambah Perintah Lembur"
            size="small"
            onClick={() => navigate("/dashboard/absensi/tambahlembur")}
            className="!max-w-[200px] !max-h-[55px]"
          />
        </div>
      </div>
      {isPeringatan && (
        <ConfirmationPopUpCard
          title={"Peringatan"}
          text="Apakah Anda yakin ingin menghapus data perintah lembur ini?"
          onClose={() => setIsPeringatan(false)}
          onRightButtonClick={confirmDeleteLembur}
          rightButtonText="Hapus"
        />
      )}
      <div className={clsx(LemburStyle.container, "px-0 pb-4")}>
        <Table<Item> data={dataLembur} columns={columns(user.role)} />
      </div>
      <div className="text-red-500 my-2 text-left mx-[40px]">
        *Wajib memasukkan periode!
      </div>
    </>
  );
};
export default Lembur;
