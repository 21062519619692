import ApiRequest from "./ApiRequest";

interface IParamType {
    id_lembur_pegawai: {
        id: number,
        is_verified: boolean
    }[];
}

class AxiosApproveLembur extends ApiRequest<IParamType> {
    constructor() {
        super(`/lembur/approve`);
    }

    public post(token: string, batch_param: IParamType) {
        return this.post_request({
            token: token,
            params: { id_lembur_pegawai: batch_param.id_lembur_pegawai },
        });
    }
}

export default AxiosApproveLembur;
