import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ContentHeader from "../../components/ContentHeader";
import TabHeader from "../../components/TabHeader";
import TabularWindows from "../../components/TabularWindow";
import AbsensiManual from "./AbsensiManual/AbsensiManual";
import Izin from "./Izin/Izin";
import ListIzin from "./Izin/ListIzin";

interface IInputAbsensiDetailProps { }
const InputAbsensiDetail: React.FC<IInputAbsensiDetailProps> = () => {

    const location = useLocation()
    const prevState = location.state
    const navigate = useNavigate();
    console.log(location)

    const [activeItem, setActiveItem] = useState(prevState?.activeItem ?? "absen");
    //izin state
    const [tanggal_mulai, setTanggal_mulai] = useState(prevState?.tanggal_mulai ?? "")
    const [tanggal_selesai, setTanggal_selesai] = useState(prevState?.tanggal_selesai ?? "")
    const [pagination_links, setPaginationLinks] = useState([]);
    const [selected_link, setSelectedLink] = useState(prevState?.selected_link ?? "");

    function switchComponent() {
        switch (activeItem) {
            case "absen":
                return <AbsensiManual />
            case "izin":
                return <>
                    <Izin />
                    <ListIzin
                        tanggal_mulai={[tanggal_mulai, setTanggal_mulai]}
                        tanggal_selesai={[tanggal_selesai, setTanggal_selesai]}
                        pagination_links={[pagination_links, setPaginationLinks]}
                        selected_link={[selected_link, setSelectedLink]}
                        watchDokumen={(id) => {
                            navigate("/dashboard/detail/dokumen?id=" + id,
                                {
                                    state: {
                                        from: location.pathname,
                                        activeItem,
                                        tanggal_mulai,
                                        tanggal_selesai,
                                        selected_link,
                                    }
                                }
                            )
                        }}
                    />
                </>
        }
    }
    return (
        <>
            <ContentHeader title={"Input Absensi"} enableBack />
            <div className="flex w-full px-12">
                <TabHeader title="Absen" onClick={() => setActiveItem("absen")} />
                <TabHeader title="Izin" onClick={() => setActiveItem("izin")} />
            </div>
            {switchComponent()}
        </>
    );
};
export default InputAbsensiDetail;
