/* eslint-disable react-hooks/exhaustive-deps */
import { ColumnDef } from "@tanstack/react-table";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AxiosGetCalendar from "../../../api/AxiosGetCalendar";
import AxiosGetRekapPresensi from "../../../api/AxiosGetRekapPresensi";
import ContentHeader from "../../../components/ContentHeader";
import InputField from "../../../components/InputField";
import Table from "../../../components/Table";
import GeneralContext from "../../../context/generalContext";
import pegawaiContext from "../../../context/SelectedPegawai";
import userAuth from "../../../context/UserAuth";
import { bulanTahunIndonesia, dateToString, flipStringDate } from "../../../helpers/DateHelper";
import { calculatePrensi } from "../../../helpers/PresensiHelper";
import MingguanStyle from "./Mingguan.module.css";
import { columns, Item } from "./MingguanTableDefinitions";
import SearchField from "../../../components/SearchField";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import AxiosSyncAbsensi from "../../../api/AxiosSyncAbsensi";
import clsx from "clsx";
import AxiosGetJabatan from "../../../api/AxiosGetJabatan";
import TOption from "../../../models/TOption";
import { capitalizeEachWord } from "../../../helpers/StringHelper";
import DropdownField from "../../../components/DropDownField";
import { Loader } from "@mantine/core";
import AxiosListCreatePegawaiOptions from "../../../api/AxiosGetCreatePegawaiOptions";
import AxiosExportRekapPresensi from "../../../api/AxiosExportRekapPresensi";

interface IHarianProps { }

const Mingguan: React.FC<IHarianProps> = () => {
  const { user } = useContext(userAuth);
  const { setPegawai } = useContext(pegawaiContext);
  const { generalContext, setGeneralContext } = useContext(GeneralContext);
  const [data, setData] = useState<Item[]>([]);
  const [queryStart, setQueryStart] = useState("");
  const [queryEnd, setQueryEnd] = useState("");
  const [columDef, setColumnDef] = useState<ColumnDef<Item, any>[]>([]);
  // const [listTanggal, setListTanggal] = useState<ColumnDef<Item, any>[]>([]);
  const [kalender, setKalender] = useState<{ tipe: string; tanggal: string }[]>(
    []
  );
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingNew, setIsLoadingNew] = useState<boolean>(false);
  const [pegawaiName, setPegawaiName] = useState<string>("");

  //filter
  const [selectedJabatanId, setSelectedJabatanId] = useState("");
  const [selectedPerusahaanId, setSelectedPerusahaanId] = useState("");
  const [selectedDepartemenId, setSelectedDepartemenId] = useState("");

  const [jabatanOptions, setJabatanOptions] = useState([]);
  const [perusahaanOptions, setPerusahaanOptions] = useState([]);
  const [departemenOptions, setDepartemenOptions] = useState([]);

  function getRekap(query?: string, namaPegawai?: string) {
    if (!user.token) return;
    if (!queryStart || !queryEnd) return;

    const axiosGetRekap = new AxiosGetRekapPresensi();
    axiosGetRekap
      .get(
        user.token,
        queryStart,
        queryEnd,
        namaPegawai,
        selectedJabatanId,
        selectedDepartemenId,
        selectedPerusahaanId,
      )
      .then((response) => {
        const data = response.data.data;
        if (!data) {
          setData([]);
          return;
        }

        const newList: Item[] = [];
        Object.entries(data).forEach((value: any, key) => {
          const detail = value[1];
          const { presensiArray, recap } = calculatePrensi(
            detail.presensi,
            new Date(queryStart),
            new Date(queryEnd),
          );
          if (!presensiArray || !recap) return;
          newList.push({
            id: detail.id,
            nama: detail.nama,
            presensi: presensiArray,
            status_absen: recap,
            action: () => {
              if (!setPegawai || !setGeneralContext) return;
              setPegawai({
                id: detail.id,
                name: detail.nama,
              });
              setGeneralContext({
                waktu_mulai: queryStart,
                waktu_selesai: queryEnd,
              });
              navigate("/dashboard/absensi/detail");
            },
          });
        });
        setData([...newList]);
        setColumnDef(columns(createHeader()));
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
      });
  }

  const handleDownloadExcel = () => {
    if (isLoading === true) return;
    if (!queryStart || !queryEnd) {
      toast.error(`Periode Harus Di isi`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return
    }
    setIsLoading(true);
    window.open(
      `${process.env.REACT_APP_BACKEND_PATH}/presensi/download?waktu_mulai=${queryStart}&waktu_selesai=${queryEnd}`,
      "_blank"
    );
    setIsLoading(false);
  };

  const handleDownloadExcelNew = () => {
    if (isLoadingNew === true) return;
    if (!queryStart || !queryEnd) {
      toast.error(`Periode Harus Di isi`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return
    }
    
    if (!user?.token) return;
  
    const axiosExportRekapPresensi = new AxiosExportRekapPresensi();
    setIsLoadingNew(true);

    axiosExportRekapPresensi
      .fetch(user.token, {
        id_perusahaan: selectedPerusahaanId,
        id_departemen: selectedDepartemenId,
        id_jabatan: selectedJabatanId,
        id_pegawai: "",
        nama: pegawaiName,
        start_date: queryStart,
        end_date: queryEnd,
        action: "export",
        source: "",
        export_type: "vertical"
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            "Rekap Absensi " + pegawaiName + " Periode " + flipStringDate(queryStart) + " - " + flipStringDate(queryEnd) + ".xlsx"
        );
        document.body.appendChild(link);
        link.click();
        setIsLoadingNew(false);
      })
  };

  useEffect(() => {
    getKalender();
    getRekap();
  }, [queryEnd, selectedJabatanId, selectedPerusahaanId, selectedDepartemenId]);

  useEffect(() => {
    setColumnDef(columns(<> </>));
    getOptions()

    if (!generalContext?.waktu_selesai || !generalContext.waktu_mulai) return
    setQueryStart(generalContext.waktu_mulai);
    setQueryEnd(generalContext.waktu_selesai);
  }, []);

  function getKalender() {
    if (!user.token) return;
    if (queryStart.length == 0 || queryEnd.length == 0) return;

    const axiosGetKalender = new AxiosGetCalendar();

    axiosGetKalender.get(user.token, new Date(queryStart)).then((response) => {
      const data = response.data.data;
      setKalender(data);
    }).catch(e => console.log("catch kalender", e));
  }

  function createHeader() {
    if (!queryStart) return;
    let tanggalCounter = new Date(queryStart);
    const dateElements = [];

    for (let i = 0; i < 7; i++) {
      dateElements.push(
        <div className={MingguanStyle.cell}>{tanggalCounter.getDate()}</div>
      );
      tanggalCounter.setDate(tanggalCounter.getDate() + 1);
    }

    return (
      <div className={MingguanStyle.status_header}>
        <div>{bulanTahunIndonesia(new Date(queryStart))}</div>
        <div className={MingguanStyle.status}>{dateElements}</div>
      </div>
    );
  }


  function getOptions() {
    if (!user?.token) return;
    const axiosGetOptions = new AxiosListCreatePegawaiOptions();

    axiosGetOptions.get(user.token).then((response) => {
      const data = response.data.data;

      const jabatanData = data.jabatan_options.map((item: any) => ({
        value: item.id,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      jabatanData.unshift({ value: 0, label: "No Filter" })
      setJabatanOptions(jabatanData);
      setSelectedJabatanId("")

      const perusahaanData = data.perusahaan_options.map((item: any) => ({
        value: item.id,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      perusahaanData.unshift({ value: 0, label: "No Filter" })
      setPerusahaanOptions(perusahaanData);
      setSelectedPerusahaanId("")

      const departemenData = data.departemen_options.map((item: any) => ({
        value: item.id,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      departemenData.unshift({ value: 0, label: "No Filter" })
      setDepartemenOptions(departemenData);
      setSelectedDepartemenId("")
    });

  }

  return (
    <>
      <ContentHeader
        title={"Absensi Mingguan"}
        additionalHeader={
          <>
            <div className={"flex gap-2"}>
              <InputField
                type="text"
                placeholder="Nama Pegawai..."
                onChange={(value) => {
                  setPegawaiName(value);
                  getRekap(undefined, value);
                }}
                className=""
              />

              <InputField
                type="date"
                placeholder="tgl"
                onChange={(value) => {
                  
                  const startDate = new Date(value);
                  let endDate = new Date(startDate);
                  endDate.setDate(endDate.getDate() + 6);
                  setQueryStart(value);
                  setQueryEnd(dateToString(endDate));


                  setGeneralContext?.({
                    waktu_mulai: dateToString(startDate),
                    waktu_selesai: dateToString(endDate)
                  })

                }}
                value={queryStart}
                className=""
              />

              <DropdownField
                id="jabatan"
                name="jabatan"
                className="!border !py-[17px] !px-[31px] !rounded !min-w-[200px]"
                options={jabatanOptions}
                onChange={(value) => {
                  setSelectedJabatanId(value);
                }}
                placeholder="Filter Jabatan"
                value={selectedJabatanId.toString()}
              />
              <DropdownField
                id="departemen"
                name="departemen"
                className="!border !py-[17px] !px-[31px] !rounded !min-w-[200px]"
                options={departemenOptions}
                onChange={(value) => {
                  setSelectedDepartemenId(value);
                }}
                placeholder="Filter Departemen"
                value={selectedDepartemenId.toString()}
              />
              <DropdownField
                id="perusahaan"
                name="perusahaan"
                className="!border !py-[17px] !px-[31px] !rounded !min-w-[200px]"
                options={perusahaanOptions}
                onChange={(value) => {
                  setSelectedPerusahaanId(value);
                }}
                placeholder="Filter Perusahaan"
                value={selectedPerusahaanId.toString()}
              />

              {/* <SearchField
                placeholder="Cari nama ..."
                onChange={(value) => onSearch(value)}
              /> */}

              <Button
                text={
                  isLoading ? (
                    <>
                      <Loader color="indigo" size="sm" />
                    </>
                  ) : (
                    "Download Excell"
                  )
                }
                isDisabled={isLoading}
                onClick={() => {
                  handleDownloadExcel();
                }}
                size="small"
              />
              <Button
                text={ isLoadingNew ? ( <>  <Loader color="indigo" size="sm" /> </> ) : ( "Download New Format" )
                }
                isDisabled={isLoadingNew}
                onClick={() => {
                  handleDownloadExcelNew();
                }}
                size="small"
              />
            </div>
          </>
        }
      />

      <div className={clsx(MingguanStyle.table_wrapper, "pb-4")}>
        <Table<Item> data={data} columns={columDef} />
      </div>
      <div className="text-red-500 mt-2 mb-[40px] text-left mx-[40px]">
        *Wajib memasukkan tanggal!
      </div>
    </>
  );
};

export default Mingguan;
