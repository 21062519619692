export function capitalizeFirst(s: string) {
  return s[0].toUpperCase() + s.substring(1);
}

// export function capitalizeEachWord(s: string, splitter: string = " ") {
//     let compiled = "";
//     const words = s.split(splitter);

//     for (let i = 0; i < words.length; i++) {
//         compiled += words[i][0].toUpperCase() + words[i].substr(1) + " ";
//     }
//     return compiled
// }

export function capitalizeEachWord(s: string, splitter: string = " ") {
  let compiled = "";
  const words = s.split(splitter);

  for (let i = 0; i < words.length; i++) {
    if (words[i]) {
      compiled += words[i][0].toUpperCase() + words[i].substr(1) + " ";
    }
  }
  return compiled.trim();
}

export function cleanupKey(s: string) {
  s = capitalizeEachWord(s, "_");
  s = s.replaceAll("_", " ");
  return s;
}

// export function numberToMoney(value: number) {
//   const formattedNumber = value.toLocaleString("en-US", { useGrouping: true });
//   return "Rp. " + formattedNumber;
// }

export function numberToMoney(value?: number) {
  if (value === undefined || value === null) {
    return "Rp. 0";
  }
  const formattedNumber = value.toLocaleString("en-US", { useGrouping: true });
  return "Rp. " + formattedNumber;
}

export function formatDate(dateStr: string) {
  const months = [
    "Januari",
    "Februari",
    "Maret",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Agustus",
    "September",
    "Oktober",
    "November",
    "Desember",
  ];

  const date = new Date(dateStr);
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  return `${day} ${months[month]} ${year}`;
}
