import ApiRequest from "./ApiRequest";

interface ICreatePotonganParams {
  id_pegawai: string;
  tipe: string;
  keterangan: string;
  tanggal: string;
  jumlah: string;
}

class AxiosCreatePotongan extends ApiRequest<ICreatePotonganParams> {
  constructor() {
    super("/potongan/create");
  }

  post(token: string, pegawai_data: ICreatePotonganParams) {
    return this.post_request({
      token: token,
      params: { ...pegawai_data },
    });
  }
}

export default AxiosCreatePotongan;
