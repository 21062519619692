import { createColumnHelper } from "@tanstack/react-table";
import Button from "../../../components/Button";
import { ButtonTypeEnum } from "../../../enums/ButtonTypeEnum";
import { capitalizeEachWord } from "../../../helpers/StringHelper";

export type Item = {
    nama: string;
    waktu: string;
    before: string;
    after: string;
    onDetail: (before: string, after: string) => void;
};

const columnHelper = createColumnHelper<Item>();
export const columns = [
    columnHelper.accessor((row) => row.nama, {
        id: "tipe",
        cell: (info) => <>{capitalizeEachWord(info.getValue(), "_")}</>,
        header: () => <span>Editor</span>,
        footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.waktu, {
        id: "alasan",
        cell: (info) => <>{info.getValue()}</>,
        header: () => <span>Waktu</span>,
        footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row, {
        id: "action",
        cell: (info) => <>
            <div className="flex flex-col gap-2 w-40">
                <Button
                    onClick={() => info.row.original.onDetail(
                        info.row.original.before,
                        info.row.original.after,
                    )}
                    text="Detail"
                    size="lite"
                />
            </div>
        </>,
        header: () => <span>Action</span>,
        footer: (info) => info.column.id,
    }),
];
