import ApiRequest from "./ApiRequest";

interface ISyncAbsensiParam {}

class AxiosSyncAbsensi extends ApiRequest<ISyncAbsensiParam> {
  constructor() {
    super("/presensi/sync");
  }

  post(token: string) {
    return this.post_request({
      token: token,
    });
  }
}

export default AxiosSyncAbsensi;
