import React from "react";
import { capitalizeEachWord } from "../../../helpers/StringHelper";
import TBahasaItem from "../../../models/TBahasaItem";
import DataPribadiStyle from "./DataPribadi.module.css";

interface IUListBahasaItem {
    data: string
}

const UneditableListBahasaItem: React.FC<IUListBahasaItem> = (props: IUListBahasaItem) => {
    const parsedData: TBahasaItem[] = JSON.parse(props.data)

    return <>
        {parsedData.map((bahasa: TBahasaItem) => {
            return <div className={DataPribadiStyle.bahasa_list_item}>
                {capitalizeEachWord(bahasa.bahasa, "_")}
                {bahasa.fasih}
            </div>
        })}
    </>
}
export default UneditableListBahasaItem;
