import {
  ColumnDef,
  ColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { ReactNode, useState } from "react";
import TableStyle from "./Table.module.css";
import { motion, AnimatePresence } from "framer-motion";
import InputField from "./InputField";
import Button from "./Button";
import ListDepartemenStyle from "./ListDepartemen.module.css";
import PaginationLinks from "./PaginationLinks";
import clsx from "clsx";

type PaginationItems = {
  url: string;
  active: boolean;
  label: string;
};
interface ITableProps<T> {
  data: T[];
  columns: ColumnDef<T, any>[];

  //pagination
  pagination_links?: PaginationItems[];
  onPaginationChanges?: (link: string) => void;
}

const Table = <T extends object>(
  props: ITableProps<T> & { children?: ReactNode }
) => {
  const [query, setQuery] = useState("");
  const table = useReactTable({
    data: props.data,
    columns: props.columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className={clsx(TableStyle.wrapper, "")}>
      {props.pagination_links ? (
        <PaginationLinks
          onPaginationClick={(link) =>
            props.onPaginationChanges?.(link + "&query=" + query)
          }
          pagination_links={props.pagination_links ?? []}
        />
      ) : null}

      <table className={clsx(TableStyle.table, "px-0 !w-full")}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className={clsx(TableStyle.t_head, "border-b")}
            >
              {headerGroup.headers.map((header) => (
                <th key={header.id} className={TableStyle.t_cell}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          <AnimatePresence>
            {table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <motion.tr
                  initial={{ height: 0, opacity: 0 }}
                  animate={{ height: 0, opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  exit={{ opacity: 0 }}
                  key={row.id}
                  className={TableStyle.t_row}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={clsx(
                        TableStyle.t_cell,
                        "border-y overflow-x-auto max-w-sm break-words"
                      )}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </motion.tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={table.getAllFlatColumns().length}
                  className={clsx(TableStyle.t_cell, "text-center")}
                >
                  Data tidak ditemukan.
                </td>
              </tr>
            )}
          </AnimatePresence>
        </tbody>
      </table>
    </div>
  );
};

export default Table;
