import { createColumnHelper } from "@tanstack/react-table";
import Button from "../../../components/Button";
import { flipStringDate } from "../../../helpers/DateHelper";

export type Item = {
    id: number;
    no_laporan: string;
    id_list_pekerjaan_spk: number;
    tanggal_pekerjaan: string;
    jumlah_output: number;
    soft_deleted: boolean;
    nama_pekerjaan: string;
    action: () => void;
};

const columnHelper = createColumnHelper<Item>();
export const columns = [
    // columnHelper.accessor("id", {
    //     header: () => "No.",
    //     cell: (info) => info.getValue(),
    //     footer: (info) => info.column.id,
    // }),

    // columnHelper.accessor((row) => row.no_laporan, {
    //   id: "no_laporan",
    //   cell: (info) => <>{info.getValue()}</>,
    //   header: () => <span>No. Laporan</span>,
    //   footer: (info) => info.column.id,
    // }),

    columnHelper.accessor((row) => row.tanggal_pekerjaan, {
        id: "date",
        cell: (info) => <>{flipStringDate(info.getValue())}</>,
        header: () => <span>Tanggal Pengerjaan</span>,
        footer: (info) => info.column.id,
    }),

    columnHelper.accessor((row) => row.nama_pekerjaan, {
        id: "nama_pekerjaan",
        cell: (info) => <>{info.getValue()}</>,
        header: () => <span>Nama Pekerjaan</span>,
        footer: (info) => info.column.id,
    }),

    columnHelper.accessor((row) => row.jumlah_output, {
        id: "targetBahan",
        cell: (info) => <>{info.getValue()}</>,
        header: () => <span>Jumlah Barang</span>,
        footer: (info) => info.column.id,
    }),

    // columnHelper.accessor('soft_deleted', {
    //     id: 'soft_deleted',
    //     cell: info => <>{info.getValue() ? "inactive" : "active"}</>,
    //     header: () => <span>Soft Deleted</span>,
    //     footer: info => info.column.id,
    // }),

    columnHelper.accessor("action", {
        header: "Action",
        cell: (inf) => (
            <Button
                size="small"
                text="Detail"
                onClick={() => {
                    inf.row.original.action();
                }}
            />
        ),
        footer: (info) => info.column.id,
    }),
];
