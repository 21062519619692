import ApiRequest from "./ApiRequest";

interface IParamType { }

type ListPegawaiConstructor = {
  jenis_perjanjian: string | undefined;
  jabatan?: string | undefined;
  id_perusahaan?: number | undefined;
  id_departemen?: number | undefined;
};

class AxiosListPegawai extends ApiRequest<IParamType> {
  jenis_perjanjian: string | undefined;
  jabatan: string | undefined;
  id_perusahaan: number | undefined;
  id_departemen: number | undefined;

  constructor({
    jenis_perjanjian = undefined,
    jabatan = undefined,
    id_perusahaan = undefined,
    id_departemen = undefined,
  }: ListPegawaiConstructor) {
    super("/list_pegawai");
    this.jenis_perjanjian = jenis_perjanjian;
    this.jabatan = jabatan;
    this.id_perusahaan = id_perusahaan;
    this.id_departemen = id_departemen;
  }

  get(token: string, pagination?: string, query?: string, jabatan?: string, status = "aktif") {
    if (pagination) this.path = pagination;
    return this.get_request(token, {
      query: query,
      status: status,
      jenis_perjanjian: this.jenis_perjanjian,
      jabatan: this.jabatan ? this.jabatan : jabatan,
      id_perusahaan: this.id_perusahaan,
      id_departemen: this.id_departemen,
    });
  }
}

export default AxiosListPegawai;
