/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import ContentHeader from "../../../components/ContentHeader";
import InputField from "../../../components/InputField";
import Table from "../../../components/Table";
import userAuth from "../../../context/UserAuth";
import { columns, Item } from "./GajiPokokTableDefinitions";
import clsx from "clsx";
import GajiPokokStyle from "./GajiPokok.module.css";
import PopUpCard from "../../../components/PopUpCard";
import Button from "../../../components/Button";
import AxiosGetListGajiPokok from "../../../api/AxiosGetListGajiPokok";
import { toast } from "react-toastify";
import PaginationLinks from "../../../components/PaginationLinks";
import AxiosUpdateGajiPokok from "../../../api/AxiosUpdateGajiPokok";
import DropdownField from "../../../components/DropDownField";
import { capitalizeEachWord } from "../../../helpers/StringHelper";
import TOption from "../../../models/TOption";
import AxiosGetJabatan from "../../../api/AxiosGetJabatan";
import SearchField from "../../../components/SearchField";
import { Loader } from "@mantine/core";

interface IGajiPokokProps {}
const GajiPokok: React.FC<IGajiPokokProps> = () => {
  const { user } = useContext(userAuth);
  const [dataBatch, setDataBatch] = useState<Item[]>([]);
  const [isPopUp, setIsPopUp] = useState(false);
  const [formData, setFormData] = useState({
    id: "",
    nama: "",
    gaji_pokok: "",
    tarif_harian: "",
    premi_hadir: "",
    tunjangan_jabatan: "",
  });
  const [pagination_links, setPaginationLinks] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  function getListGajiPokok(
    pagination_link?: string,
    query?: string,
    nama?: string,
    jabatan?: string
  ) {
    if (user.token == null) return;
    const axiosListGajiPokok = new AxiosGetListGajiPokok();

    axiosListGajiPokok
      .get(user.token, pagination_link, query, nama, jabatan)
      .then((response) => {
        const data_response = response.data.data.data;
        const updatedData = data_response.map((item: any) => ({
          ...item,
          editAction: handleEdit,
        }));
        setDataBatch(updatedData);
        setPaginationLinks(response.data.data["links"]);
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
      });
  }

  function updateGajiPokok() {
    if (!user?.token || isLoading === true) return;
    setIsLoading(true);

    if (Number(formData.id) === 0) return;
    const axiosUpdateGajiPokok = new AxiosUpdateGajiPokok();
    axiosUpdateGajiPokok
      .post(user.token, {
        id_gaji_pokok: Number(formData.id),
        gaji_pokok: Number(formData.gaji_pokok),
        tarif_harian: Number(formData.tarif_harian),
        premi_hadir: Number(formData.premi_hadir),
        tunjangan_jabatan: Number(formData.tunjangan_jabatan),
      })
      .then(() => {
        toast.success("Gaji pokok berhasil diubah!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsPopUp(false);
        setFormData({
          id: "",
          nama: "",
          gaji_pokok: "",
          tarif_harian: "",
          premi_hadir: "",
          tunjangan_jabatan: "",
        });
        setDataBatch([]);
        getListGajiPokok();
        setIsLoading(false);
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
      });
  }

  const popUpClose = {
    onClose: () => {
      setIsPopUp(false);
    },
  };

  const handleEdit = (item: Item) => {
    setFormData({
      id: item.id.toString(),
      nama: item.nama_pegawai,
      gaji_pokok: item.gaji_pokok.toString(),
      tarif_harian: item.tarif_harian.toString(),
      premi_hadir: item.premi_hadir.toString(),
      tunjangan_jabatan: item.tunjangan_jabatan.toString(),
    });
    setIsPopUp(true);
  };

  useEffect(() => {
    getListGajiPokok();
  }, []);

  function onPaginationChange(link: string) {
    getListGajiPokok(link);
  }

  const [listJabatan, setListJabatan] = useState<TOption[]>([]);
  const [jabatan, setJabatan] = useState("");

  function getJabatan() {
    if (!user?.token) return;
    const axiosGetJabatan = new AxiosGetJabatan();

    axiosGetJabatan.get(user.token).then((response) => {
      const data = response.data.data;
      const options: TOption[] = [];
      data.forEach((value: any, index: any) => {
        options.push({
          value: value.nama,
          label: capitalizeEachWord(value.nama, "_"),
          isDefault: index === 0,
        });
      });
      setListJabatan(options);
    });
  }

  useEffect(() => {
    getJabatan();
  }, []);

  let timer: any;

  const [name, setName] = useState("");

  function onSearch(value: string) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setName(value);
      getListGajiPokok(
        undefined,
        undefined,
        value,
        jabatan ? jabatan : undefined
      );
    }, 500);
  }

  return (
    <>
      <div className="mx-[40px]">
        <div className="flex justify-between mt-[40px] mb-2">
          <ContentHeader
            title="Gaji Pokok"
            className="!p-0"
            additionalHeader={
              <>
                <div className="flex gap-2">
                  <SearchField
                    placeholder="Nama pegawai ..."
                    onChange={(value) => onSearch(value)}
                  />
                  <DropdownField
                    id="jabatan"
                    name="jabatan"
                    className="!border !py-[17px] !px-[31px] !rounded !min-w-[200px]"
                    options={listJabatan}
                    onChange={(value) => {
                      setJabatan(value);
                      getListGajiPokok(
                        undefined,
                        undefined,
                        name ? name : undefined,
                        value
                      );
                    }}
                    placeholder="Filter Jabatan"
                    value={jabatan}
                  />
                </div>
              </>
            }
          />
        </div>
        {isPopUp ? (
          <PopUpCard {...popUpClose} title={"Edit Gaji Pokok"}>
            <InputField
              type="text"
              placeholder="Masukkan nama pegawai"
              title="Nama Pegawai"
              isRequired={true}
              onChange={(value) => setFormData({ ...formData, nama: value })}
              defaultValue={formData.nama}
              value={formData.nama}
              isDisabled
            />

            <InputField
              type="text"
              placeholder="Masukkan gaji pokok pegawai"
              title="Gaji Pokok"
              isRequired={true}
              onChange={(value) =>
                setFormData({ ...formData, gaji_pokok: value })
              }
              value={formData.gaji_pokok}
              defaultValue={formData.gaji_pokok}
            />
            <InputField
              type="text"
              placeholder="Masukkan Tarif Harian"
              title="Tarif Harian"
              isRequired={true}
              onChange={(value) =>
                setFormData({ ...formData, tarif_harian: value })
              }
              value={formData.tarif_harian}
              defaultValue={formData.tarif_harian}
            />
            <InputField
              type="text"
              placeholder="Masukkan Premi Hadir pegawai"
              title="Premi Hadir"
              isRequired={true}
              onChange={(value) =>
                setFormData({ ...formData, premi_hadir: value })
              }
              value={formData.premi_hadir}
              defaultValue={formData.premi_hadir}
            />

            <InputField
              type="text"
              placeholder="Masukkan tunjangan jabatan pegawai"
              title="Tunjangan Jabatan"
              isRequired={true}
              onChange={(value) =>
                setFormData({ ...formData, tunjangan_jabatan: value })
              }
              value={formData.tunjangan_jabatan}
              defaultValue={formData.tunjangan_jabatan}
            />

            <Button
              size="small"
              text={
                isLoading ? (
                  <Loader color="indigo" size="sm" />
                ) : (
                  "Update Gaji Pokok"
                )
              }
              isDisabled={isLoading}
              className="h-[55px]"
              onClick={() => updateGajiPokok()}
            />
          </PopUpCard>
        ) : (
          <> </>
        )}
        <div className="mx-[40px] mt-4">
          <PaginationLinks
            onPaginationClick={(link) => onPaginationChange(link)}
            pagination_links={pagination_links}
            className="!w-full"
          />
        </div>
        <div className={clsx(GajiPokokStyle.table_wrapper, "pb-4 mb-12")}>
          <Table<Item> data={dataBatch} columns={columns} />
        </div>
      </div>
    </>
  );
};
export default GajiPokok;
