import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";
import { IconType } from "react-icons";

export interface SidebarItem {
  title: string;
  path: string;
  whitelist: string[];
}

export interface SidebarGroup {
  title: string;
  path: string;
  icon: IconType;
  iconClosed: IconType;
  iconOpened: IconType;
  subNav: SidebarItem[];
  whitelist: string[];
}

export const SidebarData: SidebarGroup[] = [
  {
    title: "Master",
    path: "/master",
    icon: AiIcons.AiFillSave,
    iconClosed: RiIcons.RiArrowDownSFill,
    iconOpened: RiIcons.RiArrowUpSFill,
    subNav: [
      {
        title: "List Users",
        path: "/master/users",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin"],
      },
      {
        title: "List Departemen",
        path: "/master/departemen",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin"],
      },
      {
        title: "List Jabatan",
        path: "/master/jabatan",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin"],
      },
    ],
    whitelist: ["super_admin", "hrd_kepala", "hrd_admin"],
  },
  {
    title: "Produksi",
    path: "/produksi",
    icon: AiIcons.AiFillHome,
    iconClosed: RiIcons.RiArrowDownSFill,
    iconOpened: RiIcons.RiArrowUpSFill,

    subNav: [
      {
        title: "List SPK",
        path: "/produksi/spk",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin", "produksi_kepala", "produksi_admin"],
        //yang bisa delete spk hrd_kepala accomodated -> "DetailSPK.tsx"
      },
      {
        title: "Riwayat Pegawai",
        path: "/produksi/riwayat_pegawai",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin", "produksi_kepala", "produksi_admin"],
      },
    ],
    whitelist: ["super_admin", "hrd_kepala", "hrd_admin", "produksi_kepala", "produksi_admin"],
  },
  {
    title: "HRD",
    path: "/hrd",
    icon: IoIcons.IoIosPaper,
    iconClosed: RiIcons.RiArrowDownSFill,
    iconOpened: RiIcons.RiArrowUpSFill,

    subNav: [
      {
        title: "List Pegawai",
        path: "/hrd/list_pegawai",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin"],
      },
      {
        title: "PKWT",
        path: "/hrd/pkwt",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin"],
      },
      {
        title: "PKWTT",
        path: "/hrd/kontrak_PKWTT",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin"],
      },
      {
        title: "PKKB",
        path: "/hrd/pkkb",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin"],
      },
      {
        title: "Kontrak Segera Berakhir",
        path: "/hrd/list_kontrak/segeraBerakhir",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin"],
      },
      {
        title: "Missing Pegawai",
        path: "/hrd/missingPegawai",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin"],
      },
    ],
    whitelist: ["super_admin", "hrd_kepala", "hrd_admin"],
  },

  {
    title: "Absensi",
    path: "/absensi",
    icon: IoIcons.IoIosPaper,
    iconClosed: RiIcons.RiArrowDownSFill,
    iconOpened: RiIcons.RiArrowUpSFill,

    subNav: [
      {
        title: "Harian",
        path: "/absensi/harian",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin"],
      },
      {
        title: "Mingguan",
        path: "/absensi/mingguan",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin"],
      },
      {
        title: "Bulanan",
        path: "/absensi/bulanan",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin"],
      },
      {
        title: "Input Absensi",
        path: "/absensi/input",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin"],
      },
      {
        title: "THR",
        path: "/absensi/thr",
        whitelist: ["super_admin", "keuangan_kepala", "keuangan_admin"],
      },
      {
        title: "Kalender Perusahaan",
        path: "/absensi/kalender",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin"],
      },
      {
        title: "Surat Perintah Lembur",
        path: "/absensi/lembur_perintah",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin", "produksi_kepala", "produksi_admin", "gudang_kepala", "gudang_admin"],
        // produksi dan gudang diberi filter sesuai departemen nya ListAllPegawai.tsx 
        // produksi -> hanya input lembur departemen produksi saja serta harus 1 perusahaan ====> diakomodir di (BE)ListSuratPerintahLemburService.php
        // gudang -> bisa input lembur departemen gudang dan departemen sopir serta harus 1 perusahaan ====> diakomodir di (BE)ListSuratPerintahLemburService.php
        //
        // yang bisa delete kepala produksi dan kepala gudang  -> diakomodir di Lembur.tsx dan LemburTableDefinitions.tsx
        // verifikasi hanya HRD saja -> diakomodir di DetailLembur.tsx dan DetailLemburTableDefinitions.tsx
      },
      {
        title: "Lembur Pegawai",
        path: "/absensi/lembur_pegawai",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin"],
      },
      {
        title: "Cuti",
        path: "/absensi/cuti",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin", "keuangan_kepala", "keuangan_admin"],
      },
      {
        title: "Kiriman",
        path: "/absensi/kiriman",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin", "gudang_kepala", "gudang_admin"],
        //yang boleh edit dan delete hrd -> diakomodir di ListKiriman dan ListKirimanTableDefinitions
        //gudang hanya input dan lihat -> diakomodir di ListKiriman dan ListKirimanTableDefinitions
        //gudang hanya melihat setiap perusahaannya saja -> diakomodir di (BE)ListKirimanService.tsx
      },
      {
        title: "Monitoring",
        path: "/absensi/monitoring",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin"],
      },
    ],
    whitelist: ["super_admin", "hrd_kepala", "hrd_admin", "produksi_kepala", "produksi_admin", "gudang_kepala", "gudang_admin", "keuangan_admin", "keuangan_kepala"],
  },
  {
    title: "Payroll",
    path: "/payroll",
    icon: IoIcons.IoIosPaper,
    iconClosed: RiIcons.RiArrowDownSFill,
    iconOpened: RiIcons.RiArrowUpSFill,

    subNav: [
      {
        title: "Slip Gaji",
        path: "/payroll/slip",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin", "keuangan_kepala", "keuangan_admin"],
        // kalau HRD di filter pegawai selain staff
        // kalau keuangan di filter pegawai staff saja
      },
      {
        title: "Laporan",
        path: "/payroll/laporan",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin", "keuangan_kepala", "keuangan_admin"],
        // kalau HRD di filter pegawai selain staff
        // kalau keuangan di filter pegawai staff saja
      },
      {
        title: "Gaji Pokok",
        path: "/payroll/gajipokok",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin", "keuangan_kepala", "keuangan_admin"],
        // kalau HRD di filter pegawai selain staff -> ListGajiPokokService
        // kalau keuangan di filter pegawai staff saja -> ListGajiPokokService
      },
      {
        title: "Insentif",
        path: "/payroll/insentive",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin", "keuangan_kepala", "keuangan_admin"],
        // kalau HRD di filter pegawai selain staff -> ListInsentiveService
        // kalau keuangan di filter pegawai staff saja -> ListInsentiveService

      },
      {
        title: "Biaya Tambahan",
        path: "/payroll/biayatambahan",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin", "keuangan_kepala", "keuangan_admin"],
        // kalau HRD di filter pegawai selain staff -> ListBiayaTambahanService
        // kalau keuangan di filter pegawai staff saja -> ListBiayaTambahanService

      },
      {
        title: "Potongan",
        path: "/payroll/potongan",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin", "keuangan_kepala", "keuangan_admin"],
        // kalau HRD di filter pegawai selain staff -> ListPotonganService
        // kalau keuangan di filter pegawai staff saja -> ListPotonganService

      },
    ],
    whitelist: ["super_admin", "hrd_kepala", "hrd_admin", "keuangan_kepala", "keuangan_admin"],
  },
  {
    title: "Laporan",
    path: "/report",
    icon: IoIcons.IoIosPaper,
    iconClosed: RiIcons.RiArrowDownSFill,
    iconOpened: RiIcons.RiArrowUpSFill,

    subNav: [
      {
        title: "Rekap Izin",
        path: "/report/izin",
        whitelist: ["super_admin", "hrd_kepala", "hrd_admin"],
      },
    ],
    whitelist: ["super_admin", "hrd_kepala", "hrd_admin"],
  },
];
