/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import ContentHeader from "../../../components/ContentHeader";
import KalenderStyle from "./Kalender.module.css";
import Table from "../../../components/Table";
import { columns, Item } from "./KalenderTableDefinitions";
import AxiosGetCalendar from "../../../api/AxiosGetCalendar";
import userAuth from "../../../context/UserAuth";
import PopUpCard from "../../../components/PopUpCard";
import Button from "../../../components/Button";
import InputField from "../../../components/InputField";
import { LiburEnum } from "../../../enums/LiburEnum";
import DropdownField from "../../../components/DropDownField";
import AxiosCreateKalender from "../../../api/AxiosCreateKalender";
import TimeoutPopup, {
  defaultPopUpProps,
  ITimeoutPopupProps,
} from "../../../components/TimeoutPopUp";
import { toast } from "react-toastify";
import clsx from "clsx";
import { Loader } from "@mantine/core";
import AxiosUpdateKalender from "../../../api/AxiosUpdateKalender";
import AxiosDeleteKalender from "../../../api/AxiosDeleteKalender";

interface IKalenderProps { }

const Kalender: React.FC<IKalenderProps> = () => {
  const { user } = useContext(userAuth);
  const [data, setData] = useState<Item[]>([]);
  const [isPopUpCreate, setIsPopUpCreate] = useState(false);
  const [action, setAction] = useState("create");

  //create list
  const [idKalender, setIdKalender] = useState(0);
  const [tanggal, setTanggal] = useState("");
  const [nama, setNama] = useState("");
  const [tipe, setTipe] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [year, setYear] = useState(0);

  const [popUpProps, setPopUpProps] =
    useState<ITimeoutPopupProps>(defaultPopUpProps);

  useEffect(() => {
    listKalender();
  }, []);
  useEffect(() => {
    listKalender();
  }, [year]);

  const popUpClose = {
    onClose: () => setIsPopUpCreate(false),
  };

  function listKalender() {
    if (!user.token) return;
    const axiosGetCalendar = new AxiosGetCalendar();

    let dateWithYear = new Date();
    dateWithYear.setFullYear(year);
    axiosGetCalendar
      .get(user.token, year === 0 ? new Date() : dateWithYear)
      .then((response) => {
        const responseData = response.data.data;
        responseData.map((data: any) => {

          data.onEdit = (id: number) => {
            setIsPopUpCreate(true)
            setAction("edit")
            setIdKalender(id)
            setTanggal(data.tanggal)
            setNama(data.nama)
          }
          data.onDelete = (id: number) => {
            deleteKalender(id)
          }
        })

        setData(responseData);
      });
  }

  function deleteKalender(id: number) {
    if (!user.token || isLoading === true) return;
    setIsLoading(true);

    const create = new AxiosDeleteKalender();
    create
      .post(user.token, { id })
      .then((response) => {
        setIsPopUpCreate(false);
        listKalender();

        const data = response.data;
        if (data.success) {
          toast.success("Kalender berhasil di hapus", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
      });
  }
  function editKalender() {
    if (!user.token || isLoading === true) return;
    setIsLoading(true);

    const create = new AxiosUpdateKalender();
    create
      .post(user.token, { id: idKalender, nama, tanggal, tipe })
      .then((response) => {
        setIsPopUpCreate(false);
        listKalender();

        const data = response.data;
        if (data.success) {
          toast.success("Kalender berhasil di perbarui!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTanggal("");
          setNama("");
          setTipe(LiburEnum.LIBUR);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
      });
  }

  function createKalender() {
    if (!user.token || isLoading === true) return;
    setIsLoading(true);

    const create = new AxiosCreateKalender();
    create
      .post(user.token, { nama, tanggal, tipe })
      .then((response) => {
        setIsPopUpCreate(false);
        listKalender();

        const data = response.data;
        if (data.success) {
          toast.success("Kalender berhasil ditambahkan!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setTanggal("");
          setNama("");
          setTipe(LiburEnum.LIBUR);
          setIsLoading(false);
        }
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
      });
  }

  const formatLabel = (label: string): string => {
    return label
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };

  function tambahKalenderComponent() {
    return <>
      <div className={"flex flex-col gap-2"}>
        <InputField
          title="Tanggal Kegiatan"
          isRequired
          placeholder="Tanggal Kegiatan"
          type="date"
          value={tanggal}
          onChange={(value) => setTanggal(value)}
        />

        <InputField
          title="Nama Kegiatan"
          isRequired
          placeholder="Nama Kegiatan"
          type="text"
          value={nama}
          onChange={(value) => setNama(value)}
        />

        <DropdownField
          name="Jenis Kegiatan"
          id={""}
          placeholder=""
          title="Jenis Kegiatan"
          value={tipe}
          options={[
            {
              value: LiburEnum.LIBUR,
              label: formatLabel(LiburEnum.LIBUR),
            },
            {
              value: LiburEnum.CUTI_BERSAMA,
              label: formatLabel(LiburEnum.CUTI_BERSAMA),
            },
            {
              value: LiburEnum.SETENGAH_HARI,
              label: formatLabel(LiburEnum.SETENGAH_HARI),
            },
          ]}
          onChange={(value) => setTipe(value)}
          className="!border !py-[17px] !px-[31px] !rounded"
        />

        <Button
          size="small"
          text={
            isLoading ? (
              <Loader color="indigo" size="sm" />
            ) : (
              action === "create" ? "Tambah Kalender" : "Edit Kalender"
            )
          }
          isDisabled={isLoading}
          className="h-[55px]"
          onClick={() => {
            if (action === "create")
              createKalender()
            if (action === "edit")
              editKalender()
          }}
        />
      </div>
    </>
  }

  return (
    <>
      <ContentHeader
        title={"Kalender Perusahaan"}
        additionalHeader={
          <>
            {" "}
            <div className={"flex gap-2"}>
              <span className="flex flex-col justify-center font-bold !min-w-max">
                Periode Tahun
              </span>
              <InputField
                type="number"
                defaultValue={new Date().getFullYear()}
                min={2000}
                max={2099}
                onChange={(value) => {
                  const numberValue = parseInt(value, 10);
                  if (!isNaN(numberValue)) {
                    setYear(numberValue);
                  }
                }}
                placeholder=""
                className="min-w-[200px]"
                value={year.toString()}
              />
              <Button
                text="Tambah Kalender"
                onClick={() => {
                  setIsPopUpCreate(true)
                  setAction("create")
                  setIdKalender(0)
                  setTanggal("")
                  setNama("")
                }}
                size="small"
                className=""
              />
            </div>
          </>
        }
      />
      <TimeoutPopup {...popUpProps} />
      {isPopUpCreate ? (
        <PopUpCard {...popUpClose} title=
          {(action === "create" ? "Tambah Kalender" : "Edit Kalender")}
        >
          {tambahKalenderComponent()}
        </PopUpCard>
      ) : null}

      <div className={clsx(KalenderStyle.table_wrapper, "!pb-4")}>
        <Table<Item> data={data} columns={columns} />
      </div>
    </>
  );
};

export default Kalender;
