import { createContext, Dispatch, SetStateAction } from 'react'

export type User = {
    token?: string,
    name?: string,
    role?: string,
}

interface UserAuthContextType {
    user: User,
    setUser?: Dispatch<SetStateAction<User>>
}
const userAuth = createContext<UserAuthContextType>({
    user: {
        token: undefined,
        name: undefined
    },
    setUser: undefined
})

export default userAuth;
