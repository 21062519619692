/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AxiosListBatch from "../../../api/AxiosListBatchGaji";
import ContentHeader from "../../../components/ContentHeader";
import InputField from "../../../components/InputField";
import Table from "../../../components/Table";
import userAuth from "../../../context/UserAuth";
import { columns, Item } from "./LaporanSlipTableDefinitions";
import clsx from "clsx";
import LaporanSlipGajiStyle from "./LaporanSlipGaji.module.css";
import { toast } from "react-toastify";
import ConfirmationPopUpCard from "../../../components/ConfirmationPopUpCard";
import AxiosDeleteLaporanSlipGaji from "../../../api/AxiosDeleteLaporanSlipGaji";
import GeneralContext from "../../../context/generalContext";

interface ILaporanSlipGajiProps { }
const LaporanSlipGaji: React.FC<ILaporanSlipGajiProps> = () => {
  const { user } = useContext(userAuth);
  const { generalContext, setGeneralContext } = useContext(GeneralContext);
  const [dataBatch, setDataBatch] = useState<Item[]>([]);
  const [waktuMulai, setWaktuMulai] = useState("");
  const [waktuSelesai, setWaktuSelesai] = useState("");
  const [isPeringatan, setIsPeringatan] = useState(false);
  const [deleteLaporanSlipGajiMulai, setDeleteLaporanSlipGajiMulai] = useState<
    string | null
  >(null);
  const [deleteLaporanSlipGajiSelesai, setDeleteLaporanSlipGajiSelesai] =
    useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  function listBatch() {
    if (!user.token) return;
    const axiosListBatch = new AxiosListBatch();

    axiosListBatch
      .get(user.token, {
        waktu_mulai: waktuMulai,
        waktu_selesai: waktuSelesai,
      })
      .then((response) => {
        const data = response.data.data;
        data.map((batch: any) => {
          batch.periode = batch.waktu_mulai + " s/d " + batch.waktu_selesai;
          batch.action = () => {
            navigate("/dashboard/payroll/detail/" + batch.id, {
              state: { batch: JSON.parse(JSON.stringify(batch)) },
            });
          };
          batch.deleteAction = () => {
            showDeleteConfirmation(batch.waktu_mulai, batch.waktu_selesai);
          };
          return batch;
        });
        setDataBatch(data);
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
      });
  }

  function deleteLaporanSlipGaji(waktu_mulai: string, waktu_selesai: string) {
    if (!user.token || isLoading === true) return;
    setIsLoading(true);

    const deleteLaporanSlipGaji = new AxiosDeleteLaporanSlipGaji();
    deleteLaporanSlipGaji
      .post(user.token, {
        waktu_mulai: waktu_mulai,
        waktu_selesai: waktu_selesai,
      })
      .then((response) => {
        if (waktuMulai && waktuSelesai) listBatch();

        const data = response.data;
        if (data.success) {
          toast.success("Laporan slip gaji berhasil dihapus!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setIsLoading(false);
        }
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
      });
  }

  useEffect(() => {
    setWaktuMulai(generalContext?.waktu_mulai ?? "")
    setWaktuSelesai(generalContext?.waktu_selesai ?? "")
  }, []);

  useEffect(() => {
    setGeneralContext?.({
      waktu_mulai: waktuMulai,
      waktu_selesai: waktuSelesai,
    })
    if (!waktuMulai || !waktuSelesai) return;
    listBatch();
    // listGaji();
  }, [waktuMulai, waktuSelesai]);

  const confirmDeleteLaporanSlipGaji = () => {
    if (deleteLaporanSlipGajiMulai && deleteLaporanSlipGajiSelesai) {
      deleteLaporanSlipGaji(
        deleteLaporanSlipGajiMulai,
        deleteLaporanSlipGajiSelesai
      );
      setIsPeringatan(false);
    }
  };

  const showDeleteConfirmation = (
    waktu_mulai: string,
    waktu_selesai: string
  ) => {
    setDeleteLaporanSlipGajiMulai(waktu_mulai);
    setDeleteLaporanSlipGajiSelesai(waktu_selesai);
    setIsPeringatan(true);
  };

  return (
    <>
      <div className="mx-[40px]">
        <div className="flex justify-between mt-[40px] mb-2">
          <ContentHeader title="Laporan Slip Gaji" className="!p-0" />
          <div className="flex gap-2">
            <span className="flex flex-col justify-center font-bold">
              Periode
            </span>

            <InputField
              type="date"
              placeholder=""
              onChange={(value) => setWaktuMulai(value)}
              value={waktuMulai}
            />
            <span className="flex flex-col justify-center">s/d</span>
            <InputField
              type="date"
              placeholder=""
              onChange={(value) => setWaktuSelesai(value)}
              value={waktuSelesai}
            />
          </div>
        </div>

        {isPeringatan && (
          <ConfirmationPopUpCard
            title={"Peringatan"}
            text="Apakah Anda yakin ingin menghapus data laporan slip gaji ini?"
            onClose={() => setIsPeringatan(false)}
            onRightButtonClick={confirmDeleteLaporanSlipGaji}
            rightButtonText="Hapus"
          />
        )}

        <div className={clsx(LaporanSlipGajiStyle.table_wrapper, "pb-4")}>
          <Table<Item> data={dataBatch} columns={columns} />
        </div>
        <div className="text-red-500 my-2 text-left">
          *Wajib memasukkan periode!
        </div>
      </div>
    </>
  );
};
export default LaporanSlipGaji;
