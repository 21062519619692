import React, { useContext } from "react";
import LogoTMJM from "../images/logoTMJM.png";
import Sidebar from "./Sidebar";
import DashboardStyle from "./dashboard.module.css";
import clsx from "clsx";
import { BiSolidLogOut } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import userAuth from "../context/UserAuth";
interface IPanelProps { }

const Panel: React.FC<IPanelProps> = (props: IPanelProps) => {
  const navigate = useNavigate();
  const { user } = useContext(userAuth);

  return (
    <div
      className={clsx(
        DashboardStyle.dashboard_panel + " " + DashboardStyle.hide_scroll,
        "py-[40px] flex flex-col justify-between w-80"
      )}
    >
      <div>
        <div className={clsx(DashboardStyle.dashboard_header)}>
          <img src={LogoTMJM} className={DashboardStyle.logo} alt="Logo TMJM" />
          <div style={{ textAlign: "left" }}>
            <div className={DashboardStyle.dashboard_name}>{user.name}</div>
            <div className={DashboardStyle.dashboard_rank}>{user.role?.split('_').reverse().join(' ')}</div>
          </div>
        </div>

        <hr className="mx-5 mt-6 mb-4" />
        <Sidebar />
      </div>

      <div>
        <hr className="mx-5 mt-6 mb-4" />

        <div
          className="flex gap-2 mx-[20px] text-[#79818e] hover:cursor-pointer"
          onClick={() => {
            navigate("/");
            window.location.reload();
          }}
        >
          <div className="flex flex-col justify-center">
            <BiSolidLogOut className="w-[18px] h-[18px]" />
          </div>
          Keluar
        </div>
      </div>
    </div>
  );
};

export default Panel;
