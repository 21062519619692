import ApiRequest from "./ApiRequest";

interface IInsertQueueGajiParams {
    ids_pegawai: number[],
    waktu_mulai: string,
    waktu_selesai: string,
    tanggal_pembayaran_gaji: string,
    tipe_gaji: string,
}

class AxiosInsertQueueGaji extends ApiRequest<IInsertQueueGajiParams> {
    constructor() {
        super("/payroll/insert_queue");
    }

    post(token: string, gaji_data: IInsertQueueGajiParams) {
        return this.post_request({
            token: token,
            params: { ...gaji_data }
        });
    }
}

export default AxiosInsertQueueGaji;
