import { createColumnHelper } from "@tanstack/react-table";
import { NavigateFunction } from "react-router-dom";
import { calculateAge } from "../../../helpers/usiaHelper";
import Button from "../../../components/Button";
import { Pegawai } from "../../../context/SelectedPegawai";
import { capitalizeFirst } from "../../../helpers/StringHelper";

export type Item = {
  no: number;
  nip: number;
  nama: string;
  usia: number;
  jabatan: string;
  tanggal: string;
  status: string;
  detail: string;
  action_id: number;
  action: () => void;
};
type PegawaiResponse = {
  id: number;
  nip: number;
  nama: string;
  tgl_lahir: string;
  privilege: string; //harus diberi jabatan
  tgl_mulai_kerja: string;
  status: string;
};

const columnHelper = createColumnHelper<Item>();
export const columns = [
  columnHelper.accessor("no", {
    header: () => "No.",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor((row) => row.nip, {
    id: "nip",
    cell: (info) => <>{info.getValue()}</>,
    header: () => <span>NIP</span>,
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor((row) => row.nama, {
    id: "nama",
    cell: (info) => <>{info.getValue()}</>,
    header: () => <span>Nama</span>,
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor("usia", {
    header: () => "Usia",
    cell: (info) => info.renderValue(),
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor("jabatan", {
    header: () => <span>Jabatan</span>,
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor("tanggal", {
    header: "Tanggal Bergabung",
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor("status", {
    header: "Status",
    cell: (props) => {
      return (
        <p style={{ color: props.row.original.status === "Aktif" ? "green" : "red" }}>
          {props.row.original.status}
        </p>
      );
    },
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor("action_id", {
    header: "Action",
    cell: (inf) => (
      <Button
        size="small"
        text="Detail"
        onClick={() => {
          inf.row.original.action();
        }}
      />
    ),
    footer: (info) => info.column.id,
  }),
];

export function constructItemFromResponse(
  data_response: PegawaiResponse[],
  navigate: NavigateFunction,
  setPegawai: React.Dispatch<Pegawai | undefined>
) {
  const newItems: Item[] = [];
  data_response.forEach((data: PegawaiResponse, key: number) => {
    const item: Item = {
      no: key + 1,
      nip: data.nip,
      nama: data.nama,
      usia: data.tgl_lahir == undefined ? 0 : calculateAge(data.tgl_lahir),
      jabatan: capitalizeFirst("pegawai"),
      tanggal: data.tgl_mulai_kerja,
      status: data.status,
      detail: "TEST",
      action_id: data.id,
      action: () => {
        setPegawai({
          id: data.id.toString(),
          name: data.nama,
        });
        navigate("/dashboard/detail/pegawai");
      },
    };
    newItems.push(item);
  });
  return newItems;
}
