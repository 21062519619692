import { createColumnHelper } from "@tanstack/react-table";
import Button from "../../../components/Button";
import { ButtonTypeEnum } from "../../../enums/ButtonTypeEnum";
import { capitalizeEachWord } from "../../../helpers/StringHelper";

export type Item = {
    id: number;
    nama: string;
    jumlah: number;
    onEdit: (id_jatah_cuti: number, nama: string) => void;
    onDelete: (id_jatah_cuti: number) => void;
};

const columnHelper = createColumnHelper<Item>();
export const columns = [
    columnHelper.accessor((row) => row.id, {
        id: "id",
        cell: (info) => <>{info.getValue()}</>,
        header: () => <span>ID</span>,
        footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.nama, {
        id: "tipe",
        cell: (info) => <>{capitalizeEachWord(info.getValue(), "_")}</>,
        header: () => <span>Nama</span>,
        footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row.jumlah, {
        id: "alasan",
        cell: (info) => <>{info.getValue()}</>,
        header: () => <span>Jumlah</span>,
        footer: (info) => info.column.id,
    }),
    columnHelper.accessor((row) => row, {
        id: "action",
        cell: (info) => <>
            <div className="flex flex-col gap-2 w-40">
                <Button
                    onClick={() => info.row.original.onEdit(
                        info.row.original.id,
                        info.row.original.nama,
                    )}
                    text="Edit"
                    size="lite"
                />
                <Button
                    onClick={() => info.row.original.onDelete(info.row.original.id)}
                    text="Delete"
                    size="lite"
                    color="danger"
                    buttonType={ButtonTypeEnum.CLICKABLE_TEXT}
                />
            </div>
        </>,
        header: () => <span>Action</span>,
        footer: (info) => info.column.id,
    }),
];
