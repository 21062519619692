import { createColumnHelper } from "@tanstack/react-table";
import Button from "../../../components/Button";

export type Item = {
  id: number;
  nama_pegawai: string;
  pin_finpro: string;
  action_id: number;
  action: (id_finpro: string, nama: string) => void;
};

const columnHelper = createColumnHelper<Item>();
export const columns = [
  columnHelper.accessor("id", {
    header: () => "No",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor((row) => row.nama_pegawai, {
    id: "nama_pegawai",
    cell: (info) => <>{info.getValue()}</>,
    header: () => <span>Nama Pegawai</span>,
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor((row) => row.pin_finpro, {
    id: "pin_finpro",
    cell: (info) => <>{info.getValue()}</>,
    header: () => <span>Pin Finpro</span>,
    footer: (info) => info.column.id,
  }),

  columnHelper.accessor("action_id", {
    header: "Action",
    cell: (inf) => (
      <Button
        size="small"
        text="Tambahkan Pegawai"
        onClick={() => {
          inf.row.original.action(inf.row.original.pin_finpro, inf.row.original.nama_pegawai);
        }}
      />
    ),
    footer: (info) => info.column.id,
  }),
];
