import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ContentHeader from "../../../components/ContentHeader";
import Table from "../../../components/Table";
import dashboardLoading from "../../../context/dashboardLoading";
import userAuth from "../../../context/UserAuth";
import ListPegawaiStyle from "./Monitoring.module.css";
import {
  columns,
  constructItemFromResponse,
  Item,
} from "./MonitoringTable";
import pegawaiContext from "../../../context/SelectedPegawai";
import PaginationLinks from "../../../components/PaginationLinks";
import clsx from "clsx";
import { toast } from "react-toastify";
import AxiosListCreatePegawaiOptions from "../../../api/AxiosGetCreatePegawaiOptions";
import { capitalizeEachWord } from "../../../helpers/StringHelper";
import DropdownField from "../../../components/DropDownField";
import AxiosGetMonitoringAttendance from "../../../api/AxiosGetMonitoringAttendance";
import InputField from "../../../components/InputField";
import { dateToString } from "../../../helpers/DateHelper";
import AxiosGetAttendanceStatus from "../../../api/AxiosGetAttendanceStatus";

interface IMonitoringProps {}

const Monitoring: React.FC<IMonitoringProps> = (props: IMonitoringProps) => {
  const defaultData: Item[] = [];
  const { setIsLoading } = useContext(dashboardLoading);
  const { user } = useContext(userAuth);
  const [data, setData] = useState(() => [...defaultData]);
  const { setPegawai } = useContext(pegawaiContext);
  const [pagination_links, setPaginationLinks] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [tanggalQuery, setTanggalQuery] = useState(dateToString(new Date()));

  //filter
  const [selectedJabatan, setSelectedJabatan] = useState("");
  const [selectedPerusahaanId, setSelectedPerusahaanId] = useState("");
  const [selectedDepartemenId, setSelectedDepartemenId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("")

  const [jabatanOptions, setJabatanOptions] = useState([]);
  const [perusahaanOptions, setPerusahaanOptions] = useState([]);
  const [departemenOptions, setDepartemenOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);

  let timer: any;

  useEffect(() => {
    
    getMonitoringAttendance(undefined, searchQuery, tanggalQuery);
  }, [
    tanggalQuery,
    selectedJabatan,
    selectedDepartemenId,
    selectedPerusahaanId,
    selectedStatus
  ]);

  useEffect(() => {
    getOptions();
    getAttendanceStatus();
  }, []);

  function onPaginationChange(link: string) {
    getMonitoringAttendance(link, searchQuery, tanggalQuery);
  }

  function onSearch(value: string) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearchQuery(value);
      getMonitoringAttendance(undefined, value, tanggalQuery);
    }, 500);
  }

  function getMonitoringAttendance(pagination_link?: string, query?: string, date?: string) {
    if (user.token == null || !setPegawai) return;
    const axiosListPegawai = new AxiosGetMonitoringAttendance({
      id_perusahaan: selectedPerusahaanId,
      id_jabatan: selectedJabatan,
      id_departemen: selectedDepartemenId,
      status: selectedStatus,
    });

    axiosListPegawai
      .get(user.token, pagination_link, query, date)
      .then((response) => {
        const data_response = response.data.data;
        
        setPaginationLinks(response.data["links"]);
        setData(constructItemFromResponse(data_response));
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
      });
  }

  function getOptions() {
    if (!user?.token) return;
    const axiosGetOptions = new AxiosListCreatePegawaiOptions();

    axiosGetOptions.get(user.token).then((response) => {
      const data = response.data.data;

      const jabatanData = data.jabatan_options.map((item: any) => ({
        value: item.id,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      setJabatanOptions(jabatanData);

      const perusahaanData = data.perusahaan_options.map((item: any) => ({
        value: item.id,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      setPerusahaanOptions(perusahaanData);

      const departemenData = data.departemen_options.map((item: any) => ({
        value: item.id,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      setDepartemenOptions(departemenData);
    });
  }

  function getAttendanceStatus() {
    if (!user?.token) return;
    const axiosGetAttendanceStatus = new AxiosGetAttendanceStatus();

    axiosGetAttendanceStatus.get(user.token).then((response) => {
      const data = response.data.data;

      const statuses = data.statuses.map((item: any) => ({
        value: item.id,
        label: item.description
      }))
      setStatusOptions(statuses)
    })
  }


    return (
        <>
            <ContentHeader
                title={"Daftar Hadir "}
                additionalHeader={
                    <>
                        <div className={ListPegawaiStyle.header_container}>
                            <InputField
                                type="text"
                                placeholder="Nama Pegawai..."
                                onChange={(value) => {onSearch(value);}}
                                className=""
                            />

                            <InputField
                                type="date"
                                placeholder="tgl"
                                onChange={(value) => setTanggalQuery(value)}
                                value={tanggalQuery}
                                className=""
                            />
                            
                        </div>
                    </>
                }
            />

        
            <div className="flex flex-col py-[10px]">
                <div className="mx-[30px] mt-4 flex">
                    <div className="mx-[20px] mt-4 mb-4">
                        <DropdownField
                            name="nama_perusahaan"
                            id="nama_perusahaan"
                            title="Filter Perusahaan"
                            options={perusahaanOptions}
                            onChange={(value) => setSelectedPerusahaanId(value)}
                            value={selectedPerusahaanId.toString()}
                            placeholder=""
                        />
                    </div>
                    <div className="mx-[20px] mt-4 mb-4">
                        <DropdownField
                            name="nama_departemen"
                            id="nama_departemen"
                            title="Filter departemen"
                            options={departemenOptions}
                            onChange={(value) => setSelectedDepartemenId(value)}
                            value={selectedDepartemenId.toString()}
                            placeholder=""
                        />
                        <div className="w-full flex justify-end">
                            <span
                                className="hover:cursor-pointer hover:underline text-blue-500"
                                onClick={() => setSelectedDepartemenId("")}
                            >
                                Clear filter jabatan
                            </span>
                        </div>
                    </div>
                    <div className="mx-[20px] mt-4 mb-4">
                        <DropdownField
                            name="nama_jabatan"
                            id="nama_jabatan"
                            title="Filter Jabatan"
                            options={jabatanOptions}
                            onChange={(value) => setSelectedJabatan(value)}
                            value={selectedJabatan}
                            placeholder=""
                        />
                        <div className="w-full flex justify-end">
                            <span
                                className="hover:cursor-pointer hover:underline text-blue-500"
                                onClick={() => setSelectedJabatan("")}
                            >
                                Clear filter jabatan
                            </span>
                        </div>
                    </div>
                    <div className="mx-[20px] mt-4 mb-4">
                        <DropdownField
                            name="status"
                            id="status"
                            title="Filter Status"
                            options={statusOptions}
                            onChange={(value) => setSelectedStatus(value)}
                            value={selectedStatus}
                            placeholder=""
                        />
                        <div className="w-full flex justify-end">
                            <span
                                className="hover:cursor-pointer hover:underline text-blue-500"
                                onClick={() => setSelectedStatus("")}
                            >
                                Clear filter status
                            </span>
                        </div>
                    </div>
                    
                </div>

                <div
                    className={clsx(ListPegawaiStyle.table_wrapper, "pb-4 !pt-0 !mt-0")}
                    >
                    <Table<Item> data={data} columns={columns} />
                </div>

                <PaginationLinks
                    onPaginationClick={(link) => onPaginationChange(link)}
                    pagination_links={pagination_links}
                    className="!w-full"
                />
            </div>
        </>
    );
};

export default Monitoring;
