/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import AxiosCreateJabatan from "../../api/AxiosCreateJabatan";
import AxiosGetJabatan from "../../api/AxiosGetJabatan";
import AxiosUpdateJabatan from "../../api/AxiosUpdateJabatan";
import Button from "../../components/Button";
import ContentHeader from "../../components/ContentHeader";
import InputField from "../../components/InputField";
import PopUpCard from "../../components/PopUpCard";
import Table from "../../components/Table";
import TimeoutPopup, {
    defaultPopUpProps,
    ITimeoutPopupProps,
} from "../../components/TimeoutPopUp";
import userAuth from "../../context/UserAuth";
import { columns, Item } from "./JabatanDepartemenTableDefinitions";
import ListJabatanStyle from "./ListJabatan.module.css";
import clsx from "clsx";
import { toast } from "react-toastify";
import { Loader } from "@mantine/core";
import DropdownField from "../../components/DropDownField";
import TOption from "../../models/TOption";
import AxiosGetSkemaGaji from "../../api/AxiosGetSkemaGaji";
import { capitalizeEachWord } from "../../helpers/StringHelper";

interface IListJabatanProps { }
const ListJabatan: React.FC<IListJabatanProps> = () => {
    const { user } = useContext(userAuth);
    const [listJabatan, setListJabatan] = useState([]);
    const [skemaGajiOptions, setSkemaGajiOptions] = useState<TOption[]>([]);
    const [skemaGaji, setSkemaGaji] = useState(1);
    const [isPopUp, setIsPopUp] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(0);
    const [newName, setNewName] = useState("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [popUpState, setPopUpState] =
        useState<ITimeoutPopupProps>(defaultPopUpProps);

    function getSkemaGaji() {
        if (!user?.token) return;

        const axiosGetJabatan = new AxiosGetSkemaGaji();

        axiosGetJabatan.get(user.token).then((response) => {
            const data = response.data.data;
            const newData = data.map((option: any) => {
                return {
                    value: option.id,
                    label: capitalizeEachWord(option.nama, "_")
                };
            });
            setSkemaGajiOptions(newData);
        });
    }
    function getJabatan() {
        if (!user?.token) return;

        const axiosGetJabatan = new AxiosGetJabatan();

        axiosGetJabatan.get(user.token).then((response) => {
            const data = response.data.data;
            const newData = data.map((item: Item) => {
                return {
                    ...item,
                    action: () => {
                        setIsPopUp(true);
                        setIsEditing(true);
                        setNewName(item.nama);
                        setEditId(item.id);
                    },
                };
            });
            setListJabatan(newData);
        });
    }

    function createJabatan() {
        if (!user?.token || isLoading === true) return;
        setIsLoading(true);

        if (skemaGaji === 0)
            toast.error("Harus Memilih Skema Gaji", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        const axiosCreateJabatan = new AxiosCreateJabatan();
        axiosCreateJabatan
            .post(user.token, { nama: newName, id_skema_gaji: skemaGaji})
            .then(() => {
                toast.success("Jabatan berhasil ditambahkan!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                // setPopUpState({
                //   ...popUpState,
                //   popupType: PopUpEnum.SUCCESS,
                //   message: "Jabatan Berhasil Ditambahkan",
                //   timeout: 1000,
                //   isEnabled: true,
                //   onTimeout() {
                //     setPopUpState({ ...popUpState, isEnabled: false });
                //   },
                // });
                setIsPopUp(false);
                setNewName("");
                setListJabatan([]);
                getJabatan();
                setIsLoading(false);
            })
            .catch((e) => {
                const response = e.response;
                if (response.status === 422)
                    toast.error(`${response.data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                setIsLoading(false);
            });
    }

    function updateJabatan() {
        if (!user?.token || isLoading === true) return;
        setIsLoading(true);

        if (editId === 0) return;
        if (skemaGaji === 0)
            toast.error("Harus Memilih Skema Gaji", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        const axiosUpdateJabatan = new AxiosUpdateJabatan();
        axiosUpdateJabatan
            .post(user.token, {
                id: editId,
                nama: newName,
                id_skema_gaji: skemaGaji,
            })
            .then(() => {
                toast.success("Jabatan berhasil diubah!", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                // setPopUpState({
                //   ...popUpState,
                //   popupType: PopUpEnum.SUCCESS,
                //   message: "Jabatan Berhasil Di Rubah",
                //   timeout: 1000,
                //   isEnabled: true,
                //   onTimeout() {
                //     setPopUpState({ ...popUpState, isEnabled: false });
                //   },
                // });
                setIsPopUp(false);
                setNewName("");
                setEditId(0);
                setListJabatan([]);
                getJabatan();
                setIsLoading(false);
                setSkemaGaji(0)
            })
            .catch((e) => {
                const response = e.response;
                if (response.status === 422)
                    toast.error(`${response.data.message}`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                setIsLoading(false);
            });
    }

    useEffect(() => {
        getJabatan();
        getSkemaGaji();
    }, []);

    const popUpClose = {
        onClose: () => {
            setIsPopUp(false);
            setIsEditing(false);
            setEditId(0);
            setNewName("");
        },
    };

    return (
        <>
            <div className={ListJabatanStyle.header_wrapper}>
                <ContentHeader
                    title={"List Jabatan"}
                    additionalHeader={
                        <>
                            <div className={ListJabatanStyle.header_container}>
                                {/* <SearchField
                  placeholder="Cari no.spk ..."
                  onChange={(value) => onSearch(value)}
                /> */}
                                <Button
                                    text="Tambah Jabatan"
                                    onClick={() => setIsPopUp(true)}
                                    size="small"
                                />
                            </div>
                        </>
                    }
                />
            </div>
            <TimeoutPopup {...popUpState} />

            {isPopUp ? (
                <PopUpCard
                    {...popUpClose}
                    title={isEditing ? "Edit " : "Tambah Jabatan"}
                >
                    <InputField
                        title="Nama Jabatan"
                        type="text"
                        placeholder="Nama Jabatan"
                        onChange={(value) => setNewName(value)}
                        defaultValue={newName}
                        value={newName}
                        className="w-full"
                    />
                    <DropdownField
                        title="Skema Gaji"
                        name="skema gaji"
                        id="skema_gaji"
                        options={skemaGajiOptions}
                        onChange={(value) => {
                            setSkemaGaji(parseInt(value))
                            
                        }}
                        value={skemaGaji.toString()}
                    />
                    {isEditing ? (
                        <Button
                            size="small"
                            text={
                                isLoading ? (
                                    <>
                                        <Loader color="indigo" size="sm" />
                                    </>
                                ) : (
                                    "Simpan Perubahan"
                                )
                            }
                            className="h-[55px]"
                            onClick={() => updateJabatan()}
                            isDisabled={isLoading}
                        />
                    ) : (
                        <Button
                            size="small"
                            text={
                                isLoading ? (
                                    <>
                                        <Loader color="indigo" size="sm" />
                                    </>
                                ) : (
                                    "Tambahkan"
                                )
                            }
                            className="h-[55px]"
                            onClick={() => createJabatan()}
                            isDisabled={isLoading}
                        />
                    )}
                </PopUpCard>
            ) : (
                <> </>
            )}
            <div className={clsx(ListJabatanStyle.table_wrapper, "pb-4")}>
                <Table<Item> data={listJabatan} columns={columns} />
            </div>
        </>
    );
};
export default ListJabatan;
