import ApiRequest from "./ApiRequest";

interface IParamType {
  tanggal_mulai: string;
  tanggal_selesai: string;
}

class AxiosGetLembur extends ApiRequest<IParamType> {
  constructor() {
    super(`/lembur/list`);
  }

  public get(token: string, batch_param: IParamType) {
    return this.get_request(token, {
      ...batch_param,
    });
  }
}

export default AxiosGetLembur;
