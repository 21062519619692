import { TFormPegawai } from "../models/TFormPegawai";
import ApiRequest from "./ApiRequest";

export type IUpdatePegawaiParam = {
    id_pegawai: number,
    keluarga_delete_id: number[]
    pendidikan_delete_id: number[]
    kegiatan_organisasi_delete_id: number[]
    pengalaman_kerja_delete_id: number[]
}

class AxiosUpdatePegawai extends ApiRequest<IUpdatePegawaiParam & TFormPegawai> {
    constructor() {
        super("/update_pegawai");
    }

    post(
        token: string,
        data: IUpdatePegawaiParam & TFormPegawai
    ) {
        return this.post_request({
            token: token,
            params: {
                ...data,
                sim: JSON.stringify(data.sim ?? "[]"),
                bahasa_dikuasai: JSON.stringify(data.bahasa_dikuasai ?? "[]"),
                detil_sakit: JSON.stringify(data.detil_sakit ?? "[]"),
                detil_kecelakaan: JSON.stringify(data.detil_kecelakaan ?? "[]"),
                detil_polisi: JSON.stringify(data.detil_polisi ?? "[]"),
                detil_psikologi: JSON.stringify(data.detil_psikologi ?? "[]"),
                detil_militer: JSON.stringify(data.detil_militer ?? "[]"),
                detil_bacaan: JSON.stringify(data.detil_bacaan ?? "[]"),
            }
        });
    }
}

export default AxiosUpdatePegawai;
