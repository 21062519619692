import ApiRequest from "./ApiRequest";

interface IParamType {
  tanggal_mulai: string;
  tanggal_selesai: string;
  id: number;
  id_jabatan?: number;
  id_perusahaan?: number;
  id_departemen?: number;
}

class AxiosGetLemburPegawai extends ApiRequest<IParamType> {
  constructor() {
    super(`/lembur/list_pegawai`);
  }

  public get(token: string, batch_param: IParamType) {
    return this.get_request(token, {
      ...batch_param,
    });
  }
}

export default AxiosGetLemburPegawai;
