import React, { useState } from "react";
import TBacaan from "../../../models/TBacaan";
import ListForm from "../ListForm";

interface IBacaanListFormProps {
    onBacaanListChange: (value: TBacaan[]) => void;
    defaultBacaan: TBacaan[]
}

const BacaanListForm: React.FC<IBacaanListFormProps> = (
    props: IBacaanListFormProps
) => {
    return (
        <>
            <ListForm<TBacaan>
                onListChange={(list) => {
                    props.onBacaanListChange(list)
                }}
                initialList={props.defaultBacaan}
                defaultCreateItem={{
                    tema: "",
                    penulis: "",
                    id: Date.now()
                }}
                listTitle="List Bacaan"
                addButtonText="Tambah List Bacaan"
                listField={[
                    {
                        label: "Tema",
                        key: "tema",
                        type: "text",
                        placeholder: "Tema Bacaan",
                        onChange: () => { }
                    },
                    {
                        label: "Penulis",
                        key: "penulis",
                        type: "text",
                        placeholder: "Nama Penulis",
                        onChange: () => { }
                    },
                ]}
            />
        </>
    );
};

export default BacaanListForm;

