import React from "react";
import TKeluarga from "../../../models/TKeluarga";
import ListForm from "../ListForm";

interface IKeluargaListFormProps {
  onKeluargaListChange: (value: TKeluarga[], deletedId: number[]) => void;
  defaultKeluarga: TKeluarga[];
}

const KeluargaListForm: React.FC<IKeluargaListFormProps> = (
  props: IKeluargaListFormProps
) => {
  return (
    <>
      <ListForm<TKeluarga>
        onListChange={(list, deletedId) => {
          props.onKeluargaListChange(list, deletedId);
        }}
        initialList={props.defaultKeluarga}
        defaultCreateItem={{
          nama: "",
          relasi: "ayah",
          tgl_lahir: "",
          gender: 0,
          is_alive: 1,
          pendidikan: "sd",
        }}
        listTitle="Detail Keluarga"
        addButtonText="Tambah Detail Keluarga"
        listField={[
          {
            label: "Nama",
            key: "nama",
            type: "text",
            placeholder: "Nama",
            onChange: () => { },
          },
          {
            label: "Relasi",
            key: "relasi",
            type: "option",
            placeholder: "",
            onChange() { },
            options: [
              {
                label: "Ayah",
                value: "ayah",
              },
              {
                label: "Ibu",
                value: "ibu",
              },
              {
                label: "Anak",
                value: "anak",
              },
              {
                label: "Istri",
                value: "istri",
              },
              {
                label: "Suami",
                value: "suami",
              },
              {
                label: "Saudara",
                value: "saudara",
              },
            ],
          },
          {
            label: "Tanggal Lahir",
            key: "tgl_lahir",
            type: "date",
            placeholder: "",
            onChange: () => { },
          },
          {
            label: "Jenis Kelamin",
            key: "gender",
            type: "option",
            placeholder: "Jenis Kelamin",
            onChange: () => { },
            options: [
              {
                label: "Pria",
                value: "0",
              },
              {
                label: "Wanita",
                value: "1",
              },
            ],
          },
          {
            label: "Status",
            key: "is_alive",
            type: "option",
            placeholder: "",
            onChange: () => { },
            options: [
              {
                label: "Hidup",
                value: "1",
              },
              {
                label: "Meninggal",
                value: "0",
              },
            ],
          },
          {
            label: "Jenjang Pendidikan",
            key: "pendidikan",
            type: "option",
            placeholder: "",
            onChange: () => { },
            options: [
              {
                label: "SD",
                value: "sd",
              },
              {
                label: "SMP",
                value: "smp",
              },
              {
                label: "SMA",
                value: "sma",
              },
              {
                label: "S1",
                value: "s1",
              },
              {
                label: "S2",
                value: "s2",
              },
              {
                label: "S3",
                value: "s3",
              },
            ],
          },
        ]}
      />
    </>
  );
};

export default KeluargaListForm;
