import ApiRequest from "./ApiRequest";

interface IUploadFileParam {
  file: FormData;
  filename: string;
  id_pegawai: number;
  id_jenis_dokumen: number;
}

class AxiosCreateBorongan extends ApiRequest<IUploadFileParam> {
  constructor() {
    super("/produksi/upload_pekerja_spk_excel");
  }

  post(token: string, file: File) {
    const formData = new FormData();
    formData.append("pekerja_spk", file, file.name);
    
    return this.post_request({
      token: token,
      formData: formData,
    });
  }
}

export default AxiosCreateBorongan;
