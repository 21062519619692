/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import ContentHeader from "../../../components/ContentHeader";
import HasilSlip from "../Hasil/HasilSlip";
import userAuth from "../../../context/UserAuth";
import DetailStyle from "./DetailLaporan.module.css";
import { useLocation } from "react-router-dom";
import AxiosListGaji from "../../../api/AxiosListGaji";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import DropdownField from "../../../components/DropDownField";
import AxiosListCreatePegawaiOptions from "../../../api/AxiosGetCreatePegawaiOptions";
import { capitalizeEachWord } from "../../../helpers/StringHelper";

interface IDetailLaporanProps { }
const DetailLaporan: React.FC<IDetailLaporanProps> = () => {
  const { user } = useContext(userAuth);
  const location = useLocation();
  const [stateParam, setStateParam] = useState<any>("");
  const [data, setData] = useState<any[]>([]);

  //filter
  const [selectedJabatanId, setSelectedJabatanId] = useState(0);
  const [selectedPerusahaanId, setSelectedPerusahaanId] = useState(0);
  const [selectedDepartemenId, setSelectedDepartemenId] = useState(0);

  const [jabatanOptions, setJabatanOptions] = useState([]);
  const [perusahaanOptions, setPerusahaanOptions] = useState([]);
  const [departemenOptions, setDepartemenOptions] = useState([]);

  function getOptions() {
    if (!user?.token) return;
    const axiosGetOptions = new AxiosListCreatePegawaiOptions();

    axiosGetOptions.get(user.token).then((response) => {
      const data = response.data.data;

      const jabatanData = data.jabatan_options.map((item: any) => ({
        value: item.id,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      setJabatanOptions(jabatanData);

      const perusahaanData = data.perusahaan_options.map((item: any) => ({
        value: item.id,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      setPerusahaanOptions(perusahaanData);

      const departemenData = data.departemen_options.map((item: any) => ({
        value: item.id,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      setDepartemenOptions(departemenData);
    });

  }

  useEffect(() => {
    if (location) {
      setStateParam(location.state.batch);
    }

    getOptions();
  }, []);

  useEffect(() => listGaji(), [
    selectedDepartemenId,
    selectedPerusahaanId,
    selectedJabatanId,
  ]);

  useEffect(() => {
    if (stateParam) {
      listGaji();
    }
  }, [stateParam]);

  function listGaji() {
    if (!user.token) return;
    const axiosListGaji = new AxiosListGaji();

    axiosListGaji
      .get(user.token, {
        waktu_mulai: stateParam.waktu_mulai,
        waktu_selesai: stateParam.waktu_selesai,
        id_jabatan: selectedJabatanId,
        id_perusahaan: selectedPerusahaanId,
        id_departemen: selectedDepartemenId,
      })
      .then((response) => {
        const data = response.data.data;
        setData(data);
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
      });
  }

  return (
    <>
      <ContentHeader title="Laporan Slip Gaji - Hasil" enableBack />

      <div className={DetailStyle.step_list}>
        <div className={DetailStyle.step_item}>1. Setup</div>
        <div className={DetailStyle.step_item}>2. List Pegawai</div>
        <div
          className={
            DetailStyle.step_item + " " + DetailStyle.current_step_item
          }
        >
          3. Hasil
        </div>
      </div>
      <hr className="my-4 mx-[40px]" />

      <div className="mx-[20px] mt-4 mb-4">
        <DropdownField
          name="nama_jabatan"
          id="nama_jabatan"
          title="Filter Berdasarkan Jabatan"
          options={jabatanOptions}
          onChange={(value) => setSelectedJabatanId(parseInt(value))}
          value={selectedJabatanId.toString()}
          placeholder=""
        />
        <div className="w-full flex justify-end">
          <span
            className="hover:cursor-pointer hover:underline text-blue-500"
            onClick={() => setSelectedJabatanId(0)}
          >
            Clear filter jabatan
          </span>
        </div>
      </div>
      <div className="mx-[20px] mt-4 mb-4">
        <DropdownField
          name="nama_perusahaan"
          id="nama_perusahaan"
          title="Filter Berdasarkan Perusahaan"
          options={perusahaanOptions}
          onChange={(value) => setSelectedPerusahaanId(parseInt(value))}
          value={selectedPerusahaanId.toString()}
          placeholder=""
        />
      </div>
      <div className="mx-[20px] mt-4 mb-4">
        <DropdownField
          name="nama_departemen"
          id="nama_departemen"
          title="Filter Berdasarkan departemen"
          options={departemenOptions}
          onChange={(value) => setSelectedDepartemenId(parseInt(value))}
          value={selectedDepartemenId.toString()}
          placeholder=""
        />
        <div className="w-full flex justify-end">
          <span
            className="hover:cursor-pointer hover:underline text-blue-500"
            onClick={() => setSelectedDepartemenId(0)}
          >
            Clear filter jabatan
          </span>
        </div>
      </div>

      <div className={clsx(DetailStyle.component_container, "!p-0")}>
        <HasilSlip batch={stateParam} data={data} onDelete={() => {
          listGaji()
        }} />
      </div>
    </>
  );
};
export default DetailLaporan;
