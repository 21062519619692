import React from "react";
import TPengalamanKerja from "../../../models/TPengalamanKerja"; // Import the TPengalamanKerja type
import ListForm from "../ListForm";
import PengalamanKerjaStyle from "./PengalamanKerja.module.css";
import clsx from "clsx";

interface IPengalamanKerjaListFormProps {
  onPengalamanKerjaChange: (
    value: TPengalamanKerja[],
    deletedId: number[]
  ) => void;
  defaultPengalamanKerja: TPengalamanKerja[];
}

const PengalamanKerjaListForm: React.FC<IPengalamanKerjaListFormProps> = (
  props: IPengalamanKerjaListFormProps
) => {
  return (
    <div
      className={clsx(
        PengalamanKerjaStyle.wrapper,
        "w-full flex justify-center"
      )}
    >
      <ListForm<TPengalamanKerja>
        onListChange={(list, deletedId) => {
          props.onPengalamanKerjaChange(list, deletedId);
        }}
        initialList={props.defaultPengalamanKerja}
        defaultCreateItem={{
          waktu_mulai: "",
          waktu_selesai: "",
          nama_perusahaan: "",
          alamat_perusahaan: "",
          jabatan: "",
          gaji: 0,
          tunjangan: "",
          alasan_berhenti: "",
          id: Date.now(),
        }}
        listTitle="Pengalaman Kerja"
        addButtonText="Tambah Pengalaman Kerja"
        listField={[
          {
            label: "Waktu Mulai",
            key: "waktu_mulai",
            type: "date",
            placeholder: "",
            onChange: () => {},
          },
          {
            label: "Waktu Selesai",
            key: "waktu_selesai",
            type: "date",
            placeholder: "",
            onChange: () => {},
          },
          {
            label: "Nama Perusahaan",
            key: "nama_perusahaan",
            type: "text",
            placeholder: "Nama PT",
            onChange: () => {},
          },
          {
            label: "Alamat Perusahaan",
            key: "alamat_perusahaan",
            type: "text",
            placeholder: "Alamat perusahaan",
            onChange: () => {},
          },
          {
            label: "Jabatan",
            key: "jabatan",
            type: "text",
            placeholder: "Jabatan terakhir",
            onChange: () => {},
          },
          {
            label: "Gaji",
            key: "gaji",
            type: "number",
            placeholder: "Gaji terakhir",
            onChange: () => {},
          },
          {
            label: "Tunjangan",
            key: "tunjangan",
            type: "text",
            placeholder: "Tunjangan",
            onChange: () => {},
          },
          {
            label: "Alasan Berhenti",
            key: "alasan_berhenti",
            type: "text",
            placeholder: "Alasan Berhenti",
            onChange: () => {},
          },
        ]}
      />
    </div>
  );
};

export default PengalamanKerjaListForm;
