import InputField from "../../../components/InputField";
import { useContext, useEffect, useState } from "react";
import Table from "../../../components/Table";
import { columns, Item } from "./ListLogPegawaiTableDefinitions";
import userAuth from "../../../context/UserAuth";
import selectedPegawai from "../../../context/SelectedPegawai";
import ContentHeader from "../../../components/ContentHeader";
import AxiosListJatahCuti from "../../../api/AxiosListJatahCuti";
import AxiosListLogPegawai from "../../../api/AxiosListLog";
import { useDisclosure } from "@mantine/hooks";
import { Modal } from "@mantine/core";

interface IListLogProps {
}
const ListLogPegawai: React.FC<IListLogProps> = (props: IListLogProps) => {
    const year = new Date().getFullYear();

    const { user } = useContext(userAuth);
    const { pegawai } = useContext(selectedPegawai);
    const defaultData: Item[] = [];
    const [data, setData] = useState(() => [...defaultData]);

    //filter and table
    const [tanggal_mulai, setTanggalMulai] = useState("")
    const [tanggal_selesai, setTanggalSelesai] = useState("")
    const [diffElements, setDiffElement] = useState([])

    const [opened, { open, close }] = useDisclosure(false);

    function getLogPegawai() {
        if (!user?.token || !pegawai?.id) return
        if (!tanggal_mulai || !tanggal_selesai) return
        const axiosListLogPegawai = new AxiosListLogPegawai();

        axiosListLogPegawai.get(user.token, {
            id_pegawai: pegawai.id,
            tanggal_mulai: tanggal_mulai,
            tanggal_selesai: tanggal_selesai,
        }).then((response) => {
            const data = response.data.data
            data.map((e: any) => e.onDetail = (before: any, after: any) => {
                const before_json = JSON.parse(before)
                const after_json = JSON.parse(after)
                const diffParams: any = [];
                Object.keys(before_json).forEach(key => {
                    if (!after_json.hasOwnProperty(key) || before_json[key] !== after_json[key]) {
                        diffParams.push({
                            key: key,
                            values: [before_json[key], after_json[key]]
                        });
                    }
                });

                const diffElements: any = []
                diffParams.forEach((o: any) => {
                    const first = o.values[0]
                    const second = o.values[1]
                    if (!first && !second) return

                    diffElements.push(<tr>
                        <td className="w-50 border border-slate-700"> {o.key} </td>
                        <td className="min-w-50 border border-slate-700"> {o.values[0]} </td>
                        <td className="min-w-50 border border-slate-700"> {o.values[1]} </td>
                    </tr>)
                })

                setDiffElement(diffElements)
                open()
            })
            setData(data)
        })
    }

    useEffect(() => {
        getLogPegawai();
    }, [tanggal_mulai, tanggal_selesai])

    return <>
        <ContentHeader
            title={"Riwayat Edit " + pegawai?.name}
            additionalHeader={
                <>
                    <div className="flex gap-2">
                        <div className="flex gap-2">
                            <InputField type="date" placeholder=""
                                onChange={(e) => { setTanggalMulai(e) }}
                            />
                            <span className="flex flex-col justify-center">s/d</span>
                            <InputField type="date" placeholder=""
                                onChange={(e) => { setTanggalSelesai(e) }}
                            />
                        </div>
                    </div>
                </>

            }
        />

        <Modal opened={opened} onClose={close} title="" radius={"md"} centered>
            <div className={"text-4xl text-center"}>
                Detail Edit
            </div>
            <div className="flex items-center justify-center">
                <table className="table text-center">
                    <thead>
                        <td className="border border-slate-800">key</td>
                        <td className="border border-slate-800">before</td>
                        <td className="border border-slate-800">after</td>
                    </thead>
                    {diffElements}
                </table>
            </div>
        </Modal>

        <div className="flex flex-col py-[10px]">
            <div className={"flex justify-center items-center bg-white rounded-s mx-5 my-10 pb-4 !pt-0 !mt-0"} >
                <Table<Item> data={data} columns={columns} />
            </div>
        </div>
    </>
}
export default ListLogPegawai;
