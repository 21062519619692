import ApiRequest from "./ApiRequest";

interface IParamType {
    tanggal_mulai: string,
    tanggal_selesai: string,
    nama_pegawai?: string,

}

class AxiosListJatahCuti extends ApiRequest<IParamType> {
    constructor() {
        super("/jatah_cuti/list");
    }

    get(
        token: string,
        param: IParamType,
    ) {
        return this.get_request(token,
            { ...param }
        );
    }
}

export default AxiosListJatahCuti;
