import ApiRequest from "./ApiRequest";

interface IUploadFileParam {
  file: FormData;
  filename: string;
  id_pegawai: number;
  id_jenis_dokumen: number;
}

class AxiosCreateSPK extends ApiRequest<IUploadFileParam> {
  constructor() {
    super("/produksi/upload_spk_excell");
  }

  post(token: string, file: File) {
    const formData = new FormData();
    formData.append("spk", file, file.name);
    
    return this.post_request({
      token: token,
      formData: formData,
    });
  }
}

export default AxiosCreateSPK;
