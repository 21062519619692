/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import ContentHeader from "../../../components/ContentHeader";
import InputField from "../../../components/InputField";
import Table from "../../../components/Table";
import userAuth from "../../../context/UserAuth";
import { columns, Item } from "./BiayaTambahanTableDefinitions";
import clsx from "clsx";
import GajiPokokStyle from "./BiayaTambahan.module.css";
import { toast } from "react-toastify";
import PaginationLinks from "../../../components/PaginationLinks";
import DropdownField from "../../../components/DropDownField";
import { capitalizeEachWord, formatDate } from "../../../helpers/StringHelper";
import TOption from "../../../models/TOption";
import Button from "../../../components/Button";
import PopUpCard from "../../../components/PopUpCard";
import { Loader } from "@mantine/core";
import AxiosListAllPegawai from "../../../api/AxiosListAllPegawai";
import TPegawai from "../../../models/TPegawai";
import SearchField from "../../../components/SearchField";
import ConfirmationPopUpCard from "../../../components/ConfirmationPopUpCard";
import AxiosListCreatePegawaiOptions from "../../../api/AxiosGetCreatePegawaiOptions";
import AxiosGetListBiayaTambahan from "../../../api/AxiosGetListBiayaTambahan";
import AxiosCreateBiayaTambahan from "../../../api/AxiosCreateBiayaTambahan";
import AxiosDeleteBiayaTambahan from "../../../api/AxiosDeleteBiayaTambahan";

interface IBiayaTambahanProps { }
const BiayaTambahan: React.FC<IBiayaTambahanProps> = () => {
  const { user } = useContext(userAuth);
  const [dataBatch, setDataBatch] = useState<Item[]>([]);
  const [isPopUp, setIsPopUp] = useState(false);
  const [isPeringatan, setIsPeringatan] = useState(false);
  const [pegawaiOptions, setPegawaiOptions] = useState<TOption[]>([]);
  const [idPegawai, setIdPegawai] = useState("");
  const [formData, setFormData] = useState({
    id: "",
    nama: "",
    tanggal: "",
    tipe: "",
    keterangan: "",
    jumlah: 0,
  });
  const [pagination_links, setPaginationLinks] = useState([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [waktuMulai, setWaktuMulai] = useState("");
  const [waktuSelesai, setWaktuSelesai] = useState("");
  const [deleteBiayaTambahanId, setDeleteBiayaTambahanId] = useState<string | null>(
    null
  );

  function getListBiayaTambahan(
    waktuMulai?: string,
    waktuSelesai?: string,
    pagination_link?: string,
    nama?: string,
  ) {
    if (user.token == null) return;
    const axiosListBiayaTambahan = new AxiosGetListBiayaTambahan();

    axiosListBiayaTambahan
      .get(
        user.token,
        pagination_link,
        waktuMulai,
        waktuSelesai,
        nama,
        selectedJabatanId,
        selectedDepartemenId,
        selectedPerusahaanId
      )
      .then((response) => {
        const data_response = response.data.data;
        const updatedData = data_response.map((item: any) => ({
          ...item,
          id: item.id,
          nama_pegawai: capitalizeEachWord(item.nama),
          tipe: capitalizeEachWord(item.tipe),
          tanggal: formatDate(item.tanggal),
          jumlah: item.jumlah,
          deleteAction: () => showDeleteConfirmation(item.id),
        }));
        setDataBatch(updatedData);
        setPaginationLinks(response.data.data["links"]);
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
      });
  }

  function createBiayaTambahan() {
    if (!user.token || isLoading === true) return;
    setIsLoading(true);

    const axiosCreateBiayaTambahan = new AxiosCreateBiayaTambahan();
    axiosCreateBiayaTambahan
      .post(user.token, {
        id_pegawai: idPegawai,
        tipe: selectedTipe,
        keterangan: formData.keterangan,
        tanggal: formData.tanggal,
        jumlah: formData.jumlah.toString(),
      })
      .then((response) => {
        setIsPopUp(false);
        if (waktuMulai && waktuSelesai)
          getListBiayaTambahan(waktuMulai, waktuSelesai);

        const data = response.data;
        if (data.success) {
          toast.success("Biaya Tambahan berhasil ditambahkan!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setFormData({
            id: "",
            nama: "",
            tanggal: "",
            tipe: "",
            keterangan: "",
            jumlah: 0,
          });
          setIdPegawai("");
          setSelectedTipe("");
          setIsLoading(false);
        }
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422 || response.status === 400)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
      });
  }

  function deleteBiayaTambahan(id: string) {
    if (!user.token || isLoading === true) return;
    setIsLoading(true);

    const axiosDeleteBiayaTambahan = new AxiosDeleteBiayaTambahan();
    axiosDeleteBiayaTambahan
      .post(user.token, {
        id: id,
      })
      .then((response) => {
        if (waktuMulai && waktuSelesai)
          getListBiayaTambahan(waktuMulai, waktuSelesai);

        const data = response.data;
        if (data.success) {
          toast.success("Biaya Tambahan berhasil dihapus!", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setFormData({
            id: "",
            nama: "",
            tanggal: "",
            tipe: "",
            keterangan: "",
            jumlah: 0,
          });
          setIsLoading(false);
        }
      })
      .catch((e) => {
        const response = e.response;
        if (response.status === 422)
          toast.error(`${response.data.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        setIsLoading(false);
      });
  }

  const popUpClose = {
    onClose: () => {
      setIsPopUp(false);
      setIdPegawai("");
      setFormData({
        id: "",
        nama: "",
        tanggal: "",
        tipe: "",
        keterangan: "",
        jumlah: 0,
      });
    },
  };

  const showDeleteConfirmation = (id: string) => {
    setDeleteBiayaTambahanId(id);
    setIsPeringatan(true);
  };

  const confirmDeleteBiayaTambahan = () => {
    if (deleteBiayaTambahanId) {
      deleteBiayaTambahan(deleteBiayaTambahanId);
      setIsPeringatan(false);
    }
  };

  function getListPegawai() {
    if (!user?.token) return;
    const axiosListAllPegawai = new AxiosListAllPegawai();

    axiosListAllPegawai.get(user.token, {
      status: "aktif",
    }).then((response) => {
      const data: TPegawai[] = response.data.data;
      const options: TOption[] = [];
      data.forEach((item: TPegawai) => {
        options.push({
          value: item.id.toString(),
          label: capitalizeEachWord(item.nama),
        });
      });
      setPegawaiOptions(options);
    });
  }

  function onPaginationChange(link: string) {
    getListBiayaTambahan(
      waktuMulai,
      waktuSelesai,
      link,
      name,
    );
  }

  //filter
  const [selectedJabatanId, setSelectedJabatanId] = useState(0);
  const [selectedPerusahaanId, setSelectedPerusahaanId] = useState(0);
  const [selectedDepartemenId, setSelectedDepartemenId] = useState(0);

  const [jabatanOptions, setJabatanOptions] = useState([]);
  const [perusahaanOptions, setPerusahaanOptions] = useState([]);
  const [departemenOptions, setDepartemenOptions] = useState([]);

  useEffect(() => {
    getListPegawai();
    getOptions();
  }, []);

  function getOptions() {
    if (!user?.token) return;
    const axiosGetOptions = new AxiosListCreatePegawaiOptions();

    axiosGetOptions.get(user.token).then((response) => {
      const data = response.data.data;

      const jabatanData = data.jabatan_options.map((item: any) => ({
        value: item.id,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      setJabatanOptions(jabatanData);

      const perusahaanData = data.perusahaan_options.map((item: any) => ({
        value: item.id,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      setPerusahaanOptions(perusahaanData);

      const departemenData = data.departemen_options.map((item: any) => ({
        value: item.id,
        label: capitalizeEachWord(item.nama, "_"),
      }));
      setDepartemenOptions(departemenData);
    });

  }

  let timer: any;

  const [name, setName] = useState("");

  function onSearch(value: string) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setName(value);
      getListBiayaTambahan(
        waktuMulai,
        waktuSelesai,
        undefined,
        value,
      );
    }, 500);
  }

  useEffect(() => {
    if (!waktuMulai || !waktuSelesai) return;

    const timeoutId = setTimeout(() => {
      getListBiayaTambahan(
        waktuMulai,
        waktuSelesai,
        undefined,
        name,
      );
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [waktuMulai, waktuSelesai, name, selectedJabatanId, selectedDepartemenId, selectedPerusahaanId]);

  const [selectedTipe, setSelectedTipe] = useState("");
  const tipeOptions = [
    { value: "sewa_gudang", label: "Sewa Gudang" },
    { value: "sewa_kendaraan", label: "Sewa Kendaraan" },
    { value: "tambahan_borongan", label: "Tambahan Borongan" },
    { value: "tambahan_harian", label: "Tambahan Harian" },
    { value: "lain_lain", label: "Lain Lain" },
  ];

  return (
    <>
      <div className="mx-[40px]">
        <div className="flex justify-between mt-[40px] mb-2">
          <ContentHeader
            title="Biaya Tambahan"
            className="!p-0"
            additionalHeader={
              <>
                <div className="flex gap-2">
                  <span className="flex flex-col justify-center font-bold">
                    Periode
                  </span>
                  <InputField
                    type="date"
                    placeholder=""
                    onChange={(value) => setWaktuMulai(value)}
                  />
                  <span className="flex flex-col justify-center">s/d</span>
                  <InputField
                    type="date"
                    placeholder=""
                    onChange={(value) => setWaktuSelesai(value)}
                  />
                  <Button
                    text="Tambah Biaya Tambahan"
                    size="small"
                    onClick={() => setIsPopUp(true)}
                    className="!max-w-[200px] !max-h-[55px]"
                  />
                </div>
              </>
            }
          />
        </div>

        {isPeringatan && (
          <ConfirmationPopUpCard
            title={"Peringatan"}
            text="Apakah Anda yakin ingin menghapus data insentif ini?"
            onClose={() => setIsPeringatan(false)}
            onRightButtonClick={confirmDeleteBiayaTambahan}
            rightButtonText="Hapus"
          />
        )}

        {isPopUp ? (
          <PopUpCard {...popUpClose} title={"Tambah Biaya Tambahan"}>
            <DropdownField
              name="Nama Pegawai"
              id={""}
              placeholder=""
              value={idPegawai.toString()}
              title="Nama Pegawai"
              options={pegawaiOptions}
              onChange={(value) => setIdPegawai(value)}
              className="!border !py-[17px] !px-[31px] !rounded"
              isRequired={true}
            />

            <InputField
              type="date"
              placeholder="Masukkan tanggal"
              title="Tanggal"
              isRequired={true}
              onChange={(value) => setFormData({ ...formData, tanggal: value })}
              value={formData.tanggal}
              defaultValue={formData.tanggal}
            />
            <DropdownField
              name="Tipe"
              id="tipe"
              title="Tipe"
              placeholder=""
              options={tipeOptions}
              value={selectedTipe.toString()}
              onChange={(value) => setSelectedTipe(value)}
              className="!border !py-[17px] !px-[31px] !rounded"
              isRequired={true}
            />
            {/* <InputField
              type="text"
              placeholder="Masukkan tipe"
              title="Tipe"
              isRequired={true}
              onChange={(value) => setFormData({ ...formData, tipe: value })}
              value={formData.tipe}
              defaultValue={formData.tipe}
            /> */}
            <InputField
              type="text"
              placeholder="Masukkan keterangan"
              title="Keterangan"
              isRequired={true}
              onChange={(value) =>
                setFormData({ ...formData, keterangan: value })
              }
              value={formData.keterangan}
              defaultValue={formData.keterangan}
            />

            <InputField
              type="number"
              placeholder="Masukkan jumlah"
              title="Jumlah"
              isRequired={true}
              onChange={(value) =>
                setFormData({ ...formData, jumlah: Number(value) })
              }
              value={formData.jumlah.toString()}
            />

            <Button
              size="small"
              text={
                isLoading ? (
                  <Loader color="indigo" size="sm" />
                ) : (
                  "Tambahkan Biaya Tambahan"
                )
              }
              isDisabled={isLoading}
              className="h-[55px]"
              onClick={() => createBiayaTambahan()}
            />
          </PopUpCard>
        ) : (
          <> </>
        )}

        <div className=" mt-4">
          <div className="text-left font-bold">Filter</div>
          <div className="flex gap-2">
            <SearchField
              placeholder="Nama pegawai ..."
              onChange={(value) => onSearch(value)}
            />
            <DropdownField
              id="jabatan"
              name="jabatan"
              className="!border !py-[17px] !px-[31px] !rounded !min-w-[200px]"
              options={jabatanOptions}
              onChange={(value) => { setSelectedJabatanId(parseInt(value)); }}
              placeholder="Filter Jabatan"
              value={selectedJabatanId.toString()}
            />

            <DropdownField
              id="departemen"
              name="Departemen"
              className="!border !py-[17px] !px-[31px] !rounded !min-w-[200px]"
              options={departemenOptions}
              onChange={(value) => { setSelectedDepartemenId(parseInt(value)); }}
              placeholder="Filter Departemen"
              value={selectedDepartemenId.toString()}
            />
            <DropdownField
              id="perusahaan"
              name="Perusahaan"
              className="!border !py-[17px] !px-[31px] !rounded !min-w-[200px]"
              options={perusahaanOptions}
              onChange={(value) => { setSelectedPerusahaanId(parseInt(value)); }}
              placeholder="Filter Perusahaan"
              value={selectedPerusahaanId.toString()}
            />
          </div>
        </div>
        <div className="mx-[40px] mt-2">
          <PaginationLinks
            onPaginationClick={(link) => onPaginationChange(link)}
            pagination_links={pagination_links}
            className="!w-full"
          />
        </div>
        <div className={clsx(GajiPokokStyle.table_wrapper, "pb-4")}>
          <Table<Item> data={dataBatch} columns={columns} />
        </div>
        <div className="text-red-500 mt-2 mb-[40px] text-left">
          *Wajib memasukkan periode!
        </div>
      </div>
    </>
  );
};
export default BiayaTambahan;
