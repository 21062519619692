import ApiRequest from "./ApiRequest";

interface IParamType {
  id: string;
}

class AxiosGetDetailLembur extends ApiRequest<IParamType> {
  constructor() {
    super(`/lembur/get`);
  }

  public get(token: string, batch_param: IParamType) {
    return this.get_request(token, {
      ...batch_param,
    });
  }
}

export default AxiosGetDetailLembur;
