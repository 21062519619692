import { createColumnHelper } from "@tanstack/react-table";
import Button from "../../../components/Button";
import { ButtonTypeEnum } from "../../../enums/ButtonTypeEnum";
import { capitalizeEachWord } from "../../../helpers/StringHelper";

export type Item = {
  id: number;
  nama: string;
  kota: string;
  jumlah: number;
  jam_berangkat: string;
  jam_kembali: string;
  onEdit: (id_kiriman: number, nama: string) => void;
  onDelete: (id_kiriman: number) => void;
};

const columnHelper = createColumnHelper<Item>();
export const columns = (role: string) => [
  columnHelper.accessor((row) => row.id, {
    id: "id",
    cell: (info) => <>{info.getValue()}</>,
    header: () => <span>ID</span>,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor((row) => row.nama, {
    id: "tipe",
    cell: (info) => <>{capitalizeEachWord(info.getValue(), "_")}</>,
    header: () => <span>Nama</span>,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor((row) => row.kota, {
    id: "kota",
    cell: (info) => <>{info.getValue()}</>,
    header: () => <span>Kota</span>,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor((row) => row.jam_berangkat, {
    id: "jam_berangkat",
    cell: (info) => <>{info.getValue()}</>,
    header: () => <span>Berangkat</span>,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor((row) => row.jam_kembali, {
    id: "jam_kembali",
    cell: (info) => <>{info.getValue()}</>,
    header: () => <span>Kembali</span>,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor((row) => row, {
    id: "action",
    cell: (info) => {
      if (
        role === "super_admin" ||
        role === "hrd_admin" ||
        role === "hrd_kepala"
      ) return <>
        <div className="flex flex-col gap-2 w-40">
          <Button
            onClick={() => info.row.original.onEdit(
              info.row.original.id,
              info.row.original.nama,
            )}
            text="Edit"
            size="lite"
          />
          <Button
            onClick={() => info.row.original.onDelete(info.row.original.id)}
            text="Delete"
            size="lite"
            color="danger"
            buttonType={ButtonTypeEnum.CLICKABLE_TEXT}
          />
        </div>
      </>

      return <> </>
    },
    header: () => <span>Action</span>,
    footer: (info) => info.column.id,
  }),
];
