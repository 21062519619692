import { useContext, useEffect, useState } from "react";
import AxiosGetPresensiPegawai from "../../../api/AxiosGetPresensiPegawai";
import ContentHeader from "../../../components/ContentHeader";
import Table from "../../../components/Table";
import GeneralContext from "../../../context/generalContext";
import pegawaiContext from "../../../context/SelectedPegawai";
import userAuth from "../../../context/UserAuth";
import AbsensiDetailStyle from "./AbsensiDetail.module.css";
import { columns, Item } from "./AbsensiDetailTableDefinitions";

interface IAbsensiDetailProps {
}
const AbsensiDetail: React.FC<IAbsensiDetailProps> = () => {
    const { user } = useContext(userAuth)
    const { pegawai } = useContext(pegawaiContext)
    const { generalContext } = useContext(GeneralContext)
    const [data, setData] = useState<Item[]>([])
    function rekapPegawai() {
        if (
            !user?.token ||
            !pegawai?.id ||
            !generalContext?.waktu_mulai ||
            !generalContext?.waktu_selesai
        ) return
        const axiosGetPresensiPegawai = new AxiosGetPresensiPegawai();
        axiosGetPresensiPegawai.get(user.token,
            parseInt(pegawai.id),
            generalContext?.waktu_mulai,
            generalContext?.waktu_selesai,
        ).then(response => {
            const data = response.data.data
            setData(data)
        })
    }

    useEffect(() => {
        rekapPegawai()
    }, [])

    return <>
        <ContentHeader title={"Detail Absensi " + pegawai?.name} enableBack />
        <div className={AbsensiDetailStyle.table_wrapper}>
            <Table<Item>
                data={data}
                columns={columns}
            />
        </div>
    </>
}
export default AbsensiDetail;
