import React, { useRef, useEffect } from "react";
import closeIcon from "../vectors/closeIcon.svg";
import PopUpCardStyle from "./PopUpCard.module.css";
import clsx from "clsx";

interface IPopUpCardProps {
  title?: string;
  onClose: () => void;
  children?: React.ReactNode;
}

const PopUpCard: React.FC<IPopUpCardProps> = ({ title, onClose, children }) => {
  const cardRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  return (
    <div className={PopUpCardStyle.container}>
      <div
        ref={cardRef}
        className={clsx(
          PopUpCardStyle.card,
          "flex flex-col gap-4 !rounded-lg shadow-md"
        )}
      >
        <div className={clsx(PopUpCardStyle.header)}>
          <div className="w-[29px]"></div>
          <span className="font-bold text-2xl w-full">{title}</span>
          <img
            src={closeIcon}
            className={PopUpCardStyle.closeIcon}
            onClick={onClose}
            alt="Close"
          />
        </div>

        {children}
      </div>
    </div>
  );
};

export default PopUpCard;
