import { useEffect } from "react";
import { PopUpEnum } from "../enums/PopUpEnum";
import TimeoutPopUpStyle from "./TimeoutPopUp.module.css"
import greenCheck from "../vectors/greenCheck.svg"
import errorExclamation from "../vectors/errorExclamation.svg"
import { motion } from "framer-motion";

export interface ITimeoutPopupProps {
    isEnabled: boolean;
    onTimeout: () => void;
    timeout: number;
    popupType: PopUpEnum;
    message: string;
}

export const defaultPopUpProps: ITimeoutPopupProps = {
    isEnabled: false,
    timeout: 2000,
    popupType: PopUpEnum.SUCCESS,
    message: "success",
    onTimeout: () => console.log("please change timeout via state")
}

const TimeoutPopup: React.FC<ITimeoutPopupProps> = (props: ITimeoutPopupProps) => {
    useEffect(() => {
        if (props.isEnabled)
            setTimeout(() => {
                props.onTimeout()
            }, props.timeout)
    }, [props.isEnabled])

    function chooseIcon() {
        switch (props.popupType) {
            case PopUpEnum.ERROR:
                return errorExclamation;
            case PopUpEnum.SUCCESS:
            default:
                return greenCheck;
        }
    }
    return <>
        {props.isEnabled ?
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: .3 }}
                exit={{ opacity: 0 }}

                className={TimeoutPopUpStyle.container}>
                <div className={TimeoutPopUpStyle.card}>
                    <img
                        alt={"check"}
                        src={chooseIcon()}
                        className={TimeoutPopUpStyle.icon}
                    />
                    <div className={TimeoutPopUpStyle.message}>
                        {props.message}
                    </div>
                </div>
            </motion.div> :
            <></>}
    </>
}
export default TimeoutPopup;
