import { createColumnHelper } from "@tanstack/react-table";
import Button from "../../../components/Button";

export type Item = {
  id: number;
  waktu_mulai: string;
  waktu_selesai: string;
  pekerjaan: string;
  action: () => void;
  deleteAction: () => void;
};

const columnHelper = createColumnHelper<Item>();
export const columns = (role: string) => [
  // columnHelper.accessor("id", {
  //   header: () => "ID",
  //   cell: (info) => info.getValue(),
  //   footer: (info) => info.column.id,
  // }),
  {
    id: "rowIndex",
    header: "No",
    cell: (info: any) => info.row.index + 1,
  },
  columnHelper.accessor("waktu_mulai", {
    header: () => "Waktu Mulai",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("waktu_selesai", {
    header: () => "Waktu Selesai",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("pekerjaan", {
    header: () => "Pekerjaan",
    cell: (info) => info.getValue(),
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor("id", {
    header: () => "Action",
    cell: (info) => (
      <div className="flex gap-2">
        <Button
          size="small"
          text="Detail"
          onClick={() => info.row.original.action()}
        />
        {(role === "super_admin" ||
          role === "produksi_kepala" ||
          role === "gudang_kepala" ||
          role === "hrd_kepala" ||
          role === "hrd_admin"
        ) ? <Button
          size="small"
          text="Hapus"
          onClick={() => info.row.original.deleteAction()}
        /> : <> </>
        }
      </div>
    ),
    footer: (info) => info.column.id,
  }),
];
