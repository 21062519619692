import React from "react";
import TPsikologi from "../../../models/TPsikologi"; // Import the TPsikologi type
import ListForm from "../ListForm";

interface IPsikologiListFormProps {
  onPsikologiListChange: (value: TPsikologi[]) => void;
  defaultPsikologi: TPsikologi[];
}

const PsikologiListForm: React.FC<IPsikologiListFormProps> = (
  props: IPsikologiListFormProps
) => {
  return (
    <>
      <ListForm<TPsikologi>
        onListChange={(list) => {
          props.onPsikologiListChange(list);
        }}
        initialList={props.defaultPsikologi}
        defaultCreateItem={{
          keperluan: "",
          dimana: "",
          tahun: undefined,
          id: Date.now(),
        }}
        listTitle="Detil psikologi"
        addButtonText="Tambah detil psikologi"
        listField={[
          {
            label: "Keperluan",
            key: "keperluan",
            type: "text",
            placeholder: "Jenis Urusan",
            onChange: () => {},
          },
          {
            label: "Dimana",
            key: "dimana",
            type: "text",
            placeholder: "Tempat Terjadi Urusan",
            onChange: () => {},
          },
          {
            label: "Tahun",
            key: "tahun",
            type: "number",
            placeholder: "Tahun Urusan",
            onChange: () => {},
          },
        ]}
      />
    </>
  );
};

export default PsikologiListForm;
