import React from "react";
import { get17YearsBack } from "../helpers/DateHelper";
import InputStyle from "./InputField.module.css";
import clsx from "clsx";

interface IInputFieldProps {
  type: string;
  placeholder?: string;
  onChange: (value: string) => void;
  defaultValue?: any;
  size?: number;
  isLite?: boolean;
  isDateLimited?: boolean;
  min?: number | string;
  max?: number | string;
  step?: string;
  pattern?: any;
  title?: string;
  className?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  value?: string;
}

class InputField extends React.Component<IInputFieldProps> {
  fileInputId = `file-input-${Math.random().toString(36).substr(2, 9)}`;
  fileName = "";

  render(): React.ReactNode {
    let max_date;
    if (this.props.type === "date" && this.props.isDateLimited) {
      max_date = get17YearsBack();
    }

    const isFileInput = this.props.type === "file";
    const inputClassName = clsx(
      InputStyle.input,
      {
        [InputStyle.fileInput]: isFileInput,
        [InputStyle.lite]: this.props.isLite,
      },
      this.props.className
    );

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      this.props.onChange(e.target.value);

      if (e.target.files && e.target.files.length > 0) {
        this.fileName = e.target.files[0].name;
        if (this.fileName.length > 27) {
          this.fileName = `${this.fileName.substr(0, 24)}...`;
        }
        this.forceUpdate();
      }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      if (value === "") {
        this.props.onChange(value);
        return;
      }
      if (this.props.type === "number" && !/^-?\d*$/.test(value)) {
        return;
      }
      this.props.onChange(e.target.value);
    };

    return (
      <div className="flex flex-col gap-1 w-full">
        {this.props.title ? (
          <div className="text-left">
            <span>{this.props.title}</span>
            {this.props.isRequired ? (
              <span className="text-red-500">*</span>
            ) : null}
          </div>
        ) : this.props.isRequired ? (
          <span className="text-red-500">*</span>
        ) : null}

        {isFileInput && (
          <label
            htmlFor={this.fileInputId}
            className={InputStyle.fileInputLabel}
          >
            {this.fileName || "Upload file excel/csv"}
          </label>
        )}
        <input
          id={isFileInput ? this.fileInputId : undefined}
          type={this.props.type}
          className={clsx(
            inputClassName,
            `!rounded ${this.props.isDisabled
              ? "!bg-gray-300 cursor-not-allowed border"
              : "border"
            }`
          )}
          onChange={isFileInput ? handleFileChange : handleInputChange}
          max={this.props.type === "date" ? max_date : this.props.max}
          placeholder={
            this.props.type === "date" ? "MM/DD/YYYY" : this.props.placeholder
          }
          disabled={this.props.isDisabled}
          value={this.props.value}
          defaultValue={this.props.defaultValue}
          size={this.props.size}
          step={this.props.step}
          pattern={this.props.pattern}
          style={isFileInput ? { display: "none" } : {}}
        />
      </div>
    );
  }
}

export default InputField;
