
import ApiRequest from "./ApiRequest";

interface ICreateUser {
  nama: string,
  email: string,
  password: string,
  id_role: number,
  id_perusahaan: number,
}

class AxiosCreateUser extends ApiRequest<ICreateUser> {
  constructor() {
    super("/create_user");
  }

  post(token: string, departemen_data: ICreateUser) {
    return this.post_request({
      token: token,
      params: { ...departemen_data }
    });
  }
}

export default AxiosCreateUser;
